import { userPermission } from "../../../../utils/userInfo";

export const modifyOptions = (page, status, user) => {
  const options = [
    {
      value: "ยกเลิก",
      disabled: userPermission(user, "purchase", page, "cancel"),
    },
    {
      value: "คัดลอก",
      disabled: userPermission(user, "purchase", page, "create"),
    },
    {
      value: "แก้ไข",
      disabled: userPermission(user, "purchase", page, "edit"),
    },
  ];

  const extraOption = [
    {
      value: "บันทึกใบบันทึกซื้อ",
      disabled: userPermission(user, "purchase", page, "edit"),
    },
    {
      value: "บันทึกใบเพิ่มหนี้",
      disabled: userPermission(user, "purchase", page, "edit"),
    },
  ];

  if (!status) {
    return options;
  }
  switch (page) {
    case "order":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "waitApprove":
          return options;
        case "notApproved":
          return [options[0], options[1]];
        case "approved":
          return [extraOption[0], ...options];
        case "partiallyImported":
        case "fullyImported":
          return [extraOption[0], options[1]];
        case "cancelled":
          return [options[1]];
        default:
          return [];
      }
    case "return":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "waitApprove":
          return options;
        case "notApproved":
          return [options[0], options[1]];
        case "approved":
          return [extraOption[1], options[0], options[1]];
        case "finished":
          return [options[1]];
        case "cancelled":
          return [options[1]];
        default:
          break;
      }
      break;
    default:
      return options;
  }
};
