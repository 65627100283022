import { v4 as uuidv4 } from "uuid";

export const departmentType = [
  {
    id: uuidv4(),
    label: "บริหาร",
    value: "บริหาร",
  },
  {
    id: uuidv4(),
    label: "คลังสินค้า",
    value: "คลังสินค้า",
  },
  {
    id: uuidv4(),
    label: "รายรับ",
    value: "รายรับ",
  },
  {
    id: uuidv4(),
    label: "จัดซื้อ",
    value: "จัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "บัญชี",
    value: "บัญชี",
  },
  {
    id: uuidv4(),
    label: "ขนส่ง",
    value: "ขนส่ง",
  },
  {
    id: uuidv4(),
    label: "ผลิต",
    value: "ผลิต",
  },
  {
    id: uuidv4(),
    label: "ดูแลระบบ",
    value: "ดูแลระบบ",
  },
];

export const positionType = [
  {
    id: uuidv4(),
    label: "กรรมการผู้จัดการ",
    value: "กรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    label: "ผู้บริหาร",
    value: "ผู้บริหาร",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการผ่ายผลิต",
    value: "ผู้จัดการผ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
    value: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
  },
  {
    id: uuidv4(),
    label: "พนักงานฝ่ายผลิต",
    value: "พนักงานฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการฝ่ายขาย",
    value: "ผู้จัดการฝ่ายขาย",
  },
  {
    id: uuidv4(),
    label: "เซลล์",
    value: "เซลล์",
  },
  {
    id: uuidv4(),
    label: "เซลล์แอดมิน",
    value: "เซลล์แอดมิน",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าจัดซื้อ",
    value: "หัวหน้าจัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "จัดซื้อ",
    value: "จัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าฝ่ายสินเชื่อ",
    value: "หัวหน้าฝ่ายสินเชื่อ",
  },
  {
    id: uuidv4(),
    label: "บัญชีลูกหนี้",
    value: "บัญชีลูกหนี้",
  },

  {
    id: uuidv4(),
    label: "บัญชีคลัง",
    value: "บัญชีคลัง",
  },
  {
    id: uuidv4(),
    label: "บัญชี",
    value: "บัญชี",
  },
  {
    id: uuidv4(),
    label: "พนักงานคลังสโตร์",
    value: "พนักงานคลังสโตร์",
  },
  {
    id: uuidv4(),
    label: "ธุรการคลัง",
    value: "ธุรการคลัง",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าจัดส่ง",
    value: "หัวหน้าจัดส่ง",
  },
  {
    id: uuidv4(),
    label: "ผู้ดูแลระบบ",
    value: "ผู้ดูแลระบบ",
  },
];

export const permissionAction = () => {
  return [
    "ADMIN__ADMIN__ADMIN",
    "INVENTORY__ITEM_MASTER__VIEW",
    "INVENTORY__ITEM_MASTER__CREATE",
    "INVENTORY__ITEM_MASTER__EDIT",
    "INVENTORY__ITEM_MASTER__DELETE",
    "INVENTORY__RECEIVE__VIEW",
    "INVENTORY__RECEIVE__CREATE",
    "INVENTORY__RECEIVE__EDIT",
    "INVENTORY__RECEIVE__CANCEL",
    "INVENTORY__RETURN__VIEW",
    "INVENTORY__RETURN__CREATE",
    "INVENTORY__ISSUE__VIEW",
    "INVENTORY__ISSUE__CREATE",
    "INVENTORY__TRANSFER__VIEW",
    "INVENTORY__TRANSFER__CREATE",
    "INVENTORY__ADJUSTMENT__VIEW",
    "INVENTORY__ADJUSTMENT__CREATE",
    "INVENTORY__REPORT__VIEW",
    "SALES__QUOTATION__VIEW",
    "SALES__QUOTATION__CREATE",
    "SALES__QUOTATION__EDIT",
    "SALES__QUOTATION__CANCEL",
    "SALES__ORDER__VIEW",
    "SALES__ORDER__CREATE",
    "SALES__ORDER__EDIT",
    "SALES__ORDER__CANCEL",
    "SALES__RETURN__VIEW",
    "SALES__RETURN__CREATE",
    "SALES__RETURN__EDIT",
    "SALES__RETURN__CANCEL",
    "SALES__REPORT__VIEW",
    "PURCHASE__REQUEST__VIEW",
    "PURCHASE__REQUEST__VIEW_SELF",
    "PURCHASE__REQUEST__CREATE",
    "PURCHASE__REQUEST__EDIT",
    "PURCHASE__REQUEST__CANCEL",
    "PURCHASE__ORDER__VIEW",
    "PURCHASE__ORDER__CREATE",
    "PURCHASE__ORDER__EDIT",
    "PURCHASE__ORDER__CANCEL",
    "PURCHASE__RETURN__VIEW",
    "PURCHASE__RETURN__CREATE",
    "PURCHASE__RETURN__EDIT",
    "PURCHASE__RETURN__CANCEL",
    "PURCHASE__REPORT__VIEW",
    "LOGISTIC__DELIVERY_ORDER__VIEW",
    "LOGISTIC__DELIVERY_ORDER__CREATE",
    "LOGISTIC__DELIVERY_ORDER__EDIT",
    "LOGISTIC__DELIVERY_ORDER__CANCEL",
    "LOGISTIC__DELIVERY_TRIP__VIEW",
    "LOGISTIC__DELIVERY_TRIP__CREATE",
    "LOGISTIC__DELIVERY_TRIP__EDIT",
    "LOGISTIC__DELIVERY_TRIP__CANCEL",
    "LOGISTIC__REPORT__VIEW",
    "MANUFACTURE__BOM__VIEW",
    "MANUFACTURE__BOM__CREATE",
    "MANUFACTURE__BOM__EDIT",
    "MANUFACTURE__BOM__DELETE",
    "MANUFACTURE__ORDER__VIEW",
    "MANUFACTURE__ORDER__CREATE",
    "MANUFACTURE__ORDER__EDIT",
    "MANUFACTURE__ORDER__CANCEL",
    "MANUFACTURE__WORK_ORDER__VIEW",
    "MANUFACTURE__WORK_ORDER__CREATE",
    "MANUFACTURE__WORK_ORDER__EDIT",
    "MANUFACTURE__WORK_ORDER__CANCEL",
    "MANUFACTURE__REPORT__VIEW",
    "USER__ACCOUNT__VIEW",
    "USER__ACCOUNT__CREATE",
    "USER__ACCOUNT__EDIT",
    "USER__ACCOUNT__DELETE",
    "USER__RBAC__VIEW",
    "USER__RBAC__CREATE",
    "USER__RBAC__EDIT",
    "USER__RBAC__DELETE",
    "CONFIG__COMPANY_INFO__VIEW",
    "CONFIG__COMPANY_INFO__EDIT",
    "CONFIG__APPROVAL__VIEW",
    "CONFIG__APPROVAL__CREATE",
    "CONFIG__APPROVAL__EDIT",
    "CONFIG__SALES_REMARK__VIEW",
    "CONFIG__SALES_REMARK__CREATE",
    "CONFIG__SALES_REMARK__EDIT",
    "CONFIG__PURCHASE_REMARK__VIEW",
    "CONFIG__PURCHASE_REMARK__CREATE",
    "CONFIG__PURCHASE_REMARK__EDIT",
    "CONFIG__LOGISTIC_REMARK__VIEW",
    "CONFIG__LOGISTIC_REMARK__CREATE",
    "CONFIG__LOGISTIC_REMARK__EDIT",
    "CONFIG__MANUFACTURE_REMARK__VIEW",
    "CONFIG__MANUFACTURE_REMARK__CREATE",
    "CONFIG__MANUFACTURE_REMARK__EDIT",
    "CONFIG__LOCATION__VIEW",
    "CONFIG__LOCATION__CREATE",
    "CONFIG__LOCATION__EDIT",
    "CONFIG__CATEGORY__VIEW",
    "CONFIG__CATEGORY__CREATE",
    "CONFIG__CATEGORY__EDIT",
    "CONFIG__UOM__VIEW",
    "CONFIG__UOM__CREATE",
    "CONFIG__UOM__EDIT",
    "CONFIG__ATTRIBUTE__VIEW",
    "CONFIG__ATTRIBUTE__CREATE",
    "CONFIG__ATTRIBUTE__EDIT",
    "CONFIG__MANUFACTURE__VIEW",
    "CONFIG__MANUFACTURE__CREATE",
    "CONFIG__MANUFACTURE__EDIT",
    "CONFIG__LOGISTIC__VIEW",
    "CONFIG__LOGISTIC__CREATE",
    "CONFIG__LOGISTIC__EDIT",
    "CONTACT__ALL__VIEW",
    "CONTACT__ALL__CREATE",
    "CONTACT__ALL__EDIT",
    "CONTACT__ALL__DELETE",
    "CONTACT__CUSTOMER__EDIT",
    "CONTACT__CUSTOMER__DELETE",
    "CONTACT__VENDOR__EDIT",
    "CONTACT__VENDOR__DELETE",
    "CONTACT__REPORT__VIEW",
  ];
};

export const userPermission = (user, module, doc, type) => {
  let disabled = false;
  let allPermissionList = [];

  user?.role_list?.forEach((role) =>
    allPermissionList.push(...role?.permission_list)
  );

  const mapModule = (module) => {
    switch (module) {
      case "setting":
        return "config";
      default:
        return module;
    }
  };

  const actionPermission = allPermissionList
    .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
    .filter(
      (list) => list.split("__")[1] === doc.replace("-", "_").toUpperCase()
    )
    .map((list) => list.split("__")[2]);

  disabled =
    !allPermissionList.includes("ADMIN__ADMIN__ADMIN") &&
    !actionPermission.includes(type.toUpperCase());

  return disabled;
};

export const userHavePermission = (user, module, doc, type) => {
  let permission = false;
  let allPermissionList = [];

  user?.role_list?.forEach((role) =>
    allPermissionList.push(...role?.permission_list)
  );

  const mapModule = (module) => {
    switch (module) {
      case "setting":
        return "config";
      default:
        return module;
    }
  };

  const actionPermission = allPermissionList
    .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
    .filter(
      (list) => list.split("__")[1] === doc.replace("-", "_").toUpperCase()
    )
    .map((list) => list.split("__")[2]);

  permission =
    allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
    actionPermission.includes(type.toUpperCase());

  return permission;
};
