import React from "react";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../../Controlled/ControlledSelect";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { useFieldArray, useWatch } from "react-hook-form";
import CustomizedChips from "../../../Custom/CustomizedChips";

const deliveryOptions = [
  {
    id: 1,
    label: "รับเอง",
    value: "รับเอง",
  },
  {
    id: 2,
    label: "บริษัทจัดส่งหน้างาน",
    value: "บริษัทจัดส่งหน้างาน",
  },
  {
    id: 3,
    label: "บริษัทจัดส่งโดยขนส่งเอกชน",
    value: "บริษัทจัดส่งโดยขนส่งเอกชน",
  },
];

const scaffoldOptions = [
  {
    id: 1,
    label: "ผู้ว่าจ้าง",
    value: "ผู้ว่าจ้าง",
  },
  {
    id: 2,
    label: "ผู้รับจ้าง",
    value: "ผู้รับจ้าง",
  },
];

const deliveryCartageMethod = [
  {
    id: 1,
    label: "กระเช้า",
    value: "กระเช้า",
  },
  {
    id: 2,
    label: "ลิฟท์",
    value: "ลิฟท์",
  },
  {
    id: 3,
    label: "เครน",
    value: "เครน",
  },
  {
    id: 4,
    label: "คนงานยก",
    value: "คนงานยก",
  },
];
const InstallationForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();

  const watchDeliveryMethod = useWatch({
    control,
    name: "delivery_method",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "delivery_cartage_method",
  });

  const addCartageMethodHandler = (value) => {
    if (value) {
      const methods = fields.map((method) => method.label);
      if (!methods.includes(value.label)) {
        append(value);
      }
    }
  };

  const deleteCartageMethodHandler = (index) => {
    remove(index);
  };

  return (
    <CustomizedBox>
      <Typography ml={1} fontWeight="bold">
        {t("engineer.estimate.installation_detail")}
      </Typography>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <ControlledSelect
              name="delivery_method"
              control={control}
              error={errors.delivery_method}
              label={t("engineer.estimate.delivery_method")}
              disabled={disabled}
              options={deliveryOptions}
            />
          </Grid>
          {watchDeliveryMethod === "บริษัทจัดส่งหน้างาน" && (
            <>
              <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                <ControlledTextField
                  control={control}
                  name="delivery_count"
                  error={errors.delivery_count}
                  label={t("engineer.estimate.delivery_count")}
                  disabled={disabled}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
                <ControlledTextField
                  control={control}
                  name="delivery_floor"
                  error={errors.delivery_floor}
                  label={t("engineer.estimate.delivery_floor")}
                  disabled={disabled}
                  type="number"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <ControlledSelect
              name="delivery_scaffold"
              control={control}
              error={errors.delivery_scaffold}
              label={t("engineer.estimate.delivery_scaffold")}
              disabled={disabled}
              options={scaffoldOptions}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography ml={1} my={2} fontWeight="bold">
        {t("engineer.estimate.delivery_cartage_method")}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <CustomizedComboBox
            options={deliveryCartageMethod}
            onChange={(_, newValue) => {
              addCartageMethodHandler(newValue);
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
          <Box display="flex" gap={1}>
            {fields.map((field, index) => (
              <CustomizedChips
                key={field.id}
                value={field.label}
                onDelete={
                  disabled ? null : () => deleteCartageMethodHandler(index)
                }
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default InstallationForm;
