import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ItemInfo from "./ItemInfo";
import Transaction from "./Transaction";
import OnHandItemWarehouse from "./OnHandItemWarehouse";
import {
  deleteItem,
  getItem,
} from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { useSnackbar } from "notistack";
import { itemMasterActions } from "../../../features/Inventory/ItemMaster/itemMaster-slice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./schema";
import { formatVatOption } from "../../../utils/dataTransformer";
import HistoryIcon from "@mui/icons-material/History";
import RightDrawer from "../../../components/UI/RightDrawer";
import {
  getAllUom,
  getAllUomGroup,
} from "../../../features/Setting/Uom/uom-actions";
import { getActivityLogsById } from "../../../features/ActivityLogs/activityLogs-actions";
import { getAllCategory } from "../../../features/Setting/Category/category-actions";
import GlobalService from "../../../services/Global";
import { getAllAttribute } from "../../../features/Setting/Attribute/attributes-actions";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import DeleteItemConfirmation from "../../../components/UI/Confirmation/DeleteItemConfirmation";
import { usePermission } from "../../../hooks/use-auth";

const ItemMasterContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { item, isLoading } = useSelector((state) => state.itemMaster);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const { editPermission, deletePermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: Boolean(id) ? item.name : t("inventory.items.addItem"),
    },
  ];

  const tabs = [
    {
      label: t("inventory.items.itemInfomation"),
      path: `${pathname}?tab=item&subtab=general`,
    },
    {
      label: t("inventory.items.onHandItemWarehouseTab"),
      path: `${pathname}?tab=onhand`,
      disabled: !Boolean(id) || !item.is_stockable,
    },
    {
      label: t("inventory.items.transaction"),
      path: `${pathname}?tab=transaction`,
      disabled: !Boolean(id) || !item.is_stockable,
    },
  ];

  const currentTab =
    pathname +
    (tab ? `?tab=${tab}` : "") +
    (tab === "item" ? "&subtab=general" : "");

  const findInput = useMemo(() => {
    return {
      findManyInput: {
        include: {
          sub_level_2_list: {
            include: {
              sub_level_3_list: null,
            },
          },
        },
      },
    };
  }, []);

  const getID = useCallback(async () => {
    const itemID = await GlobalService.getRunningDocumentId("item");
    setValue("document_id", itemID);
  }, [setValue]);

  useEffect(() => {
    //get data of the selected item
    dispatch(getAllUom());
    dispatch(getAllCategory(findInput));
    dispatch(getAllUomGroup());
    dispatch(getAllAttribute());
    if (!id) {
      getID();
    } else if (id) {
      dispatch(getItem({ document_id: id }, enqueueSnackbar));
      dispatch(getActivityLogsById({ documentId: id }));
    }
    return () => dispatch(itemMasterActions.resetItem());
  }, [dispatch, enqueueSnackbar, id, findInput, getID]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (item.docement_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        //formating some fields before using
        if (key === "sales_vat_type") setValue(key, formatVatOption(value));
        else if (key === "purchase_vat_type")
          setValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setValue(
            key,
            value.map((item) => {
              return {
                ...item,
                ...item.attribute,
              };
            })
          );
        else if (key === "uom_group") {
          setValue(key, value);
          setValue("uom_conversion_list", value.uom_conversion_list);
        }
        // else if (key === "main_bom" && value === null) {
        //   setValue(key, { id: "", name: "" });
        // }
        else setValue(key, value);
      });
    }
  }, [item, setValue]);

  const renderTab = () => {
    switch (tab) {
      case "transaction":
        return <Transaction />;
      case "onhand":
        return <OnHandItemWarehouse />;
      default:
        return (
          <ItemInfo
            control={control}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            reset={reset}
            setValue={setValue}
            viewOnly={id && !editPermission}
            getID={getID}
            isInventoryPage
          />
        );
    }
  };

  const handleOpenModalDelete = () => {
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setModalDelete(false);
  };

  const handleDeleteItem = () => {
    dispatch(deleteItem(item.document_id, enqueueSnackbar, navigate, t));
    handleCloseModalDelete();
  };

  return (
    <>
      {!isLoading.item && (
        <>
          {id && (
            <DeleteItemConfirmation
              openDeleteItemConfirmation={modalDelete}
              closeDeleteItemConfirmationHandler={handleCloseModalDelete}
              deleteItemConfirmationAction={handleDeleteItem}
              title={"ยืนยันหากต้องการลบสินค้า " + item.name}
              width={620}
            />
          )}
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{item.name}</Typography>
            <Box
              sx={{
                display: "flex",
              }}
              gap={1}
            >
              {id && (
                <CustomizedMenuOption
                  label="ตัวเลือก"
                  options={[
                    {
                      value: "ลบ",
                      disabled: !deletePermission,
                    },
                  ]}
                  size="medium"
                  onSelect={handleOpenModalDelete}
                />
              )}
              <CustomizedTooltip title="ดูการเคลื่อนไหว">
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Box>
          {renderTab()}
          <RightDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            title={t("inventory.activity")}
            documentId={id}
            documentType="item"
          />
        </>
      )}
    </>
  );
};

export default ItemMasterContainer;
