import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../../Custom/CustomizedBox";

const RemarkForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("engineer.estimate.remark")}
      </Typography>
      <ControlledTextField
        name="remark"
        control={control}
        error={errors?.remark}
        disabled={disabled}
        rows={4}
        multiline
      />
    </CustomizedBox>
  );
};

export default RemarkForm;
