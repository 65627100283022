import { graphQLClientWithHeader } from "../graphQL";
import {
  CREATE_APPROVAL_TEMPLATE,
  CREATE_ATTRUBUTE,
  CREATE_BIN_LOCATION,
  CREATE_COMPANY_SETTING,
  CREATE_ITEM_GROUPS_WITH_SUB_LEVELS,
  CREATE_ITEM_GROUP_LV1,
  CREATE_ITEM_GROUP_LV2,
  CREATE_ITEM_GROUP_LV3,
  CREATE_REMARK_TEMPLATE,
  CREATE_UOM,
  CREATE_UOM_GROUP,
  CREATE_UOM_GROUPS,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_LV1,
  CREATE_WAREHOUSE_LV2,
  CREATE_WAREHOUSE_LV3,
  CREATE_WAREHOUSE_WITH_SUB_LEVELS,
  DELETE_APPROVAL_TEMPLATE,
  DELETE_APPROVAL_TEMPLATE_WITH_NEXT_STEP,
  DELETE_ATTRUBUTE,
  DELETE_BIN_LOCATION,
  DELETE_ITEM_GROUP_LV1,
  DELETE_ITEM_GROUP_LV2,
  DELETE_ITEM_GROUP_LV3,
  DELETE_UOM,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSE_LV1,
  DELETE_WAREHOUSE_LV2,
  DELETE_WAREHOUSE_LV3,
  REMOVE_REMARK_TEMPLATE,
  UPDATE_APPROVAL_TEMPLATE,
  UPDATE_ATTRUBUTE,
  UPDATE_BIN_LOCATION,
  UPDATE_COMPANY_SETTING,
  UPDATE_ITEM_GROUP_LV1,
  UPDATE_ITEM_GROUP_LV2,
  UPDATE_ITEM_GROUP_LV3,
  UPDATE_REMARK_TEMPLATE,
  UPDATE_UOM,
  UPDATE_UOM_GROUP,
  UPDATE_WAREHOUSE,
  UPDATE_WAREHOUSE_LV1,
  UPDATE_WAREHOUSE_LV2,
  UPDATE_WAREHOUSE_LV3,
} from "./mutation";
import {
  GET_ALL_ATTRIBUTES,
  GET_ALL_CATEGORY,
  GET_ALL_LOCATION,
  GET_ALL_UOM,
  GET_ALL_UOM_GROUP,
  GET_APPROVAL_TEMPLATES_BY_DOCUMENT_TYPE,
  GET_ATTRIBUTE_BY_ID,
  GET_COMPANY_SETTING,
  GET_COMPANY_SETTINGS,
  GET_REMARK_TEMPLATES,
  GET_UOM,
  GET_UOM_GROUP,
  GET_WAREHOUSE,
} from "./queries";

class SettingService {
  async getAllUom(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uoms } = await graphQLClient.request(GET_ALL_UOM, input);
    return uoms;
  }
  async getUom(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uom } = await graphQLClient.request(GET_UOM, input);
    return uom;
  }
  async getUomGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroup } = await graphQLClient.request(GET_UOM_GROUP, input);
    return uomGroup;
  }
  async getAllUomGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroups } = await graphQLClient.request(GET_ALL_UOM_GROUP, input);
    return uomGroups;
  }
  async getWarehouse(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(GET_WAREHOUSE, {
      uniqueInput,
    });
    return warehouse;
  }
  async getAllLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouses } = await graphQLClient.request(GET_ALL_LOCATION, input);
    return warehouses;
  }
  async getAllCategory(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel1s } = await graphQLClient.request(
      GET_ALL_CATEGORY,
      input
    );
    return itemGroupSubLevel1s;
  }
  async getAllAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributes } = await graphQLClient.request(
      GET_ALL_ATTRIBUTES,
      input
    );
    return attributes;
  }
  async getAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attribute } = await graphQLClient.request(
      GET_ATTRIBUTE_BY_ID,
      input
    );
    return attribute;
  }
  async postCreateAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeCreate } = await graphQLClient.request(
      CREATE_ATTRUBUTE,
      input
    );
    return attributeCreate;
  }
  async postUpdateAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeUpdate } = await graphQLClient.request(
      UPDATE_ATTRUBUTE,
      input
    );
    return attributeUpdate;
  }
  async postDeleteAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeDelete } = await graphQLClient.request(
      DELETE_ATTRUBUTE,
      input
    );
    return attributeDelete;
  }
  async postCreateUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomCreate } = await graphQLClient.request(CREATE_UOM, input);
    return uomCreate;
  }
  async postUpdateUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomUpdate } = await graphQLClient.request(UPDATE_UOM, input);
    return uomUpdate;
  }
  async postDeleteUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomDelete } = await graphQLClient.request(DELETE_UOM, input);
    return uomDelete;
  }
  async postCreateUOMGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupCreate } = await graphQLClient.request(
      CREATE_UOM_GROUP,
      input
    );
    return uomGroupCreate;
  }
  async postCreateUOMGroups(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupCreateMany } = await graphQLClient.request(
      CREATE_UOM_GROUPS,
      createManyInput
    );
    return uomGroupCreateMany;
  }
  async postUpdateUOMGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupUpdate } = await graphQLClient.request(
      UPDATE_UOM_GROUP,
      input
    );
    return uomGroupUpdate;
  }
  async postCreateWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouseCreate } = await graphQLClient.request(
      CREATE_WAREHOUSE,
      input
    );
    return warehouseCreate;
  }
  async postUpdateWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouseUpdate } = await graphQLClient.request(
      UPDATE_WAREHOUSE,
      input
    );
    return warehouseUpdate;
  }
  async postDeleteWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(DELETE_WAREHOUSE, input);
    return warehouse;
  }
  async postCreateWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postDeleteWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postCreateWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postDeleteWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postCreateWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postCreateWarehouseWithSubLevels(createManyInput) {
    console.log(createManyInput);
    const graphQLClient = graphQLClientWithHeader();
    const { warehouseCreateManyWithSubLevels } = await graphQLClient.request(
      CREATE_WAREHOUSE_WITH_SUB_LEVELS,
      createManyInput
    );
    return warehouseCreateManyWithSubLevels;
  }
  async postDeleteWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postCreateBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postUpdateBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postDeleteBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postCreateItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV1,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV1,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel1Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV1,
      input
    );
    return itemGroupSubLevel1Delete;
  }
  async postCreateItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV2,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV2,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel2Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV2,
      input
    );
    return itemGroupSubLevel2Delete;
  }
  async postCreateItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV3,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV3,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel3Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV3,
      input
    );
    return itemGroupSubLevel3Delete;
  }
  async postCreateItemGroupWithSubLevels(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupCreateManyWithSubLevels } = await graphQLClient.request(
      CREATE_ITEM_GROUPS_WITH_SUB_LEVELS,
      createManyInput
    );
    return itemGroupCreateManyWithSubLevels;
  }
  // async getAllGR(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  // async getAllGI(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  // async getAllGT(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  async getCompanySettings() {
    const graphQLClient = graphQLClientWithHeader();
    const { companySettings } = await graphQLClient.request(
      GET_COMPANY_SETTINGS
    );
    return companySettings;
  }
  async getCompanySetting(companySettingId) {
    const graphQLClient = graphQLClientWithHeader();
    const { companySetting } = await graphQLClient.request(
      GET_COMPANY_SETTING,
      { companySettingId }
    );
    return companySetting;
  }
  async createCompanySetting(createCompanySettingInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createCompanySetting } = await graphQLClient.request(
      CREATE_COMPANY_SETTING,
      { createCompanySettingInput }
    );
    return createCompanySetting;
  }
  async updateCompanySetting(updateCompanySettingInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateCompanySetting } = await graphQLClient.request(
      UPDATE_COMPANY_SETTING,
      { updateCompanySettingInput }
    );
    return updateCompanySetting;
  }
  async getRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplate } = await graphQLClient.request(
      GET_COMPANY_SETTING,
      input
    );
    return remarkTemplate;
  }
  async getRemarkTemplates(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplates } = await graphQLClient.request(
      GET_REMARK_TEMPLATES,
      input
    );
    return remarkTemplates;
  }
  async createRemarkTemplate(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplateCreate } = await graphQLClient.request(
      CREATE_REMARK_TEMPLATE,
      { createInput }
    );
    return remarkTemplateCreate;
  }
  async updateRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplate } = await graphQLClient.request(
      UPDATE_REMARK_TEMPLATE,
      input
    );
    return remarkTemplate;
  }
  async deleteRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplateDelete } = await graphQLClient.request(
      REMOVE_REMARK_TEMPLATE,
      input
    );
    return remarkTemplateDelete;
  }

  async getApprovalTemplatesByDocumentType(documentType) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplatesByDocumentType } = await graphQLClient.request(
      GET_APPROVAL_TEMPLATES_BY_DOCUMENT_TYPE,
      { documentType }
    );
    return approvalTemplatesByDocumentType;
  }
  async createApprovalTemplate(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createApprovalTemplate } = await graphQLClient.request(
      CREATE_APPROVAL_TEMPLATE,
      { createInput }
    );
    return createApprovalTemplate;
  }
  async updateApprovalTemplate(updateApprovalTemplateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateApprovalTemplate } = await graphQLClient.request(
      UPDATE_APPROVAL_TEMPLATE,
      updateApprovalTemplateInput
    );
    return updateApprovalTemplate;
  }

  async deleteApprovalTemplate(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplateDelete } = await graphQLClient.request(
      DELETE_APPROVAL_TEMPLATE,
      { uniqueInput }
    );
    return approvalTemplateDelete;
  }

  async deleteApprovalTemplateWithNextStep(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplateDeleteNextStep } = await graphQLClient.request(
      DELETE_APPROVAL_TEMPLATE_WITH_NEXT_STEP,
      { uniqueInput }
    );
    console.log(approvalTemplateDeleteNextStep);
    return approvalTemplateDeleteNextStep;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SettingService();
