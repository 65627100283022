import { gql } from "graphql-request";

export const GET_ALL_UOM = gql`
  query Uoms($findManyUomsInput: UomFindManyInput) {
    uoms(findManyInput: $findManyUomsInput) {
      id
      document_id
      name
    }
  }
`;

export const GET_UOM = gql`
  query Uom($uniqueInput: UomWhereUniqueInput!) {
    uom(uniqueInput: $uniqueInput) {
      id
      document_id
      name
    }
  }
`;

export const GET_ALL_UOM_GROUP = gql`
  query UomGroups($findManyInput: UomGroupFindManyInput) {
    uomGroups(findManyInput: $findManyInput) {
      id
      name
      description
      document_id
      base_uom_document_id
      base_uom {
        id
        name
      }
      convertable_uom_list {
        id
        document_id
        name
      }
      uom_conversion_list {
        id
        base_uom_rate
        target_uom_document_id
        target_uom {
          id
          name
        }
        target_uom_rate
      }
    }
  }
`;

export const GET_UOM_GROUP = gql`
  query UomGroup($uniqueInput: UomGroupWhereUniqueInput!) {
    uomGroup(uniqueInput: $uniqueInput) {
      id
      name
      base_uom_document_id
      document_id
      base_uom {
        id
        name
      }
      description
      convertable_uom_list {
        id
        document_id
        name
      }
      uom_conversion_list {
        id
        base_uom_rate
        target_uom_document_id
        target_uom {
          id
          name
        }
        target_uom_rate
      }
    }
  }
`;

export const GET_WAREHOUSE = gql`
  query Warehouse($uniqueInput: WarehouseWhereUniqueInput!) {
    warehouse(uniqueInput: $uniqueInput) {
      thai_name
    }
  }
`;

export const GET_ALL_LOCATION = gql`
  query Warehouses($findManyInput: WarehouseFindManyInput) {
    warehouses(findManyInput: $findManyInput) {
      id
      document_id
      type
      thai_name
      eng_name
      description
      created_date
      is_active
      sub_level_1_list {
        id
        document_id
        name
        sub_level_2_list {
          id
          document_id
          name
          sub_level_3_list {
            id
            document_id
            name
            bin_location_list {
              id
              document_id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_CATEGORY = gql`
  query Query($findManyInput: ItemGroupSubLevel1FindManyInput) {
    itemGroupSubLevel1s(findManyInput: $findManyInput) {
      id
      name
      document_id
      sub_level_2_list {
        id
        name
        document_id
        sub_level_3_list {
          id
          name
          document_id
        }
      }
    }
  }
`;

export const GET_ALL_ATTRIBUTES = gql`
  query Query($findManyInput: AttributeFindManyInput) {
    attributes(findManyInput: $findManyInput) {
      id
      name
      acronym
      description
      data_type
    }
  }
`;

export const GET_ATTRIBUTE_BY_ID = gql`
  query Attribute($uniqueInput: AttributeWhereUniqueInput!) {
    attribute(uniqueInput: $uniqueInput) {
      id
      name
      acronym
      description
      data_type
    }
  }
`;

export const GET_COMPANY_SETTINGS = gql`
  query CompanySettings {
    companySettings {
      id
      company_document_id
      img_url
      last_updator_document_id
      created_date
      identity_no
      registered_capital
      contact_type_1
      contact_type_2
      company_name
      contact_name_1
      contact_name_2
      initial
      register_date
      is_registered_vat
      vat_registration_date
      contact_person_list {
        position
        initial
        name_1
        name_2
        name_3
        img_url
        remark
        contact_channel_list {
          contact_channel
          contact_info_1
          contact_info_2
        }
      }
      contact_channel_list {
        contact_channel
        contact_info_1
        contact_info_2
      }
      address_list {
        address_type
        default_address
        same_as_default_address
        address
        sub_district
        district
        province
        postal_code
        country
        address_contact_name
        address_contact_phone
      }
    }
  }
`;

export const GET_COMPANY_SETTING = gql`
  query CompanySetting($companySettingId: Int!) {
    companySetting(id: $companySettingId) {
      id
      company_document_id
      img_url
      last_updator_document_id
      created_date
      identity_no
      registered_capital
      contact_type_1
      contact_type_2
      company_name
      contact_name_1
      contact_name_2
      initial
      register_date
      is_registered_vat
      vat_registration_date
      contact_person_list {
        position
        initial
        name_1
        name_2
        name_3
        img_url
        remark
        contact_channel_list {
          contact_channel
          contact_info_1
          contact_info_2
        }
      }
      contact_channel_list {
        contact_channel
        contact_info_1
        contact_info_2
      }
      address_list {
        address_type
        default_address
        same_as_default_address
        address
        sub_district
        district
        province
        postal_code
        country
        address_contact_name
        address_contact_phone
      }
    }
  }
`;

export const GET_REMARK_TEMPLATE = gql`
  query RemarkTemplate($uniqueInput: RemarkTemplateUniqueInput!) {
    remarkTemplate(uniqueInput: $uniqueInput) {
      id
      name
      document_type
      remark_text
    }
  }
`;

export const GET_REMARK_TEMPLATES = gql`
  query RemarkTemplates {
    remarkTemplates {
      id
      name
      document_type
      remark_text
    }
  }
`;

export const GET_APPROVAL_TEMPLATES_BY_DOCUMENT_TYPE = gql`
  query ApprovalTemplatesByDocumentType($documentType: DocumentType!) {
    approvalTemplatesByDocumentType(documentType: $documentType) {
      id
      step_number
      step_name
      action
      workflow_step_number
      workflow_document_type
      reviewer_document_id_list
      reviewer_list {
        document_id
        first_name
        last_name
        status
        phone
        email
        img_url
        line_uid
        position
        department
      }
      required_approval_number
      is_required_approve
    }
  }
`;
