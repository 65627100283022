import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ControlledSelect from "../../../Controlled/ControlledSelect";

const ItemList = ({
  control,
  fields,
  remove,
  errors,
  groupIndex,
  categoryIndex,
  disabled,
  status,
}) => {
  return fields.map((item, itemIndex) => (
    <TableRow key={item.id}>
      <TableCell align="center">
        <Typography fontSize={14}>{itemIndex + 1}</Typography>
      </TableCell>
      <TableCell>
        <CustomizedTextField
          value={`${item.item_document_id} - ${item.item_name}`}
          disabled
        />
      </TableCell>
      <TableCell align="center">
        <ControlledTextField
          type="number"
          control={control}
          name={`engineer_data[${groupIndex}].category_list[${categoryIndex}].item_list[${itemIndex}].qty`}
          disabled={disabled}
          InputProps={{
            inputProps: {
              min: 0,
              style: { textAlign: "center" },
            },
          }}
          error={
            errors &&
            errors.engineer_data &&
            errors.engineer_data[groupIndex] &&
            errors.engineer_data[groupIndex].category_list &&
            errors.engineer_data[groupIndex].category_list[categoryIndex] &&
            errors.engineer_data[groupIndex].category_list[categoryIndex]
              .item_list &&
            errors.engineer_data[groupIndex].category_list[categoryIndex]
              .item_list[itemIndex] &&
            errors.engineer_data[groupIndex].category_list[categoryIndex]
              .item_list[itemIndex].qty
          }
        />
      </TableCell>
      {(status === "closed" || status === "finished") && (
        <TableCell align="center">
          <ControlledTextField
            type="number"
            control={control}
            name={`engineer_data[${groupIndex}].category_list[${categoryIndex}].item_list[${itemIndex}].reserved_qty`}
            disabled={disabled}
            InputProps={{
              inputProps: {
                min: 0,
                style: { textAlign: "center" },
              },
            }}
            error={
              errors &&
              errors.engineer_data &&
              errors.engineer_data[groupIndex] &&
              errors.engineer_data[groupIndex].category_list &&
              errors.engineer_data[groupIndex].category_list[categoryIndex] &&
              errors.engineer_data[groupIndex].category_list[categoryIndex]
                .item_list &&
              errors.engineer_data[groupIndex].category_list[categoryIndex]
                .item_list[itemIndex] &&
              errors.engineer_data[groupIndex].category_list[categoryIndex]
                .item_list[itemIndex].reserved_qty
            }
          />
        </TableCell>
      )}
      <TableCell align="center">
        <ControlledSelect
          control={control}
          name={`engineer_data[${groupIndex}].category_list[${categoryIndex}].item_list[${itemIndex}].uom_id`}
          options={[
            {
              label: item.uom_group?.base_uom?.name,
              value: item.uom_group?.base_uom?.id,
            },
            ...item.uom_group?.convertable_uom_list?.map((uom) => ({
              label: uom.name,
              value: uom.id,
            })),
          ]}
          disabled={disabled}
        />
      </TableCell>
      <TableCell align="center">
        <ControlledTextField
          control={control}
          name={`engineer_data[${groupIndex}].category_list[${categoryIndex}].item_list[${itemIndex}].item_remark`}
          disabled={disabled}
        />
      </TableCell>
      {!disabled && (
        <TableCell align="center">
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() => remove(itemIndex)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  ));
};

export default ItemList;
