import UserService from "../../../services/User";
import GlobalService from "../../../services/Global";
import { rbacActions } from "./rbac-slice";
import { formatPriviledgePayload } from "../../../utils/dataTransformer";

export const getAllRoles =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const allRoles = await UserService.getAllRoles(input);
      if (params) params.successCallback(allRoles.results, allRoles.count);
      dispatch(rbacActions.loadedAllRoles(allRoles.results));
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      if (params) params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllRolesNoAggrid =
  (findManyInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const allroles = await UserService.getAllRolesNoAggrid(findManyInput);
      dispatch(rbacActions.loadedAllRoles(allroles));
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getRole = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(rbacActions.onLoading("role"));
  try {
    const role = await UserService.getRole(uniqueInput);
    dispatch(rbacActions.loadedRole(role));
  } catch (err) {
    dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const postNewRole =
  (documentType, createRoleInput, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(rbacActions.onLoading("role"));
    try {
      const document_id = await GlobalService.getRunningDocumentId(
        documentType
      );
      const payload = {
        document_id,
        ...createRoleInput,
      };
      const insertedRole = await UserService.postNewRole(payload);
      dispatch(rbacActions.appendedNewRole(insertedRole));
      enqueueSnackbar("เพิ่มสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "role" }));
      enqueueSnackbar("เพิ่มสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const setPriviledgeList = (priviledgeList) => async (dispatch) => {
  console.log(priviledgeList);
  dispatch(rbacActions.loadedPriviledgeList(priviledgeList));
  for (const [, priviledges] of Object.entries(priviledgeList)) {
    priviledges.forEach((priviledge) => {
      const payload = formatPriviledgePayload(priviledge);
      dispatch(rbacActions.loadedPriviledgeToRole(payload));
    });
  }
};

export const removePriviledge = (priviledge) => async (dispatch) => {
  priviledge.forEach((item) => {
    const payload = formatPriviledgePayload(item);
    dispatch(rbacActions.removedPriviledgeFromRole(payload));
  });
};

export const updateRole =
  (uniqueInput, updateInput, enqueueSnackbar, navigate) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const updatedRole = await UserService.updateRole(
        uniqueInput,
        updateInput
      );
      dispatch(rbacActions.updatedRole(updatedRole));
      enqueueSnackbar("แก้ไขสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate && navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("แก้ไขสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const deleteRole =
  (uniqueInput, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(rbacActions.onLoading("allRoles"));
    try {
      const deletedRole = await UserService.deleteRole(uniqueInput);
      dispatch(rbacActions.deletedRole(deletedRole));
      enqueueSnackbar("ลบสิทธิ์สำเร็จ", {
        variant: "success",
      });
      navigate("/user/rbac");
    } catch (err) {
      dispatch(rbacActions.rejectedActions({ ...err, name: "allRoles" }));
      enqueueSnackbar("ลบสิทธิ์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
