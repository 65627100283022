import React, { useState, useRef } from "react";
import { Grid, Box, Typography, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import ImporterDropzoneUI from "../../../../components/UI/ImporterDropzoneUI";
import SettingService from "../../../../services/Setting";
import AgGrid from "../../../../components/Table/AgGrid";

const keys = [];

const columns = [
  "warehouse_document_id",
  "warehouse_sub_level_1",
  "warehouse_sub_level_2",
  "warehouse_sub_level_3",
  "bin_location",
];

const requiredKeys = ["document_id", "type"];

const ItemGroupImporterPage = () => {
  //keys need to be defined in order
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: "ชื่อคลังสินค้า",
      filter: "agTextColumnFilter",
    },
    {
      field: "sub_level_1_name",
      headerName: "ชื่อคลังสินค้าย่อยระดับ 1",
      filter: "agTextColumnFilter",
    },
    {
      field: "sub_level_2_name",
      headerName: "ชื่อคลังสินค้าย่อยระดับ 2",
      filter: "agTextColumnFilter",
    },
    {
      field: "sub_level_3_name",
      headerName: "ชื่อคลังสินค้าย่อยระดับ 3",
      filter: "agTextColumnFilter",
    },
    {
      field: "bin_location_name",
      headerName: "ชื่อคลังสินค้าย่อยระดับ 3",
      filter: "agTextColumnFilter",
    },
  ];

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.location.index"),
      to: "/setting/inventory/location",
    },
    {
      name: "นำเข้าสถานที่จัดเก็บ",
    },
  ];

  const formatLocationData = (data) => {
    let missingCols;

    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));
      console.log(missingCols);

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const formattedData = data.map((location) => {
        const document_id = location.warehouse_document_id?.toString().trim();
        const sub_level_1 = location.warehouse_sub_level_1?.toString().trim();
        const sub_level_2 = location.warehouse_sub_level_2?.toString().trim();
        const sub_level_3 = location.warehouse_sub_level_3?.toString().trim();
        const bin_location = location.bin_location?.toString().trim();

        return {
          document_id,
          thai_name: document_id,
          type: "imported",
          sub_level_1_document_id: sub_level_1
            ? `${document_id}-${sub_level_1}`
            : undefined,
          sub_level_1_name: sub_level_1,
          sub_level_2_document_id: sub_level_2
            ? `${document_id}-${sub_level_1}-${sub_level_2}`
            : undefined,
          sub_level_2_name: sub_level_2,
          sub_level_3_document_id: sub_level_3
            ? `${document_id}-${sub_level_1}-${sub_level_2}-${sub_level_3}`
            : undefined,
          sub_level_3_name: sub_level_3,
          bin_location_document_id: bin_location
            ? `${document_id}-${sub_level_1}-${sub_level_2}-${sub_level_3}-${bin_location}`
            : undefined,
          bin_location_name: bin_location,
        };
      });

      return formattedData;
    } catch (err) {
      console.log(err);
      if (err.message === "template") {
        enqueueSnackbar(
          `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`,
          {
            variant: "error",
          }
        );
      }
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setIsLoading(true);
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
            field: requiredKeys[j],
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      try {
        const data = await SettingService.postCreateWarehouseWithSubLevels({
          createManyInput: formattedData,
        });
        console.log(data);
        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
        navigate("/setting/inventory/location");
      } catch (err) {
        console.log(err);
        setHasCreateError(true);
      }
    }
    setIsLoading(false);
  };

  const renderCreateErrors = () => {
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    return <></>;
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าสถานที่จัดเก็บ"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/location_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatLocationData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={formattedData.length === 0 || isLoading}
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={formattedData}
            groupDefaultExpanded={1}
            disableFloatingFilter
          />
        </Box>
      )}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default ItemGroupImporterPage;
