import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AgGrid from "../AgGrid";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { purchaseRequestColumnDefs } from "../ColumnDefs/purchaseRequestColumnDef";

const PurchaseRequestTable = ({ gridRef, onGridReady, rowData, isReport }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [columnDefs, setColumnDefs] = useState(purchaseRequestColumnDefs(t));

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "document_id",
          headerName: t("sales.documentId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          width: 200,
        },
        {
          field: "issue_date",
          headerName: t("sales.issueDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
        },
        {
          field: "due_date",
          headerName: t("purchase.request.dueDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
        },
        {
          field: "render_status",
          headerName: t("sales.status"),
          sortable: false,
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              return filterStatusValueFormatter(params.value);
            },
            values: [
              "draft",
              "PENDING",
              "DECLINED",
              "approved",
              "cancelled",
              "partiallyOrdered",
              "fullyOrdered",
            ],
          },
          cellRenderer: (params) => (
            <CustomizedStatus status={params.data.render_status} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "item_document_id",
          headerName: t("reports.item_document_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_name",
          headerName: t("reports.item_name"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "qty",
          headerName: t("reports.po_qty"),
          filter: false,
        },
        {
          field: "uom",
          headerName: t("reports.uom"),
          filter: false,
        },
        {
          field: "ordered_qty",
          headerName: t("reports.ordered_qty"),
          filter: false,
        },
        {
          field: "pending_qty",
          headerName: t("reports.pending_qty"),
          filter: false,
        },
        {
          field: "item_remark",
          headerName: t("reports.remark"),
          filter: false,
        },
        {
          field: "destination_warehouse_name",
          headerName: t("reports.destination_warehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "requester_name",
          headerName: t("purchase.request.employee"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "requester_department",
          headerName: t("user.account.department"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "requester_position",
          headerName: t("user.account.position"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "reference_document_id",
          headerName: t("reports.reference_document_id"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "external_ref_id",
          headerName: t("reports.external_ref_id"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "created_date",
          headerName: t("reports.created_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "updated_date",
          headerName: t("reports.updated_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "created_by",
          headerName: t("reports.created_by"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          // cellRenderer: (params) => <CustomizedAvatar avatars={params.value} />,
        },
        {
          field: "employee_list",
          headerName: t("sales.employeeList"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          // cellRenderer: (params) => <CustomizedAvatar avatars={params.value} />,
        },
      ]);
    }
  }, [isReport, t]);

  const onRowDoubleClicked = (params) => {
    navigate(
      `/purchase/request/${encodeURIComponent(params.data.document_id)}`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default PurchaseRequestTable;
