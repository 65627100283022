import { useState, useRef, forwardRef } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Avatar,
  TextField,
  Autocomplete,
  // createFilterOptions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CheckboxModalTable from "../../../Table/CheckboxModalTable";
import UserService from "../../../../services/User";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { departmentType, positionType } from "../../../../utils/userInfo";

const UserDescriptionForm = forwardRef(
  ({ control, setValue, errors, viewOnly, isDeliveryOrder }, ref) => {
    const { t } = useTranslation();
    const selectUsersGridRef = useRef();
    const [showSelectUsers, setShowSelectUsers] = useState(false);
    const [employeeIds, setUserIds] = useState([]);
    const [employeeIdsSnapshot, setUserIdsSnapshot] = useState([]);

    const openUserTable = () => {
      setShowSelectUsers(true);
    };

    const closeUserTable = () => {
      setShowSelectUsers(false);
    };

    const finishUsersSelect = (data) => {
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === "document_id") setValue("requester_document_id", value);
        if (key === "first_name") setValue("requester_name", value);
        if (key === "department") setValue("requester_department", value);
        if (key === "position") setValue("requester_position", value);
      });
      setShowSelectUsers(false);
    };

    const columnDefs = [
      {
        field: "document_id",
        headerName: t("user.account.employeeId"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.picture"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params) => {
          if (params.data.img_url) {
            return <Avatar alt="img_url" src={params.data.img_url} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.data.first_name + " " + params.data.last_name}
              />
            );
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "first_name",
        headerName: t("user.account.firstname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.lastname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "บริหาร",
            "คลังสินค้า",
            "รายรับ",
            "จัดซื้อ",
            "บัญชี",
            "ขนส่ง",
            "ผลิต",
            "ดูแลระบบ",
          ],
        },
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agTextColumnFilter",
      },
    ];

    const datasource = {
      async getRows(params) {
        const request = params.request;
        try {
          const allUsers = await UserService.getAllUsers(
            {
              startRow: request.startRow,
              endRow: request.endRow,
              filterModel: request.filterModel,
              sortModel: request.sortModel,
            },
            params
          );
          params.successCallback(allUsers.results, allUsers.count);
        } catch (err) {
          console.log(err);
          params.failCallback();
        }
      },
    };

    const onGridReady = (params) => {
      params.api.setServerSideDatasource(datasource);
    };

    return (
      <>
        <CustomizedBox ref={ref}>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
            <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
              <Typography fontWeight="bold" sx={{ ml: 1 }}>
                {t("purchase.request.employeeDesciption")}
              </Typography>
            </Grid>
            {!viewOnly && !isDeliveryOrder && (
              <Grid item xs={2}>
                <Box>
                  <IconButton
                    onClick={openUserTable}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <CheckboxModalTable
                  modalTitle="พนักงาน"
                  btnTitle={t("button.add")}
                  gridRef={selectUsersGridRef}
                  height={450}
                  columnDefs={columnDefs}
                  rowSelection="single"
                  onFinishEditing={finishUsersSelect}
                  modalIsOpen={showSelectUsers}
                  closeModal={closeUserTable}
                  onGridReady={onGridReady}
                  selectedIds={employeeIds}
                  setSelectedIds={setUserIds}
                  idsSnapshot={employeeIdsSnapshot}
                  setIdsSnapshot={setUserIdsSnapshot}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name="requester_name"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    fullWidth
                    error={Boolean(errors.requester_name)}
                    helperText={errors.requester_name?.message}
                    label={t("purchase.request.employee")}
                    required
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                key={`requester_department-creatable-freesolo`}
                name="requester_department"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    autoSelect
                    fullWidth
                    onChange={(e, option) => {
                      if (typeof option === "string") {
                        return field.onChange(option);
                      } else field.onChange(option ? option.value : "");
                    }}
                    options={departmentType}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.value || option.type;
                    }}
                    disabled={viewOnly}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("user.account.department")}
                        size="small"
                        name="requester_department"
                        error={Boolean(errors.requester_department)}
                        helperText={errors.requester_department?.message}
                        disabled={viewOnly}
                        required
                      />
                    )}
                    readOnly={viewOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                key={`requester_position-creatable-freesolo`}
                name="requester_position"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    autoSelect
                    fullWidth
                    onChange={(e, option) => {
                      if (typeof option === "string") {
                        return field.onChange(option);
                      } else field.onChange(option ? option.value : "");
                    }}
                    options={positionType}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.value || option.type;
                    }}
                    disabled={viewOnly}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("user.account.position")}
                        size="small"
                        name="requester_position"
                        error={Boolean(errors.requester_position)}
                        helperText={errors.requester_position?.message}
                        disabled={viewOnly}
                      />
                    )}
                    readOnly={viewOnly}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      </>
    );
  }
);

export default UserDescriptionForm;
