import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import PurchaseOrderPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseOrderById } from "../../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { useState } from "react";
import ContactService from "../../../../services/Contact";
import Setting from "../../../../services/Setting";
import { useAuth } from "../../../../hooks/use-auth";
import { useSnackbar } from "notistack";
import PurchaseOrderPdf from "../../../../components/Table/Pdf/PurchaseOrderPdf";

const PurchaseOrderPDF = () => {
  const dispatch = useDispatch();
  const { purchaseOrder } = useSelector((state) => state.purchaseOrder);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [contact, setContact] = useState({
    contact_name: "",
    identity_no: "",
  });
  const [destinationWarehouse, setDestinationWarehouse] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: purchaseOrder?.document_id,
  });
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getPurchaseOrderById(
        {
          document_id: id,
        },
        user,
        enqueueSnackbar
      )
    );
  }, [dispatch, enqueueSnackbar, id, user]);

  useEffect(() => {
    const getContact = async () => {
      if (purchaseOrder && purchaseOrder.contact_document_id) {
        const contactData = await ContactService.getContact({
          document_id: purchaseOrder.contact_document_id,
        });
        setContact({
          contact_name:
            contactData.contact_name_1 +
            (contactData.contact_name_2
              ? " " + contactData.contact_name_2
              : ""),
          identity_no: contactData.identity_no,
        });
      }
    };
    const getDestinationWarehouse = async () => {
      if (purchaseOrder && purchaseOrder.destination_warehouse_document_id) {
        const { thai_name } = await Setting.getWarehouse({
          document_id: purchaseOrder.destination_warehouse_document_id,
        });
        setDestinationWarehouse(thai_name);
      }
    };
    getContact();
    getDestinationWarehouse();
  }, [purchaseOrder]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบสั่งซื้อ/Purchase Order</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              p: 3,
            }}
            ref={componentRef}
          >
            <Box>
              <PDFHeader documentType="ใบสั่งซื้อ/Purchase Order" />
              <Divider />
              <PurchaseOrderPDFInfo
                purchaseOrder={purchaseOrder}
                contact={contact}
                destinationWarehouse={destinationWarehouse}
              />
              <Divider />
              <PurchaseOrderPdf itemList={purchaseOrder.item_list} />
              <PDFFooter data={purchaseOrder} />
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box
              sx={{
                py: 3,
                breakInside: "avoid",
              }}
            >
              <PDFSigner />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseOrderPDF;
