import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomizedMenuBox from "../components/Custom/CustomizedMenuBox";
import { useAuth, usePermission } from "../hooks/use-auth";

const Landing = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [allPermissionList, setAllPermissionList] = useState([]);

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const {
    itemPermission,
    goodsReceivePermission,
    goodsReceiveReturnPermission,
    goodsIssuePermission,
    goodsAdjustmentPermission,
    goodsTransferPermission,
    inventoryReportPermission,
    contactPermission,
    contactReportPermission,
    // quotationPermission,
    // salesOrderPermission,
    // salesReturnPermission,
    // salesReportPermission,
    // purchaseRequestPermission,
    // purchaseOrderPermission,
    // purchaseReturnPermission,
    // purchaseReportPermission,
    // deliveryOrderPermission,
    // deliveryTripPermission,
    // deliveryReportPermission,
    // bomPermission,
    // manufactureOrderPermission,
    // workOrderPermission,
    // manufactureReportPermission,
    companyInfoPermission,
    approvalSettingPermission,
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    manufactureSettingPermission,
    logisticSettingPermission,
  } = usePermission();

  const menuList = [
    allPermissionList.some((permission) =>
      [contactPermission.VIEW, contactReportPermission.VIEW, isAdmin].includes(
        permission
      )
    ) && { title: t("contact.index"), navigateTo: "contact" },
    allPermissionList.some((permission) =>
      [
        itemPermission.VIEW,
        goodsReceivePermission.VIEW,
        goodsReceiveReturnPermission.VIEW,
        goodsIssuePermission.VIEW,
        goodsAdjustmentPermission.VIEW,
        goodsTransferPermission.VIEW,
        inventoryReportPermission.VIEW,
        isAdmin,
      ].includes(permission)
    ) && { title: t("inventory.index"), navigateTo: "inventory" },
    { title: t("engineer.index"), navigateTo: "engineer" },
    // allPermissionList.some((permission) =>
    //   [
    //     quotationPermission.VIEW,
    //     salesOrderPermission.VIEW,
    //     salesReturnPermission.VIEW,
    //     salesReportPermission.VIEW,
    //     isAdmin,
    //   ].includes(permission)
    // ) && { title: t("sales.index"), navigateTo: "sales" },
    // allPermissionList.some((permission) =>
    //   [
    //     deliveryOrderPermission.VIEW,
    //     deliveryTripPermission.VIEW,
    //     deliveryReportPermission.VIEW,
    //     isAdmin,
    //   ].includes(permission)
    // ) && {
    //   title: t("logistic.index"),
    //   navigateTo: "logistic",
    // },
    // allPermissionList.some((permission) =>
    //   [
    //     purchaseRequestPermission.VIEW,
    //     purchaseOrderPermission.VIEW,
    //     purchaseReturnPermission.VIEW,
    //     purchaseReportPermission.VIEW,
    //     isAdmin,
    //   ].includes(permission)
    // ) && {
    //   title: t("purchase.index"),
    //   navigateTo: "purchase",
    // },
    // allPermissionList.some((permission) =>
    //   [
    //     bomPermission.VIEW,
    //     manufactureOrderPermission.VIEW,
    //     workOrderPermission.VIEW,
    //     manufactureReportPermission.VIEW,
    //     isAdmin,
    //   ].includes(permission)
    // ) && {
    //   title: t("manufacture.index"),
    //   navigateTo: "manufacture",
    // },
    {
      title: t("user.index"),
      navigateTo: "user",
    },
    allPermissionList.some((permission) =>
      [
        companyInfoPermission.VIEW,
        approvalSettingPermission.VIEW,
        salesRemarkPermission.VIEW,
        purchaseRemarkPermission.VIEW,
        logisticRemarkPermission.VIEW,
        manufactureRemarkPermission.VIEW,
        locationPermission.VIEW,
        categoryPermission.VIEW,
        uomPermission.VIEW,
        attributePermission.VIEW,
        manufactureSettingPermission.VIEW,
        logisticSettingPermission.VIEW,
        isAdmin,
      ].includes(permission)
    ) && {
      title: t("setting.index"),
      navigateTo: "setting",
    },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const renderMenuList = () =>
    formatMenuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <Typography fontWeight="bold">หน้าแรก</Typography>
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Landing;
