import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import ItemList from "./ItemList";
import ItemTableModal from "../../../UI/ItemTableModal";
import { AddCircleOutline } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";

const CategoryList = ({
  control,
  errors,
  groupIndex,
  disabled,
  categoryList,
  status,
}) => {
  const [currCategory, setCurrCategory] = useState(null);
  const {
    fields: woodField,
    append: woodAppend,
    remove: woodRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[0].item_list`,
  });

  const {
    fields: subMaterialField,
    append: subMaterialAppend,
    remove: subMaterialRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[1].item_list`,
  });

  const {
    fields: mainMaterialField,
    append: mainMaterialAppend,
    remove: mainMaterialRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[2].item_list`,
  });

  const {
    fields: wastedMaterialField,
    append: wastedMaterialAppend,
    remove: wastedMaterialRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[3].item_list`,
  });

  const {
    fields: serviceField,
    append: serviceAppend,
    remove: serviceRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[4].item_list`,
  });

  const {
    fields: otherField,
    append: otherAppend,
    remove: otherRemove,
  } = useFieldArray({
    control: control,
    name: `engineer_data[${groupIndex}].category_list[5].item_list`,
  });

  const currentField = (category_name) => {
    switch (category_name) {
      case "วัสดุไม้":
        return {
          fields: woodField,
          remove: woodRemove,
        };
      case "วัสดุประกอบ":
        return {
          fields: subMaterialField,
          remove: subMaterialRemove,
        };
      case "วัสดุอุปกรณ์หลัก":
        return {
          fields: mainMaterialField,
          remove: mainMaterialRemove,
        };
      case "วัสดุสิ้นเปลือง":
        return {
          fields: wastedMaterialField,
          remove: wastedMaterialRemove,
        };
      case "บริการ":
        return {
          fields: serviceField,
          remove: serviceRemove,
        };
      case "อื่นๆ":
        return {
          fields: otherField,
          remove: otherRemove,
        };
      default:
        return {
          fields: [],
          remove: null,
        };
    }
  };

  const selectItemListRef = useRef();
  const [openItemListModal, setOpenItemListModal] = useState(false);

  const openItemListModalHandler = (category) => {
    setOpenItemListModal(true);
    setCurrCategory(category);
  };

  const closeItemListModalHandler = () => {
    setOpenItemListModal(false);
    setCurrCategory(null);
  };

  const handleSelectItems = () => {
    const selectedItems = selectItemListRef.current.api.getSelectedRows();
    selectedItems.forEach((item) => {
      const formatItem = {
        item_document_id: item?.document_id || "",
        item_name: item?.name || "",
        qty: 1,
        reserved_qty: 1,
        uom_id: item?.base_uom.id,
        uom_group: item?.uom_group,
        item_remark: "",
      };
      switch (currCategory) {
        case "วัสดุไม้":
          woodAppend(formatItem);
          break;
        case "วัสดุประกอบ":
          subMaterialAppend(formatItem);
          break;
        case "วัสดุอุปกรณ์หลัก":
          mainMaterialAppend(formatItem);
          break;
        case "วัสดุสิ้นเปลือง":
          wastedMaterialAppend(formatItem);
          break;
        case "บริการ":
          serviceAppend(formatItem);
          break;
        case "อื่นๆ":
          otherAppend(formatItem);
          break;
        default:
          return;
      }
    });
    closeItemListModalHandler();
  };

  return (
    <Fragment>
      {categoryList.map((category, categoryIndex) => (
        <Fragment key={category.category_name}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Typography fontWeight={600} fontSize={14}>
                ประเภท: {category.category_name}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {(status === "closed" || status === "finished") && (
              <TableCell></TableCell>
            )}
            {!disabled && (
              <TableCell align="center">
                <IconButton
                  aria-label="add"
                  size="small"
                  sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                  onClick={() =>
                    openItemListModalHandler(category.category_name)
                  }
                >
                  <AddCircleOutline fontSize="small" />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
          <ItemList
            fields={currentField(category.category_name).fields}
            remove={currentField(category.category_name).remove}
            control={control}
            errors={errors}
            disabled={disabled}
            groupIndex={groupIndex}
            categoryIndex={categoryIndex}
            status={status}
          />
        </Fragment>
      ))}
      <ItemTableModal
        selectItemListRef={selectItemListRef}
        openItemListModal={openItemListModal}
        closeItemListModalHandler={closeItemListModalHandler}
        handleSelectItems={handleSelectItems}
        currCategory={currCategory}
      />
    </Fragment>
  );
};

export default CategoryList;
