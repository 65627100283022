import { useRef, useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AgGrid from "../../components/Table/AgGrid";
import { contactColumnDefs } from "../../components/Table/ColumnDefs/Contact";
import ContactService from "../../services/Contact";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import { usePermission } from "../../hooks/use-auth";
import GlobalService from "../../services/Global";

const Contacts = () => {
  const gridRef = useRef();
  const filterRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({});
  const tab = searchParams.get("type");
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [contactsGroupsList, setContactsGroupsList] = useState([]);
  // const [isInit, setIsInit] = useState(false);
  const { createPermission } = usePermission();

  // useEffect(() => {
  //   if (!isInit) {
  //     const getAllContactsGroupsList = async () => {
  //       const contactsGroupsList = await ContactService.getAllContactsGroups();
  //       setContactsGroupsList(contactsGroupsList);
  //     };
  //     getAllContactsGroupsList();
  //     setIsInit(true);
  //   }
  // }, [isInit]);

  filterRef.current = filter;

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    // console.log("results", results);
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    // setTagList(mapTagValue);
    return mapTagValue;
  }, []);

  useEffect(() => {
    switch (tab) {
      case "vendor":
        setFilter({
          is_vendor: { filterType: "boolean", type: "equals", filter: "true" },
        });
        break;
      case "customer":
        setFilter({
          is_customer: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        });
        break;
      default:
        setFilter({});
    }
    if (gridRef.current.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [gridRef, tab]);

  const columnDefs = contactColumnDefs(t, getAllTag);

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const filterModel = {
        ...request.filterModel,
        ...filterRef.current,
        document_id: {
          ...request.filterModel.document_id,
          mode: "insensitive",
        },
        tag_list: request.filterModel.tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: request.filterModel.tag_list.values,
                },
              },
            }
          : {},
      };
      try {
        const allContacts = await ContactService.getAllContactsAgGrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel,
            sortModel: request.sortModel,
          },
          params
        );
        params.successCallback(allContacts.results, allContacts.count);
      } catch (err) {
        console.log(err);
        params.failCallback();
      }
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params) => {
    const { document_id } = params.data;
    navigate(`/contact/edit/${document_id}`);
  };

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("contact_status");
    instance.setModel({ filterType: "set", values: ["active"] });
    gridRef.current.api.onFilterChanged();
  };

  const tabs = [
    {
      label: "ทั้งหมด",
      path: `${pathname}`,
    },
    {
      label: t("contact.contact_type.customer"),
      path: `${pathname}?type=customer`,
    },
    {
      label: t("contact.contact_type.vendor"),
      path: `${pathname}?type=vendor`,
    },
  ];

  const currentTab = tab ? pathname + `?type=${tab}` : pathname;

  return (
    <>
      <CustomizedStatus bgcolor="#C8EDDC">
        <Typography
          variant="button"
          sx={{
            color: "#246527",
          }}
        >
          ใช้งาน
        </Typography>
      </CustomizedStatus>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("contact.index")}</Typography>
        {createPermission && (
          <Box>
            <CustomizedButton
              sx={{ mr: 2 }}
              title="สร้างผู้ติดต่อ"
              variant="contained"
              onClick={() => navigate(`/contact/add`)}
            />
            <CustomizedButton
              title="นำเข้าผู้ติดต่อ"
              variant="contained"
              onClick={() => navigate(`/contact/importer`)}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable table />
      </Box>
      <Box>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onRowDoubleClicked={rowSelectHandler}
          onFirstDataRendered={onFirstDataRendered}
        />
      </Box>
    </>
  );
};

export default Contacts;
