import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
// import Cookies from "universal-cookie";
// import jwtDecode from "jwt-decode";
import ExpiredConfirmation from "../components/UI/Confirmation/ExpiredConfirmation";

const AuthVerify = () => {
  let location = useLocation();
  const { isExpired, logout } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // const cookies = new Cookies();
    // const accessToken = cookies.get("access_token");
    if (isExpired) {
      // if (accessToken) {
      //   const decodedJwt = jwtDecode(accessToken);
      //   if (decodedJwt.exp * 1000 < Date.now()) {
      //     setOpen(true);
      //   }
      // }
      setOpen(true);
    }
  }, [isExpired, location]);

  // useEffect(() => {
  // if (isAuthenticated) {
  //   const interval = setInterval(async () => {
  //     verify();
  //   }, 3600000);
  //   return () => clearInterval(interval);
  // }
  // }, [isAuthenticated, verify]);

  return (
    <ExpiredConfirmation
      open={open}
      logout={() => {
        logout();
        setOpen(false);
      }}
    />
  );
};

export default AuthVerify;
