import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../components/Custom/CustomizedMenuBox";
import { useAuth, usePermission } from "../../hooks/use-auth";

const Setting = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [allPermissionList, setAllPermissionList] = useState([]);

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const breadcrumbs = [
    {
      name: t("setting.index"),
    },
  ];

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const {
    // companyInfoPermission,
    // approvalSettingPermission,
    // salesRemarkPermission,
    // purchaseRemarkPermission,
    // logisticRemarkPermission,
    // manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    // manufactureSettingPermission,
    // logisticSettingPermission,
  } = usePermission();

  const menuList = [
    // allPermissionList.some((permission) =>
    //   [companyInfoPermission.VIEW, isAdmin].includes(permission)
    // ) && { title: t("setting.companyInfo"), navigateTo: "company-info" },
    // allPermissionList.some((permission) =>
    //   [approvalSettingPermission.VIEW, isAdmin].includes(permission)
    // ) && { title: t("setting.approval.index"), navigateTo: "approval" },
    // allPermissionList.some((permission) =>
    //   [
    //     salesRemarkPermission.VIEW,
    //     purchaseRemarkPermission.VIEW,
    //     logisticRemarkPermission.VIEW,
    //     manufactureRemarkPermission.VIEW,
    //     isAdmin,
    //   ].includes(permission)
    // ) && { title: t("setting.remarkSetting"), navigateTo: "remark" },
    allPermissionList.some((permission) =>
      [
        locationPermission.VIEW,
        categoryPermission.VIEW,
        uomPermission.VIEW,
        attributePermission.VIEW,
        isAdmin,
      ].includes(permission)
    ) && { title: t("setting.warehouseSetting"), navigateTo: "inventory" },
    // allPermissionList.some((permission) =>
    //   [manufactureSettingPermission.VIEW, isAdmin].includes(permission)
    // ) && { title: t("setting.manufacture.index"), navigateTo: "manufacture" },
    // allPermissionList.some((permission) =>
    //   [logisticSettingPermission.VIEW, isAdmin].includes(permission)
    // ) && { title: t("setting.logisticSetting"), navigateTo: "logistic" },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderMenuList = () =>
    formatMenuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Setting;
