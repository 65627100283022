import { Grid, IconButton, Typography, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { dateToUnix } from "../../../utils/date-converter";
import { useAuth } from "../../../hooks/use-auth";
import InventoryService from "../../../services/Inventory";
import PurchaseService from "../../../services/Purchase";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const IssueDetail = ({
  isCreate,
  control,
  setValue,
  getValues,
  errors,
  disabled,
  isLoading,
  watchWarehouse,
  generateDocumentId,
  replace,
  reset,
  resetFieldBarcode,
}) => {
  const [referenceDocumentOptions, setReferenceDocumentOptions] = useState([]);
  const { t } = useTranslation();
  const { allLocation } = useSelector((state) => state.location);
  const { user } = useAuth();

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.document_id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const watchType = useWatch({ control, name: "type" });
  const watchRefDoc = useWatch({ control, name: "reference_document_id" });

  const typeOption = [
    { label: "ขาย", value: "ขาย" },
    // { label: "ขายออนไลน์", value: "ขายออนไลน์" }, //SO
    // { label: "ผลิตวัตถุดิบ", value: "ผลิตวัตถุดิบ" }, //MO
    // { label: "ผลิตแปรรูป", value: "ผลิตแปรรูป" }, //MO
    // { label: "ผลิตแปรรูป - PT", value: "ผลิตแปรรูป - PT" }, //PT -> MO
    { label: "ส่งคืน", value: "ส่งคืน" }, //RS
    { label: "เบิกตัวอย่าง", value: "เบิกตัวอย่าง" },
    // { label: "สินค้าเสีย", value: "สินค้าเสีย" },
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  const fetchDocument = useCallback(async (docType) => {
    let results = null;
    if (docType === "RS") {
      results = await PurchaseService.getAllPurchaseReturnWithoutAggrid();
    }
    const formatData = results.map((doc) => {
      return {
        ...doc,
        item_list: doc.item_list.map((item) => {
          return {
            ...item,
            document_id: item.item_document_id,
            name: item.item_name,
            description: item.item_description,
            quantity: item.qty,
            uom: item.uom,
          };
        }),
      };
    });
    setReferenceDocumentOptions(formatData);
  }, []);

  const resetValue = useCallback(
    (onlyList) => {
      if (isCreate) {
        if (!onlyList) setValue("reference_document_id", "");
        setValue("goods_issue_list", []);
      }
    },
    [isCreate, setValue]
  );

  const checkTypeIfSameDoc = useCallback(async () => {
    if (watchRefDoc.slice(0, 2) === "SO" && watchType !== "ขาย") {
      resetValue();
    } else if (watchRefDoc.slice(0, 2) === "RS" && watchType !== "ส่งคืน") {
      resetValue();
    } else if (watchType === "อื่นๆ" || watchType === "เบิกตัวอย่าง") {
      resetValue();
    } else if (watchType !== "อื่นๆ" && watchRefDoc === "") resetValue(true);
  }, [watchRefDoc, watchType, resetValue]);

  useEffect(() => {
    if (watchType === "อื่นๆ") resetValue(true);
  }, [currentWarehouse, watchType, resetValue]);

  useEffect(() => {
    //TODO: get sales order document list
    // if (watchType === "ขาย" || watchType === "ขายออนไลน์") {
    //   fetchDocument("SO");
    // } else
    if (watchType === "ส่งคืน") {
      fetchDocument("RS");
    } else {
      setReferenceDocumentOptions([]);
    }
    checkTypeIfSameDoc();
  }, [checkTypeIfSameDoc, fetchDocument, watchType]);

  const getTeList = useCallback(
    async (allItems) => {
      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          inventory_object_item: {
            filterType: "object",
            filter: {
              goods_issue: {
                filterType: "object",
                filter: {
                  reference_document_id: {
                    filterType: "text",
                    filter: watchRefDoc,
                    type: "contains",
                  },
                },
              },
            },
          },
          is_active: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        },
        sortModel: [],
      };
      if (watchRefDoc) {
        const allItems = getValues("goods_issue_list");
        const { results } = await InventoryService.getAllSEAggrid(input);
        // console.log("results", results);
        const newItemWithCalData = [];
        allItems.forEach((item) => {
          const newItems = results.filter(
            (result) =>
              result.item_document_id === item.stock_entry_list.item.document_id
          );
          return newItemWithCalData.push({
            ...item,
            already_posted_quantity: newItems.reduce(
              (prev, curr) => prev + Math.abs(curr.posted_quantity),
              0
            ),
          });
        });
        setValue("goods_issue_list", newItemWithCalData);
      }
    },
    [getValues, setValue, watchRefDoc]
  );

  useEffect(() => {
    getTeList();
  }, [getTeList, watchRefDoc]);

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={disabled ? 12 : 10}
          sm={disabled ? 12 : 10}
          md={6}
          lg={4}
          xl={4}
          display="flex"
          justifyContent="center"
        >
          <Controller
            control={control}
            name="document_id"
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.issue.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                required
                disabled={!isCreate || isLoading}
              />
            )}
          />
        </Grid>
        {disabled ? null : (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={() => generateDocumentId()}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={typeOption}
                label={t("inventory.issue.issueType")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {watchType === "อื่นๆ" ||
          watchType === "ขาย" ||
          watchType === "เบิกตัวอย่าง" ? (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.referenceNo")}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  noValidate
                  disabled={true}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  options={referenceDocumentOptions.map((doc) => {
                    return {
                      id: doc.id,
                      value: doc.document_id,
                      label: doc.document_id,
                    };
                  })}
                  label={t("inventory.referenceNo")}
                  onChange={(_, newValue) => {
                    const currValue = getValues();
                    resetFieldBarcode("barcode");
                    // reset to initial form (before mutate goods_issue_list)
                    if (newValue && newValue.value) {
                      if (currValue.reference_document_id !== newValue.value) {
                        reset({
                          ...currValue,
                          reference_document_id: newValue.value,
                          goods_issue_list: [],
                        });
                      }

                      const findItemFromRef = referenceDocumentOptions.find(
                        (doc) => doc.document_id === newValue.value
                      );
                      if (findItemFromRef) {
                        const formatValue = findItemFromRef.item_list.map(
                          (item) => {
                            return {
                              initial_quantity: item.quantity,
                              posted_quantity: item.quantity,
                              posted_value: 0,
                              stock_entry_list: {
                                destination_warehouse: "",
                                entry_type: "",
                                is_active: true,
                                item: item,
                                posted_date: dateToUnix(new Date()),
                                posted_quantity: item.quantity,
                                posted_value: 0,
                                reference_document_id: "",
                                reference_document_type: "issue",
                                source_warehouse_document_id: watchWarehouse,
                                trace_entry_list: [],
                                created_by: user,
                                uom: item.uom,
                              },
                            };
                          }
                        );
                        replace(formatValue);
                      }
                      field.onChange(newValue.value);
                    } else {
                      reset({
                        ...currValue,
                        reference_document_id: "",
                        goods_issue_list: [],
                      });
                      field.onChange("");
                    }
                  }}
                  disabled={!isCreate || isLoading}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                value={mapWarehouseIdToName()}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"delivery_employee"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.issue.deliveryEmployee")}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"google_map_link"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.issue.googleMap")}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"delivery_address"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.issue.deliveryAddress")}
                disabled={isLoading}
                multiline
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <CustomizedTextField
            label={t("inventory.remarkStaff")}
            value={item.remark_staff || ""}
            disabled={!isCreate}
          />
        </Grid> */}
      </Grid>
    </CustomizedBox>
  );
};

export default IssueDetail;
