import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const PurchaseReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.report.index"),
    },
  ];

  const menuList = [
    { title: t("purchase.report.request.index"), navigateTo: "request" },
    { title: t("purchase.report.order.index"), navigateTo: "order" },
    { title: t("purchase.report.return.index"), navigateTo: "return" },
    {
      title: t("purchase.report.purchaseByVendor"),
      navigateTo: "purchase-by-vendor",
    },
    {
      title: t("purchase.report.purchaseByItem"),
      navigateTo: "purchase-by-item",
    },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default PurchaseReport;
