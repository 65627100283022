import * as Yup from "yup";

export const validation = Yup.object().shape({
  document_id: Yup.string().required("กรุณากรอก"),
  type: Yup.string().required("กรุณากรอก"),
  reference_document_id: Yup.string().when("type", {
    is: (value) => value === "ส่งคืน",
    then: Yup.string().required("กรุณาระบุเอกสารอ้างอิง"),
  }),
  source_warehouse_document_id: Yup.string().required("กรุณากรอก"),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  goods_issue_list: Yup.array()
    .of(
      Yup.object().shape({
        stock_entry_list: Yup.object().shape({
          trace_entry_list: Yup.array().of(
            Yup.object().shape({
              current_quantity: Yup.number(),
              source_bin_location_document_id:
                Yup.string().required("กรุณาระบุสถานที่"),
              posted_quantity: Yup.number()
                .typeError("กรุณาระบุจำนวนที่จะนำออก")
                .when(
                  "current_quantity",
                  (value, schema) =>
                    (value || value === 0) &&
                    schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
                )
                .required("กรุณาระบุจำนวนที่จะนำออก")
                .min(1, "กรุณาระบุจำนวนที่จะนำออก"),
            })
          ),
        }),
      })
    )
    .min(1, "กรุณาเพิ่มรายการสินค้า"),
});
