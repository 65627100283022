import { useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ApprovalSettingBox from "../../../components/UI/ApprovalSettingBox";
import SettingService from "../../../services/Setting";
import { useSnackbar } from "notistack";
import CustomizedRadioGroup from "../../../components/Custom/CustomizedRadioGroup";
import { usePermission } from "../../../hooks/use-auth";

const SalesApprovalSetting = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const [templatesSnapshot, setTemplatesSnapshot] = useState([]);
  const [stepsSelectedRowIds, setStepsSelectedRowIds] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const { editPermission } = usePermission();

  useEffect(() => {
    switch (tab) {
      case "quotation":
        setDocumentType("quotation");
        setDocumentId(1);
        break;
      case "order":
        setDocumentType("sales_order");
        setDocumentId(2);
        break;
      case "return":
        setDocumentType("sales_return");
        setDocumentId(3);
        break;
      default:
        setDocumentType("quotation");
        setDocumentId(1);
        break;
    }
  }, [tab]);

  useEffect(() => {
    setIsInit(false);
    if (documentType) {
      const getApprovalTemplates = async () => {
        const data = await SettingService.getApprovalTemplatesByDocumentType(
          documentType
        );
        setTemplatesSnapshot(data);
      };
      getApprovalTemplates();
      setIsInit(true);
    }
  }, [documentType]);

  const { control: settingControl, reset: resetSetting } = useForm({
    defaultValues: { enableSetting: false },
  });

  const { control, setValue, getValues, reset } = useForm({
    defaultValues: { approvers: [] },
  });

  const watchApprovers = useWatch({
    control,
    name: "approvers",
  });

  const watchEnableSetting = useWatch({
    control: settingControl,
    name: "enableSetting",
  });

  const shouldEnableSetting =
    watchEnableSetting === "true" || watchEnableSetting === true;

  useEffect(() => {
    const mappedReviewers = templatesSnapshot.map((template) => {
      return {
        selectedEmployees: [...template.reviewer_list],
      };
    });
    reset({ approvers: mappedReviewers });
    resetSetting({ enableSetting: templatesSnapshot[0]?.is_required_approve });
  }, [templatesSnapshot, reset, resetSetting]);

  useEffect(() => {
    if (shouldEnableSetting) {
      if (watchApprovers.length > 0) {
        const allStepsSelectedRowIds = watchApprovers.map((row) => {
          return row.selectedEmployees.map((employee) => employee.document_id);
        });
        setStepsSelectedRowIds(allStepsSelectedRowIds);
      }
    } else {
      setStepsSelectedRowIds([[]]);
    }
  }, [shouldEnableSetting, watchApprovers]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.approval.index"), to: "/setting/approval" },
    { name: t("setting.approval.sales") },
  ];

  useEffect(() => {
    reset({ approvers: [] });
    resetSetting({ enableSetting: false });
  }, [tab, reset, resetSetting]);

  const tabs = [
    {
      label: t("sales.quotation.index"),
      path: `${pathname}?tab=quotation`,
    },
    {
      label: t("sales.order.index"),
      path: `${pathname}?tab=order`,
    },
    {
      label: t("sales.return.index"),
      path: `${pathname}?tab=return`,
    },
  ];

  const radioLists = [
    { value: false, label: "ไม่ตั้งค่าการอนุมัติ" },
    {
      value: true,
      label: "ตั้งค่าการอนุมัติ",
      tooltip: "ผู้มีสิทธิ์อนุมัติผู้ใดก็ได้ในลำดับนั้นๆ สามารถอนุมัติได้",
    },
  ];

  const submitUpdatedTemplate = async () => {
    const maxSteps = 3;
    try {
      for (let index = 0; index < maxSteps; index++) {
        if (typeof stepsSelectedRowIds[index] === "undefined" && index !== 0) {
          if (typeof templatesSnapshot[index] === "undefined") {
            continue;
          }
          await SettingService.deleteApprovalTemplateWithNextStep({
            id_step_number: { id: documentId, step_number: index + 1 },
          });
        } else if (
          templatesSnapshot.findIndex(
            (template) => template.step_number === index + 1
          ) !== -1
        ) {
          await SettingService.updateApprovalTemplate({
            uniqueInput: {
              id_step_number: { id: documentId, step_number: index + 1 },
            },
            updateInput: {
              id: documentId,
              step_number: index + 1,
              step_name: `อนุมัติครั้งที่ ${index + 1}`,
              action: "อนุมัติ",
              workflow_step_number: 2,
              workflow_document_type: documentType,
              reviewer_document_id_list: stepsSelectedRowIds[index],
              required_approval_number: 1,
              is_required_approve: shouldEnableSetting,
            },
          });
        } else {
          await SettingService.createApprovalTemplate({
            id: documentId,
            step_number: index + 1,
            step_name: `อนุมัติครั้งที่ ${index + 1}`,
            action: "อนุมัติ",
            workflow_step_number: 2,
            workflow_document_type: documentType,
            reviewer_document_id_list: stepsSelectedRowIds[index],
            required_approval_number: 1,
            is_required_approve: shouldEnableSetting,
          });
        }
      }
      const refreshedData =
        await SettingService.getApprovalTemplatesByDocumentType(documentType);
      setTemplatesSnapshot(refreshedData);
      enqueueSnackbar("บันทึกการตั้งค่าสิทธิ์สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("เกิดความผิดพลาดในการตั้งค่าสิทธิ์", {
        variant: "error",
      });
    }
  };

  const cancelUpdateTemplate = () => {
    const mappedReviewers = templatesSnapshot.map((template) => {
      return { selectedEmployees: [...template.reviewer_list] };
    });
    reset({ approvers: mappedReviewers });
  };

  const currentTab = tab
    ? pathname + `?tab=${tab}`
    : pathname + `?tab=quotation`;

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ width: "100%" }}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable divider />
        <Controller
          name="enableSetting"
          control={settingControl}
          render={({ field }) => (
            <Box sx={{ ml: 2 }}>
              <CustomizedRadioGroup
                radioList={radioLists}
                {...field}
                disabled={!editPermission}
              />
            </Box>
          )}
        />
        {shouldEnableSetting && (
          <ApprovalSettingBox
            control={control}
            setValue={setValue}
            getValues={getValues}
            stepsSelectedRowIds={stepsSelectedRowIds}
            isInit={isInit}
            viewOnly={!editPermission}
          />
        )}
        {editPermission && (
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                onClick={cancelUpdateTemplate}
              />
            </Box>
            <CustomizedButton
              title={"บันทึก"}
              variant="contained"
              onClick={submitUpdatedTemplate}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SalesApprovalSetting;
