import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const Setting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.approval.index") },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* <Grid item xs={6} md={3}>
          <CustomizedMenuBox
            isGridItem
            title={t("setting.approval.sales")}
            onClick={() => navigate(`${pathname}/sales`)}
          />
        </Grid> */}
        <Grid item xs={6} md={3}>
          <CustomizedMenuBox
            isGridItem
            title={t("setting.approval.purchase")}
            onClick={() => navigate(`${pathname}/purchase`)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Setting;
