export const calendarObjectFormatter = (allEvents) => {
  //   const renderBgColor = (status) => {
  //     switch (status) {
  //       case "planning":
  //         return "#D3D3D3";
  //       case "appointment":
  //         return "#FCE69F";
  //       case "check_in":
  //         return "#C4DBFD";
  //       case "finished":
  //         return "#E5F8EF";
  //       default:
  //         return "#D3D3D3";
  //     }
  //   };

  const formattedEvents = allEvents?.map((event) => {
    const formattedOption = {
      start: event.start_delivery_date ?? undefined,
      end: event.end_delivery_date ?? undefined,
      title: event.document_id,
      //   backgroundColor: renderBgColor(event.aggrid_status),
      display: "block",
      ...event,
    };
    return formattedOption;
  });

  return formattedEvents;
};
