import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../hooks/use-auth";
import { schema, validation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useSnackbar } from "notistack";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { errorMessageHandler } from "../../../utils/dataTransformer";

const LoginForm = ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema,
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onLoginSubmit = async ({ email, password }) => {
    try {
      await login(email, password);
      enqueueSnackbar("ยินดีต้อนรับสู่ Biowood ERP", {
        variant: "success",
      });
    } catch (err) {
      if (err.message === "Network request failed") {
        enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
          variant: "error",
        });
      } else {
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(errorMessageHandler(error.message), {
              variant: "error",
            });
          } else {
            enqueueSnackbar("ล็อกอินไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      }
    }
    navigate(from, { replace: true });
  };

  return (
    <form onSubmit={handleSubmit(onLoginSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <CustomizedTextField
            testId="user-email-text-field"
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            autoComplete="off"
            label="อีเมลผู้ใช้งาน"
            margin="dense"
            size={isMobile ? "medium" : "small"}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            fullWidth
            size={isMobile ? "medium" : "small"}
            sx={{ mt: 1 }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              รหัสผ่าน
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              data-test-id="user-password-text-field"
              {...field}
            />
          </FormControl>
        )}
      />
      <CustomizedButton
        testId="login-button"
        type="submit"
        title="เข้าสู่ระบบ"
        variant="contained"
        fullWidth
        size="medium"
        sx={{ mt: 1 }}
      />
    </form>
  );
};

export default LoginForm;
