import { createSlice } from "@reduxjs/toolkit";
import { estimateInitialState } from "./estimate-inital";

const estimateSlice = createSlice({
  name: "estimate",
  initialState: estimateInitialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllEstimates(state, action) {
      state.allEstimates = action.payload;
      state.isLoading.allEstimates = false;
    },
    loadedEstimate(state, action) {
      state.estimate = action.payload;
      state.isLoading.estimate = false;
    },
    resetAllEstimates(state) {
      state.allEstimates = estimateInitialState.allEstimates;
      state.error = estimateInitialState.error;
    },
    resetEstimate(state) {
      state.estimate = estimateInitialState.estimate;
      state.error = estimateInitialState.error;
    },
  },
});

export const estimateActions = estimateSlice.actions;

export default estimateSlice.reducer;
