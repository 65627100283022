import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
// import AgGrid from "../../../components/UI/AgGrid";
import HistoryIcon from "@mui/icons-material/History";
import IssueDetail from "./Detail";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createGoodsIssueWithApprove,
  getGoodsIssueById,
  updateGoodsIssue,
} from "../../../features/Inventory/GoodsIssue/goodsIssue-actions";
import RightDrawer from "../../../components/UI/RightDrawer";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import GlobalService from "../../../services/Global";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import GoodsIssueTable from "../../../components/Table/DocumentTable/GoodsIssueTable";
import { useSnackbar } from "notistack";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import InventoryService from "../../../services/Inventory";
import { useAuth } from "../../../hooks/use-auth";
import moment from "moment";
import { dateToUnix } from "../../../utils/date-converter";
import { formatGIPayload } from "../../../utils/dataTransformer";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import { goodsIssueActions } from "../../../features/Inventory/GoodsIssue/goodsIssue-slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";
import ControlledDateTimePicker from "../../../components/Controlled/ControlledDateTimePicker";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GoodsIssueContainer = ({ isCreate }) => {
  const scrollRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { item, isLoading } = useSelector((state) => state.goodsIssue);
  const [createDateIsOpen, setCreateDateIsOpen] = useState(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState(false);
  const [startDeliveryDateIsOpen, setStartDeliveryDateIsOpen] = useState(false);
  const [endDeliveryDateIsOpen, setEndDeliveryDateIsOpen] = useState(false);
  const { user } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [totalError, setTotalError] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [showError, setShowError] = useState({
    type: "success",
    value: true,
    text: "awdascascawdaw dapowdk a; mdl",
  });
  const [scanData, setScanData] = useState("");
  const [scanDataCurr, setScanDataCurr] = useState("");
  const [isInterVal, setIsInterval] = useState(false);
  const { state } = useLocation();
  // let interval = useRef();

  useEffect(() => {
    if (currentTab) {
      setShowCamera(false);
    }
  }, [currentTab]);

  // on camera scroll

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (state) {
      if (state.reference_document_type !== "manufacture_order") {
        const formatValue = state.item_list.map((item) => {
          return {
            initial_quantity:
              state.reference_document_type === "sales_order"
                ? item.qty
                : item.qty_return,
            posted_quantity: item.qty,
            posted_value: 0,
            stock_entry_list: {
              item_uid: item.uid,
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: {
                document_id: item.item_document_id,
                name: item.item_name,
                description: item.item_dscription,
                quantity: item.qty,
                ...item,
              },
              posted_date: dateToUnix(new Date()),
              posted_quantity:
                state.reference_document_type === "sales_order"
                  ? item.qty
                  : item.qty_return,
              posted_value: 0,
              reference_document_id: state.document_id,
              reference_document_type: state.reference_document_type,
              source_warehouse_document_id: null,
              trace_entry_list: [],
              created_by: user,
              uom: item.uom,
            },
          };
        });
        setValue("goods_issue_list", formatValue);
      } else {
        const formatValue = state.ingredient_list?.map((item) => {
          return {
            initial_quantity: item.qty,
            posted_quantity: item.qty - item.goods_issue_qty,
            already_posted_qty: item.goods_issue_qty,
            posted_value: 0,
            stock_entry_list: {
              item_uid: item.uid,
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: {
                document_id: item.item_document_id,
                name: item.item_name,
                description: item.item_dscription,
                quantity: item.qty,
                ...item,
              },
              posted_date: dateToUnix(new Date()),
              posted_quantity: item.qty - item.goods_issue_qty,
              posted_value: 0,
              reference_document_id: state.document_id,
              reference_document_type: state.reference_document_type,
              source_warehouse_document_id: null,
              trace_entry_list: [],
              created_by: user,
              uom: { document_id: item.uom, name: item.uom },
            },
          };
        });
        setValue("goods_issue_list", formatValue);
      }
      if (state.reference_document_type === "sales_order")
        setValue("type", "ขาย");
      else if (state.reference_document_type === "purchase_return")
        setValue("type", "ส่งคืน");
      else if (state.reference_document_type === "manufacture_order")
        setValue("type", "ผลิตวัตถุดิบ");
      setValue("reference_document_id", state.document_id);
    }
  }, [setValue, state, user]);

  const {
    control: barcodeControl,
    handleSubmit: handleBarcodeSubmit,
    resetField: resetFieldBarcode,
  } = useForm({
    defaultValues: {
      barcode: "",
    },
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "goods_issue_list",
    keyName: "genId",
  });

  const generateDocumentId = useCallback(async () => {
    const newDocumentId = await GlobalService.getRunningDocumentId(
      "goods_issue"
    );
    return setValue("document_id", newDocumentId);
  }, [setValue]);

  useEffect(() => {
    dispatch(getAllLocation());
    if (id) {
      dispatch(getGoodsIssueById({ uniqueInput: { document_id: id } }));
    } else {
      generateDocumentId();
    }
    return () => dispatch(goodsIssueActions.resetItem());
  }, [dispatch, generateDocumentId, id]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (id && item.goods_issue_list.length > 0) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      setValue("created_date", moment());
      setValue("document_date", moment().endOf("day"));
      setValue("start_delivery_date", moment());
      setValue("end_delivery_date", moment());
    }
    console.log(item);
  }, [id, item, setValue]);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.issue.index"),
      to: "/inventory/issue",
    },
    {
      name: id ?? t("inventory.issue.add"),
    },
  ];

  const watchType = useWatch({ control, name: "type" });
  const watchWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  useEffect(() => {
    if (errors && errors?.goods_issue_list?.message) {
      enqueueSnackbar(errors?.goods_issue_list?.message, {
        variant: "error",
      });
    } else if (errors && Array.isArray(errors?.goods_issue_list)) {
      if (
        errors?.goods_issue_list.some(
          (goods) =>
            goods.stock_entry_list.trace_entry_list.message ===
            "กรุณาเพิ่ม SN ในรายการสินค้า"
        )
      ) {
        enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", {
          variant: "error",
        });
      } else if (
        errors?.goods_issue_list.find((goods) =>
          goods.stock_entry_list.trace_entry_list.some(
            (trace) =>
              trace.posted_quantity.message ===
              "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง"
          )
        )
      ) {
        enqueueSnackbar("กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  const soType = ["ขาย", "ขายออนไลน์"];
  const moType = ["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"];

  const onSubmit = async (data) => {
    const serializedData = await formatGIPayload(data);
    console.log(serializedData);
    if (
      data.goods_issue_list.some(
        (list) => list.stock_entry_list.trace_entry_list.length > 0
      )
    )
      if (totalError) {
        enqueueSnackbar("กรุณาระบุจำนวนนำออกไม่เกินจำนวนจากเอกสาร", {
          variant: "error",
        });
      } else {
        dispatch(
          createGoodsIssueWithApprove(
            serializedData,
            enqueueSnackbar,
            navigate,
            user
          )
        );
        if (soType.includes(serializedData.type))
          serializedData.goods_issue_list.forEach(async (goods) => {
            await InventoryService.updateItemQuantity(
              goods.stock_entry_list.item_document_id,
              {
                current_committed_sales_qty: {
                  decrement: goods.posted_quantity,
                },
              }
            );
          });
        else if (moType.includes(serializedData.type))
          serializedData.goods_issue_list.forEach(async (goods) => {
            await InventoryService.updateItemQuantity(
              goods.stock_entry_list.item_document_id,
              {
                current_committed_manufacture_qty: {
                  decrement: goods.posted_quantity,
                },
              }
            );
          });
      }
    else
      return enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้าอย่างน้อย 1 ตัว", {
        variant: "error",
      });
  };

  const onUpdateSubmit = (data) => {
    const serializedData = { ...item };
    serializedData.google_map_link = getValues("google_map_link");
    serializedData.delivery_employee = getValues("delivery_employee");
    serializedData.delivery_address = getValues("delivery_address");
    serializedData.start_delivery_date = moment(
      getValues("start_delivery_date")
    ).toDate();
    serializedData.end_delivery_date = moment(
      getValues("end_delivery_date")
    ).toDate();
    serializedData.created_date = dateToUnix(getValues("created_date"));
    serializedData.document_date = dateToUnix(getValues("document_date"));

    const format_goods_issue_list = serializedData.goods_issue_list.map(
      (gil) => {
        const { stock_entry_list, id, ...othersGIL } = gil;

        const {
          created_by,
          created_date,
          destination_warehouse,
          document_id,
          entry_type,
          id: idSEL,
          is_active,
          item,
          reference_document_id,
          source_warehouse,
          uom,
          reference_document_type,
          trace_entry_list,
          ...otherSEL
        } = stock_entry_list;

        const uom_document_id = uom.document_id;
        const creator_document_id = created_by.document_id;
        const item_document_id = item.document_id;
        const format_trace_entry_list = trace_entry_list.map((entry) => {
          const {
            created_by: created_byTEL,
            created_date: created_dateTEL,
            document_id: document_idTEL,
            destination_bin_location,
            id: idTEL,
            is_active: is_activeTEL,
            item: itemTEL,
            item_document_id,
            source_bin_location,
            uom: uomTEL,
            uom_document_id,
            scanned_date,
            stock_entry_document_id,
            previous_quantity,
            ...otherTEL
          } = entry;

          return {
            ...otherTEL,
            uom_document_id: uom_document_id ?? uomTEL.document_id,
            previous_quantity: previous_quantity ?? 0,
            scanned_date: parseInt(scanned_date),
            item_document_id: item_document_id ?? itemTEL.document_id,
          };
        });

        const format_stock_entry_list = {
          ...otherSEL,
          uom_document_id: uom_document_id,
          creator_document_id: creator_document_id,
          item_document_id: item_document_id,
          trace_entry_list: format_trace_entry_list,
        };

        return { stock_entry_list: format_stock_entry_list, ...othersGIL };
      }
    );

    serializedData.goods_issue_list = format_goods_issue_list;
    // console.log(formatGIL);

    // delete excess fields for update payload
    delete serializedData.created_by;
    delete serializedData.created_by_document_id;
    delete serializedData.source_warehouse;
    delete serializedData.id;
    delete serializedData.source_warehouse_thai_name;
    delete serializedData.source_warehouse_eng_name;
    delete serializedData.status;

    const payload = {
      uniqueInput: { document_id: item.document_id },
      updateInput: serializedData,
    };

    console.log(payload);
    dispatch(updateGoodsIssue(payload, enqueueSnackbar, user));
  };

  const checkSmallScreenError = useCallback(
    (type, text) => {
      return !isSmallScreen
        ? enqueueSnackbar(text, {
            variant: type,
          })
        : setShowError({ type: type, value: true, text });
    },
    [enqueueSnackbar, isSmallScreen]
  );

  const onBarcodeSubmitHandler = useCallback(
    async (data) => {
      if (data.trim().length > 0) {
        const serialNumber = data
          .split("#", 4)
          .filter((_, index) => index !== 0)
          .join("#");
        const { results } =
          await InventoryService.getItemCurrentStockSerialNumber({
            startRow: 0,
            endRow: 999,
            filterModel: {
              serial_number: {
                filter: serialNumber,
                filterType: "text",
                type: "equals",
              },
            },
          });
        if (results.length === 0)
          return checkSmallScreenError(
            "error",
            "QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่"
          );
        const checkItemIsActive = results.some((te) => !te.item_is_active);
        if (checkItemIsActive)
          return checkSmallScreenError(
            "error",
            "ไม่สามารถสแกนได้ เนื่องจากสินค้านี้มีสถานะหยุดการใช้งานอยู่"
          );
        const filterTraceEntry = results.filter(
          (result) => result.warehouse_document_id === watchWarehouse
        );
        if (filterTraceEntry.length === 0)
          return checkSmallScreenError(
            "error",
            "QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่"
          );
        if (filterTraceEntry.every((result) => result.current_quantity <= 0))
          return checkSmallScreenError("error", "SN นี้ไม่มีสินค้าในคลัง");
        const teQuantityNotZero = filterTraceEntry.filter(
          (te) => te.current_quantity !== 0
        );
        const lastIndexTraceEntry =
          teQuantityNotZero[teQuantityNotZero.length - 1];
        const goodsIssueList = getValues("goods_issue_list");
        const foundMatchItem = goodsIssueList.findIndex(
          (item) =>
            item.stock_entry_list.item.document_id ===
            lastIndexTraceEntry.item_document_id
        );
        //1MT10.0-L-10M#1#GR2211020010#00001#10
        const formatTraceEntry = {
          ...lastIndexTraceEntry,
          uom: {
            name: lastIndexTraceEntry.base_uom_name,
            document_id: lastIndexTraceEntry.base_uom_document_id,
          },
          item: {
            name: lastIndexTraceEntry.item_name,
            document_id: lastIndexTraceEntry.item_document_id,
            sku: lastIndexTraceEntry.item_sku,
          },
          warehouse: {
            document_id: lastIndexTraceEntry.warehouse_document_id,
            name: lastIndexTraceEntry.warehouse_thai_name,
          },
          source_bin_location: {
            document_id: lastIndexTraceEntry.source_bin_location_document_id,
          },
          source_bin_location_document_id:
            lastIndexTraceEntry.source_bin_location_document_id || "",
          batch_number: data,
          is_scanned: true,
          posted_quantity: lastIndexTraceEntry.current_quantity,
          created_by: user,
          scanned_by: user,
          scanned_date: new Date(),
          posted_date: moment().unix(),
          location_list: filterTraceEntry.map((result) => {
            return {
              id: result.source_bin_location_document_id,
              label: `${result.source_bin_location_document_id} (${result.current_quantity} ${lastIndexTraceEntry.base_uom_name})`,
              value: result.source_bin_location_document_id,
              current_quantity: result.current_quantity,
            };
          }),
        };
        const formatItemList = [
          ...goodsIssueList,
          {
            initial_quantity: 0,
            posted_quantity: 0,
            posted_value: 0,
            stock_entry_list: {
              source_warehouse_document_id:
                formatTraceEntry.warehouse.document_id,
              posted_quantity: 0,
              posted_value: 0,
              posted_date: dateToUnix(new Date()),
              uom: formatTraceEntry.uom,
              item: formatTraceEntry.item,
              created_by: user,
              trace_entry_list: [formatTraceEntry],
            },
          },
        ];
        if (foundMatchItem >= 0) {
          const currTraceEntry = getValues(
            `goods_issue_list[${foundMatchItem}].stock_entry_list.trace_entry_list`
          );
          if (currTraceEntry.some((teList) => teList.batch_number === data)) {
            checkSmallScreenError(
              "error",
              "QR/Barcode สินค้านี้ถูกสแกนและบันทึกลงรายการแล้ว"
            );
          } else {
            setValue(
              `goods_issue_list[${foundMatchItem}].stock_entry_list.trace_entry_list`,
              [...currTraceEntry, formatTraceEntry]
            );
            checkSmallScreenError("success", `สแกน SN: ${serialNumber} สำเร็จ`);
          }
        } else if (watchType !== "ส่งคืน") {
          setValue(`goods_issue_list`, formatItemList);
          checkSmallScreenError("success", `สแกน SN: ${serialNumber} สำเร็จ`);
        } else {
          checkSmallScreenError(
            "error",
            "QR/Barcode สินค้านี้ไม่อยู่ในรายการกรุณาสแกนใหม่"
          );
        }
      }
    },
    [
      checkSmallScreenError,
      getValues,
      setValue,
      user,
      watchType,
      watchWarehouse,
    ]
  );

  useEffect(() => {
    if (showError.value) {
      setTimeout(() => {
        setShowError({ type: "success", value: false, text: "" });
      }, 1.5 * 1000);
    }
  }, [showError]);

  const tabError =
    errors &&
    (errors?.document_id ||
      errors?.type ||
      errors?.reference_document_id ||
      errors?.source_warehouse_document_id ||
      errors?.remark);

  // useEffect(() => {
  //   //   const interval = setInterval(() => {
  //   //     setScanData("");
  //   //     console.log("clear ScanData");
  //   //   }, 2000);

  //   if (scanData !== scanDataCurr) {
  //     // clearInterval(interval.current);
  //     setScanDataCurr(scanData);
  //     console.log("scanned");
  //     console.log("scanData", scanData);
  //     console.log("scanDataCurr", scanDataCurr);
  //     onBarcodeSubmitHandler(scanData);
  //   }
  //   //   interval.current = setInterval(() => {
  //   //     console.log("in interval");
  //   //     setScanDataCurr("");
  //   //   }, 2000);
  //   // } else clearInterval(interval.current);
  // }, [onBarcodeSubmitHandler, scanData, scanDataCurr]);

  useEffect(() => {
    if (isInterVal && scanData !== scanDataCurr) {
      setScanDataCurr(scanData);
      onBarcodeSubmitHandler(scanData);
      setTimeout(() => {
        setIsInterval(false);
        setScanDataCurr("");
      }, 2000);
    }
  }, [isInterVal, onBarcodeSubmitHandler, scanData, scanDataCurr]);

  // console.log("scanData", scanData);

  return (
    <>
      {!isSmallScreen ? (
        <>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
              <Typography variant="h5">
                {t("inventory.issue.goodsIssueList")}
              </Typography>
              <CustomizedStatus status={item.status} />
            </Box>
            <CustomizedTooltip title="ดูการเคลื่อนไหว">
              <IconButton onClick={() => setOpenDrawer(true)}>
                <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </CustomizedTooltip>
            <RightDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              title={t("inventory.activity")}
              documentId={id}
              documentType="goods_issue"
            />
          </Box>
          <Grid container justifyContent={"space-between"} my={2} spacing={2}>
            <Grid item>
              <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
                <Typography>{t("inventory.issue.exportedBy")}</Typography>
                <CustomizedAvatar
                  avatars={
                    item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                  }
                />
              </Box>
            </Grid>
            <Grid item>
              <Box
                display={"flex"}
                sx={{
                  alignItems: "center",
                  gap: 1,
                  sm: { flexDirection: "column", ml: 0 },
                  justifyContent: "flex-end",
                }}
              >
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  error={errors.created_date}
                  isOpen={createDateIsOpen}
                  onClose={() => setCreateDateIsOpen(false)}
                  onOpen={() => setCreateDateIsOpen(true)}
                  label={t("inventory.stockEntry.createdDate")}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  disabled
                />
                <ControlledDatePicker
                  name="document_date"
                  control={control}
                  error={errors.document_date}
                  isOpen={issueDateIsOpen}
                  onClose={() => setIssueDateIsOpen(false)}
                  onOpen={() => setIssueDateIsOpen(true)}
                  label={t("inventory.issue.issueDate")}
                  disabled={isLoading.item || Boolean(id)}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  required
                />
                <ControlledDateTimePicker
                  name="start_delivery_date"
                  control={control}
                  error={errors.start_delivery_date}
                  isOpen={startDeliveryDateIsOpen}
                  onClose={() => setStartDeliveryDateIsOpen(false)}
                  onOpen={() => setStartDeliveryDateIsOpen(true)}
                  label={t("inventory.issue.startDeliveryDate")}
                  sx={{ width: { sx: "auto", md: 180 } }}
                  disabled={isLoading.item}
                />
                <ControlledDateTimePicker
                  name="end_delivery_date"
                  control={control}
                  error={errors.end_delivery_date}
                  isOpen={endDeliveryDateIsOpen}
                  onClose={() => setEndDeliveryDateIsOpen(false)}
                  onOpen={() => setEndDeliveryDateIsOpen(true)}
                  label={t("inventory.issue.endDeliveryDate")}
                  disabled={isLoading.item}
                  sx={{ width: { sx: "auto", md: 180 } }}
                />
              </Box>
            </Grid>
          </Grid>
          <IssueDetail
            isCreate={isCreate}
            control={control}
            replace={replace}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            isLoading={isLoading.item}
            disabled={id}
            watchWarehouse={watchWarehouse}
            generateDocumentId={generateDocumentId}
            reset={reset}
            resetFieldBarcode={resetFieldBarcode}
          />
          <CustomizedBox>
            <Typography sx={{ fontWeight: 700, mb: 3 }}>
              สแกน Barcode
            </Typography>
            {!id ? (
              <Grid container mb={2}>
                <Grid item xs={4}>
                  <Controller
                    control={barcodeControl}
                    name="barcode"
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        label={t("inventory.scanBarcode")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey === false) {
                            const data = e.target.value;
                            handleBarcodeSubmit(onBarcodeSubmitHandler(data));
                            resetFieldBarcode("barcode");
                          }
                        }}
                        disabled={!watchWarehouse || isLoading.item}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            <GoodsIssueTable
              control={control}
              fields={fields}
              append={append}
              remove={remove}
              disabled={id}
              isLoading={isLoading.item}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              totalError={totalError}
              setTotalError={setTotalError}
            />
          </CustomizedBox>
        </>
      ) : (
        <Box>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box my={3}>
            <Tabs
              centered
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor:
                    tabError && currentTab === 0 ? "#F44336" : "#419644",
                },
              }}
            >
              <Tab
                label="ข้อมูลนำออก"
                {...a11yProps(0)}
                sx={{
                  "&.Mui-selected": {
                    color: tabError ? "#F44336" : "#419644",
                  },
                  color: tabError ? "#F44336" : "#419644",
                }}
              />
              <Tab label="สแกน Barcode" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                <Typography variant="h5">
                  {t("inventory.issue.goodsIssueList")}
                </Typography>
                <CustomizedStatus status={item.status} />
              </Box>
              <IconButton onClick={() => setOpenDrawer(true)}>
                <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
              </IconButton>
              <RightDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                title={t("inventory.activity")}
                documentId={id}
                documentType="goods_issue"
              />
            </Box>
            <Box
              display={"flex"}
              sx={{ alignItems: "center", gap: 2, flexDirection: "column" }}
              mt={3}
            >
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createDateIsOpen}
                onClose={() => setCreateDateIsOpen(false)}
                onOpen={() => setCreateDateIsOpen(true)}
                label={t("inventory.stockEntry.createdDate")}
                sx={{ width: { sx: "auto", md: 155 } }}
                disabled
              />
              <ControlledDatePicker
                name="document_date"
                control={control}
                error={errors.document_date}
                isOpen={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("inventory.issue.issueDate")}
                disabled={Boolean(id) || isLoading.item}
                sx={{ width: { sx: "auto", md: 155 } }}
                required
              />
              <ControlledDateTimePicker
                name="start_delivery_date"
                control={control}
                error={errors.start_delivery_date}
                isOpen={startDeliveryDateIsOpen}
                onClose={() => setStartDeliveryDateIsOpen(false)}
                onOpen={() => setStartDeliveryDateIsOpen(true)}
                label={t("inventory.issue.startDeliveryDate")}
                disabled={isLoading.item}
                sx={{ width: { sx: "auto", md: 155 } }}
              />
              <ControlledDateTimePicker
                name="end_delivery_date"
                control={control}
                error={errors.end_delivery_date}
                isOpen={endDeliveryDateIsOpen}
                onClose={() => setEndDeliveryDateIsOpen(false)}
                onOpen={() => setEndDeliveryDateIsOpen(true)}
                label={t("inventory.issue.endDeliveryDate")}
                disabled={isLoading.item}
                sx={{ width: { sx: "auto", md: 155 } }}
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }} my={3}>
              <Typography>ผู้สร้าง</Typography>
              <CustomizedAvatar
                avatars={
                  item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                }
              />
            </Box>
            <IssueDetail
              isCreate={isCreate}
              control={control}
              replace={replace}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              disabled={id}
              watchWarehouse={watchWarehouse}
              isLoading={isLoading.item}
              generateDocumentId={generateDocumentId}
              reset={reset}
              resetFieldBarcode={resetFieldBarcode}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {!id ? (
              <Grid container>
                <Grid item xs={10}>
                  <Controller
                    control={barcodeControl}
                    name="barcode"
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        label={t("inventory.scanBarcode")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey === false) {
                            const data = e.target.value;
                            handleBarcodeSubmit(onBarcodeSubmitHandler(data));
                            resetFieldBarcode("barcode");
                          }
                        }}
                        disabled={!watchWarehouse}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton onClick={() => setShowCamera(!showCamera)}>
                    <QrCodeScannerIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}
            {showError.value && (
              <Box
                sx={(theme) => ({
                  backgroundColor:
                    showError.type === "success"
                      ? theme.palette.success.light
                      : theme.palette.error.light,
                  width: "maxContent",
                  height: 47,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                })}
                mt={1}
                display="flex"
                justifyContent="center"
                textAlign="center"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: showError.type === "success" ? "#1E4620" : "#621B16",
                  }}
                >
                  {showError.text}
                </Typography>
              </Box>
            )}
            <Box display={showCamera ? "block" : "none"} marginY={2}>
              {showCamera && (
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result, error) => {
                    if (!!result) {
                      setScanData(result.text);
                      setIsInterval(true);
                      // onBarcodeSubmitHandler(result.text);
                    }
                    if (!!error) {
                      // console.log("error", error);
                      // console.info(error);
                    }
                  }}
                  scanDelay={1000}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </Box>
            <Box margin={showCamera ? "0 0 2rem 0" : "2rem 0"}>
              <GoodsIssueTable
                control={control}
                fields={fields}
                append={append}
                remove={remove}
                disabled={id}
                isLoading={isLoading.item}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                totalError={totalError}
                setTotalError={setTotalError}
              />
            </Box>
          </TabPanel>
        </Box>
      )}
      {(isCreate && !isSmallScreen) ||
      (isCreate && isSmallScreen && currentTab === 1) ? (
        <Box display="flex" gap={1} mt={isSmallScreen ? 2 : 0}>
          <CustomizedButton
            size={isSmallScreen ? "medium" : "small"}
            fullWidth={isSmallScreen}
            // type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            title="นำออก"
            disabled={isLoading.item}
          />
        </Box>
      ) : null}
      {(item.status === "finished" && !isSmallScreen) ||
      (item.status === "finished" && isSmallScreen && currentTab === 1) ? (
        <Box display="flex" gap={1} mt={isSmallScreen ? 2 : 0}>
          <CustomizedButton
            size={isSmallScreen ? "medium" : "small"}
            fullWidth={isSmallScreen}
            // type="submit"
            onClick={() => onUpdateSubmit()}
            variant="contained"
            title="บันทึก"
            disabled={isLoading.item}
          />
        </Box>
      ) : null}
    </>
  );
};

export default GoodsIssueContainer;
