import { createContext, useEffect, useState, useCallback } from "react";
import UserService from "../services/User";
import { useAuth } from "../hooks/use-auth";

const initialState = {
  approvals: [],
  pendingsCount: 0,
};

const ApprovalContext = createContext({
  ...initialState,
  refreshApprovals: () => {},
});

export const ApprovalContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [isInit, setIsInit] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [pendingsCount, setPendingsCount] = useState(0);
  const [allProgressCount, setAllProgressCount] = useState(0);
  
  useEffect(() => {
    if (!isAuthenticated) {
      setApprovals([]);
      setPendingsCount(0);
      setAllProgressCount(0);
      setIsInit(false);
    }
  }, [isAuthenticated]);

  const getPendingsCount = useCallback(async () => {
    const pendings = await UserService.getSelfPendingApprovalProgressCount();
    return pendings;
  }, []);

  const refresh = useCallback(async () => {
    if (isInit) {
      const newProgressCount =
        await UserService.getSelfAllApprovalProgressCount();
      if (newProgressCount !== allProgressCount) {
        setAllProgressCount(newProgressCount);
      } else {
        return;
      }
    }
    const data = await UserService.getSelfApprovalProgresses();
    setApprovals(data);
  }, [isInit, allProgressCount]);

  useEffect(() => {
    if (!isInit && isAuthenticated) {
      const getInitialAllProgressCount = async () => {
        const newProgressCount =
          await UserService.getSelfAllApprovalProgressCount();
        setAllProgressCount(newProgressCount);
      };
      const getInitialPendingsCount = async () => {
        const pendings = await getPendingsCount();
        setPendingsCount(pendings);
      };
      refresh();
      getInitialPendingsCount();
      getInitialAllProgressCount();
      setIsInit(true);
    }
  }, [
    isInit,
    approvals,
    getPendingsCount,
    refresh,
    allProgressCount,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(async () => {
        const pendings = await getPendingsCount();
        if (pendings !== pendingsCount) {
          setPendingsCount(pendings);
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [pendingsCount, getPendingsCount, isAuthenticated]);

  const state = { approvals, pendingsCount };

  return (
    <ApprovalContext.Provider value={{ ...state, refreshApprovals: refresh }}>
      {children}
    </ApprovalContext.Provider>
  );
};

export default ApprovalContext;
