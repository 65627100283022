import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { filterUom } from "../../../utils/dataTransformer";

export default function General({
  control,
  errors,
  setValue,
  getValues,
  viewOnly,
  isInventoryPage,
}) {
  const { t } = useTranslation();

  const { allCategory } = useSelector((state) => state.category);
  const { allUoms } = useSelector((state) => state.uom);

  const lengthOption = filterUom(allUoms, [
    "ไมครอน",
    "มิลลิเมตร",
    "เซนติเมตร",
    "เมตร",
    "หลา",
    "นิ้ว",
    "ฟุต",
    "กรัม",
  ]);
  // const weightOption = filterUom(allUoms, ["กรัม", "กิโลกรัม"]);
  // const volumeOption = filterUom(allUoms, ["มิลลิลิตร", "ลิตร"]);

  const dimensionList = [
    {
      testId: "item-width-text-field",
      label: t("inventory.items.width"),
      name: "width",
      uom: "width_uom",
      option: lengthOption,
      error: errors.width,
      uomError: errors.width_uom,
      disabledUom: viewOnly,
    },
    {
      testId: "item-length-text-field",
      label: t("inventory.items.length"),
      name: "length",
      uom: "length_uom",
      option: lengthOption,
      error: errors.length,
      uomError: errors.length_uom,
      disabledUom: viewOnly,
    },
    {
      testId: "item-height-text-field",
      label: t("inventory.items.height"),
      name: "height",
      uom: "height_uom",
      option: lengthOption,
      error: errors.height,
      uomError: errors.height_uom,
      disabledUom: viewOnly,
    },
    {
      testId: "item-weight-text-field",
      label: t("inventory.items.thickness"),
      name: "thickness",
      uom: "thickness_uom",
      option: lengthOption,
      error: errors.thickness,
      uomError: errors.thickness_uom,
      disabledUom: viewOnly,
    },
    // {
    //   testId: "item-weight-text-field",
    //   label: t("inventory.items.weight"),
    //   name: "weight",
    //   uom: "weight_uom",
    //   option: weightOption,
    //   error: errors.weight,
    //   uomError: errors.weight_uom,
    //   disabledUom: true,
    // },
  ];

  const watchCategoryLv1 = useWatch({
    control,
    name: "item_group_sub_level_1_document_id",
  });

  const watchCategoryLv2 = useWatch({
    control,
    name: "item_group_sub_level_2_document_id",
  });

  const optionCategory = () => {
    if (allCategory && allCategory.length > 0) {
      const result = allCategory.map((category) => {
        if (category.document_id === "" || category.name === "") return null;
        return {
          id: category.document_id,
          value: category.document_id,
          label: category.name,
        };
      });
      return result;
    } else return [];
  };

  const mapValueCategory = (value) => {
    const mapValue = allCategory.find(
      (category) => category.document_id === value
    );
    if (mapValue) return mapValue.name || "";
    else return "";
  };

  const mapValueCategoryLv2 = (value) => {
    const allCategoryLv2 = [];
    allCategory.forEach((category) => {
      if (category.sub_level_2_list.length > 0)
        category.sub_level_2_list.forEach((categoryLv2) =>
          allCategoryLv2.push(categoryLv2)
        );
    });
    const mapValue = allCategoryLv2.find(
      (category) => category.document_id === value
    );
    if (mapValue) return mapValue.name || "";
    else return "";
  };

  const mapValueCategoryLv3 = (value) => {
    const allCategoryLv3 = [];
    allCategory.forEach((category) => {
      if (category.sub_level_2_list.length > 0)
        category.sub_level_2_list.forEach((categoryLv2) => {
          if (categoryLv2.sub_level_3_list.length > 0)
            categoryLv2.sub_level_3_list.forEach((categoryLv3) =>
              allCategoryLv3.push(categoryLv3)
            );
        });
    });
    const mapValue = allCategoryLv3.find(
      (category) => category.document_id === value
    );
    if (mapValue) return mapValue.name || "";
    else return "";
  };

  // const mapValueCategoryLv3 = (value) => {
  //   const mapValue = allCategory.find(
  //     (category) => category.document_id === value
  //   );
  //   if (mapValue) return mapValue.name || "";
  //   else return "";
  // };

  const findIndexChildOne = () => {
    const find = allCategory.find((val) => {
      return val.document_id === `${watchCategoryLv1}`;
    });
    if (
      find === undefined ||
      find.sub_level_2_list === undefined ||
      find.sub_level_2_list.length === 0
    )
      return null;
    const optionValue = find.sub_level_2_list.map((categoryLv2) => {
      return {
        id: categoryLv2.document_id,
        value: categoryLv2.document_id,
        label: categoryLv2.name,
      };
    });

    return (
      <Controller
        name="item_group_sub_level_2_document_id"
        control={control}
        render={({ field }) => (
          <CustomizedComboBox
            {...field}
            testId="item-group-lv-2-field"
            options={optionValue}
            onChange={(_, newValue) =>
              field.onChange(newValue ? newValue.value : "")
            }
            label={t("inventory.items.itemGroup") + " 2"}
            value={mapValueCategoryLv2(field.value)}
            disabled={viewOnly}
          />
        )}
      />
    );
  };

  const findIndexChildTwo = () => {
    const findCate = allCategory.find((val) => {
      return val.document_id === `${watchCategoryLv1}`;
    });
    if (
      findCate === undefined ||
      findCate.sub_level_2_list === undefined ||
      findCate.sub_level_2_list.length === 0
    )
      return null;
    const find2 = findCate.sub_level_2_list.find((val) => {
      return val.document_id === `${watchCategoryLv2}`;
    });
    if (
      find2 === undefined ||
      find2.sub_level_3_list === undefined ||
      find2.sub_level_3_list.length === 0
    )
      return null;
    const optionValue = find2.sub_level_3_list.map((categoryLv3) => {
      return {
        id: categoryLv3.document_id,
        value: categoryLv3.document_id,
        label: categoryLv3.name,
      };
    });

    return (
      <Controller
        name="item_group_sub_level_3_document_id"
        control={control}
        render={({ field }) => (
          <CustomizedComboBox
            {...field}
            testId="item-group-lv-3-field"
            options={optionValue}
            onChange={(_, newValue) =>
              field.onChange(newValue ? newValue.value : "")
            }
            label={t("inventory.items.itemGroup") + " 3"}
            value={mapValueCategoryLv3(field.value)}
            disabled={viewOnly}
          />
        )}
      />
    );
  };

  return (
    <Box>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.itemGroup")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            name="item_group_sub_level_1_document_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                // {...field}
                testId="item-group-lv-1-field"
                options={optionCategory()}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.value : "");
                  setValue("item_group_sub_level_2_document_id", "");
                  setValue("item_group_sub_level_3_document_id", "");
                }}
                label={t("inventory.items.itemGroup") + " 1"}
                error={Boolean(errors.item_group_sub_level_1_document_id)}
                helperText={errors.item_group_sub_level_1_document_id?.message}
                disabled={!isInventoryPage || viewOnly}
                value={mapValueCategory(field.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          {findIndexChildOne()}
        </Grid>
        <Grid item xs={4}>
          {findIndexChildTwo()}
        </Grid>
      </Grid>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.dimension")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {dimensionList.map((dimension, index) => (
            <Grid container spacing={2} key={index} mb={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={dimension.name}
                  render={({ field }) => (
                    <CustomizedTextField
                      {...field}
                      label={dimension.label}
                      error={Boolean(dimension.error)}
                      helperText={dimension.error?.message}
                      disabled={!isInventoryPage || viewOnly}
                      testId={dimension.testId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`${dimension.uom}.name`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("inventory.unit")}
                      options={dimension.option}
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.label : null)
                      }
                      error={Boolean(dimension.uomError)}
                      helperText={dimension.uomError?.message}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
