import {
  Box,
  IconButton,
  Toolbar,
  Badge,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneAllIcon from "@mui/icons-material/DoneAll";
// import { alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CustomizedButton from "../Custom/CustomizedButton";
import CustomizedDashboardNavbar from "../Custom/CustomizedDashboardNavbar";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth } from "../../hooks/use-auth";
import { useApproval } from "../../hooks/use-approval";
import { useSelector } from "react-redux";
import UserBadge from "./UserBadge";

const TopNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebar } = useNavbar();
  const { user, logout } = useAuth();
  const { pendingsCount, refreshApprovals } = useApproval();
  const { isLoading: purchaseRequestLoading } = useSelector(
    (state) => state.purchaseRequest
  );
  const { isLoading: purchaseOrderLoading } = useSelector(
    (state) => state.purchaseOrder
  );
  const { isLoading: purchaseReturnLoading } = useSelector(
    (state) => state.purchaseReturn
  );
  const { isLoading: itemLoading } = useSelector((state) => state.itemMaster);
  const { isLoading: goodsReceiveLoading } = useSelector(
    (state) => state.goodsReceive
  );
  const { isLoading: goodsIssueLoading } = useSelector(
    (state) => state.goodsIssue
  );
  const { isLoading: goodsTransferLoading } = useSelector(
    (state) => state.goodsTransfer
  );
  const { isLoading: goodsAdjustmentLoading } = useSelector(
    (state) => state.goodsAdjustment
  );
  const { isLoading: rbacLoading } = useSelector((state) => state.rbac);
  const { isLoading: accountLoading } = useSelector((state) => state.account);
  const { isLoading: purchaseReportLoading } = useSelector(
    (state) => state.purchaseReport
  );
  const { isLoading: estimateloading } = useSelector((state) => state.estimate);

  const logoutHandler = () => {
    logout();
    navigate("/", { replace: true });
  };

  const goToApprovalProgress = () => {
    refreshApprovals();
    navigate("/approval");
  };

  return (
    <>
      <CustomizedDashboardNavbar open={!isSmallScreen && sidebar.isSidebarOpen}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!sidebar.isSidebarOpen && (
            <IconButton onClick={() => sidebar.setIsSidebarOpen(true)}>
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
          <Box sx={{ ml: sidebar.isSidebarOpen ? 0 : 4 }}>
            {!isSmallScreen && (
              <CustomizedButton
                variant="outlined"
                title={t("button.back")}
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIcon fontSize="small" />}
              />
            )}
            {isSmallScreen && (
              <Box
                sx={{
                  border: "1px solid rgba(50, 143, 53, 0.6)",
                  borderRadius: "4px",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon
                    sx={{
                      color: "#328F35",
                      fontSize: "0.75rem",
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Badge badgeContent={pendingsCount} color="primary" sx={{ mr: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton size="small" onClick={() => goToApprovalProgress()}>
                <DoneAllIcon />
              </IconButton>
            </Box>
          </Badge>
          {user && <UserBadge {...user} onLogout={logoutHandler} />}
        </Toolbar>
        {(itemLoading.item ||
          goodsReceiveLoading.item ||
          goodsIssueLoading.item ||
          goodsTransferLoading.item ||
          goodsAdjustmentLoading.item ||
          rbacLoading.allRoles ||
          rbacLoading.role ||
          rbacLoading.priviledgeList ||
          accountLoading.allUsers ||
          accountLoading.user ||
          purchaseOrderLoading.allPurchaseOrder ||
          purchaseOrderLoading.purchaseOrder ||
          purchaseRequestLoading.allPurchaseRequest ||
          purchaseRequestLoading.purchaseRequest ||
          purchaseReturnLoading.allPurchaseReturns ||
          purchaseReturnLoading.purchaseReturn ||
          purchaseReportLoading.purchaseReportByVendor ||
          purchaseReportLoading.purchaseReportByProduct ||
          estimateloading.estimate) && <LinearProgress />}
      </CustomizedDashboardNavbar>
    </>
  );
};

export default TopNavbar;
