import { useState, useCallback, useRef, useEffect, forwardRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  // InputAdornment,
  Avatar,
} from "@mui/material";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";
import CustomizedMenuOption from "../../../Custom/CustomizedMenuOption";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CheckboxModalTable from "../../../Table/CheckboxModalTable";
import { useAuth } from "../../../../hooks/use-auth";
import GlobalService from "../../../../services/Global/index";
import RelatedEmployeeIcon from "./RelatedEmployeeIcon";
import { getAllUsers } from "../../../../features/User/Account/account-actions";
import { modifyOptions } from "./modifyOptions";
import { createOptions } from "./createOptions";
import { shouldDisable } from "./shouldDisable";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import CopyConfirmation from "../../../UI/Confirmation/CopyConfirmation";
import CancelConfirmation from "../../../UI/Confirmation/CancelConfirmation";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import RightDrawer from "../../../UI/RightDrawer";
import { useSnackbar } from "notistack";
import { cancelPurchaseOrder } from "../../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { cancelPurchaseReturn } from "../../../../features/Purchase/PurchaseReturn/purchase-return-actions";
import { cancelPurchaseRequest } from "../../../../features/Purchase/PurchaseRequest/purchase-request-actions";
import CustomizedMuliChip from "../../../Custom/CustomizedMuliChip";

const PurchaseHeaderForm = forwardRef(
  (
    {
      control,
      errors,
      getValues,
      setValue,
      getMainValues,
      setMainValue,
      mainFormControl,
      viewOnly,
      documentType,
      editClickHandler,
      currentState,
      isEdit,
      approvalList,
      isReturn,
      setOpenPurchaseInvoiceConfirmation,
      setOpenDebitNoteConfirmation,
      gridRef,
      setSalesOrderIds,
      setSalesOrderIdsSnapshot,
      setPurchaseRequestIds,
      setPurchaseRequestIdsSnapshot,
      setIsEdit,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuth();
    const selectEmployeesGridRef = useRef();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const status = useWatch({
      control: mainFormControl,
      name: "render_status",
    });
    const { allLocation } = useSelector((state) => state.location);
    const [isApproval, setIsApproval] = useState(false);

    const watchRelatedEmployees = useWatch({
      control,
      name: "employee_list",
    });

    const watchReferenceDocumentList = useWatch({
      control,
      name: "reference_document_list",
    });

    const watchReferenceDocumentId = useWatch({
      control,
      name: "reference_document_id",
    });

    const watchCreatorDocumentId = useWatch({
      control: mainFormControl,
      name: "creator_document_id",
    });

    const watchCreatedBy = useWatch({
      control: mainFormControl,
      name: "created_by",
    });

    const [showSelectEmployees, setShowSelectEmployees] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

    const [createdAtDateisOpen, setCreatedAtDateisOpen] = useState(false);
    const [issueDateIsOpen, setIssueDateIsOpen] = useState(false);
    const [dueDateIsOpen, setDueDateIsOpen] = useState(false);
    const [expectDateIsOpen, setExpectDateIsOpen] = useState(false);
    const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const currentPage = pathname.split("/")[2];

    const [fieldsToDisable, setFieldsToDisable] = useState(
      shouldDisable(currentPage, status)
    );
    const [selectCreateOptions, setSelectCreateOptions] = useState(
      createOptions(currentPage, status, user)
    );
    const [selectModifyOptions, setSelectModifyOptions] = useState(
      modifyOptions(currentPage, status, user)
    );

    const { enqueueSnackbar } = useSnackbar();

    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [copyConfirmation, setCopyConfirmation] = useState(false);

    const { fields, remove } = useFieldArray({
      control,
      name: "employee_list",
    });

    const currentWarehouse = useWatch({
      control,
      name: "destination_warehouse_document_id",
    });

    const warehouseOption = allLocation?.map((location) => {
      return {
        id: location.id,
        label: location.thai_name,
        value: location.document_id,
      };
    });

    const mapWarehouseIdToName = () => {
      const findId = allLocation?.find(
        (location) => location.document_id === currentWarehouse
      );
      if (findId) return findId.thai_name;
      else return "";
    };

    const printOptions = ["พิมพ์เอกสาร"];

    useEffect(() => {
      if (fieldsToDisable.due_date === "hide" || fieldsToDisable.due_date) {
        return;
      }
      if (fieldsToDisable.issue_date === "hide" || fieldsToDisable.issue_date) {
        return;
      }
    }, [fieldsToDisable]);

    useEffect(() => {
      if (!watchCreatorDocumentId) {
        setMainValue("creator_document_id", user.document_id);
        setMainValue("created_by", {
          document_id: user.document_id,
          first_name: user.first_name,
          last_name: user.last_name,
          img_url: user.img_url,
        });
      }
    }, [watchCreatorDocumentId, setMainValue, user]);

    useEffect(() => {
      if (
        pathname.split("/").at(-1) === "add" &&
        watchRelatedEmployees &&
        watchRelatedEmployees.length === 0
      ) {
        // setValue("employee_list", [{ ...user }]);
        setEmployeeIds([user.document_id]);
        setEmployeeIdsSnapshot([user.document_id]);
      } else {
        if (watchCreatorDocumentId) {
          const mappedEmployeeIds =
            getValues("employee_list")?.map(
              (employee) => employee?.document_id
            ) ?? [];
          setEmployeeIds([watchCreatorDocumentId, ...mappedEmployeeIds]);
          setEmployeeIdsSnapshot([
            watchCreatorDocumentId,
            ...mappedEmployeeIds,
          ]);
        }
      }
    }, [
      pathname,
      watchRelatedEmployees,
      watchCreatorDocumentId,
      user,
      setValue,
      getValues,
    ]);

    useEffect(() => {
      setFieldsToDisable(shouldDisable(currentPage, status));
      setSelectCreateOptions(createOptions(currentPage, status, user));
      setSelectModifyOptions(modifyOptions(currentPage, status, user));
    }, [currentPage, status, user]);

    const generateDocumentId = useCallback(async () => {
      if (viewOnly) {
        return;
      }
      try {
        const document_id = await GlobalService.getRunningDocumentId(
          documentType
        );
        setValue("document_id", document_id);
      } catch (err) {
        console.log(err);
      }
    }, [documentType, setValue, viewOnly]);

    useEffect(() => {
      if (pathname.split("/").at(-1) === "add") {
        generateDocumentId();
      }
    }, [pathname, generateDocumentId]);

    const openEmployeeTable = () => {
      setShowSelectEmployees(true);
    };

    const closeEmployeeTable = () => {
      setShowSelectEmployees(false);
    };

    const getRowId = useCallback((params) => {
      return params.data.document_id;
    }, []);

    const finishEmployeesSelect = (data) => {
      // filter out employees not selected in current modal session
      let filteredEmployees = fields.filter((employee) =>
        employeeIds.includes(employee.document_id)
      );

      // get current employees id to prevent duplication when appending newly selected employees
      const filteredEmployeesId = filteredEmployees.map(
        (employee) => employee.document_id
      );

      data.forEach((employee) => {
        if (!filteredEmployeesId.includes(employee.document_id)) {
          filteredEmployees.push(employee);
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredEmployees = filteredEmployees.sort((a, b) => {
        return (
          employeeIds.indexOf(a.document_id) -
          employeeIds.indexOf(b.document_id)
        );
      });
      setValue(
        "employee_list",
        filteredEmployees.filter(
          (employee) => employee.document_id !== watchCreatorDocumentId
        )
      );
      setShowSelectEmployees(false);
    };

    const getHeaderName = () => {
      switch (currentPage) {
        case "request":
          return t("purchase.request.index");
        case "order":
          return t("purchase.order.index");
        case "invoice":
          return t("purchase.invoice.index");
        case "payment-made":
          return t("purchase.paymentMade.index");
        case "return":
          return t("purchase.return.index");
        default:
          return;
      }
    };

    const getDueDateLabel = () => {
      return "ต้องการภายในวันที่";
    };

    const removeEmployee = (index) => {
      remove(index);
      setEmployeeIds((prevIds) => [
        ...prevIds.slice(0, index),
        ...prevIds.slice(index + 1),
      ]);
      setEmployeeIdsSnapshot((prevIds) => [
        ...prevIds.slice(0, index),
        ...prevIds.slice(index + 1),
      ]);
    };

    const renderAvatars = () => {
      return fields.map((item, index) => {
        return (
          <RelatedEmployeeIcon
            key={item.id}
            first_name={item.first_name}
            last_name={item.last_name}
            index={index}
            avatar={item.img_url}
            remove={removeEmployee}
            viewOnly={viewOnly}
          />
        );
      });
    };

    const renderCreatorAvatar = () => {
      if (watchCreatorDocumentId) {
        return (
          <RelatedEmployeeIcon
            isMain={watchCreatedBy && fields.length > 0}
            first_name={watchCreatedBy?.first_name}
            last_name={watchCreatedBy?.last_name}
            avatar={watchCreatedBy?.img_url}
          />
        );
      } else {
        return null;
      }
    };

    // const removeRefDocByIndex = (index) => {
    //   const refDocId = getValues("reference_document_id");
    //   const newRefDocId = refDocId.filter((_, ind) => ind !== index);
    //   setValue("reference_document_id", newRefDocId);
    // };

    const columnDefs = [
      {
        field: "document_id",
        headerName: t("user.account.employeeId"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.picture"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params) => {
          if (params.data.img_url) {
            return <Avatar alt="img_url" src={params.data.img_url} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.data.first_name + " " + params.data.last_name}
              />
            );
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "first_name",
        headerName: t("user.account.firstname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.lastname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "บริหาร",
            "คลังสินค้า",
            "รายรับ",
            "จัดซื้อ",
            "บัญชี",
            "ขนส่ง",
            "ผลิต",
            "ดูแลระบบ",
          ],
        },
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "status",
        headerName: t("user.account.status"),
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return <CustomizedStatus status={params.value} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ];

    const datasource = {
      getRows(params) {
        const request = params.request;
        dispatch(
          getAllUsers(
            {
              startRow: request.startRow,
              endRow: request.endRow,
              filterModel: {
                ...request.filterModel,
                document_id: {
                  ...request.filterModel.document_id,
                  mode: "insensitive",
                },
                first_name: {
                  ...request.filterModel.first_name,
                  mode: "insensitive",
                },
                last_name: {
                  ...request.filterModel.last_name,
                  mode: "insensitive",
                },
              },
              sortModel: request.sortModel,
            },
            params
          )
        );
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const creatorRow =
      pathname.split("/").at(-1) === "add"
        ? [user.document_id]
        : [watchCreatorDocumentId];

    const closeCancelConfirmationHandler = () => {
      setCancelConfirmation(false);
    };

    const cancelConfirmationAction = () => {
      // TODO: Cancel API
      closeCancelConfirmationHandler();
      const payload = {
        document_id: id,
        creator_document_id: user.document_id,
        render_status: status,
      };
      switch (currentPage) {
        case "request":
          dispatch(
            cancelPurchaseRequest(
              {
                document_type: "purchase_request",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        case "order":
          dispatch(
            cancelPurchaseOrder(
              {
                document_type: "purchase_order",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar,
              getMainValues("render_status"),
              getMainValues("item_list"),
              setMainValue,
              gridRef
            )
          );
          break;
        case "return":
          dispatch(
            cancelPurchaseReturn(
              {
                document_type: "purchase_return",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        default:
          break;
      }
    };

    const closeConfirmationHandler = () => {
      setCopyConfirmation(false);
    };

    const copyConfirmationAction = () => {
      switch (documentType) {
        case "purchase_request":
          navigate("/purchase/request/add", {
            state: { ...currentState, isCopied: true },
          });
          break;
        case "purchase_order":
          navigate("/purchase/order/add", {
            state: { ...currentState, isCopied: true },
          });
          break;
        case "purchase_return":
          navigate("/purchase/return/add", {
            state: { ...currentState, isCopied: true },
          });
          break;
        default:
      }
      enqueueSnackbar("คัดลอกสำเร็จ", {
        variant: "success",
      });
    };

    const openApprovalLogHandler = () => {
      setOpenDrawer(true);
      setIsApproval(true);
    };

    const referenceNavigation = (currentDocument, currentDocumentId) => {
      switch (currentDocument) {
        case "manufacture-order":
          window.open(
            `/manufacture/order/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "purchase_request":
          window.open(
            `/purchase/request/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "purchase_order":
          window.open(
            `/purchase/order/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "purchase_return":
          window.open(
            `/purchase/return/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "sales_order":
          window.open(
            `/sales/order/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        default:
          return "";
      }
    };

    const handleDeleteRefDoc = (refId) => {
      const itemList = getMainValues("item_list");
      const filterItem = itemList.filter(
        (item) => item.ref_document_id !== refId
      );
      setValue(
        "reference_document_list",
        watchReferenceDocumentList.filter(
          (list) => list.reference_document_id !== refId
        )
      );
      const refDocList = watchReferenceDocumentList
        .filter((list) => list.reference_document_id !== refId)
        .map((list) => list.reference_document_id);
      if (documentType === "purchase_request") {
        setSalesOrderIds(refDocList);
        setSalesOrderIdsSnapshot(refDocList);
      } else if (documentType === "purchase_order") {
        setPurchaseRequestIds(refDocList);
        setPurchaseRequestIdsSnapshot(refDocList);
      }
      setMainValue("item_list", filterItem);
    };

    const formatApprovalList =
      approvalList && approvalList.length > 0
        ? approvalList.filter((approval) => approval.approval_status)
        : [];

    return (
      <>
        <Grid container spacing={2} justifyContent="space-between" ref={ref}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
                {getHeaderName()}
              </Typography>
              <Box sx={{ ml: 2 }}>
                <CustomizedStatus status={status} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {!(fieldsToDisable.printOption === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3.25} xl={3}>
                  <CustomizedMenuOption
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      if (e.target.innerText === "พิมพ์เอกสาร") {
                        switch (documentType) {
                          case "purchase_request":
                            navigate(
                              `/purchase/request/${encodeURIComponent(id)}/pdf`
                            );
                            break;
                          case "purchase_order":
                            navigate(
                              `/purchase/order/${encodeURIComponent(id)}/pdf`
                            );
                            break;
                          default:
                            console.log("print");
                        }
                      }
                      if (setIsEdit) {
                        setIsEdit(false);
                      }
                    }}
                    disabled={fieldsToDisable.printOption}
                  />
                </Grid>
              )}
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <CustomizedMenuOption
                  fullWidth
                  size="medium"
                  label={"ตัวเลือก"}
                  options={selectModifyOptions}
                  onSelect={(e) => {
                    switch (e.target.innerText) {
                      case "ยกเลิก":
                        setCancelConfirmation(true);
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        break;
                      case "คัดลอก":
                        setCopyConfirmation(true);
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        break;
                      case "แก้ไข":
                        editClickHandler();
                        break;
                      case "บันทึกใบบันทึกซื้อ":
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        setOpenPurchaseInvoiceConfirmation(true);
                        break;
                      case "บันทึกใบเพิ่มหนี้":
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        setOpenDebitNoteConfirmation(true);
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={fieldsToDisable.modifyOption}
                />
              </Grid>
              {!(fieldsToDisable.createOption === "hide") && (
                <Grid item xs={6} sm={6} md={3} lg={2.5} xl={2}>
                  <CustomizedMenuOption
                    fullWidth
                    size="medium"
                    label={"สร้าง"}
                    options={selectCreateOptions}
                    onSelect={(e) => {
                      switch (e.target.innerText) {
                        case "ใบสั่งซื้อ":
                          navigate("/purchase/order/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              creator_document_id: user.document_id,
                              created_by: {
                                document_id: user.document_id,
                                first_name: user.first_name,
                                last_name: user.last_name,
                                img_url: user.img_url,
                              },
                              reference_document_type: "purchase_request",
                            },
                          });
                          break;
                        case "ใบนำเข้า":
                          navigate("/inventory/receive/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "purchase_order",
                            },
                          });
                          break;
                        case "ใบส่งคืน":
                          navigate("/purchase/return/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "purchase_order",
                            },
                          });
                          break;
                        case "ใบนำออก":
                          navigate("/inventory/issue/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "purchase_return",
                            },
                          });
                          break;
                        default:
                      }
                    }}
                    disabled={fieldsToDisable.createOption}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.activity_log === "hide") && (
                <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                  <CustomizedTooltip
                    title="ดูความเคลื่อนไหว"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={() => {
                        setOpenDrawer(true);
                      }}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <RestoreOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              {!(fieldsToDisable.approval === "hide") &&
                approvalList &&
                approvalList.length > 0 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl={3}
                      alignSelf="center"
                    >
                      <Typography fontWeight="bold" sx={{ ml: 1 }}>
                        ผู้อนุมัติ
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                      sm={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                      md={9}
                      lg={9}
                      xl={9}
                    >
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {approvalList &&
                          approvalList.length > 0 &&
                          approvalList.map((approval, index) => (
                            <CustomizedMuliChip
                              approval={approval}
                              key={index}
                            />
                          ))}
                      </Box>
                      {approvalList &&
                        approvalList.length > 0 &&
                        approvalList[0].approval_status && (
                          <Typography
                            variant="button"
                            sx={{ color: "#328F35", cursor: "pointer" }}
                            onClick={openApprovalLogHandler}
                          >
                            ดูประวัติ
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={3}
                xl={3}
                alignSelf="center"
              >
                <Typography fontWeight="bold" sx={{ ml: 1 }}>
                  {t("purchase.documentId")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                sm={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                md={6}
                lg={6}
                xl={6}
              >
                <Controller
                  name="document_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.document_id)}
                      helperText={errors.document_id?.message}
                      {...field}
                      disabled={viewOnly || fieldsToDisable.document_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                {!viewOnly && !fieldsToDisable.document_id && (
                  <CustomizedTooltip
                    title="เรียกเลขที่เอกสารใหม่"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={generateDocumentId}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={3}
                xl={3}
                alignSelf="center"
              >
                <Typography fontWeight="bold" sx={{ ml: 1 }}>
                  {"อ้างอิงถึง"}
                </Typography>
              </Grid>
              {!fieldsToDisable.reference_document && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6.01} xl={6.01}>
                    {documentType !== "purchase_return" ? (
                      // Step 1: Document Relation will return as array
                      watchReferenceDocumentList &&
                      watchReferenceDocumentList?.length > 0 ? (
                        // Step 2: From state(useLocation) should format as array too
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            width: "65vw",
                          }}
                        >
                          {watchReferenceDocumentList.map((ref) => (
                            <CustomizedChips
                              onClick={() =>
                                referenceNavigation(
                                  ref.reference_document_type,
                                  ref.reference_document_id
                                )
                              }
                              value={ref.reference_document_id}
                              color="primary"
                              handleDelete={
                                viewOnly
                                  ? null
                                  : () =>
                                      handleDeleteRefDoc(
                                        ref.reference_document_id
                                      )
                              }
                            />
                          ))}
                        </Box>
                      ) : (
                        // Step 3: When both document relation and state were connected, the pipeline should be worked
                        "-"
                      )
                    ) : // Step 1: Document Relation will return as array
                    watchReferenceDocumentId &&
                      watchReferenceDocumentId?.length > 0 ? (
                      // Step 2: From state(useLocation) should format as array too
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                          width: "65vw",
                        }}
                      >
                        {watchReferenceDocumentId.map((reference) => (
                          <CustomizedChips
                            onClick={() =>
                              referenceNavigation(
                                reference.reference_document_type,
                                reference.reference_document_id
                              )
                            }
                            value={reference.reference_document_id}
                            color="primary"
                          />
                        ))}
                      </Box>
                    ) : (
                      // Step 3: When both document relation and state were connected, the pipeline should be worked
                      "-"
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={3}
                    xl={3}
                    alignSelf="center"
                  >
                    <Typography fontWeight="bold" sx={{ ml: 1 }}>
                      {t("sales.externalRefDocumentId")}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="external_ref_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.external_ref_id)}
                      helperText={errors.external_ref_id?.message}
                      {...field}
                      disabled={viewOnly || fieldsToDisable.external_ref_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {!(fieldsToDisable.created_date === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <ControlledDatePicker
                    name="created_date"
                    control={control}
                    error={errors.created_date}
                    isOpen={createdAtDateisOpen}
                    onClose={() => setCreatedAtDateisOpen(false)}
                    onOpen={() => setCreatedAtDateisOpen(true)}
                    label="วันที่สร้าง"
                    disabled={
                      (!isEdit && fieldsToDisable.created_date) || viewOnly
                    }
                  />
                </Grid>
              )}
              {!(fieldsToDisable.issue_date === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <ControlledDatePicker
                    name="issue_date"
                    control={control}
                    error={errors.issue_date}
                    isOpen={issueDateIsOpen}
                    onClose={() => setIssueDateIsOpen(false)}
                    onOpen={() => setIssueDateIsOpen(true)}
                    label="วันที่ออกเอกสาร"
                    required
                    disabled={
                      (!isEdit && fieldsToDisable.issue_date) || viewOnly
                    }
                  />
                </Grid>
              )}
              {!(fieldsToDisable.expect_date === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <ControlledDatePicker
                    name="expect_date"
                    control={control}
                    error={errors.expect_date}
                    isOpen={expectDateIsOpen}
                    onClose={() => setExpectDateIsOpen(false)}
                    onOpen={() => setExpectDateIsOpen(true)}
                    label="วันประมาณการณ์สินค้าเข้า"
                    required
                    disabled={
                      (!isEdit && fieldsToDisable.expect_date) || viewOnly
                    }
                  />
                </Grid>
              )}
              {!(fieldsToDisable.due_date === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <ControlledDatePicker
                    name="due_date"
                    control={control}
                    error={errors.due_date}
                    isOpen={dueDateIsOpen}
                    onClose={() => setDueDateIsOpen(false)}
                    onOpen={() => setDueDateIsOpen(true)}
                    label={getDueDateLabel()}
                    required
                    disabled={(!isEdit && fieldsToDisable.due_date) || viewOnly}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.delivery_date === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <ControlledDatePicker
                    name="delivery_date"
                    control={control}
                    error={errors.delivery_date}
                    isOpen={deliveryDateIsOpen}
                    onClose={() => setDeliveryDateIsOpen(false)}
                    onOpen={() => setDeliveryDateIsOpen(true)}
                    label={t("purchase.return.deliveryDate")}
                    required
                    disabled={
                      (!isEdit && fieldsToDisable.delivery_date) || viewOnly
                    }
                  />
                </Grid>
              )}
            </Grid>
            {!(fieldsToDisable.credit_day === "hide") && (
              <Grid
                container
                spacing={2}
                sx={{ mt: 0.5 }}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                  <Controller
                    name="credit_day"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        type="number"
                        fullWidth
                        error={Boolean(errors.credit_day)}
                        helperText={errors.credit_day?.message}
                        label="เครดิต (วัน)"
                        {...field}
                        disabled={
                          (!isEdit && fieldsToDisable.credit_day) || viewOnly
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isReturn && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={2.5} lg={1.5} xl={1.5}>
              <Typography
                fontWeight="bold"
                sx={{ ml: 1, mt: { xs: 0, md: 1 } }}
              >
                {t("purchase.warehouseTarget")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3.5} xl={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Controller
                    name="destination_warehouse_document_id"
                    control={control}
                    render={({ field }) => (
                      <CustomizedSelect
                        options={warehouseOption}
                        error={Boolean(
                          errors.destination_warehouse_document_id
                        )}
                        helperText={
                          errors.destination_warehouse_document_id?.message
                        }
                        value={mapWarehouseIdToName()}
                        label="คลังปลายทาง"
                        disabled={
                          (!isEdit &&
                            fieldsToDisable.destination_warehouse_document_id) ||
                          viewOnly
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={1.5}>
            <Typography fontWeight="bold" sx={{ ml: 1, mt: { xs: 0, md: 1 } }}>
              {t("sales.employeeList")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
            <Grid container spacing={1}>
              {renderCreatorAvatar()}
              {renderAvatars()}
              {!viewOnly && !fieldsToDisable.employee_list && (
                <Grid item>
                  <CustomizedTooltip
                    title="เพิ่มผู้เกี่ยวข้อง"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={openEmployeeTable}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <ControlPointOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <CheckboxModalTable
          modalTitle="ผู้เกี่ยวข้อง"
          btnTitle={t("button.add")}
          gridRef={selectEmployeesGridRef}
          height={450}
          columnDefs={columnDefs}
          rowSelection="multiple"
          onFinishEditing={finishEmployeesSelect}
          modalIsOpen={showSelectEmployees}
          getRowId={getRowId}
          closeModal={closeEmployeeTable}
          onGridReady={onGridReady}
          selectedIds={employeeIds}
          setSelectedIds={setEmployeeIds}
          idsSnapshot={employeeIdsSnapshot}
          setIdsSnapshot={setEmployeeIdsSnapshot}
          lockRows={creatorRow}
        />
        <CancelConfirmation
          openCancelConfirmation={cancelConfirmation}
          cancelConfirmationAction={cancelConfirmationAction}
          closeCancelConfirmationHandler={closeCancelConfirmationHandler}
        />
        <CopyConfirmation
          openCopyConfirmation={copyConfirmation}
          copyConfirmationAction={copyConfirmationAction}
          closeCopyConfirmationHandler={closeConfirmationHandler}
        />
        <RightDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
            setIsApproval(false);
          }}
          title={isApproval ? "ประวัติการอนุมัติ" : t("inventory.activity")}
          documentId={id}
          isApproval={isApproval}
          approvalLogs={formatApprovalList}
          documentType={documentType}
        />
      </>
    );
  }
);

export default PurchaseHeaderForm;
