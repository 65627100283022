import { Box } from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { validationConversion } from "./validation";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const ConversionForm = forwardRef(
  (
    {
      control,
      errors,
      setValueForm,
      getValues,
      watchBaseUom,
      watchConversion,
      disabled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { allUoms } = useSelector((state) => state.uom);

    const { fields, append, replace } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "uom_conversion_list", // unique name for your Field Array
    });

    const allUomConversion = watchConversion?.map(
      (uom) => uom.target_uom?.name
    );

    useEffect(() => {
      const newValue = getValues("uom_conversion_list");
      replace(newValue);
    }, [getValues, replace]);

    const filterUom = allUoms
      ?.map((uom) => {
        return {
          ...uom,
          id: uom.document_id,
          name: uom.name,
        };
      })
      .filter((uom) => uom.id !== watchBaseUom.name)
      .filter((uom) => !allUomConversion.includes(uom.name));

    const handleAppendData = () => {
      append({
        base_uom_rate: 1,
        target_uom_rate: 1,
        base_uom_document_id: watchBaseUom.name,
        target_uom: {
          document_id: filterUom[0].document_id,
          name: filterUom[0].name,
        },
      });
    };

    const mapValueUom = (value) => {
      const mapValue = allUoms.find((uom) => uom.document_id === value);
      if (mapValue) return mapValue.name || "";
      else return "";
    };

    const columnDefs = [
      {
        field: "base_uom_rate",
        headerName: t("setting.inventory.conversion.quantity"),
        width: 230,
        cellRenderer: (params) => {
          return (
            <Box width={230} sx={{ mt: "2px" }}>
              <Controller
                name={`uom_conversion_list[${params.node.rowIndex}].base_uom_rate`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    testId={`item-conversion-base-rate-text-field-${params.node.rowIndex}`}
                    type="number"
                    // error={Boolean(errors[params.node.rowIndex]?.base_uom_rate)}
                    // helperText={
                    //   errors[params.node.rowIndex]?.base_uom_rate?.message
                    // }
                    disabled={disabled}
                    defaultValue=""
                    {...field}
                    onChange={(e) => {
                      if (e.target.value !== null)
                        setValueForm(
                          `uom_conversion_list[${params.node.rowIndex}].base_uom_rate`,
                          parseFloat(e.target.value)
                        );
                      else
                        setValueForm(
                          `uom_conversion_list[${params.node.rowIndex}].base_uom_rate`,
                          e.target.value
                        );
                    }}
                  />
                )}
              />
            </Box>
          );
        },
      },
      {
        field: "base_uom_document_id",
        headerName: t("setting.inventory.conversion.baseUom"),
        width: 230,
        cellRenderer: (params) => {
          return (
            <Box width={230} sx={{ mt: "2px" }} alignItems={"center"}>
              <CustomizedComboBox
                disabled
                options={
                  allUoms?.map((uom) => {
                    return {
                      id: uom.document_id,
                      value: uom.document_id,
                      label: uom.name,
                    };
                  }) || []
                }
                value={watchBaseUom.name}
              />
            </Box>
          );
        },
      },
      {
        field: "target_uom_rate",
        headerName: t("setting.inventory.conversion.quantityConversion"),
        width: 230,
        cellRenderer: (params) => {
          return (
            <Box width={230} sx={{ mt: "2px" }}>
              <Controller
                control={control}
                name={`uom_conversion_list[${params.node.rowIndex}].target_uom_rate`}
                render={({ field }) => (
                  <CustomizedTextField
                    testId={`item-conversion-target-rate-text-field-${params.node.rowIndex}`}
                    type={"number"}
                    error={Boolean(
                      errors.uom_conversion_list?.[params.node.rowIndex]
                        ?.target_uom_rate
                    )}
                    helperText={
                      errors.uom_conversion_list?.[params.node.rowIndex]
                        ?.target_uom_rate?.message
                    }
                    defaultValue=""
                    disabled={disabled}
                    {...field}
                    onChange={(e) => {
                      if (e.target.value !== null)
                        setValueForm(
                          `uom_conversion_list[${params.node.rowIndex}].target_uom_rate`,
                          parseFloat(e.target.value)
                        );
                      else
                        setValueForm(
                          `uom_conversion_list[${params.node.rowIndex}].target_uom_rate`,
                          e.target.value
                        );
                    }}
                  />
                )}
              />
            </Box>
          );
        },
      },
      {
        field: "target_uom_document_id",
        headerName: t("setting.inventory.conversion.conversionUom"),
        floatingFilter: false,
        width: 230,
        cellRenderer: (params) => {
          return (
            <Box width={230} sx={{ mt: "2px" }}>
              <Controller
                name={`uom_conversion_list[${params.node.rowIndex}].target_uom.document_id`}
                error={errors}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    testId={`item-conversion-target-uom-field-${params.node.rowIndex}`}
                    options={allUoms
                      ?.map((uom) => {
                        return {
                          id: uom.document_id,
                          value: uom.document_id,
                          label: uom.name,
                          name: uom.name,
                        };
                      })
                      .filter((uom) => uom.id !== watchBaseUom.name)
                      .filter((uom) => !allUomConversion.includes(uom.name))}
                    {...field}
                    disabled={disabled}
                    value={mapValueUom(field.value)}
                    onChange={(_, newValue) => {
                      field.onChange(newValue ? newValue.id : "");
                      setValueForm(
                        `uom_conversion_list[${params.node.rowIndex}].target_uom.name`,
                        newValue.name
                      );
                    }}
                  />
                )}
              />
            </Box>
          );
        },
      },
      {
        field: "",
        headerName: "",
        width: 90,
        cellRenderer: (params) =>
          !disabled && (
            <>
              <IconButton
                data-test-id={`item-conversion-delete-btn-${params.node.rowIndex}`}
                onClick={() => {
                  const newValue = fields.filter(
                    (_, index) => index !== params.node.rowIndex
                  );
                  replace(newValue);
                }}
              >
                <ClearIcon />
              </IconButton>
            </>
          ),
      },
    ];

    return (
      <>
        {!disabled && (
          <CustomizedButton
            testId="item-add-conversion-btn"
            color="secondary"
            onClick={handleAppendData}
            title={t("button.add")}
            variant="outlined"
            sx={{ mt: 1, mr: 2 }}
            disabled={!watchBaseUom || filterUom.length === 0}
          />
        )}
        <Box sx={{ my: disabled ? 0 : 1 }}>
          <div
            className="ag-theme-material"
            style={{ height: 400, width: "auto" }}
          >
            <AgGridReact ref={ref} rowData={fields} columnDefs={columnDefs} />
          </div>
        </Box>
      </>
    );
  }
);

export default ConversionForm;
