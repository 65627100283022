import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import Inventory from "./pages/Inventory";
import Items from "./pages/Inventory/Items";
import ItemsImporterWithUomGroup from "./pages/Inventory/Items/ImporterWithUomGroup";
import GoodsReceive from "./pages/Inventory/Receive";
import GoodsReceiveReturn from "./pages/Inventory/Return";
import GoodsIssue from "./pages/Inventory/Issue";
import DeliveryTable from "./pages/Inventory/deliveryTable";
import GoodsTransfer from "./pages/Inventory/Transfer";
import Adjustment from "./pages/Inventory/Adjustment";
import InventoryReport from "./pages/Inventory/Report";
import Contact from "./pages/Contact";
import Contacts from "./pages/Contact/contacts";
import NewContactContainer from "./pages/Contact/NewContactContainer";
import ContactImporter from "./pages/Contact/Importer";
import ContactReportIndex from "./pages/Contact/Report";
import ContactReportPage from "./pages/Contact/Report/ContactReportPage";
import Purchase from "./pages/Purchase";
import PurchaseRequest from "./pages/Purchase/Request";
import PurchaseOrder from "./pages/Purchase/Order";
import PurchaseReturn from "./pages/Purchase/Return";
import PurchaseReport from "./pages/Purchase/Report";
import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import Rbac from "./pages/User/Rbac";
import Setting from "./pages/Setting";
import Login from "./pages/Login";
import RequireAuth from "./pages/RequireAuth";
import AccountContainer from "./pages/User/Account/Container";
import ItemMasterContainer from "./pages/Inventory/Items/Container";
import { useAuth, usePermission } from "./hooks/use-auth";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import RbacContainer from "./pages/User/Rbac/Container";
import GoodsReceiveContainer from "./pages/Inventory/Receive/Container";
import GoodsReceiveReturnContainer from "./pages/Inventory/Return/Container";
import AdjustmentContainer from "./pages/Inventory/Adjustment/Container";
import GoodsTransferContainer from "./pages/Inventory/Transfer/Container";
import DashboardLayout from "./components/Layout/DashboardLayout";
import Conversion from "./pages/Setting/Inventory/Conversion";
import Attribute from "./pages/Setting/Inventory/Attribute";
import InventorySetting from "./pages/Setting/Inventory";
import UomGroup from "./pages/Setting/Inventory/UomGroup";
import Uom from "./pages/Setting/Inventory/Uom";
import UomGroupImporter from "./pages/Setting/Inventory/UomGroupImporter";
import Location from "./pages/Setting/Inventory/Location";
import LocationImporter from "./pages/Setting/Inventory/Location/importer";
import Category from "./pages/Setting/Inventory/Category";
import CategoryImporter from "./pages/Setting/Inventory/Category/importer";
import NotFound from "./pages/NotFound";
import GoodsReceiptReport from "./pages/Inventory/Report/GoodsReceiptReport";
import GoodsReceiptReturnReport from "./pages/Inventory/Report/GoodsReceiptReturnReport";
import GoodsIssueReport from "./pages/Inventory/Report/GoodsIssueReport";
import GoodsTransferReport from "./pages/Inventory/Report/GoodsTransferReport";
import GoodsAdjustmentReport from "./pages/Inventory/Report/GoodsAdjustmentReport";
import StockEntry from "./pages/Inventory/Report/StockEntryReport";
import TraceEntry from "./pages/Inventory/Report/TraceEntryReport";
import ItemNoEntry from "./pages/Inventory/Report/ItemNoEntry";
import ItemCurrentStockLocation from "./pages/Inventory/Report/ItemCurrentStockLocation";
import ItemCurrentStockSerialNumber from "./pages/Inventory/Report/ItemCurrentStockSerialNumber";
import NotActiveSerialNumber from "./pages/Inventory/Report/NotActiveSerialNumber";
import ItemCurrentStock from "./pages/Inventory/Report/ItemCurrentStock";
import ItemMasterReport from "./pages/Inventory/Report/ItemMasterReport";
import ApprovalSetting from "./pages/Setting/Approval";
import SalesApproval from "./pages/Setting/Approval/Sales";
import PurchaseApproval from "./pages/Setting/Approval/Purchase";
import LogisticApproval from "./pages/Setting/Approval/Logistic";
import ManufactureApproval from "./pages/Setting/Approval/Manufacture";
import CompanyInfoSetting from "./pages/Setting/CompanyInfo";
import Approval from "./pages/Approval";
import SalesTemplate from "./pages/Setting/Remark/Sales";
import PurchaseTemplate from "./pages/Setting/Remark/Purchase";
import LogisticTemplate from "./pages/Setting/Remark/Logistic";
import { RemoveTrailingSlash } from "./pages/RemoveTrailingSlash";
import GoodsIssueContainer from "./pages/Inventory/Issue/Container";
import GoodsTransferPDF from "./pages/Inventory/Transfer/Pdf";
import AuthVerify from "./pages/AuthVerify";
import PurchaseRequestContainer from "./pages/Purchase/Request/Container";
import PurchaseOrderContainer from "./pages/Purchase/Order/Container";
import PurchaseReturnContainer from "./pages/Purchase/Return/Container";
import PurchaseOrderPDF from "./pages/Purchase/Order/Pdf";
import PurchaseRequestReport from "./pages/Purchase/Report/RequestReport";
import PurchaseOrderReport from "./pages/Purchase/Report/OrderReport";
import PurchaseReturnReport from "./pages/Purchase/Report/ReturnReport";
import PurchaseByVendor from "./pages/Purchase/Report/PurchaseByVendor";
import PurchaseByItem from "./pages/Purchase/Report/PurchaseByItem";
import PurchaseRequestPDF from "./pages/Purchase/Request/Pdf";
import RemarkSetting from "./pages/Setting/Remark";
import ManufactureSetting from "./pages/Setting/Manufacture";
import WorkCenterSetting from "./pages/Setting/Manufacture/WorkCenter";
import ManufactureTemplate from "./pages/Setting/Remark/Manufacture";
import Unauthorized from "./pages/Unauthoried";
import Landing from "./pages/Landing";
import Engineer from "./pages/Engineer";
import Estimate from "./pages/Engineer/Estimate";
import EstimateContainer from "./pages/Engineer/Estimate/Container";
import BarcodePdf from "./pages/Inventory/Receive/BarcodePdf";

function App() {
  const { isInitialized, isAuthenticated } = useAuth();
  const {
    itemPermission,
    goodsReceivePermission,
    goodsReceiveReturnPermission,
    goodsIssuePermission,
    goodsAdjustmentPermission,
    goodsTransferPermission,
    inventoryReportPermission,
    contactPermission,
    // customerPermission,
    // vendorPermission,
    contactReportPermission,
    purchaseRequestPermission,
    purchaseOrderPermission,
    purchaseReturnPermission,
    purchaseReportPermission,
    accountPermission,
    rbacPermission,
    companyInfoPermission,
    approvalSettingPermission,
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    manufactureSettingPermission,
  } = usePermission();

  return (
    <>
      {!isInitialized && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {isInitialized && (
        <>
          <RemoveTrailingSlash />
          <AuthVerify />
          <Routes>
            <Route
              path="/"
              element={
                isInitialized && isAuthenticated ? (
                  <Navigate replace to="/landing" />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route
              path="/login"
              element={
                isInitialized && isAuthenticated ? (
                  <Navigate replace to="/landing" />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              element={
                <RequireAuth allowedPermissions={["GENERAL__GENERAL__VIEW"]} />
              }
            >
              <Route element={<DashboardLayout />}>
                <Route path="/landing" element={<Landing />} />
                <Route path="/inventory">
                  <Route index element={<Inventory />} />
                  <Route
                    element={
                      <RequireAuth allowedPermissions={[itemPermission.VIEW]} />
                    }
                  >
                    <Route path="items">
                      <Route index element={<Items />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[itemPermission.CREATE]}
                          />
                        }
                      >
                        <Route path="add" element={<ItemMasterContainer />} />
                        <Route
                          path="importer"
                          element={<ItemsImporterWithUomGroup />}
                        />
                      </Route>
                      <Route path=":id" element={<ItemMasterContainer />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[goodsReceivePermission.VIEW]}
                      />
                    }
                  >
                    <Route path="receive">
                      <Route index element={<GoodsReceive />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[goodsReceivePermission.CREATE]}
                          />
                        }
                      >
                        <Route path="add" element={<GoodsReceiveContainer />} />
                      </Route>
                      <Route path=":id" element={<GoodsReceiveContainer />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[goodsReceiveReturnPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="return">
                      <Route index element={<GoodsReceiveReturn />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              goodsReceiveReturnPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<GoodsReceiveReturnContainer />}
                        />
                      </Route>
                      <Route
                        path=":id"
                        element={<GoodsReceiveReturnContainer />}
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[goodsIssuePermission.VIEW]}
                      />
                    }
                  >
                    <Route path="issue">
                      <Route index element={<GoodsIssue />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[goodsIssuePermission.CREATE]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<GoodsIssueContainer isCreate />}
                        />
                      </Route>
                      <Route path=":id" element={<GoodsIssueContainer />} />
                    </Route>
                  </Route>

                  <Route path="delivery-table">
                    <Route index element={<DeliveryTable />} />
                  </Route>

                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[goodsAdjustmentPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="adjustment">
                      <Route index element={<Adjustment />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              goodsAdjustmentPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<AdjustmentContainer isCreate />}
                        />
                      </Route>
                      <Route path=":id" element={<AdjustmentContainer />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[goodsTransferPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="transfer">
                      <Route index element={<GoodsTransfer />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              goodsTransferPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<GoodsTransferContainer isCreate />}
                        />
                      </Route>
                      <Route path=":id">
                        <Route index element={<GoodsTransferContainer />} />
                        <Route path="pdf" element={<GoodsTransferPDF />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[inventoryReportPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="report">
                      <Route index element={<InventoryReport />} />
                      <Route path="items" element={<ItemMasterReport />} />
                      <Route path="receive" element={<GoodsReceiptReport />} />
                      <Route
                        path="return"
                        element={<GoodsReceiptReturnReport />}
                      />
                      <Route path="issue" element={<GoodsIssueReport />} />
                      <Route
                        path="transfer"
                        element={<GoodsTransferReport />}
                      />
                      <Route
                        path="adjustment"
                        element={<GoodsAdjustmentReport />}
                      />
                      <Route path="stock-entry" element={<StockEntry />} />
                      <Route path="trace-entry" element={<TraceEntry />} />
                      <Route path="items-no-entry" element={<ItemNoEntry />} />
                      <Route
                        path="itemcurrent-stock"
                        element={<ItemCurrentStock />}
                      />
                      <Route
                        path="itemcurrent-stock-location"
                        element={<ItemCurrentStockLocation />}
                      />
                      <Route
                        path="itemcurrent-stock-serial-number"
                        element={<ItemCurrentStockSerialNumber />}
                      />
                      <Route
                        path="not-active-serial-number"
                        element={<NotActiveSerialNumber />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedPermissions={[contactPermission.VIEW]}
                    />
                  }
                >
                  <Route path="/contact">
                    <Route index element={<Contact />} />
                    <Route path="contacts" element={<Contacts />} />
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[contactPermission.CREATE]}
                        />
                      }
                    >
                      <Route path="add" element={<NewContactContainer />} />
                    </Route>
                    <Route
                      path="edit/:contactId"
                      element={<NewContactContainer />}
                    />
                    <Route path="importer" element={<ContactImporter />} />
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[contactReportPermission.VIEW]}
                        />
                      }
                    >
                      <Route path="report">
                        <Route index element={<ContactReportIndex />} />
                        <Route path="all" element={<ContactReportPage />} />
                        <Route
                          path="customer"
                          element={<ContactReportPage />}
                        />
                        <Route path="vendor" element={<ContactReportPage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="/engineer">
                  <Route index element={<Engineer />} />
                  <Route path="estimate">
                    <Route index element={<Estimate />} />
                    <Route path="add" element={<EstimateContainer />} />
                    <Route path=":id">
                      <Route index element={<EstimateContainer />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="/purchase">
                  <Route index element={<Purchase />} />
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[
                          purchaseRequestPermission.VIEW,
                          purchaseRequestPermission.VIEW_SELF,
                        ]}
                      />
                    }
                  >
                    <Route path="request">
                      <Route index element={<PurchaseRequest />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              purchaseRequestPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                purchaseRequestPermission.CREATE,
                              ]}
                            />
                          }
                        >
                          <Route
                            path="add"
                            element={<PurchaseRequestContainer />}
                          />
                        </Route>
                      </Route>
                      {/* <Route path="importer" element={<QuotationImporter />} /> */}
                      <Route path=":id">
                        <Route index element={<PurchaseRequestContainer />} />
                        <Route path="pdf" element={<PurchaseRequestPDF />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[purchaseOrderPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="order">
                      <Route index element={<PurchaseOrder />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              purchaseOrderPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<PurchaseOrderContainer />}
                        />
                      </Route>
                      <Route path=":id">
                        <Route index element={<PurchaseOrderContainer />} />
                        <Route path="pdf" element={<PurchaseOrderPDF />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[purchaseReturnPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="return">
                      <Route index element={<PurchaseReturn />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              purchaseReturnPermission.CREATE,
                            ]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<PurchaseReturnContainer />}
                        />
                      </Route>
                      {/* <Route path="importer" element={<QuotationImporter />} /> */}
                      <Route path=":id">
                        <Route index element={<PurchaseReturnContainer />} />
                        {/* <Route path="pdf" element={<QuotationPDF />} /> */}
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[purchaseReportPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="report">
                      <Route index element={<PurchaseReport />} />
                      <Route
                        path="purchase-by-vendor"
                        element={<PurchaseByVendor />}
                      />
                      <Route
                        path="purchase-by-item"
                        element={<PurchaseByItem />}
                      />
                      <Route
                        path="request"
                        element={<PurchaseRequestReport />}
                      />
                      <Route path="order" element={<PurchaseOrderReport />} />
                      <Route path="return" element={<PurchaseReturnReport />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="/user">
                  <Route index element={<User />} />
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[accountPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="account">
                      <Route index element={<UserAccount />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[accountPermission.CREATE]}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={<AccountContainer addable />}
                        />
                      </Route>
                      <Route
                        path=":employeeId"
                        element={<AccountContainer viewOnly />}
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth allowedPermissions={[rbacPermission.VIEW]} />
                    }
                  >
                    <Route path="rbac">
                      <Route index element={<Rbac />} />
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[rbacPermission.CREATE]}
                          />
                        }
                      >
                        <Route path="add" element={<RbacContainer />} />
                      </Route>
                      <Route
                        path=":roleId"
                        element={<RbacContainer viewOnly />}
                      />
                    </Route>
                  </Route>
                  <Route path="setting">
                    <Route index element={<AccountContainer setting />} />
                  </Route>
                </Route>
                <Route path="/setting">
                  <Route index element={<Setting />} />
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[
                          locationPermission.VIEW,
                          categoryPermission.VIEW,
                          uomPermission.VIEW,
                          attributePermission.VIEW,
                        ]}
                      />
                    }
                  >
                    <Route path="inventory">
                      <Route index element={<InventorySetting />} />
                      <Route path="attribute" element={<Attribute />} />
                      <Route path="uom" element={<Uom />} />
                      <Route path="location">
                        <Route index element={<Location />} />
                        <Route path="importer" element={<LocationImporter />} />
                      </Route>
                      <Route path="category">
                        <Route index element={<Category />} />
                        <Route path="importer" element={<CategoryImporter />} />
                      </Route>
                      <Route path="uomgroup">
                        <Route index element={<UomGroup />} />
                        <Route path=":id" element={<Conversion />} />
                        <Route path="importer" element={<UomGroupImporter />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[approvalSettingPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="approval">
                      <Route index element={<ApprovalSetting />} />
                      <Route path="sales" element={<SalesApproval />} />
                      <Route path="purchase" element={<PurchaseApproval />} />
                      <Route path="logistic" element={<LogisticApproval />} />
                      <Route
                        path="manufacture"
                        element={<ManufactureApproval />}
                      />
                    </Route>
                  </Route>
                  <Route path="remark">
                    <Route index element={<RemarkSetting />} />
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[salesRemarkPermission.VIEW]}
                        />
                      }
                    >
                      <Route path="sales" element={<SalesTemplate />} />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[purchaseRemarkPermission.VIEW]}
                        />
                      }
                    >
                      <Route path="purchase" element={<PurchaseTemplate />} />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[logisticRemarkPermission.VIEW]}
                        />
                      }
                    >
                      <Route path="logistic" element={<LogisticTemplate />} />
                    </Route>
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={[
                            manufactureRemarkPermission.VIEW,
                          ]}
                        />
                      }
                    >
                      <Route
                        path="manufacture"
                        element={<ManufactureTemplate />}
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[companyInfoPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="company-info">
                      <Route index element={<CompanyInfoSetting />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth
                        allowedPermissions={[manufactureSettingPermission.VIEW]}
                      />
                    }
                  >
                    <Route path="manufacture">
                      <Route index element={<ManufactureSetting />} />
                      <Route
                        path="work-center"
                        element={<WorkCenterSetting />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedPermissions={[approvalSettingPermission.VIEW]}
                    />
                  }
                >
                  <Route path="approval">
                    <Route index element={<Approval />} />
                  </Route>
                </Route>
              </Route>
              <Route path="/print-barcode" element={<BarcodePdf />} />
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/*" element={<NotFound />}></Route>
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
