import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import PurchaseRequestPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseRequestById } from "../../../../features/Purchase/PurchaseRequest/purchase-request-actions";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import Setting from "../../../../services/Setting";
import PurchaseRequestPdf from "../../../../components/Table/Pdf/PurchaseRequestPdf";

const PurchaseRequestPDF = () => {
  const dispatch = useDispatch();
  const { purchaseRequest } = useSelector((state) => state.purchaseRequest);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { id } = useParams();
  const [destinationWarehouse, setDestinationWarehouse] = useState("");

  console.log("handlePrint", handlePrint);

  useEffect(() => {
    dispatch(
      getPurchaseRequestById(
        {
          document_id: id,
        },
        user,
        enqueueSnackbar
      )
    );
  }, [dispatch, enqueueSnackbar, id, user]);

  useEffect(() => {
    const getDestinationWarehouse = async () => {
      if (
        purchaseRequest &&
        purchaseRequest.destination_warehouse_document_id
      ) {
        const { thai_name } = await Setting.getWarehouse({
          document_id: purchaseRequest.destination_warehouse_document_id,
        });
        setDestinationWarehouse(thai_name);
      }
    };
    getDestinationWarehouse();
  }, [purchaseRequest]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบขอซื้อ/PurchaseRequest</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              p: 3,
            }}
            ref={componentRef}
          >
            <Box>
              <PDFHeader documentType="ใบขอซื้อ/PurchaseRequest" />
              <Divider />
              <PurchaseRequestPDFInfo
                purchaseRequest={purchaseRequest}
                destinationWarehouse={destinationWarehouse}
              />
              <Divider />
              <PurchaseRequestPdf itemList={purchaseRequest.item_list} />
              <PDFFooter
                data={purchaseRequest}
                documentType="purchase_request"
              />
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box
              sx={{
                py: 3,
                breakInside: "avoid",
              }}
            >
              <PDFSigner />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseRequestPDF;
