export const initialState = {
  isLoading: {
    allRoles: false,
    role: false,
    priviledgeList: false,
  },
  allRoles: [],
  priviledgeList: {
    // priviledge_name:[array of priviledge]
  },
  role: {
    document_id: "",
    name: "",
    description: "",
    employee_list: [],
    CRM: {
      PROJECT: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
      APPOINTMENT: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
    },
    INVENTORY: {
      ITEM_MASTER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
      RECEIVE: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      RETURN: {
        VIEW: "CLOSE",
        CREATE: false,
      },
      ISSUE: {
        VIEW: "CLOSE",
        CREATE: false,
      },
      TRANSFER: {
        VIEW: "CLOSE",
        CREATE: false,
      },
      ADJUSTMENT: {
        VIEW: "CLOSE",
        CREATE: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
    ENGINEER: {
      ESTIMATION: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
    },
    SALES: {
      QUOTATION: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      ORDER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      RETURN: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
    PURCHASE: {
      REQUEST: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      ORDER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      RETURN: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
    LOGISTIC: {
      DELIVERY_ORDER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      DELIVERY_TRIP: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
    MANUFACTURE: {
      BOM: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
      ORDER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      WORK_ORDER: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        CANCEL: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
    USER: {
      ACCOUNT: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      RBAC: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
    },
    CONFIG: {
      COMPANY_INFO: {
        VIEW: "CLOSE",
        EDIT: false,
      },
      APPROVAL: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      SALES_REMARK: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      PURCHASE_REMARK: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      LOGISTIC_REMARK: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      MANUFACTURE_REMARK: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      LOCATION: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      CATEGORY: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      UOM: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      ATTRIBUTE: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      MANUFACTURE: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
      LOGISTIC: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
      },
    },
    CONTACT: {
      ALL: {
        VIEW: "CLOSE",
        CREATE: false,
        EDIT: false,
        DELETE: false,
      },
      CUSTOMER: {
        EDIT: false,
        DELETE: false,
      },
      VENDOR: {
        EDIT: false,
        DELETE: false,
      },
      REPORT: {
        VIEW: "CLOSE",
      },
    },
  },
  error: null,
};
