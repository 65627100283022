import { purchaseOrderActions } from "./purchase-order-slice";
import PurchaseService from "../../../services/Purchase";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import {
  purchaseExportFormatter,
  purchaseQueryFormatter,
} from "../../../utils/purchasePayloadFormatter";
import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";
import {
  dateToUnix,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import { notifyTemplate } from "../../../utils/notifyTemplate";
import { postNotify } from "../../../services/Notification";

export const getAllPurchaseOrder =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("allPurchaseOrder"));
    try {
      const allPurchaseOrder = await PurchaseService.getAllPurchaseOrder(input);
      params.successCallback(allPurchaseOrder.results, allPurchaseOrder.count);
      dispatch(
        purchaseOrderActions.loadedAllPurchaseOrder(allPurchaseOrder.results)
      );
    } catch (err) {
      dispatch(
        purchaseOrderActions.rejectedActions({
          ...err,
          name: "allPurchaseOrder",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseOrderItem =
  (input, enqueueSnackbar) => async (dispatch) => {
    // dispatch(purchaseOrderActions.onLoading("allPurchaseOrder"));
    try {
      const allPurchaseOrder =
        await PurchaseService.getAllPurchaseOrderItemList(input);
      dispatch(
        purchaseOrderActions.loadedAllPurchaseOrder(allPurchaseOrder.results)
      );
    } catch (err) {
      dispatch(
        purchaseOrderActions.rejectedActions({
          ...err,
          name: "allPurchaseOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("allPurchaseOrder"));
    try {
      const { results } = await PurchaseService.getAllPurchaseOrder(input);
      let purchaseOrders = purchaseExportFormatter(results, "purchase_order");
      dispatch(
        purchaseOrderActions.loadedAllPurchaseOrdersExports(purchaseOrders)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseOrderActions.rejectedActions({
          ...err,
          name: "allPurchaseOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewPurchaseOrder =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const updatedDate = dateToUnix(new Date());
      const { creator_document_id, ...otherPayload } = payload;
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const result = await PurchaseService.createPurchaseOrder(otherPayload);
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "purchase_order",
        "สร้างใบสั่งซื้อ"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_list.length > 0) {
        payload.reference_document_list.forEach(async (list) => {
          const createRefActivityLog = createActivityLogPayload(
            {
              ...activityLogPayload,
              document_id: list.reference_document_id,
            },
            "purchase_request",
            "สร้างใบสั่งซื้อ"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: createRefActivityLog,
          });
        });
      }

      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "purchase_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "purchase_order",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_order",
          null,
          "wait_approve"
        );
        await PurchaseService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
        const message = notifyTemplate(
          updatedDate,
          "ใบสั่งซื้อ",
          result.document_id,
          "waitApprove",
          `https://biowood-dev.npr.digital/purchase/order/${encodeURIComponent(
            result.document_id
          )}`
        );
        await postNotify(message, process.env.REACT_APP_PURCHASE_TOKEN);
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_order",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/purchase/order/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบสั่งซื้อไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getPurchaseOrderById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const purchaseOrder = await PurchaseService.getPurchaseOrderById(
        uniqueInput
      );
      const formatPurchaseOrder = await purchaseQueryFormatter(
        purchaseOrder,
        "purchase_order"
      );

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_order"
        );

      const approvalProgress = await PurchaseService.getApprovalProgress({
        reference_document_type: "purchase_order",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        purchaseOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        purchaseOrderActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(purchaseOrderActions.loadedPurchaseOrder(formatPurchaseOrder));
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseOrderActions.rejectedActions({
          ...err,
          name: "purchaseOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodReceiveTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const { results } = await InventoryService.getAllGRAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "contains",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsReceive) => ({
        ...goodsReceive,
        created_date: unixToDateWithFormat(goodsReceive.created_date),
        document_date: unixToDateWithFormat(goodsReceive.document_date),
      }));

      dispatch(purchaseOrderActions.loadedGoodReceiveTab(formatResults));
    } catch (err) {
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseReturnTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const purchaseReturnTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredPurchaseReturn = referenceDocument.filter(
        (document) => document.document_type === "purchase_return"
      );
      for (const [index, value] of filteredPurchaseReturn.entries()) {
        const purchaseReturn = await PurchaseService.getPurchaseReturnById({
          document_id: value.document_id,
        });
        purchaseReturnTab[index] = purchaseReturn;
      }
      dispatch(purchaseOrderActions.loadedPurchaseReturnTab(purchaseReturnTab));
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updatePurchaseOrder =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const updatedDate = dateToUnix(new Date());
      const { document_id, creator_document_id, ...otherPayload } = payload;
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const purchaseOrder = await PurchaseService.updatePurchaseOrder(
        { document_id },
        otherPayload
      );
      const formatPurchaseOrder = await purchaseQueryFormatter(
        purchaseOrder,
        "purchase_order"
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "purchase_order",
        };
        if (status === "notApproved") {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_order",
            "not_approved",
            "wait_approve"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_order",
            step: 1,
          });
          await PurchaseService.postNextStatus(documentInput);
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
          dispatch(
            purchaseOrderActions.loadedPurchaseOrder({
              ...formatPurchaseOrder,
              render_status: "waitApprove",
            })
          );
        } else {
          const updatedPurchaseOrder = await PurchaseService.postNextStatus(
            documentInput
          );

          if (
            updatedPurchaseOrder?.approval_progress_list
              ?.map((approval) => approval?.approval_status)
              .includes("PENDING")
          ) {
            dispatch(
              purchaseOrderActions.updateApprovalStatus({
                approval_list: updatedPurchaseOrder.approval_progress_list,
                user,
              })
            );
            const sendToApproveActivityLog = createActivityLogStatusPayload(
              activityLogPayload,
              "purchase_order",
              "draft",
              "wait_approve"
            );
            dispatch(
              purchaseOrderActions.loadedPurchaseOrder({
                ...formatPurchaseOrder,
                render_status: "waitApprove",
              })
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: sendToApproveActivityLog,
            });
            enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
              variant: "success",
            });
            const message = notifyTemplate(
              updatedDate,
              "ใบสั่งซื้อ",
              formatPurchaseOrder.document_id,
              "waitApprove",
              `https://biowood-dev.npr.digital/purchase/order/${encodeURIComponent(
                formatPurchaseOrder.document_id
              )}`
            );
            await postNotify(message, process.env.REACT_APP_PURCHASE_TOKEN);
          } else {
            enqueueSnackbar("อัพเดทใบสั่งซื้อสำเร็จ", {
              variant: "success",
            });

            dispatch(
              purchaseOrderActions.loadedPurchaseOrder({
                ...formatPurchaseOrder,
                render_status: updatedPurchaseOrder.status,
              })
            );
          }
        }
      } else {
        if (status === "notApproved") {
          const setDocumentStep = await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_order",
            step: 1,
          });

          const sendToDraftActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_order",
            "not_approved",
            "draft"
          );

          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToDraftActivityLog,
          });

          dispatch(
            purchaseOrderActions.loadedPurchaseOrder({
              ...formatPurchaseOrder,
              render_status: setDocumentStep.status,
            })
          );
          enqueueSnackbar("บันทึกร่างสำเร็จ", {
            variant: "success",
          });
        } else {
          if (
            status === "approved" ||
            status === "partiallyImported" ||
            status === "fullyImported"
          ) {
            const createActivityLog = createActivityLogPayload(
              activityLogPayload,
              "purchase_order",
              "บันทึกใบบันทึกซื้อ"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: createActivityLog,
            });
          }
          const editActivityLog = createActivityLogEditPayload(
            activityLogPayload,
            "purchase_order"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: editActivityLog,
          });
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "purchase_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "purchase_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
          switch (status) {
            case "draft":
              dispatch(
                purchaseOrderActions.loadedPurchaseOrder(formatPurchaseOrder)
              );
              enqueueSnackbar("บันทึกร่างสำเร็จ", {
                variant: "success",
              });
              break;
            case "approved":
            case "partiallyImported":
            case "fullyImported":
              dispatch(
                purchaseOrderActions.loadedPurchaseOrder(formatPurchaseOrder)
              );
              enqueueSnackbar("บันทึกใบบันทึกซื้อสำเร็จ", {
                variant: "success",
              });
              break;
            default:
              dispatch(
                purchaseOrderActions.loadedPurchaseOrder({
                  ...formatPurchaseOrder,
                  render_status: status,
                })
              );
              enqueueSnackbar("แก้ไขใบสั่งซื้อสำเร็จ", {
                variant: "success",
              });
          }
        }
      }
    } catch (err) {
      dispatch(
        purchaseOrderActions.rejectedActions({
          ...err,
          name: "purchaseOrder",
        })
      );
      switch (status) {
        case "draft":
          enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
            variant: "error",
          });
          break;
        default:
          enqueueSnackbar("อัพเดทใบสั่งซื้อไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approvePurchaseOrder =
  (
    documentInput,
    payload,
    user,
    enqueueSnackbar,
    itemList,
    setValue,
    gridRef
  ) =>
  async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const updatedDate = dateToUnix(new Date());
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_order"
        );

      const { approval_progress_list } =
        await PurchaseService.postApproveStatus(documentInput);

      dispatch(
        purchaseOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          purchaseOrderActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        dispatch(purchaseOrderActions.updatePurchaseOrderStatus("waitApprove"));
        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_order"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_order"
        );

        dispatch(
          purchaseOrderActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );

        dispatch(purchaseOrderActions.updatePurchaseOrderStatus("approved"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_order",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      for (const [index, value] of itemList.entries()) {
        await InventoryService.updateItemQuantity(value.item_document_id, {
          current_ordered_purchase_qty: {
            increment: value.qty_uom,
          },
        });
        const item = await InventoryService.getItem({
          document_id: value.item_document_id,
        });

        itemList[index] = {
          ...value,
          uom_group: item?.uom_group,
          current_ordered_purchase_qty: item?.current_ordered_purchase_qty,
          current_ordered_manufacture_qty:
            item?.current_ordered_manufacture_qty,
          current_committed_Purchase_qty: item?.current_committed_Purchase_qty,
          current_committed_manufacture_qty:
            item?.current_committed_manufacture_qty,
          current_stock_qty: item?.current_stock_qty,
          current_available_qty: item?.current_available_qty,
          purchase_standard_price: item?.purchase_standard_price,
        };
      }
      setValue("item_list", await itemList);
      gridRef.current.api.redrawRows();
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
      const message = notifyTemplate(
        updatedDate,
        "ใบสั่งซื้อ",
        payload.document_id,
        "approved",
        `https://biowood-dev.npr.digital/purchase/order/${encodeURIComponent(
          payload.document_id
        )}`
      );
      await postNotify(message, process.env.REACT_APP_PURCHASE_TOKEN);
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declinePurchaseOrder =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const updatedDate = dateToUnix(new Date());
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_order",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } =
        await PurchaseService.postDeclineStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_order"
        );

      dispatch(
        purchaseOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        purchaseOrderActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );
      dispatch(purchaseOrderActions.updatePurchaseOrderStatus("notApproved"));
      const message = notifyTemplate(
        updatedDate,
        "ใบสั่งซื้อ",
        payload.document_id,
        "notApproved",
        `https://biowood-dev.npr.digital/purchase/order/${encodeURIComponent(
          payload.document_id
        )}`
      );
      await postNotify(message, process.env.REACT_APP_PURCHASE_TOKEN);
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelPurchaseOrder =
  (
    documentInput,
    payload,
    user,
    enqueueSnackbar,
    status,
    itemList,
    setValue,
    gridRef
  ) =>
  async (dispatch) => {
    dispatch(purchaseOrderActions.onLoading("purchaseOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_order",
        null,
        "cancelled"
      );
      await PurchaseService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      if (status === "approved") {
        for (const [index, value] of itemList.entries()) {
          await InventoryService.updateItemQuantity(value.item_document_id, {
            current_ordered_purchase_qty: {
              decrement: value.qty_uom,
            },
          });
          const item = await InventoryService.getItem({
            document_id: value.item_document_id,
          });

          itemList[index] = {
            ...value,
            uom_group: item?.uom_group,
            current_ordered_purchase_qty: item?.current_ordered_purchase_qty,
            current_ordered_manufacture_qty:
              item?.current_ordered_manufacture_qty,
            current_committed_sales_qty: item?.current_committed_sales_qty,
            current_committed_manufacture_qty:
              item?.current_committed_manufacture_qty,
            current_stock_qty: item?.current_stock_qty,
            current_available_qty: item?.current_available_qty,
            purchase_standard_price: item?.purchase_standard_price,
          };
        }
        setValue("item_list", await itemList);
        gridRef.current.api.redrawRows();
      }
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(purchaseOrderActions.updatePurchaseOrderStatus("cancelled"));
    } catch (err) {
      console.log("error", err);
      dispatch(
        purchaseOrderActions.rejectedActions({ ...err, name: "purchaseOrder" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
