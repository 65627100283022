import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useWatch } from "react-hook-form";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import GlobalService from "../../../../services/Global";
import Employee from "../../Manufacture/Order/Header/Employee";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";
import CustomizedMenuOption from "../../../Custom/CustomizedMenuOption";
import CopyConfirmation from "../../../UI/Confirmation/CopyConfirmation";
import CancelConfirmation from "../../../UI/Confirmation/CancelConfirmation";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RightDrawer from "../../../UI/RightDrawer";
import { useModifyOptions } from "../../../../hooks/Estimation/use-modify-options";
import StepperUI from "../../../UI/StepperUI";
import { useActiveStep } from "../../../../hooks/Estimation/use-active-step";

const EstimateHeaderForm = ({
  control,
  errors,
  disabled,
  setValue,
  getValues,
  copyEstimateHandler,
  cancelConfirmation,
  setCancelConfirmation,
  cancelEstimateHandler,
  editClickHandler,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [createdDateIsOpen, setCreatedDateIsOpen] = useState(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState(false);
  const [startDateIsOpen, setStartDateIsOpen] = useState(false);
  const [endDateIsOpen, setEndDateIsOpen] = useState(false);

  const [copyConfirmation, setCopyConfirmation] = useState(false);

  const watchStatus = useWatch({
    control,
    name: "status",
  });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },
    {
      label: t("status.new_estimation"),
      value: "new_estimation",
    },
    {
      label: t("status.audited"),
      value: "audited",
    },
    {
      label: t("status.queuing"),
      value: "queuing",
    },
    {
      label: t("status.assigning"),
      value: "assigning",
    },
    {
      label: t("status.work_in_progress"),
      value: "work_in_progress",
    },
    {
      label: t("status.reviewing"),
      value: "reviewing",
    },
    {
      label: t("status.closed"),
      value: "closed",
    },
    {
      label: t("status.finished"),
      value: "finished",
    },
  ];

  const selectModifyOptions = useModifyOptions(watchStatus);
  const { activeStep, percentage } = useActiveStep(watchStatus);

  const generateDocumentId = useCallback(async () => {
    if (disabled) {
      return;
    }
    try {
      const document_id = await GlobalService.getRunningDocumentId(
        "estimation"
      );
      setValue("document_id", document_id);
    } catch (err) {
      console.error(err);
    }
  }, [setValue, disabled]);

  useEffect(() => {
    if (pathname.split("/").at(-1) === "add") {
      generateDocumentId();
    }
  }, [pathname, generateDocumentId]);

  const closeCopyConfirmationHandler = () => {
    setCopyConfirmation(false);
  };

  const closeCancelConfirmationHandler = () => {
    setCancelConfirmation(false);
  };

  const errorHandler = (index) => {
    if (index === 1 && watchStatus === "not_approve_audit") {
      return true;
    } else if (index === 3 && watchStatus === "not_approve_queue") {
      return true;
    } else if (index === 6 && watchStatus === "not_approve_review") {
      return true;
    } else {
      return false;
    }
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nextStatus = (status) => {
    switch (status) {
      case "not_approve_audit":
      case "draft":
        return t("status.new_estimation");
      case "new_estimation":
        return t("status.audited");
      case "not_approve_queue":
      case "audited":
        return t("status.queuing");
      case "queuing":
        return t("status.assigning");
      case "assigning":
        return t("status.work_in_progress");
      case "not_approve_review":
      case "work_in_progress":
        return t("status.reviewing");
      case "reviewing":
        return t("status.closed");
      case "closed":
        return t("status.finished");
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container mt={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("engineer.estimate.index")}</Typography>
            {!isMobile && <CustomizedStatus status={watchStatus} />}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      setCopyConfirmation(true);
                      break;
                    case "ยกเลิก":
                      setCancelConfirmation(true);
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ my: 4 }}>
        {isMobile ? (
          watchStatus ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography fontWeight="bold" variant="h6">
                {t(`status.${watchStatus}`)}
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ width: "85%", mr: 1 }}>
                  <LinearProgress variant="determinate" value={percentage} />
                </Box>
                <Box sx={{ width: "15%" }}>
                  <Typography>
                    {watchStatus === "finished" ? activeStep : activeStep + 1}{" "}
                    ใน 9
                  </Typography>
                </Box>
              </Box>
              {watchStatus !== "finished" && (
                <Typography variant="body2">
                  สถานะถัดไป {nextStatus(watchStatus)}
                </Typography>
              )}
            </Box>
          ) : null
        ) : (
          <StepperUI
            steps={steps}
            activeStep={activeStep}
            errorHandler={errorHandler}
          />
        )}
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.document_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={6} xl={6}>
              <ControlledTextField
                name="document_id"
                control={control}
                error={Boolean(errors?.document_id)}
                helperText={errors?.document_id && errors?.document_id.message}
                disabled={id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateDocumentId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            {/* <Grid item sx={12} sm={12} md={2} lg={3} xl={3}>
                <Typography fontWeight="bold">
                  {t("manufacture.order.external_ref_id")}
                </Typography>
              </Grid>
              <Grid item sx={11} sm={11} md={9} lg={7} xl={7}>
                <ControlledTextField
                  name="external_ref_id"
                  control={control}
                  error={Boolean(errors?.external_ref_id)}
                  helperText={
                    errors?.external_ref_id && errors?.external_ref_id.message
                  }
                  disabled={watchStatus !== "finished" ? disabled : true}
                />
              </Grid> */}
            <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={10} lg={9} xl={9}>
              <Employee
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("engineer.estimate.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                isOpen={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("engineer.estimate.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="start_date"
                control={control}
                error={errors.start_date}
                isOpen={startDateIsOpen}
                onClose={() => setStartDateIsOpen(false)}
                onOpen={() => setStartDateIsOpen(true)}
                label={t("engineer.estimate.start_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="end_date"
                control={control}
                error={errors.expect_product_date}
                isOpen={endDateIsOpen}
                onClose={() => setEndDateIsOpen(false)}
                onOpen={() => setEndDateIsOpen(true)}
                label={t("engineer.estimate.end_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label="ครั้งที่"
                name="revision_count"
                control={control}
                error={Boolean(errors.revision_count)}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <RightDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        title={t("inventory.activity")}
        documentId={id}
        documentType="estimation"
      />
      <CopyConfirmation
        openCopyConfirmation={copyConfirmation}
        copyConfirmationAction={copyEstimateHandler}
        closeCopyConfirmationHandler={closeCopyConfirmationHandler}
      />
      <CancelConfirmation
        openCancelConfirmation={cancelConfirmation}
        cancelConfirmationAction={cancelEstimateHandler}
        closeCancelConfirmationHandler={closeCancelConfirmationHandler}
      />
    </>
  );
};

export default EstimateHeaderForm;
