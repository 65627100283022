import { Box } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { filterParamsOptions } from "../../../utils/filterparams";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedStatus from "../../Custom/CustomizedStatus";

export const ItemColumnDef = (
  t,
  enableCheckbox,
  tagList,
  onClickTagList,
  getAllTag
) => [
  {
    field: "document_id",
    headerName: t("inventory.items.itemId"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: filterParamsOptions("string"),
    },
    checkboxSelection: enableCheckbox,
    width: 200,
  },
  {
    field: "name",
    headerName: t("inventory.items.itemName"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: filterParamsOptions("string"),
    },
  },
  // {
  //   field: "sku",
  //   headerName: t("inventory.items.itemInternalName"),
  //   filter: "agTextColumnFilter",
  // },
  {
    field: "description",
    headerName: t("inventory.items.itemDescription"),
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: filterParamsOptions("string"),
    },
  },
  {
    field: "type",
    headerName: t("inventory.items.itemType"),
    filter: "agSetColumnFilter",
    filterParams: {
      values: [
        "วัสดุไม้",
        "วัสดุประกอบ",
        "วัสดุอุปกรณ์หลัก",
        "วัสดุสิ้นเปลือง",
        "บริการ",
        "อื่นๆ",
      ],
    },
  },
  {
    field: "current_stock_qty",
    headerName: t("inventory.quantities.currentStockQty"),
    filter: false,
    filterParams: {
      filterOptions: filterParamsOptions("number"),
    },
    cellRenderer: (params) => {
      return (
        <NumericFormat
          value={params.value}
          thousandSeparator=","
          displayType="text"
        />
      );
    },
  },
  {
    field: "current_available_qty",
    headerName: t("inventory.quantities.currentAvailableQty"),
    filter: false,
    filterParams: {
      filterOptions: filterParamsOptions("number"),
    },
    cellRenderer: (params) => {
      return (
        <NumericFormat
          value={params.value}
          thousandSeparator=","
          displayType="text"
        />
      );
    },
  },
  {
    field: "tag_list",
    headerName: t("inventory.items.groupTagList"),
    filter: "agSetColumnFilter",
    sortable: false,
    filterParams: {
      values: async (params) => {
        // fetch values from server
        const values = await getAllTag();
        const formatValues = values
          .filter((tag) => tag.entity === "item")
          .map((tag) => tag.name);
        params.success(formatValues);
      },
    },
    cellRenderer: (params) => {
      return params.value.map((tag) => (
        <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
          <CustomizedChips
            key={tag.name}
            onClick={() => onClickTagList(tag.name)}
            value={`${tag.name}`}
          />
        </Box>
      ));
    },
  },
  {
    field: "item_group_sub_level_1",
    headerName: t("inventory.items.itemGroup") + " 1",
    filter: "agTextColumnFilter",
    sortable: false,
    cellRenderer: (params) => {
      if (params.value) return <div>{params.value.name}</div>;
    },
  },
  // {
  //   field: "item_group_sub_level_2",
  //   headerName: t("inventory.items.itemGroup") + " 2",
  //   filter: false,
  //   sortable: false,
  //   cellRenderer: (params) => {
  //     if (params.value) return <div>{params.value.name}</div>;
  //   },
  // },
  // {
  //   field: "item_group_sub_level_3",
  //   headerName: t("inventory.items.itemGroup") + " 3",
  //   filter: false,
  //   sortable: false,
  //   cellRenderer: (params) => {
  //     if (params.value) return <div>{params.value.name}</div>;
  //   },
  // },
  // {
  //   field: "base_uom.name",
  //   headerName: t("inventory.unit"),
  //   filter: false,
  // },
  {
    field: "is_active",
    headerName: t("inventory.status"),
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["true", "false"],
      valueFormatter: (params) => {
        if (params.value === "true") {
          return "ใช้งาน";
        }
        return "หยุดใช้งาน";
      },
    },
    cellRenderer: (params) => (
      <CustomizedStatus status={params.value ? "active" : "inactive"} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
    },
  },
];
