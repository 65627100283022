import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../Table/AgGrid";
import { filteredRow } from "../../../utils/dataTransformer";

const ConfigPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "COMPANY_INFO",
        name: t("setting.companyInfo"),
        view: "view",
        edit: "edit",
      },
      {
        functionName: "APPROVAL",
        name: t("setting.approval.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      // {
      //   functionName: "SALES_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.sales"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      {
        functionName: "PURCHASE_REMARK",
        name: "หมายเหตุ" + t("setting.remark.purchase"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      // {
      //   functionName: "LOGISTIC_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.logistic"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "MANUFACTURE_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.manufacture"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      {
        functionName: "LOCATION",
        name: t("setting.inventory.location.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      {
        functionName: "CATEGORY",
        name: t("setting.inventory.category.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      {
        functionName: "UOM",
        name: t("setting.inventory.uom.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      {
        functionName: "ATTRIBUTE",
        name: t("setting.inventory.attribute.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      // {
      //   functionName: "MANUFACTURE",
      //   name: t("setting.manufacture.index"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "LOGISTIC",
      //   name: t("setting.logisticSetting"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
    ];
    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default ConfigPermission;
