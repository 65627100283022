import moment from "moment";
import { useSnackbar } from "notistack";
import { Avatar, Box, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AgGrid from "../../../components/Table/AgGrid";
import { NumericFormat } from "react-number-format";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import {
  dateToUnix,
  formatDate,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import InventoryService from "../../../services/Inventory";
import styled from "@emotion/styled";
import { entryTypeToThai } from "../../../utils/dataTransformer";
import TotalBox from "../../../components/UI/TotalBox";
import { useForm } from "react-hook-form";
import NewReportDateFilter from "../../../components/UI/newReportDateFilter";
import {
  exportCSVParams,
  filterParamsOptions,
} from "../../../utils/filterparams";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const StockEntry = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const [sum, setSum] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [allItems, setAllItems] = useState([]);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.stockEntry.index"),
    },
  ];

  const navigateToGoodPage = (type, document_id) => {
    window.open(
      `/inventory/${type}/${encodeURIComponent(document_id)}`,
      "_blank"
    );
  };

  const renderType = (type) => {
    switch (type) {
      case "นำเข้า":
        return "receive";
      case "นำออก":
        return "issue";
      case "โอนย้าย":
        return "transfer";
      case "ปรับปรุง":
        return "adjustment";
      default:
        break;
    }
  };

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: false,
    },
    {
      field: "reference_document_id",
      headerName: t("inventory.stockEntry.referenceDocument"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => {
        const { reference_document_type, reference_document_id } = params.data;
        if (!reference_document_type || !reference_document_id) {
          return "-";
        }
        return (
          <StyledLink
            onClick={() =>
              navigateToGoodPage(
                renderType(reference_document_type),
                reference_document_id
              )
            }
          >
            {reference_document_id}
          </StyledLink>
        );
      },
    },
    {
      field: "item_item_group",
      headerName: t("inventory.items.itemGroup") + " " + 1,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_description",
      headerName: t("inventory.items.itemDescription"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "posted_quantity",
      headerName: t("inventory.list.quantityDifference"),
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        return (
          <NumericFormat
            value={params.value}
            thousandSeparator=","
            displayType="text"
          />
        );
      },
    },
    {
      field: "uom_name",
      headerName: t("inventory.unit"),
      filter: false,
      sortable: false,
    },
    {
      field: "reference_document_type",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["นำเข้า", "นำออก", "โอนย้าย", "ปรับปรุง"],
      },
    },
    {
      field: "document_type",
      headerName: t("inventory.stockEntry.documentType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "ซื้อ",
          "ผลิต",
          "แปรรูป",
          "รับคืนจากลูกค้า",
          "รับคืนจากผลิต",
          "ขาย",
          "ขายออนไลน์",
          "ผลิตวัตถุดิบ",
          "ผลิตแปรรูป",
          "ผลิตแปรรูป - PT",
          "ส่งคืน",
          "สินค้าเสีย",
          "อื่นๆ",
        ],
      },
    },
    {
      field: "lot",
      headerName: t("inventory.list.lotNumber"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "created_by_full_name",
      headerName: t("inventory.stockEntry.createdBy"),
      sortable: false,
      filter: "agSetColumnFilter",
      cellRenderer: (params) => {
        if (params.data.created_by.img_url) {
          return <Avatar alt="img_url" src={params.data.created_by.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={
                params.data.created_by.first_name +
                " " +
                params.data.created_by.last_name
              }
            />
          );
        }
      },
    },
  ];

  const formatValueForAgGrid = () => {
    if (allItems.length !== 0) {
      const formatValue = allItems.map((list, index) => {
        let remark = "";
        let type = "";
        if (list.inventory_object_item) {
          const notNull = list.inventory_object_item;
          if (notNull.goods_receive) {
            remark = notNull.goods_receive.remark;
            type = notNull.goods_receive.type;
          } else if (notNull.goods_issue) {
            remark = notNull.goods_issue.remark;
            type = notNull.goods_issue.type;
          } else if (notNull.goods_transfer)
            remark = notNull.goods_transfer.remark;
          else if (notNull.goods_adjustment)
            remark = notNull.goods_adjustment.remark;
        }
        return {
          ...list,
          source_warehouse_document_id: list.source_warehouse?.document_id,
          source_warehouse_name: list.source_warehouse?.thai_name,
          source_bin_location_document_id: list.source_bin_location_document_id,
          source_bin_location_name: list.source_bin_location?.name,
          destination_warehouse_document_id:
            list.destination_warehouse?.document_id,
          destination_warehouse_name: list.destination_warehouse?.thai_name,
          destination_bin_location_document_id:
            list.destination_bin_location_document_id,
          destination_bin_location_name: list.destination_bin_location?.name,
          item_document_id: list.item_document_id,
          item_name: list.item?.name,
          item_description: list.item?.description,
          item_item_group: list.item?.item_group_sub_level_1?.name,
          lot: list.inventory_object_item?.goods_receive?.lot
            ? formatDate(list.inventory_object_item.goods_receive.lot)
            : "",
          remark: remark,
          uom_name: list.uom?.name,
          reference_document_type: entryTypeToThai(
            list.reference_document_type
          ),
          document_type: type,
          reference_document_id: list.reference_document_id,
          created_date: list.created_date
            ? unixToDateWithFormat(list.created_date)
            : "",
          created_by_full_name: list.created_by
            ? list.created_by?.first_name + " " + list.created_by?.last_name
            : "",
        };
      });
      return formatValue;
    } else return [];
  };

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(7, "day")),
      dateTo: new Date(),
    },
  });

  const exportHandler = async () => {
    setIsLoadingExport(true);
    try {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");
      const params = exportCSVParams(
        t("inventory.stockEntry.index"),
        startDate,
        endDate
      );
      gridRef.current.api.exportDataAsCsv(params);
      // exportAsExcel(formattedSE, t("inventory.stockEntry.index"));
      enqueueSnackbar("นำออกรายงานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกรายงานไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      setIsLoadingExport(false);
    }
  };

  const getAllItems = useCallback(
    async (isDefault) => {
      setIsLoadingFilter(true);
      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          created_date: {
            filter: parseInt(
              dateToUnix(moment(new Date()).startOf("day").subtract(7, "day"))
            ),
            filterTo: parseInt(dateToUnix(moment(new Date()).endOf("day"))),
            filterType: "number",
            type: "inRange",
          },
          is_active: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        },
        sortModel: [],
      };
      if (isDefault) {
        const { results } = await InventoryService.getAllSEAggrid(input);
        setSum(results.reduce((prev, curr) => prev + curr.posted_quantity, 0));
        setAllItems(results);
      } else {
        const getDateValue = getValues("date");
        const getDateToValue = getValues("dateTo");
        input.filterModel.created_date.filter = dateToUnix(getDateValue);
        input.filterModel.created_date.filterTo = dateToUnix(getDateToValue);
        const { results } = await InventoryService.getAllSEAggrid(input);
        setAllItems(results);
      }
      setIsLoadingFilter(false);
    },
    [getValues]
  );

  const onFilterChanged = () => {
    let newValue = 0;
    gridRef.current.api.forEachNodeAfterFilter(
      (data) => (newValue += data.data.posted_quantity)
    );
    setSum(newValue);
  };

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
    getAllItems(true);
    reset();
  };

  useEffect(() => {
    getAllItems(true);
  }, [getAllItems]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4} md={3}>
          <TotalBox title="ยอดรวม" total={sum} />
        </Grid>
      </Grid>
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("inventory.stockEntry.index")}</Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
          disabled={isLoadingExport}
        />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <NewReportDateFilter
              t={t}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.5} alignSelf="center">
            <Box display="flex" gap={2}>
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={() => getAllItems(false)}
                disabled={isLoadingFilter}
                fullWidth
              />
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={formatValueForAgGrid()}
        onFilterChanged={onFilterChanged}
        height={649}
      />
    </>
  );
};

export default StockEntry;
