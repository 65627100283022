import { List } from "@mui/material";
import { LeftNavbarItem } from "./LeftNavbarItem";

const renderNavItems = ({
  depth = 0,
  items,
  path,
  onItemClick,
  searchParams,
  isSmallScreen,
}) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            depth,
            item,
            path,
            onItemClick,
            searchParams,
            isSmallScreen,
          }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  path,
  onItemClick,
  searchParams,
  isSmallScreen,
}) => {
  const key = `${item.title}-${depth}`;
  const modulePath = path.split("/")[1];
  const moduleItemPath = item.path.split("/")[1];
  const partialMatch = item.path
    ? modulePath.includes(moduleItemPath) && path.includes(item.path)
    : false;
  const itemParams = item.path.split("?")[1];
  const searchParamsMatch = searchParams?.some((params) => {
    return `${params[0]}=${params[1]}` === itemParams;
  });
  const exactMatch = path === item.path; // We don't compare query params

  if (isSmallScreen && item?.disabledMobile !== undefined) return acc;

  if (item.children) {
    acc.push(
      <LeftNavbarItem
        activeBg={exactMatch && searchParams.length === 0}
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        onItemClick={onItemClick}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path,
          onItemClick,
          searchParams,
          isSmallScreen,
        })}
      </LeftNavbarItem>
    );
  } else {
    acc.push(
      <LeftNavbarItem
        active={partialMatch || searchParamsMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        onItemClick={onItemClick}
      />
    );
  }
  return acc || null;
};

export const LeftNavbarSection = (props) => {
  const { items, path, onItemClick, isSmallScreen, searchParams, ...other } =
    props;

  return (
    <List {...other}>
      {renderNavItems({
        items,
        path,
        onItemClick,
        searchParams,
        isSmallScreen,
      })}
    </List>
  );
};
