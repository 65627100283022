export const initialState = {
  isLoading: {
    allPurchaseReturns: false,
    purchaseReturn: false,
  },
  allPurchaseReturns: [],
  allPurchaseReturnsExport: [],
  isHaveApproval: false,
  isHaveApprovalPermission: false,
  purchaseReturn: {
    target_status: null,
    render_status: null,
    id: null,
    document_id: "",
    external_ref_id: "",
    reason_to_return: "",
    reference_document_id: null,
    issue_date: null,
    delivery_date: null,
    price_vat_type: "excluded_vat",
    credit_day: "",
    customer_id: "",
    pre_vat_amount: 0,
    additional_discount: 0,
    vat_exempted_amount: 0,
    vat_0_amount: 0,
    vat_7_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
    created_date: null,
    updated_date: null,
    creator_document_id: "",
    vendor: {
      email: "",
      phone: "",
      fax: "",
      billing_address: {
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      },
      delivery_address: {
        address_type: "",
        is_same_as_default_address: false,
        address_contact_name: "",
        address_contact_phone: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      },
    },
    employee_list: [],
    item_list: [],
    template_remark_id: "",
    remark: "",
  },
  purchaseOrderItemList: [],
  approvalList: [],
  goodIssueTab: [],
  error: null,
};
