import React, { forwardRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../table.css";
import { InputAdornment, Typography } from "@mui/material";
import CustomizedTextField from "../Custom/CustomizedTextField";
import SearchIcon from "@mui/icons-material/Search";
import AG_GRID_LOCALE_TH from "../../locale.th";
import CustomDate from "../UI/CustomDate";

const AgGrid = forwardRef(
  (
    {
      isClientSide,
      rowData,
      columnDefs,
      onRowDoubleClicked,
      height,
      groupDisplayType,
      groupDefaultExpanded,
      groupIncludeFooter,
      groupIncludeTotalFooter,
      searchBox,
      onGridReady,
      onFirstDataRendered,
      masterDetail,
      detailCellRenderer,
      detailRowHeight,
      autoHeight,
      rowSelection,
      onRowClicked,
      onRowSelected,
      enableRangeSelection,
      components,
      onRowDataUpdated,
      suppressMenu,
      rowDragManaged,
      onRowDragMove,
      disabledSidebar,
      disableFloatingFilter,
      rowDragEntireRow,
      getRowId,
      headerClass,
      rowDragMultiRow,
      rowMultiSelectWithClick,
      disableResized,
      onFilterChanged,
      detailCellRendererParams,
      autoGroupColumnDef,
      paginationSize,
    },
    ref
  ) => {
    const sideBar = {
      toolPanels: ["columns", "filters"],
    };

    const defaultColDef = useMemo(() => {
      return {
        editable: false,
        floatingFilter:
          typeof disableFloatingFilter !== "undefined"
            ? !disableFloatingFilter
            : isClientSide
            ? false
            : true,
        filter: isClientSide ? false : true,
        resizable: disableResized ? false : true,
        sortable: isClientSide ? false : true,
        suppressMenu: suppressMenu ? true : false,
        headerClass: headerClass || [],
      };
    }, [
      disableFloatingFilter,
      disableResized,
      headerClass,
      isClientSide,
      suppressMenu,
    ]);

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_TH;
    }, []);

    const onFilterTextBoxChanged = useCallback(
      (event) => {
        ref.current.api.setQuickFilter(
          document.getElementById("filter-text-box").value
        );
      },
      [ref]
    );

    const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }) => (
      <Typography>{noRowsMessageFunc()}</Typography>
    );

    const noRowsOverlayComponent = useMemo(() => {
      return CustomizedNoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
      return {
        noRowsMessageFunc: () => "ไม่มีข้อมูล",
      };
    }, []);

    const getContextMenuItems = useCallback((params) => {
      return [
        ...params.defaultItems,
        {
          name: "Export Selected Rows",
          icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          subMenu: [
            {
              name: "CSV Export",
              icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
              action: () =>
                params.api.exportDataAsCsv({
                  onlySelected: true,
                }),
            },
            {
              name: "Excel Export",
              icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
              action: () =>
                params.api.exportDataAsExcel({
                  onlySelected: true,
                }),
            },
          ],
        },
      ];
    }, []);

    const defaultComponents = useMemo(() => {
      return {
        agDateInput: CustomDate,
      };
    }, []);

    return (
      <>
        {searchBox && (
          <CustomizedTextField
            id="filter-text-box"
            onChange={onFilterTextBoxChanged}
            sx={{ mt: 2, mb: 2, maxWidth: 300 }}
            placeholder="ค้นหา"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        <div
          id="myGrid"
          className="ag-theme-material ag-details-row"
          style={{ height: height || "100%", width: "100%" }}
        >
          <AgGridReact
            domLayout={autoHeight ? "autoHeight" : "normal"}
            ref={ref}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            rowData={rowData}
            localeText={localeText}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            sideBar={disabledSidebar ? false : sideBar}
            detailRowHeight={detailRowHeight}
            enableRangeSelection={enableRangeSelection}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            onRowDoubleClicked={onRowDoubleClicked}
            onRowClicked={onRowClicked}
            onRowSelected={onRowSelected}
            groupDisplayType={groupDisplayType}
            groupDefaultExpanded={groupDefaultExpanded}
            groupIncludeFooter={groupIncludeFooter}
            groupIncludeTotalFooter={groupIncludeTotalFooter}
            detailCellRenderer={detailCellRenderer}
            masterDetail={masterDetail}
            detailCellRendererParams={detailCellRendererParams}
            rowModelType={isClientSide || rowData ? "clientSide" : "serverSide"}
            // serverSideStoreType={isClientSide || rowData ? "full" : "partial"}
            pagination={isClientSide && rowData ? false : true}
            paginationPageSize={
              paginationSize
                ? paginationSize
                : isClientSide && rowData
                ? undefined
                : 10
            }
            cacheBlockSize={isClientSide && rowData ? undefined : 10}
            rowSelection={rowSelection}
            getContextMenuItems={getContextMenuItems}
            components={components || defaultComponents}
            onRowDataUpdated={onRowDataUpdated}
            rowDragMultiRow={rowDragMultiRow}
            rowDragManaged={rowDragManaged}
            onRowDragMove={onRowDragMove}
            rowDragEntireRow={rowDragEntireRow}
            getRowId={getRowId}
            enableCellTextSelection={true}
            suppressContextMenu={true}
            suppressMovableColumns={isClientSide ? true : false}
            suppressAggFuncInHeader={true}
            rowMultiSelectWithClick={rowMultiSelectWithClick}
            onFilterChanged={onFilterChanged}
            autoGroupColumnDef={autoGroupColumnDef}
            serverSideInfiniteScroll={isClientSide ? false : true}
            serverSideSortOnServer={isClientSide ? false : true}
            serverSideSortAllLevels={isClientSide ? false : true}
            serverSideFilterOnServer={isClientSide ? false : true}
            getRowStyle={(params) => {
              if (params.node.footer) {
                return { fontWeight: "bold" };
              }
            }}
          />
        </div>
      </>
    );
  }
);

export default AgGrid;
