import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import * as XLSX from "xlsx";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const deepen = (obj) => {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split(".");

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  Object.keys(result).forEach((key) => {
    if (key.includes("__EMPTY")) {
      delete result[key];
    }
  });
  return result;
};

const isAllNull = (item) => {
  if (typeof item === "object" && item !== null) {
    for (const val of Object.values(item)) {
      if (!isAllNull(val)) {
        return false;
      }
    }
  } else if (item !== null) {
    return false;
  }
  return true;
};

// function for traversing nested objects
const getNestedObj = (objKeyStr, obj, objType) => {
  let indexFromLast = objType === "working" ? 2 : 1;
  const objKeySplit = objKeyStr.split(".");
  let objPointer;
  objKeySplit.reduce((acc, curr) => {
    if (objKeySplit.indexOf(curr) === objKeySplit.length - indexFromLast) {
      objPointer = acc[curr];
    }
    return acc[curr];
  }, obj);
  return objPointer;
};

// get object 1 level before the deepest
const nestedWorkingObj = (objKeyStr, workingObj) => {
  return getNestedObj(objKeyStr, workingObj, "working");
};

// get deepest level object
const nestedCurrentObj = (objKeyStr, currentObj) => {
  return getNestedObj(objKeyStr, currentObj, "current");
};

const ImporterDropzoneUI = ({
  keys,
  setImportedDoc,
  dataFormatter,
  setLoading,
  disabled,
  setErrors,
  setHasError,
  hasLabelHeader,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isInit, setIsInit] = useState(false);
  const [tempDoc, setTempDoc] = useState([]);

  const mapNestedData = (inputData) => {
    try {
      if (!inputData) {
        return;
      }
      // store keys for nested array
      const arrayKeys = keys.filter((key) => Array.isArray(key));

      const tempData = [...inputData];
      const mainId = Object.keys(tempData[0])[0];

      // loops through everything to convert first elements of each specified keys into array
      for (let i = 0; i < tempData.length; i++) {
        // picking object with id to assign other values to it, skip if there's no ID
        let workingObject = { ...tempData[i] };
        if (workingObject[mainId] === null) {
          continue;
        }
        // convert first elements of each keys to array
        for (let j = 0; j < keys.length; j++) {
          if (Array.isArray(keys[j])) {
            continue;
          }
          // if key is for nested objects, call function to traverse the levels
          if (keys[j].includes(".")) {
            const lastLevelKey = keys[j].split(".").pop();
            const nestedWorkObj = nestedWorkingObj(keys[j], workingObject);
            if (!Array.isArray(nestedWorkObj[lastLevelKey])) {
              if (typeof nestedWorkObj[lastLevelKey] === "object") {
                nestedWorkObj[lastLevelKey] = [
                  { ...nestedWorkObj[lastLevelKey] },
                ];
              } else {
                nestedWorkObj[lastLevelKey] = [nestedWorkObj[lastLevelKey]];
              }
            }
          } else {
            if (workingObject[keys[j]] === null) {
              break;
            }
            if (!Array.isArray(workingObject[keys[j]])) {
              workingObject[keys[j]] = [workingObject[keys[j]]];
            }
          }
          tempData[i] = workingObject;
        }
      }

      // loops through everything to assign values into their respective arrays
      for (let i = 0; i < tempData.length; i++) {
        let workingObject = { ...tempData[i] };
        let currentObject;
        if (workingObject[mainId] === null) {
          continue;
        }
        for (let j = i + 1; j < tempData.length; j++) {
          currentObject = { ...tempData[j] };
          if (currentObject[mainId] !== null) {
            break;
          }
          for (let k = 0; k < keys.length; k++) {
            if (Array.isArray(keys[k])) {
              continue;
            }
            // assign values for nested objects
            if (keys[k].includes(".")) {
              const lastLevelKey = keys[k].split(".").pop();
              const nestedWorkObj = nestedWorkingObj(keys[k], workingObject);
              const nestedCurrObj = nestedCurrentObj(keys[k], currentObject);
              if (typeof nestedCurrObj === "object") {
                //skips current key for current row if it's value including nested objects are null
                if (isAllNull(nestedCurrObj)) {
                  continue;
                }
                if (!Array.isArray(nestedWorkObj[lastLevelKey])) {
                  nestedWorkObj[lastLevelKey] = [
                    { ...nestedWorkObj[lastLevelKey] },
                  ];
                }
                nestedWorkObj[lastLevelKey] = [
                  ...nestedWorkObj[lastLevelKey],
                  nestedCurrObj,
                ];
              } else {
                if (!Array.isArray(nestedWorkObj[lastLevelKey])) {
                  nestedWorkObj[lastLevelKey] = [nestedWorkObj[lastLevelKey]];
                }
                if (nestedCurrObj === null) {
                  continue;
                }
                nestedWorkObj[lastLevelKey] = [
                  ...nestedWorkObj[lastLevelKey],
                  nestedCurrObj,
                ];
              }
              continue;
            }

            if (currentObject[keys[k]] === null) {
              continue;
            }

            if (typeof currentObject[keys[k]] === "object") {
              if (isAllNull(currentObject[keys[k]])) {
                continue;
              }
              if (!Array.isArray(workingObject[keys[k]])) {
                workingObject[keys[k]] = [{ ...workingObject[keys[k]] }];
              } else {
                workingObject[keys[k]] = [
                  ...workingObject[keys[k]],
                  { ...currentObject[keys[k]] },
                ];
              }
            } else {
              if (currentObject[keys[k]] === null) {
                continue;
              }
              if (!Array.isArray(workingObject[keys[k]])) {
                workingObject[keys[k]] = [workingObject[keys[k]]];
              } else {
                workingObject[keys[k]] = [
                  ...workingObject[keys[k]],
                  currentObject[keys[k]],
                ];
              }
            }
          }

          tempData[i] = workingObject;
        }
      }
      // map for data with nested array
      for (let i = 0; i < tempData.length; i++) {
        let workingObject = { ...tempData[i] };
        if (workingObject[mainId] === null) {
          continue;
        }
        for (let j = 0; j < arrayKeys.length; j++) {
          let nestedWorkObj = nestedCurrentObj(
            `${arrayKeys[j][0]}`,
            workingObject
          );
          if (!Array.isArray(nestedWorkObj)) {
            continue;
          }
          let indexToAssignTo = 0;
          if (!Array.isArray(nestedWorkObj[indexToAssignTo][arrayKeys[j][1]])) {
            if (
              typeof nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] ===
              "object"
            ) {
              nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                { ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] },
              ];
            } else {
              nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
              ];
            }
          }
          for (let k = 0; k < nestedWorkObj.length; k++) {
            if (
              nestedWorkObj[k] &&
              Object.values(nestedWorkObj[k]).every(
                (item) => item === null || typeof item === "object"
              ) &&
              !isAllNull(nestedWorkObj[k][arrayKeys[j][1]])
            ) {
              if (typeof nestedWorkObj[k][arrayKeys[j][1]] === "object") {
                nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                  ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
                  { ...nestedWorkObj[k][arrayKeys[j][1]] },
                ];
              } else {
                nestedWorkObj[indexToAssignTo][arrayKeys[j][1]] = [
                  ...nestedWorkObj[indexToAssignTo][arrayKeys[j][1]],
                  nestedWorkObj[k][arrayKeys[j][1]],
                ];
              }
              nestedWorkObj.splice(k, 1);
              // step back 1 index as current index is gone now
              k--;
            } else {
              if (!Array.isArray(nestedWorkObj[k][arrayKeys[j][1]])) {
                nestedWorkObj[k][arrayKeys[j][1]] = [
                  { ...nestedWorkObj[k][arrayKeys[j][1]] },
                ];
              }
              indexToAssignTo = k;
            }
          }
        }
      }
      const filteredTempData = tempData.filter((data) => data[mainId] !== null);
      return filteredTempData;
    } catch (err) {
      if (setErrors && setHasError) {
        setErrors([
          {
            type: "template_from_importer",
          },
        ]);
        setHasError(true);
      }
      setLoading(false);
    }
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setTempDoc([]);
      setImportedDoc([]);
      setIsInit(false);
      setLoading(true);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        const wb = XLSX.read(binaryStr, { type: "binary", cellDates: true });
        wb.SheetNames.forEach(function (sheetName) {
          const XL_row_object = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
            defval: null,
            range: hasLabelHeader ? 1 : undefined,
          });
          const deepened = XL_row_object.map((record) => {
            return deepen(record);
          });
          const converted = mapNestedData(deepened);
          setTempDoc(converted);
        });
      };
      try {
        reader.readAsArrayBuffer(acceptedFiles[0]);
      } catch (err) {
        enqueueSnackbar("ประเภทไฟล์ไม่รองรับ", {
          variant: "error",
        });
      }
    },
    disabled: disabled,
  });

  useEffect(() => {
    if (!isInit && tempDoc && tempDoc.length > 0) {
      const getFormattedData = async () => {
        const formattedData = await dataFormatter(tempDoc);
        setImportedDoc(formattedData);
        console.log(formattedData);
        setLoading(false);
        setIsInit(true);
      };
      getFormattedData();
    }
  }, [tempDoc, dataFormatter, setImportedDoc, isInit, setLoading]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>ลากมาวางเพื่ออัปโหลด</p>
      </div>
    </section>
  );
};

export default ImporterDropzoneUI;
