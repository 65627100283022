import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ControlledSelect from "../../../components/Controlled/ControlledSelect";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox.js";
import { Controller, useForm, useWatch } from "react-hook-form";
import Calendar from "../../../components/UI/Calendar/index.js";
import { goodsIssueActions } from "../../../features/Inventory/GoodsIssue/goodsIssue-slice";
import { getAllGoodsIssue } from "../../../features/Inventory/GoodsIssue/goodsIssue-actions";
import {
  dateToUnix,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import moment from "moment";
import ModalUI from "../../../components/UI/ModalUI.js";
import { calendarObjectFormatter } from "../../../hooks/Calendar/use-calendar-object-formatter.js";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField.js";
import CustomizedChips from "../../../components/Custom/CustomizedChips.js";
import ControlledDateTimePicker from "../../../components/Controlled/ControlledDateTimePicker.js";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect.js";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus.js";
import DirectionsIcon from "@mui/icons-material/Directions";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip.js";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const typeOption = [
  { label: "ขาย", value: "ขาย" },
  // { label: "ขายออนไลน์", value: "ขายออนไลน์" }, //SO
  // { label: "ผลิตวัตถุดิบ", value: "ผลิตวัตถุดิบ" }, //MO
  // { label: "ผลิตแปรรูป", value: "ผลิตแปรรูป" }, //MO
  // { label: "ผลิตแปรรูป - PT", value: "ผลิตแปรรูป - PT" }, //PT -> MO
  { label: "ส่งคืน", value: "ส่งคืน" }, //RS
  { label: "เบิกตัวอย่าง", value: "เบิกตัวอย่าง" },
  // { label: "สินค้าเสีย", value: "สินค้าเสีย" },
  { label: "อื่นๆ", value: "อื่นๆ" },
];

const DeliveryTable = () => {
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { allItems, isLoading, item } = useSelector(
    (state) => state.goodsIssue
  );
  //   const { allLocation } = useSelector((state) => state.location);

  const [eventObjectsList, setEventObjectsList] = useState([]);
  const [eventObjectsFiltered, setEventObjectsFiltered] = useState([]);
  const [modal, setModal] = useState(false);

  //   const warehouseOption = allLocation?.map((location) => {
  //     return {
  //       id: location.document_id,
  //       label: location.thai_name,
  //       value: location.document_id,
  //     };
  //   });

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.issue.deliveryTable"),
    },
  ];

  const { control, getValues, reset } = useForm({
    defaultValues: item,
  });

  const {
    control: controlFilter,
    getValues: getValuesFilter,
    setValue: setValuesFilter,
  } = useForm({
    defaultValues: {
      status: "",
      deliveryEmployee: "",
      startDate: "",
      endDate: "",
    },
  });

  const filterStatusWatch = useWatch({
    control: controlFilter,
    name: "status",
  });
  const filterDeliveryEmployeeWatch = useWatch({
    control: controlFilter,
    name: "deliveryEmployee",
  });

  const giStatusOptions = useMemo(
    () => [
      {
        label: t("status.all"),
        value: "ALL",
      },
      {
        label: t("status.draft"),
        value: "draft",
      },
      {
        label: t("status.finished"),
        value: "finished",
      },
    ],
    [t]
  );

  const getAllItems = useCallback(
    async (isDefault) => {
      //   setIsLoadingFilter(true);
      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          document_date: {
            filter: parseInt(dateToUnix(moment(new Date()).startOf("month"))),
            filterTo: parseInt(dateToUnix(moment(new Date()).endOf("month"))),
            filterType: "number",
            type: "inRange",
          },
        },
        sortModel: [
          { colId: "document_id", sort: "desc" },
          { colId: "created_date", sort: "desc" },
        ],
      };
      if (isDefault) {
        dispatch(getAllGoodsIssue(input, null, enqueueSnackbar));
      } else {
        const getDateValue = getValuesFilter("startDate");
        const getDateToValue = getValuesFilter("endDate");
        input.filterModel.document_date.filter = dateToUnix(
          moment(getDateValue).startOf("day")
        );
        input.filterModel.document_date.filterTo = dateToUnix(getDateToValue);
        dispatch(getAllGoodsIssue(input, null, enqueueSnackbar));
      }
      //   setIsLoadingFilter(false);
    },
    [dispatch, enqueueSnackbar, getValuesFilter]
  );

  const getDeliveryEmployeeOption = useCallback(() => {
    // Use a Set to get unique employee names
    const uniqueEmployeeNames = [
      ...new Set(allItems.map((item) => item.delivery_employee)),
    ];

    // Create a new array of objects in the desired format
    let comboBoxOptions = uniqueEmployeeNames.map((name, index) => ({
      id: index + 1, // You can use a unique identifier here
      value: name,
      label: name,
    }));

    comboBoxOptions = [
      { id: "ALL", value: "ALL", label: "ทั้งหมด" },
      ...comboBoxOptions,
    ];

    return comboBoxOptions;
  }, [allItems]);

  const eventClickHandler = (info) => {
    const eventDocumentId = info.event.extendedProps.document_id;
    const event = allItems.find(
      (event) => event.document_id === eventDocumentId
    );

    reset(event);
    setModal(true);
  };

  const datesSetHandler = (info) => {
    const { start, end } = info;
    setValuesFilter("startDate", start);
    setValuesFilter("endDate", end);

    const startTimestamp = moment(start).valueOf(); // Get timestamp of start date
    const endTimestamp = moment(end).valueOf(); // Get timestamp of end date
    const middleTimestamp = (startTimestamp + endTimestamp) / 2; // Calculate the average
    const middleDate = moment(middleTimestamp);

    if (
      middleDate.month() !== moment().month() ||
      info.view.type === "dayGridMonth"
    ) {
      getValuesFilter("startDate") &&
        getValuesFilter("endDate") &&
        getAllItems(false);
    } else getAllItems(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    getAllItems(true);
    setValuesFilter("status", giStatusOptions[0].value);
    setValuesFilter("deliveryEmployee", "ทั้งหมด");
    return () => dispatch(goodsIssueActions.resetAllItems());
  }, [dispatch, getAllItems, giStatusOptions, setValuesFilter]);

  useEffect(() => {
    const eventObjects = calendarObjectFormatter(allItems ?? []);
    setEventObjectsList(eventObjects);
    setEventObjectsFiltered(eventObjects);
  }, [allItems]);

  useEffect(() => {
    const filteredEvent = eventObjectsList.filter((event) => {
      let filters = true;

      if (filterStatusWatch) {
        if (filterStatusWatch !== "ALL") {
          filters = filters && event.status === filterStatusWatch;
        }
      }

      if (filterDeliveryEmployeeWatch) {
        if (filterDeliveryEmployeeWatch !== "ทั้งหมด")
          filters =
            filters && event.delivery_employee === filterDeliveryEmployeeWatch;
      }

      return filters;
    });

    setEventObjectsFiltered(filteredEvent);
  }, [filterStatusWatch, filterDeliveryEmployeeWatch, eventObjectsList]);

  return (
    <Box ref={scrollRef}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"space-between"}
        spacing={2}
        my={3}
      >
        <Grid item md={6}>
          <Typography variant="h5">
            {t("inventory.issue.deliveryTable")}
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={6} flexDirection={"row"} spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={controlFilter}
              name={"deliveryEmployee"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  // loading={view === "calendar" ? false : isLoadingAllStatus}
                  label={t("inventory.issue.deliveryEmployee")}
                  options={getDeliveryEmployeeOption()}
                  onChange={(_, value) => {
                    field.onChange(value ? value.label : "");
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelect
              name="status"
              label={t("สถานะ")}
              control={controlFilter}
              options={giStatusOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Calendar
        events={eventObjectsFiltered}
        eventClickHandler={eventClickHandler}
        datesSetHandler={datesSetHandler}
      />
      <ModalUI
        open={modal}
        handleClose={handleCloseModal}
        title={"แผนการทำงาน"}
        maxWidth="sm"
      >
        <Grid container spacing={2}>
          <Grid container item xs={12} justifyContent={"space-between"}>
            <Grid item container spacing={1} alignItems={"center"}>
              <Grid item>
                <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                  {getValues("document_id")}
                </Typography>
              </Grid>
              <Grid item>
                <CustomizedTooltip title="เปิดเอกสาร">
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    size="small"
                    onClick={() => {
                      window.open(
                        `/inventory/issue/${encodeURIComponent(
                          getValues("document_id")
                        )}`,
                        "_blank"
                      );
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            </Grid>
            <Grid item>
              <CustomizedStatus status={getValues("status")} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  options={typeOption}
                  label={t("inventory.issue.issueType")}
                  // error={Boolean(errors.document_id)}
                  // helperText={errors.document_id?.message}
                  disabled={true}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"source_warehouse_thai_name"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.warehouse")}
                  disabled={true}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledDateTimePicker
              name="start_delivery_date"
              control={control}
              //   error={errors.start_delivery_date}
              //   isOpen={startDeliveryDateIsOpen}
              //   onClose={() => setStartDeliveryDateIsOpen(false)}
              //   onOpen={() => setStartDeliveryDateIsOpen(true)}
              label={t("inventory.issue.startDeliveryDate")}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledDateTimePicker
              name="end_delivery_date"
              control={control}
              //   error={errors.end_delivery_date}
              //   isOpen={endDeliveryDateIsOpen}
              //   onClose={() => setEndDeliveryDateIsOpen(false)}
              //   onOpen={() => setEndDeliveryDateIsOpen(true)}
              label={t("inventory.issue.endDeliveryDate")}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name={"remark"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.remark")}
                  // error={Boolean(errors.remark)}
                  // helperText={errors.remark?.message}
                  disabled={true}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name={"delivery_employee"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.issue.deliveryEmployee")}
                  disabled={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name={"google_map_link"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.issue.googleMap")}
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CustomizedTooltip title="เปิดลิงก์ Google Map">
                          <IconButton
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="directions"
                            onClick={() => {
                              window.open(field.value, "_blank");
                            }}
                          >
                            <DirectionsIcon />
                          </IconButton>
                        </CustomizedTooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"delivery_address"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.issue.deliveryAddress")}
                  disabled={true}
                  multiline
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <CustomizedChips
                value={`วันที่สร้าง ${unixToDateWithFormat(
                  getValues("created_date")
                )}`}
              />
            </Grid>
            <Grid item>
              <CustomizedChips
                value={`วันที่นำออก ${unixToDateWithFormat(
                  getValues("document_date")
                )}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </ModalUI>
    </Box>
  );
};

export default DeliveryTable;
