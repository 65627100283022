import React, { useRef, useEffect, useMemo } from "react";
import { uomActions } from "../../../features/Setting/Uom/uom-slice";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ModalUI from "../../../components/UI/ModalUI";
import UomGroupForm from "../../../components/Form/UomGroup";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import IconButton from "@mui/material/IconButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AG_GRID_LOCALE_TH from "../../../locale.th";
import {
  getAllUomGroup,
  // getUomGroupById,
} from "../../../features/Setting/Uom/uom-actions";

const UomGroup = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { modal, allUomGroup, uomGroup } = useSelector((state) => state.uom);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.uom.group"),
    },
  ];

  const columnDefs = [
    {
      field: "id",
      headerName: t("setting.id"),
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "document_id",
      headerName: t("setting.inventory.uom.groupDocumentId"),
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "name",
      headerName: t("setting.inventory.uom.groupName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "description",
      headerName: t("setting.description"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <Box
          display={"flex"}
          flex={"end"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div>{params.data.description}</div>
          {/* <IconButton
            onClick={() => {
              handleOpen(params, params.node.rowIndex);
            }}
          >
            <EditOutlinedIcon />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllUomGroup());
  }, [dispatch]);

  // const handleOpen = async (params, index) => {
  //   await dispatch(uomActions.loadedUomGroupIndex(index));
  //   if (params && params.data && params.data.id) {
  //     await dispatch(getUomGroupById({ uniqueInput: { id: params.data.id } }));
  //   }
  //   await dispatch(uomActions.openModal("uomGroup"));
  // };

  const handleClose = () => {
    dispatch(uomActions.closeModal("uomGroup"));
    setTimeout(() => {
      dispatch(uomActions.resetUomGroup());
    }, 500);
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      floatingFilter: true,
      filter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_TH;
  }, []);

  const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }) => (
    <Typography>{noRowsMessageFunc()}</Typography>
  );

  const noRowsOverlayComponent = useMemo(() => {
    return CustomizedNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "ไม่มีข้อมูล",
    };
  }, []);

  const onRowDoubleClicked = (params) => {
    navigate("/setting/inventory/uomgroup/" + params.data.id);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="h5">
            {t("setting.inventory.uom.group")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          alignSelf="center"
          justifySelf="end"
          textAlign={"end"}
        >
          <Box sx={{ display: "inline-block" }}>
            <CustomizedButton
              sx={{ mr: 2 }}
              title={t("setting.inventory.uom.add")}
              variant="contained"
              // onClick={handleOpen}
            />
            <CustomizedButton
              title="นำเข้ากลุ่มของหน่วย"
              variant="contained"
              onClick={() => navigate(`/setting/inventory/uomgroup/importer`)}
            />
          </Box>
        </Grid>
      </Grid>
      <ModalUI
        open={Boolean(modal.uomGroup)}
        handleClose={handleClose}
        title={
          !uomGroup.id ? t("setting.inventory.uom.add") : t("setting.edit")
        }
      >
        <UomGroupForm handleClose={handleClose} />
      </ModalUI>
      <Box sx={{ my: 2 }}>
        <div
          id="myGrid"
          className="ag-theme-material"
          style={{ height: 450, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={allUomGroup}
            localeText={localeText}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onRowDoubleClicked={onRowDoubleClicked}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            enableRangeSelection={false}
          />
        </div>
      </Box>
    </>
  );
};

export default UomGroup;
