import * as Yup from "yup";

export const validation = Yup.object().shape({
  document_id: Yup.string().required("กรุณากรอก"),
  type: Yup.string().required("กรุณากรอก"),
  reference_document_id: Yup.string().when("type", {
    is: (value) => value !== "อื่นๆ",
    then: Yup.string().required("กรุณาระบุเอกสารอ้างอิง"),
  }),
  source_warehouse_document_id: Yup.string().required("กรุณาระบุคลัง"),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  goods_receive_list: Yup.array()
    .of(
      Yup.object().shape({
        stock_entry_list: Yup.object().shape({
          posted_quantity: Yup.number().required("กรุณากรอก"),
          trace_entry_list: Yup.array().of(
            Yup.object().shape({
              posted_quantity: Yup.number()
                .typeError("กรุณาระบุจำนวนที่จะรับคืน")
                .required("กรุณาระบุจำนวนที่จะรับคืน")
                .min(1, "กรุณาระบุจำนวนมากกว่า 0"),
            })
          ),
        }),
      })
    )
    .min(1, "กรุณาเพิ่มรายการสินค้า"),
  // barcode: Yup.string().required("กรุณากรอก"),
  // description: Yup.string().required("กรุณากรอก"),
  // sales_standard_price: positiveNumberValidate,
  // maximum_discount: positiveNumberValidate,
  // purchase_standard_price: positiveNumberValidate,
  // sales_vat_type: Yup.string().required("กรุณากรอก"),
});
