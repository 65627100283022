import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useSelector } from "react-redux";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { useParams } from "react-router-dom";
import { dateToUnix } from "../../../utils/date-converter";
import { useAuth } from "../../../hooks/use-auth";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import InventoryService from "../../../services/Inventory";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";

const ReceiveDetail = ({
  control,
  errors,
  getValues,
  setValue,
  viewOnly,
  isInventoryPage,
  resetFieldBarcode,
  watchType,
  watchRefDoc,
  replace,
  reset,
  generateDocumentId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  // const { item } = useSelector((state) => state.goodsReceive);
  const { allLocation } = useSelector((state) => state.location);
  const [referenceDocumentOptions, setReferenceDocumentOptions] = useState([]);
  const [receiveDateIsOpen, setReceiveDateIsOpen] = useState(false);
  // const [traceValue, setTraceValue] = useState([]);

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  const typeOption = [
    { label: "รับคืนจากลูกค้า", value: "รับคืนจากลูกค้า" }, //ใบรับคืน
    // { label: "รับคืนจากผลิต", value: "รับคืนจากผลิต" }, //MO
  ];

  const fetchDocument = useCallback(async (docType) => {
    let results = null;
    const formatData = results.map((doc) => {
      return {
        ...doc,
        item_list: doc.item_list.map((item) => {
          return {
            document_id: item.item_document_id,
            name: item.item_name,
            description: item.item_description,
            quantity: item.qty,
            quantity_return: item.qty_return,
            uom: item.uom,
          };
        }),
      };
    });
    setReferenceDocumentOptions(formatData);
  }, []);

  const resetValue = useCallback(() => {
    if (!Boolean(id)) {
      setValue("reference_document_id", "");
      setValue("goods_receive_list", []);
    }
  }, [id, setValue]);

  const checkTypeIfSameDoc = useCallback(async () => {
    if (watchRefDoc.slice(0, 2) === "SR" && watchType !== "รับคืนจากลูกค้า") {
      resetValue();
    }
  }, [watchRefDoc, watchType, resetValue]);

  useEffect(() => {
    if (watchType === "รับคืนจากลูกค้า") {
      fetchDocument("SR");
    } else {
      setReferenceDocumentOptions([]);
    }
    checkTypeIfSameDoc();
  }, [checkTypeIfSameDoc, fetchDocument, watchType]);

  const checkIfAlreadyReturn = useCallback(async (newValue) => {
    const formatFilterModel = {
      reference_document_id: {
        filterType: "text",
        type: "equals",
        filter: newValue.value,
      },
    };
    const allItems = await InventoryService.getAllGRAggrid({
      startRow: 0,
      endRow: 999,
      filterModel: formatFilterModel,
    });
    return allItems;
  }, []);

  const setValueFormRefDoc = useCallback(
    async (field, newValue) => {
      const currValue = getValues();
      if (newValue && newValue.value) {
        if (currValue.reference_document_id !== newValue.value) {
          reset({
            ...currValue,
            reference_document_id: newValue.value,
            goods_receive_list: [],
          });
        }
        const findItemFromRef = referenceDocumentOptions.find(
          (doc) => doc.document_id === newValue.value
        );
        if (findItemFromRef) {
          const { results } = await checkIfAlreadyReturn(newValue);
          const formatValue = findItemFromRef.item_list.map((item) => {
            const newItem = [];
            for (let i = 0; i < results.length; i++) {
              results[i].goods_receive_list.forEach((list) => {
                list.stock_entry_list[0].trace_entry_list.forEach((teList) =>
                  newItem.push(teList)
                );
              });
            }
            const prevQuantity = newItem
              .filter((result) => result.item_document_id === item.document_id)
              .reduce((prev, curr) => prev + curr.posted_quantity, 0);
            return {
              initial_quantity: item.quantity_return,
              posted_quantity: item.quantity_return,
              posted_value: 0,
              already_posted_quantity: prevQuantity,
              stock_entry_list: {
                destination_warehouse: "",
                entry_type: "",
                is_active: true,
                item: item,
                posted_date: dateToUnix(new Date()),
                posted_quantity: 0,
                posted_value: 0,
                reference_document_id: "",
                reference_document_type: "issue",
                source_warehouse_document_id: getValues(
                  "source_warehouse_document_id"
                ),
                trace_entry_list: [],
                created_by: user,
                uom: item.uom,
              },
            };
          });
          // setTraceValue(formatValue);
          replace(formatValue);
        }
        field.onChange(newValue.value);
      } else {
        reset({
          ...currValue,
          reference_document_id: "",
          goods_receive_list: [],
        });
        field.onChange("");
      }
      field.onChange(newValue ? newValue.value : "");
    },
    [
      checkIfAlreadyReturn,
      getValues,
      referenceDocumentOptions,
      replace,
      reset,
      user,
    ]
  );

  useEffect(() => {
    resetFieldBarcode({
      barcode: "",
      source_bin_location_document_id: "",
    });
  }, [currentWarehouse, resetFieldBarcode]);

  const checkStage = !id;

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={checkStage ? 10 : 12}
          sm={checkStage ? 10 : 12}
          md={6}
          lg={4}
          xl={4}
        >
          <Controller
            control={control}
            name={"document_id"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.return.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
              />
            )}
          />
        </Grid>
        {!id && (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={generateDocumentId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"type"}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={typeOption}
                label={t("inventory.return.returnType")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {watchType === "อื่นๆ" ? (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.referenceNo")}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  disabled={
                    !isInventoryPage || viewOnly || watchType === "อื่นๆ" || id
                      ? true
                      : false
                  }
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  options={referenceDocumentOptions.map((doc) => {
                    return {
                      id: doc.id,
                      value: doc.document_id,
                      label: doc.document_id,
                    };
                  })}
                  label={t("inventory.referenceNo")}
                  onChange={(_, newValue) =>
                    setValueFormRefDoc(field, newValue)
                  }
                  disabled={
                    !isInventoryPage || viewOnly || Boolean(id) ? true : false
                  }
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledDatePicker
            name="lot"
            control={control}
            error={errors.lot}
            isOpen={receiveDateIsOpen}
            onClose={() => setReceiveDateIsOpen(false)}
            onOpen={() => setReceiveDateIsOpen(true)}
            label="Lot"
            disabled={Boolean(id)}
            // sx={{ width: { sx: "auto", md: 155 } }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.value : "");
                }}
                value={mapWarehouseIdToName()}
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={Boolean(id)}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ReceiveDetail;
