import { combineReducers } from "@reduxjs/toolkit";
import accountReducer from "../features/User/Account/account-slice";
import rbacReducer from "../features/User/Rbac/rbac-slice";
import itemMasterReducer from "../features/Inventory/ItemMaster/itemMaster-slice";
import uiReducer from "../features/UI/ui-slice";
import attributeReducer from "../features/Setting/Attribute/attribute-slice";
import uomReducer from "../features/Setting/Uom/uom-slice";
import locationReducer from "../features/Setting/Location/location-slice";
import categoryReducer from "../features/Setting/Category/category-slice";
import goodsReceiveReducer from "../features/Inventory/GoodsReceive/goodsReceive-slice";
import goodsIssueReducer from "../features/Inventory/GoodsIssue/goodsIssue-slice";
import goodsTransferReducer from "../features/Inventory/GoodsTransfer/goodsTransfer-slice";
import goodsAdjustmentReducer from "../features/Inventory/GoodsAdjustment/goodsAdjustment-slice";
import inventoryReport from "../features/Inventory/Report/report-slice";
import contactReducer from "../features/Contact/contact-slice";
import activityLogsReducer from "../features/ActivityLogs/activityLogs-slice";
import remarkTemplateReducer from "../features/Setting/RemarkTemplate/remark-template-slice";
import vehicleReducer from "../features/Setting/Vehicle/vehicle-slice";
import workCenterReducer from "../features/Setting/Manufacture/manufacture-slice";
import purchaseRequestReducer from "../features/Purchase/PurchaseRequest/purchase-request-slice";
import purchaseOrderReducer from "../features/Purchase/PurchaseOrder/purchase-order-slice";
import purchaseReturnReducer from "../features/Purchase/PurchaseReturn/purchase-return-slice";
import purchaseReportReducer from "../features/Purchase/PurchaseReport/purchase-report-slice";
import estimateReducer from "../features/Engineer/Estimate/estimate-slice";

export const rootReducer = combineReducers({
  account: accountReducer,
  rbac: rbacReducer,
  itemMaster: itemMasterReducer,
  ui: uiReducer,
  attribute: attributeReducer,
  uom: uomReducer,
  location: locationReducer,
  category: categoryReducer,
  goodsReceive: goodsReceiveReducer,
  goodsIssue: goodsIssueReducer,
  goodsTransfer: goodsTransferReducer,
  goodsAdjustment: goodsAdjustmentReducer,
  inventoryReport: inventoryReport,
  contact: contactReducer,
  activityLogs: activityLogsReducer,
  remarkTemplate: remarkTemplateReducer,
  vehicle: vehicleReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseRequest: purchaseRequestReducer,
  purchaseReturn: purchaseReturnReducer,
  purchaseReport: purchaseReportReducer,
  workCenter: workCenterReducer,
  estimate: estimateReducer,
});
