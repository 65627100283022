import { Typography } from "@mui/material";
import React from "react";
// import { formatDate } from "../../utils/date-converter";
import { CustomizedBox } from "../Custom/CustomizedBox";

const TotalBox = ({ title, total, date, unit, borderColor }) => {
  return (
    <CustomizedBox
      padding=".5rem .5rem 1rem 1rem"
      margin="1rem 0 0 0"
      radius="5px"
      borderColor={borderColor}
      total
    >
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "16px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "30px",
          lineHeight: "35px",
          my: ".5rem",
        }}
      >
        {total}
      </Typography>
      {/* <Box sx={{ position: "relative" }}>
        <Typography
          sx={{
            position: "absolute",
            right: 1,
            bottom: -15,
            color: "#9F9F9F",
          }}
          variant="caption"
        >
          {`อัพเดทล่าสุดเมื่อ ${date ? date : formatDate(new Date())}`}
          {unit && `${unit}`}
        </Typography>
      </Box> */}
    </CustomizedBox>
  );
};

export default TotalBox;
