import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AccountTab from "./AccountTab";
import PermissionTab from "./PermissionTab";
import { useForm } from "react-hook-form";
import { validation } from "../../../components/Form/UserAccount/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadFileToS3 } from "../../../utils/s3";
import { useAuth } from "../../../hooks/use-auth";
import { useDispatch, useSelector } from "react-redux";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import {
  postNewUser,
  getUser,
  updateUser,
} from "../../../features/User/Account/account-actions";
import { useSnackbar } from "notistack";
import { accountActions } from "../../../features/User/Account/account-slice";

const AccountContainer = ({ viewOnly, addable, setting }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const step = searchParams.get("step");
  const { role } = useSelector((state) => state.rbac);
  const { user: employee } = useSelector((state) => state.account);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: { ...employee, ...role },
    resolver: yupResolver(validation),
  });

  const onUserSubmit = async (data, type) => {
    if (type !== "triggerRevalidate") {
      //wait to be fixed
      let img_url = "";

      if (
        data.img_url &&
        Array.isArray(data.img_url) &&
        data.img_url.length > 0 &&
        data.img_url[0] instanceof File
      ) {
        const { Location } = await uploadFileToS3(
          data.img_url[0],
          "user",
          user.document_id
        );
        img_url = Location;
      } else if (Array.isArray(data.img_url) && data.img_url.length === 0) {
        img_url = "";
      } else {
        img_url = data.img_url;
      }

      const role_list = data.group_role_list.map((role) => role.value);

      const newUserPayload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email.toLowerCase(),
        img_url,
        phone: data.phone.toString(),
        position: data.position,
        department: data.department,
        status: data.status,
        role_list: role_list,
      };

      if (data?.new_password === data?.confirm_new_password) {
        newUserPayload.password = data.new_password;
      }

      if (viewOnly || setting) {
        //case : update existing user
        dispatch(
          updateUser(
            { document_id: setting ? user.document_id : employeeId },
            newUserPayload,
            setting,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        //case : create new user
        dispatch(
          postNewUser("employee", newUserPayload, navigate, enqueueSnackbar)
        );
      }
    }
  };

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: setting ? t("user.setting.index") : t("user.account.index"),
      to: setting ? "/user/setting" : "/user/account",
    },
    {
      name: viewOnly
        ? `${employee.first_name} ${employee.last_name}`
        : t("user.account.addNewAccount"),
    },
  ];
  setting && breadcrumbs.splice(2, 1);

  const tabs = [
    {
      label: t("user.account.index"),
      path: `${pathname}`,
    },
    {
      label: t("user.account.permission"),
      path: `${pathname}?step=permission&subtab=inventory`,
    },
  ];

  useEffect(() => {
    //use new method

    //clear state if user go to user setting page
    //as they sharing same components
    if (pathname === "/user/setting") {
      dispatch(rbacActions.resetRole());
      dispatch(accountActions.resetUser());
    }
    //clear state if user go to different path
    return () => {
      dispatch(rbacActions.resetRole());
      dispatch(accountActions.resetUser());
    };
  }, [dispatch, enqueueSnackbar, pathname]);

  useEffect(() => {
    //get data to be rendered as default values
    if (employeeId || setting) {
      const getUserPayload = {
        document_id: setting ? user.document_id : employeeId,
      };

      dispatch(getUser(getUserPayload, enqueueSnackbar));
    }
  }, [dispatch, employeeId, enqueueSnackbar, setting, user]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (employee) {
      Object.entries(employee).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [employee, setValue]);

  const currentTab =
    pathname +
    (step
      ? `?step=${step}${step === "permission" ? "&subtab=inventory" : ""}`
      : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      <form onSubmit={handleSubmit(onUserSubmit)}>
        {!step ? (
          <AccountTab
            control={control}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            viewOnly={viewOnly}
            addable={addable}
            getValues={getValues}
            onTriggerRevalidate={handleSubmit((data) =>
              onUserSubmit(data, "triggerRevalidate")
            )}
            setting={setting}
          />
        ) : (
          <PermissionTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            viewOnly={viewOnly}
            addable={addable}
            setting={setting}
          />
        )}
      </form>
    </>
  );
};

export default AccountContainer;
