import { Box, IconButton, Typography } from "@mui/material";
import {
  customHeaderColRender,
  RenderCustomizedSelect,
  RenderCustomizedTextField,
  RenderItemNameCell,
} from "../../../../utils/customCellRenderer";
import { formatNumber } from "../../../../utils/dataTransformer";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";
import WithholdingTax from "./WithholdingTax";

export const poColumnDefs = (
  t,
  status,
  documentType,
  control,
  errors,
  getValues,
  setValue,
  handleShowItemModal,
  openDeleteItemConfirmationHandler,
  viewOnly
) => {
  const customCellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 6px",
  };

  const vatOption = [
    { value: "ไม่มี", label: "ไม่มี" },
    { value: "0", label: "0 %" },
    { value: "7", label: "7 %" },
  ];

  const columnsDef = [
    {
      field: "no",
      rowDrag: viewOnly ? false : true,
      headerComponentParams: {
        template: customHeaderColRender("No.", "รายการ"),
      },
      valueGetter: (params) => params.node.rowIndex + 1,
      cellStyle: customCellStyle,
      width: 90,
    },
    {
      field: "item_name",
      headerComponentParams: {
        template: customHeaderColRender("Name", "ชื่อสินค้า", true),
      },
      cellRenderer: (params) => (
        <RenderItemNameCell
          data={params.node.data}
          index={params.node.rowIndex}
          control={control}
          errors={errors}
          viewOnly={viewOnly}
          handleShowItemModal={handleShowItemModal}
          documentType={documentType}
          status={status}
          name="item_list"
        />
      ),
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        padding: "0 6px",
      },
      width: 280,
    },
    {
      field: "qty",
      headerComponentParams: {
        template: customHeaderColRender("PO Qty", "จำนวนสั่งซื้อ", true),
      },
      cellRenderer: (params) => {
        return (
          <>
            {viewOnly ? (
              <Typography variant="body2" align="center">
                {params.data.qty.toLocaleString()}
              </Typography>
            ) : (
              <RenderCustomizedTextField
                control={control}
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[params.node.rowIndex] &&
                    errors?.item_list[params.node.rowIndex]?.qty
                )}
                helperText={
                  errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex]?.qty &&
                  errors?.item_list[params.node.rowIndex]?.qty?.message
                }
                name={`item_list[${params.node.rowIndex}].qty`}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                onChange={(e) => {
                  const newPreVatAmount =
                    e.target.value *
                      getValues(
                        `item_list[${params.node.rowIndex}].price_per_unit`
                      ) -
                    getValues(
                      `item_list[${params.node.rowIndex}].discount_amount`
                    );
                  //update data in fieldArray
                  setValue(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`,
                    newPreVatAmount
                  );
                  //update data in pre_vat_amount cell
                  const rowNode = params.api.getRowNode(
                    params.data.uid + (params.data.ref_document_id ?? "")
                  );
                  rowNode.setDataValue("pre_vat_amount", newPreVatAmount);
                  rowNode.setDataValue("qty", e.target.value);
                  const unit = getValues(
                    `item_list[${params.node.rowIndex}].uom_id`
                  );
                  if (unit) {
                    if (unit === params.data.uom_group?.base_uom?.id) {
                      setValue(
                        `item_list[${params.node.rowIndex}].qty_uom`,
                        Math.ceil(e.target.value)
                      );
                      rowNode.setDataValue(
                        "qty_uom",
                        Math.ceil(e.target.value)
                      );
                    } else {
                      const conversionUom =
                        params.data.uom_group.uom_conversion_list.find(
                          (conversion) => conversion.target_uom.id === unit
                        );
                      const targetUomQty = conversionUom.target_uom_rate;
                      setValue(
                        `item_list[${params.node.rowIndex}].qty_uom`,
                        Math.ceil(e.target.value / targetUomQty)
                      );
                      rowNode.setDataValue(
                        "qty_uom",
                        Math.ceil(e.target.value / targetUomQty)
                      );
                    }
                  }
                  const withholding_tax = getValues(
                    `item_list[${params.node.rowIndex}].withholding_tax`
                  );
                  const pre_vat_amount = getValues(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`
                  );
                  if (withholding_tax) {
                    const withholding_tax_amount =
                      (pre_vat_amount *
                        parseFloat(
                          withholding_tax.type === "ยังไม่ระบุ" ||
                            withholding_tax.type === "ไม่มี" ||
                            withholding_tax.type === ""
                            ? 0
                            : withholding_tax.type
                        )) /
                      100;
                    setValue(
                      `item_list[${params.node.rowIndex}].withholding_tax`,
                      {
                        type: withholding_tax.type,
                        amount: withholding_tax_amount,
                      }
                    );
                    rowNode.setDataValue("withholding_tax", {
                      type: withholding_tax.type,
                      amount: withholding_tax_amount,
                    });
                  }
                }}
              />
            )}
          </>
        );
      },
      cellStyle: customCellStyle,
      width: 135,
    },
    {
      field: "uom_id",
      headerComponentParams: {
        template: customHeaderColRender("Unit", "หน่วย", true),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.uom.name}
            </Typography>
          ) : (
            <RenderCustomizedSelect
              control={control}
              error={Boolean(
                errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex]?.uom_id
              )}
              helperText={
                errors?.item_list &&
                errors?.item_list[params.node.rowIndex] &&
                errors?.item_list[params.node.rowIndex]?.uom_id &&
                errors?.item_list[params.node.rowIndex]?.uom_id?.message
              }
              name={`item_list[${params.node.rowIndex}].uom_id`}
              options={[
                {
                  label: params.data.uom_group?.base_uom?.name,
                  value: params.data.uom_group?.base_uom?.id,
                },
                ...params.data.uom_group?.convertable_uom_list?.map((uom) => ({
                  label: uom.name,
                  value: uom.id,
                })),
              ]}
              params={params}
              getValues={getValues}
              setValue={setValue}
            />
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 130,
    },
    {
      field: "price_per_unit",
      headerComponentParams: {
        template: customHeaderColRender(
          "Price/Unit",
          t("sales.price/unit"),
          true
        ),
      },
      cellRenderer: (params) => {
        return (
          <>
            {viewOnly ? (
              <Typography variant="body2" align="center">
                {formatNumber(params.data.price_per_unit)}
              </Typography>
            ) : (
              <CustomizedNumberTextField
                control={control}
                error={
                  errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex].price_per_unit
                }
                name={`item_list[${params.node.rowIndex}].price_per_unit`}
                onChange={(value) => {
                  const newPreVatAmount =
                    getValues(`item_list[${params.node.rowIndex}].qty`) *
                      value -
                    getValues(
                      `item_list[${params.node.rowIndex}].discount_amount`
                    );

                  setValue(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`,
                    newPreVatAmount
                  );
                  //update data in pre_vat_amount cell
                  const rowNode = params.api.getRowNode(
                    params.data.uid + (params.data.ref_document_id ?? "")
                  );
                  rowNode.setDataValue("pre_vat_amount", newPreVatAmount);
                  rowNode.setDataValue("price_per_unit", value);
                  const withholding_tax = getValues(
                    `item_list[${params.node.rowIndex}].withholding_tax`
                  );
                  const pre_vat_amount = getValues(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`
                  );
                  if (withholding_tax) {
                    const withholding_tax_amount =
                      (pre_vat_amount *
                        parseFloat(
                          withholding_tax.type === "ยังไม่ระบุ" ||
                            withholding_tax.type === "ไม่มี" ||
                            withholding_tax.type === ""
                            ? 0
                            : withholding_tax.type
                        )) /
                      100;
                    setValue(
                      `item_list[${params.node.rowIndex}].withholding_tax`,
                      {
                        type: withholding_tax.type,
                        amount: withholding_tax_amount,
                      }
                    );
                    rowNode.setDataValue("withholding_tax", {
                      type: withholding_tax.type,
                      amount: withholding_tax_amount,
                    });
                  }
                }}
              />
            )}
          </>
        );
      },
      cellStyle: customCellStyle,
      width: 125,
    },

    {
      field: "discount_amount",
      headerComponentParams: {
        template: customHeaderColRender("Discount", t("sales.discount")),
      },
      cellRenderer: (params) => {
        return (
          <>
            {viewOnly ? (
              <Typography variant="body2" align="center">
                {formatNumber(params.data.discount_amount)}
              </Typography>
            ) : (
              <CustomizedNumberTextField
                control={control}
                name={`item_list[${params.node.rowIndex}].discount_amount`}
                onChange={(value) => {
                  const newPreVatAmount =
                    getValues(`item_list[${params.node.rowIndex}].qty`) *
                      getValues(
                        `item_list[${params.node.rowIndex}].price_per_unit`
                      ) -
                    value;

                  setValue(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`,
                    newPreVatAmount
                  );
                  //update data in pre_vat_amount cell
                  const rowNode = params.api.getRowNode(
                    params.data.uid + (params.data.ref_document_id ?? "")
                  );
                  rowNode.setDataValue("pre_vat_amount", newPreVatAmount);
                  rowNode.setDataValue("discount_amount", value);
                  const withholding_tax = getValues(
                    `item_list[${params.node.rowIndex}].withholding_tax`
                  );
                  const pre_vat_amount = getValues(
                    `item_list[${params.node.rowIndex}].pre_vat_amount`
                  );
                  if (withholding_tax) {
                    const withholding_tax_amount =
                      (pre_vat_amount *
                        parseFloat(
                          withholding_tax.type === "ยังไม่ระบุ" ||
                            withholding_tax.type === "ไม่มี" ||
                            withholding_tax.type === ""
                            ? 0
                            : withholding_tax.type
                        )) /
                      100;
                    setValue(
                      `item_list[${params.node.rowIndex}].withholding_tax`,
                      {
                        type: withholding_tax.type,
                        amount: withholding_tax_amount,
                      }
                    );
                    rowNode.setDataValue("withholding_tax", {
                      type: withholding_tax.type,
                      amount: withholding_tax_amount,
                    });
                  }
                }}
              />
            )}
          </>
        );
      },

      cellStyle: customCellStyle,
      width: 125,
    },
    {
      field: "vat_type",
      headerComponentParams: {
        template: customHeaderColRender("Vat (%)", `${t("sales.vat")} (%)`),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.vat_type}{" "}
              {params.data.vat_type === "ไม่มี" ? "" : "%"}
            </Typography>
          ) : (
            <RenderCustomizedSelect
              control={control}
              name={`item_list[${params.node.rowIndex}].vat_type`}
              options={vatOption}
            />
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 120,
    },
    {
      field: "pre_vat_amount",
      headerComponentParams: {
        template: customHeaderColRender("Pre-vat Amount", t("sales.amount")),
      },
      valueFormatter: (params) => formatNumber(params.data.pre_vat_amount),
      cellStyle: customCellStyle,
      width: 155,
    },
    {
      field: "qty_uom",
      headerComponentParams: {
        template: customHeaderColRender("Stock Qty", "จำนวนคลัง"),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Box>
              <Typography variant="body2" align="center">
                {params.data.qty_uom.toLocaleString()}
              </Typography>
              <Typography
                align="center"
                sx={{ fontSize: "10px", whiteSpace: "normal" }}
              >
                {params.data.uom_group?.base_uom?.name}
              </Typography>
            </Box>
          ) : (
            <Box mt={"22px"}>
              <RenderCustomizedTextField
                control={control}
                name={`item_list[${params.node.rowIndex}].qty_uom`}
                disabled={true}
                helperText={params.data.uom_group?.base_uom?.name}
              />
            </Box>
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 120,
    },
    {
      field: "ref_document_id",
      headerComponentParams: {
        template: customHeaderColRender("PR Qty", "ใบขอซื้อ"),
      },
      cellRenderer: (params) => {
        return (
          <Typography variant="body2" align="center">
            {params.value || "-"}
          </Typography>
        );
      },
      cellStyle: customCellStyle,
    },
    {
      field: "withholding_tax",
      headerComponentParams: {
        template: customHeaderColRender(
          "Witholding Tax (%)",
          `${t("sales.withholdingTax")} (%)`
        ),
      },
      cellRenderer: (params) => {
        return (
          <>
            {viewOnly ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography variant="body2" align="center">
                  {params.data.withholding_tax.type}{" "}
                  {params.data.withholding_tax.type === "ยังไม่ระบุ" ||
                  params.data.withholding_tax.type === "ไม่มี"
                    ? ""
                    : "%"}
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    mt: "24px",
                    minWidth: 150,
                    width: "100%",
                  }}
                >
                  <WithholdingTax
                    control={control}
                    getValues={getValues}
                    params={params}
                    setValue={setValue}
                  />
                </Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                    height: "fit-content",
                    alignSelf: "center",
                    ml: 0.5,
                  }}
                  onClick={() =>
                    openDeleteItemConfirmationHandler(params.node.rowIndex)
                  }
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </>
        );
      },
      cellStyle: customCellStyle,
      width: 220,
    },
  ];

  if (status === "partiallyImported" || status === "fullyImported") {
    columnsDef.push({
      field: "received_qty",
      headerComponentParams: {
        template: customHeaderColRender("Received Qty", `จำนวนนำเข้า`),
      },
      valueFormatter: (params) => params.value ?? 0,
      cellStyle: customCellStyle,
    });
  }
  return columnsDef;
};
