import { gql } from "graphql-request";

export const GET_ALL_USERS = gql`
  query Users($aggridInput: AggridEmployeeInput!) {
    employeesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        first_name
        last_name
        email
        phone
        img_url
        department
        position
        status
        created_date
        last_login_date
        role_list {
          document_id
          name
          description
          permission_list
        }
      }
      count
    }
  }
`;

export const GET_ALL_USERS_DOCUMENT_ID_NAME = gql`
  query Users($aggridInput: AggridEmployeeInput!) {
    employeesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        first_name
        last_name
      }
      count
    }
  }
`;

export const GET_ALL_USERS_NO_AGGRID = gql`
  query Employees {
    employees {
      document_id
      first_name
      last_name
      email
      phone
      img_url
      department
      position
      status
      created_date
      last_login_date
      role_list {
        document_id
        name
        description
        permission_list
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query RolesAggrid($aggridInput: RolesAggridInput) {
    rolesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        name
        description
        permission_list
        employee_list {
          document_id
        }
      }
      count
    }
  }
`;

export const GET_USER = gql`
  query Employee($uniqueInput: EmployeeWhereUniqueInput!) {
    employee(uniqueInput: $uniqueInput) {
      document_id
      first_name
      last_name
      email
      phone
      img_url
      department
      position
      status
      created_date
      last_login_date
      role_list {
        document_id
        name
        description
        permission_list
      }
    }
  }
`;

export const GET_ROLE = gql`
  query Role($uniqueInput: RoleWhereUniqueInput) {
    role(uniqueInput: $uniqueInput) {
      document_id
      name
      description
      permission_list
      employee_list {
        document_id
        first_name
        last_name
        email
        phone
        img_url
        department
        position
        status
        created_date
        last_login_date
      }
    }
  }
`;

export const GET_ALL_ROLES_NO_AGGRID = gql`
  query Roles($findManyInput: RoleFindManyInput) {
    roles(findManyInput: $findManyInput) {
      id
      document_id
      name
      description
      permission_list
      employee_list {
        document_id
      }
    }
  }
`;

export const GET_APPROVAL_PROGRESSES_SELF = gql`
  query ApprovalProgressesSelf {
    approvalProgressesSelf {
      id
      created_date
      created_by_document_id
      approved_by_document_id
      created_by_document_id
      approval_status
      workflow_progress {
        reference_document_id
        reference_document_type
      }
    }
  }
`;

export const GET_APPROVAL_PROGRESS_COUNT_SELF = gql`
  query ApprovalProgressCountSelf($requestStatus: String) {
    approvalProgressesCountSelf(requestStatus: $requestStatus)
  }
`;
