import * as Yup from "yup";

const schema = {
  item_name: Yup.string().required("กรุณากรอกชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .required("กรุณาระบุจำนวนสั่งซื้อ")
    .min(1, "กรุณาระบุจำนวนสั่งซื้อ"),
  uom_id: Yup.string().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .positive("กรุณากรอกจำนวนเงินที่มากกว่า 0")
    .required("กรุณากรอกราคาต่อหน่วย"),
};

const schemaPR = {
  item_name: Yup.string().required("กรุณากรอกชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .required("กรุณาระบุจำนวนส่งคืน")
    .min(1, "กรุณาระบุจำนวนส่งคืน"),
  uom_id: Yup.string().required("กรุณาเลือกหน่วย"),
};

export const validationItemList = Yup.object().shape({
  item_list: Yup.array()
    .of(Yup.object().shape(schema))
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
});

export const validationItemListPR = Yup.object().shape({
  item_list: Yup.array()
    .of(Yup.object().shape(schemaPR))
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
});
