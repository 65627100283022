import { graphQLClientWithHeader } from "../graphQL";
import { CREATE_ESTIMATE, UPDATE_ESTIMATE } from "./mutations";
import { GET_ALL_ESTIMATES, GET_ESTIMATE } from "./queries";

class EngineerService {
  async getAllEstimates(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { estimationAggrid } = await graphQLClient.request(
      GET_ALL_ESTIMATES,
      {
        aggridInput,
      }
    );
    return estimationAggrid;
  }
  async getEstimateById(document_id) {
    const graphQLClient = graphQLClientWithHeader();
    const { estimation } = await graphQLClient.request(GET_ESTIMATE, {
      uniqueInput: { document_id },
    });
    return estimation;
  }
  async createEstimate(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { estimationCreate } = await graphQLClient.request(CREATE_ESTIMATE, {
      createInput,
    });
    return estimationCreate;
  }
  async updateEstimate(document_id, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { estimationUpdate } = await graphQLClient.request(UPDATE_ESTIMATE, {
      uniqueInput: { document_id },
      updateInput,
    });
    return estimationUpdate;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EngineerService();
