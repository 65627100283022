import InventoryService from "../../../services/Inventory";
import { inventoryReportActions } from "./report-slice";

export const getAllStockEntries = (input, params) => async (dispatch) => {
  dispatch(inventoryReportActions.onLoading("stockEntries"));
  try {
    const stockEntries = await InventoryService.getAllSEAggrid(input);
    dispatch(inventoryReportActions.loadedStockEntries(stockEntries.results));
    if (params)
      params.successCallback(stockEntries.results, stockEntries.count);
  } catch (err) {
    dispatch(
      inventoryReportActions.rejectedActions({ ...err, name: "stockEntries" })
    );
  }
};

export const getAllTraceEntry = (input, params) => async (dispatch) => {
  dispatch(inventoryReportActions.onLoading("traceEntry"));
  try {
    const traceEntry = await InventoryService.getAllTEAggrid(input);
    dispatch(inventoryReportActions.loadedTraceEntry(traceEntry.results));
    if (params) params.successCallback(traceEntry.results, traceEntry.count);
  } catch (err) {
    dispatch(
      inventoryReportActions.rejectedActions({ ...err, name: "traceEntry" })
    );
  }
};

export const getItemsCurrentStock =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStock"));
    try {
      const itemCurrentStock = await InventoryService.getAllICS(input);
      params.successCallback(itemCurrentStock.results, itemCurrentStock.count);
      dispatch(
        inventoryReportActions.loadedItemCurrentStock(itemCurrentStock.results)
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStock",
        })
      );
      params.failCallback();
    }
  };

export const getItemsCurrentStockLocation =
  (input, params, enqueueSnackbar, setSum) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockLocation"));
    try {
      const itemCurrentStockLocation = await InventoryService.getAllICSL(input);
      params.successCallback(
        itemCurrentStockLocation.results,
        itemCurrentStockLocation.count
      );
      dispatch(
        inventoryReportActions.loadedItemCurrentStockLocation(
          itemCurrentStockLocation.results
        )
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockLocation",
        })
      );
      params.failCallback();
    }
  };

export const getItemsCurrentStockSerialNumber =
  (input, params, notReport) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockSerialNumber"));
    try {
      const itemCurrentStockSerial =
        await InventoryService.getItemCurrentStockSerialNumber(input);
      // const newItem = itemCurrentStockSerial.results.filter(
      //   (result) => result.current_quantity !== 0
      // );
      // if (notReport) params.successCallback(newItem, newItem.length);
      // else
      params.successCallback(
        itemCurrentStockSerial.results,
        itemCurrentStockSerial.count
      );
      dispatch(
        inventoryReportActions.loadedItemCurrentStockSerialNumber(
          itemCurrentStockSerial.results
        )
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockSerialNumber",
        })
      );
      params.failCallback();
    }
  };
