import { Controller } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import CustomizedTextField from "../Custom/CustomizedTextField";

const ControlledDateTimePicker = ({
  name,
  control,
  error,
  open,
  onClose,
  onOpen,
  label,
  required,
  disabled,
  disablePast,
  sx,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            inputFormat="dd/MM/yyyy HH:mm"
            label={label}
            onChange={onChange}
            value={value}
            ref={ref}
            renderInput={(params) => (
              <CustomizedTextField
                {...params}
                ref={params.inputRef}
                error={Boolean(error)}
                helperText={error?.message}
                required={required}
                sx={sx}
              />
            )}
            disablePast={disablePast}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDateTimePicker;
