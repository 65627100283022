import { useSnackbar } from "notistack";
import React, { useCallback, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAllPurchaseRequest } from "../../features/Purchase/PurchaseRequest/purchase-request-actions";
import CheckboxModalTable from "./CheckboxModalTable";
import { purchaseRequestColumnDefs } from "./ColumnDefs/purchaseRequestColumnDef";
import InventoryService from "../../services/Inventory";

const CheckedPurchaseRequestTable = ({
  control,
  setValue,
  setHeaderValue,
  openPurchaseRequestModal,
  setOpenPurchaseRequestModal,
  purchaseRequestIds,
  setPurchaseRequestIds,
  purchaseRequestIdsSnapshot,
  setPurchaseRequestIdsSnapshot,
  getValues,
}) => {
  const watchItemList = useWatch({
    control,
    name: "item_list",
  });
  const selectPurchaseRequestGridRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const dispatch = useDispatch();

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { render_status } = filterModel;

      const formatFilterModel = {
        render_status: render_status,
      };

      dispatch(
        getAllPurchaseRequest(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const instance = params.api.getFilterInstance("render_status");
    instance.setModel({
      filterType: "set",
      values: ["approved", "partiallyOrdered", "fullyOrdered"],
    });
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  const finishPurchaseRequestsSelect = async (data) => {
    // filter out employees not selected in current modal session
    let filteredPurchaseRequest = watchItemList.filter((purchaseRequest) =>
      purchaseRequestIds.includes(purchaseRequest.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredPurchaseRequestId = filteredPurchaseRequest.map(
      (purchaseRequest) => purchaseRequest.document_id
    );

    data.forEach((purchaseRequest) => {
      if (!filteredPurchaseRequestId.includes(purchaseRequest.document_id)) {
        filteredPurchaseRequest.push(purchaseRequest);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredPurchaseRequest = filteredPurchaseRequest.sort((a, b) => {
      return (
        purchaseRequestIds.indexOf(a.document_id) -
        purchaseRequestIds.indexOf(b.document_id)
      );
    });

    const newItemList = [];

    const allItemsDocumentId = [];
    filteredPurchaseRequest.forEach((refDoc) =>
      refDoc.item_list.map((item) =>
        allItemsDocumentId.push(item.item_document_id)
      )
    );

    const items = await InventoryService.getAllItems({
      findManyInput: {
        where: {
          document_id: {
            in: allItemsDocumentId,
          },
        },
      },
    });

    // const oldItemList = getValues("item_list");

    filteredPurchaseRequest.forEach((order) => {
      order.item_list.forEach((list) => {
        const findItem = items.find(
          (item) => list.item_document_id === item.document_id
        );
        // if (!oldItemList.some((oldItem) => oldItem.uid === list.uid))
        newItemList.push({
          ...list,
          qty: list.qty - list.ordered_qty,
          uom_id: list.uom.id,
          ref_document_id: order.document_id,
          ref_document_type: "purchase_request",
          uom_group: findItem?.uom_group || [],
          price_per_unit: findItem?.purchase_standard_price,
          discount_amount: 0,
          qty_uom: list.qty - list.ordered_qty,
          vat_type: "ไม่มี",
          pre_vat_amount: findItem?.purchase_standard_price ?? 0,
          withholding_tax: {
            type: "ยังไม่ระบุ",
            amount: 0,
          },
          current_ordered_purchase_qty:
            findItem?.current_ordered_purchase_qty ?? 0,
          current_ordered_manufacture_qty:
            findItem?.current_ordered_manufacture_qty ?? 0,
          current_committed_sales_qty:
            findItem?.current_committed_sales_qty ?? 0,
          current_stock_qty: findItem?.current_stock_qty ?? 0,
          current_available_qty: findItem?.current_available_qty ?? 0,
        });
      });
    });

    // console.log("oldItemList", oldItemList);

    const refList = [];

    newItemList.forEach((list) => {
      if (
        refList.some(
          (value) => value.reference_document_id === list.ref_document_id
        )
      ) {
        return null;
      } else
        refList.push({
          reference_document_id: list.ref_document_id,
          reference_document_type: list.ref_document_type,
        });
    });

    // setValue("item_list", [...oldItemList, ...newItemList]);
    setValue("item_list", newItemList);
    setHeaderValue("reference_document_list", refList);
    setOpenPurchaseRequestModal(false);
  };

  return (
    <CheckboxModalTable
      modalTitle="นำเข้าใบขอซื้อ"
      btnTitle={t("button.add")}
      gridRef={selectPurchaseRequestGridRef}
      height={450}
      columnDefs={purchaseRequestColumnDefs(t)}
      rowSelection="multiple"
      onFinishEditing={finishPurchaseRequestsSelect}
      modalIsOpen={openPurchaseRequestModal}
      closeModal={() => setOpenPurchaseRequestModal(false)}
      onGridReady={onGridReady}
      selectedIds={purchaseRequestIds}
      setSelectedIds={setPurchaseRequestIds}
      idsSnapshot={purchaseRequestIdsSnapshot}
      setIdsSnapshot={setPurchaseRequestIdsSnapshot}
      getRowId={getRowId}
      disabledSidebar
    />
  );
};

export default CheckedPurchaseRequestTable;
