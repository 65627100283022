export const postNotify = async (message, token) => {
  const xMineApiKey = process.env.REACT_APP_XMine_API_Key;
  try {
    const response = await fetch(
      "https://asia-east2-minenoti-app.cloudfunctions.net/service/line-noti",
      {
        method: "POST",
        headers: {
          "x-mineapi-key": xMineApiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service: "LINE-NOTI",
          payload: [
            {
              to: token,
              message,
            },
          ],
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};
