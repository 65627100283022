import { dateToUnix } from "./date-converter";
import differenceBy from "lodash/differenceBy";

export const createActivityLogPayload = (payload, document_type, message) => {
  return {
    document_id: payload.document_id,
    activity_details: { message },
    activity_type: "create",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())),
    document_type,
  };
};

export const createActivityLogStatusPayload = (
  payload,
  document_type,
  prev_status,
  curr_status
) => {
  return {
    document_id: payload.document_id,
    activity_details: { message: "เปลี่ยนสถานะ", prev_status, curr_status },
    activity_type: "status_change",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())) + 1,
    document_type,
  };
};

export const createActivityLogCopiedPayload = (payload, document_type) => {
  return {
    document_id: payload.document_id,
    activity_details: {
      message: "คัดลอก",
    },
    activity_type: "copy",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())),
    document_type,
  };
};

export const createActivityLogEditPayload = (payload, document_type) => {
  return {
    document_id: payload.document_id,
    activity_details: {
      message: "แก้ไข",
    },
    activity_type: "edit",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())),
    document_type,
  };
};

export const createActivityLogDeletePayload = (payload, document_type) => {
  return {
    document_id: payload.document_id,
    activity_details: {
      message: "ลบ",
    },
    activity_type: "delete",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())),
    document_type,
  };
};

export const findDifferenceEmployee = (payload, existing) => {
  const { employee_list: existingEmployeeList } = existing;
  const { employee_list: currentEmployeeList } = payload;

  console.log("existingEmployeeList", existingEmployeeList);
  console.log("currentEmployeeList", currentEmployeeList);
  if (existingEmployeeList) {
    const existingDocumentId = existingEmployeeList.map((exist) => ({
      document_id: exist.document_id,
      first_name: exist.first_name,
      last_name: exist.last_name,
    }));

    const currentDocumentId = currentEmployeeList.map((curr) => ({
      document_id: curr.document_id,
      first_name: curr.first_name,
      last_name: curr.last_name,
    }));

    const addedEmployeeList = differenceBy(
      currentDocumentId,
      existingDocumentId,
      "document_id"
    );

    console.log("added", addedEmployeeList);
    const deletedEmployeeList = differenceBy(
      existingDocumentId,
      currentDocumentId,
      "document_id"
    );
    console.log("deleted", addedEmployeeList);

    return {
      addedEmployeeList,
      deletedEmployeeList,
    };
  }
};

export const createActivityLogEmployeePayload = (
  payload,
  activity_type,
  employee_list,
  document_type
) => {
  if (employee_list) {
    return {
      document_id: payload.document_id,
      activity_details: activity_type.startsWith("add")
        ? {
            message: "เพิ่มผู้เกี่ยวข้อง",
            added_related_employee: employee_list,
          }
        : {
            message: "ลบผู้เกี่ยวข้อง",
            deleted_related_employee: employee_list,
          },
      activity_type,
      creator_document_id: payload.creator_document_id,
      created_date: parseInt(dateToUnix(new Date())) + 1,
      document_type,
    };
  }
};

export const createActivityLogApprovePayload = (
  payload,
  message,
  document_type
) => {
  return {
    document_id: payload.document_id,
    activity_details: {
      message,
    },
    activity_type: "approve",
    creator_document_id: payload.creator_document_id,
    created_date: parseInt(dateToUnix(new Date())),
    document_type,
  };
};
