import { Box, DialogContentText } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as vendorDescSchema,
  validation as vendorDescValidation,
} from "../../../components/Form/Purchase/VendorDescription/schema";
import PurchaseHeaderForm from "../../../components/Form/Purchase/Header/index";
import {
  purchaseReturnSchema,
  purchaseReturnValidation,
} from "../../../components/Form/Purchase/Header/schema";
import GoodIssueTab from "./GoodIssueTab";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { purchaseReturnActions } from "../../../features/Purchase/PurchaseReturn/purchase-return-slice";
import {
  approvePurchaseReturn,
  createNewPurchaseReturn,
  declinePurchaseReturn,
  getPurchaseReturnById,
  updatePurchaseReturn,
} from "../../../features/Purchase/PurchaseReturn/purchase-return-actions";
import {
  salesPrimaryButtonHandler,
  salesSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import { validationReturnItemList } from "../../../components/Form/Purchase/AddItemListForm/returnSchema";
import {
  purchasePayload,
  setPurchaseValueFormatter,
} from "../../../utils/purchasePayloadFormatter";
import { useApproval } from "../../../hooks/use-approval";
import { confirmationValidation } from "../../../components/Form/Sales/Header/schema";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import ModalUI from "../../../components/UI/ModalUI";

const defaultValues = {
  render_status: null,
  price_vat_type: "excluded_vat",
  contact_document_id: "",
  creator_document_id: "",
  created_by: {},
  item_list: [],
  reason_to_return: "",
  additional_discount: 0,
  summary: {},
  template_remark_id: "",
  gr_document_id_list: [],
  remark: "",
};

const PurchaseReturnContainer = () => {
  const { refreshApprovals } = useApproval();
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { createPermission, editPermission } = usePermission();
  const {
    isLoading,
    purchaseReturn,
    approvalList,
    isHaveApproval,
    isHaveApprovalPermission,
  } = useSelector((state) => state.purchaseReturn);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const vendorDescRef = useRef();
  const itemListRef = useRef();
  const reasonRef = useRef();
  const [openDebitNoteConfirmation, setOpenDebitNoteConfirmation] =
    useState(false);

  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationReturnItemList),
  });

  const {
    control: confirmationControl,
    getValues: getConfirmationValues,
    trigger: validateConfirmation,
    formState: { errors: confirmationErrors },
  } = useForm({
    defaultValues: {
      external_ref_id_confirmation: "",
    },
    resolver: yupResolver(confirmationValidation),
  });

  const [currentTab, setCurrentTab] = useState(pathname);

  const watchStatus = useWatch({ control, name: "render_status" });

  const vendorDescUseForm = useForm({
    defaultValues: { ...vendorDescSchema },
    resolver: yupResolver(vendorDescValidation),
  });

  const {
    trigger: validateVendorDescription,
    setValue: setVendorDescValue,
    getValues: getVendorDescValues,
    getFieldState: getVendorDescFieldState,
  } = vendorDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...purchaseReturnSchema },
    resolver: yupResolver(purchaseReturnValidation),
  });

  useEffect(() => {
    switch (tab) {
      case "good-issue":
        setCurrentTab(pathname + "?tab=good-issue");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.return.index"),
      to: "/purchase/return",
    },
    {
      name: id ?? `สร้าง${t("purchase.return.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("purchase.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.issue.index"),
      path: `${pathname}?tab=good-issue`,
    },
  ];

  useEffect(() => {
    switch (watchStatus) {
      case "waitApprove":
      case "approved":
      case "notApproved":
      case "finished":
      case "cancelled":
      case "expired":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    if (state) {
      const formatItemList = state["item_list"].map((item) => ({
        ...item,
        qty_return: 1,
        pre_vat_amount: 1 * item.price_per_unit - item.discount_amount,
      }));
      const formatPurchaseReturn = {
        ...state,
        item_list: formatItemList,
        additional_discount: 0,
      };
      dispatch(
        purchaseReturnActions.loadedPurchaseReturn(formatPurchaseReturn)
      );
      dispatch(purchaseReturnActions.loadedPurchaseOrderItem(state.item_list));
    } else if (id) {
      dispatch(
        getPurchaseReturnById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(purchaseReturnActions.resetPurchaseReturn());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  useEffect(() => {
    if (state) {
      const formatItemList = state["item_list"].map((item) => ({
        ...item,
        qty_return: 1,
        pre_vat_amount: 1 * item.price_per_unit - item.discount_amount,
      }));
      const formatPurchaseReturn = {
        ...state,
        item_list: formatItemList,
        additional_discount: 0,
      };
      setPurchaseValueFormatter(
        purchaseReturnSchema,
        vendorDescSchema,
        defaultValues,
        setHeaderValue,
        setVendorDescValue,
        setValue,
        formatPurchaseReturn,
        true,
        "purchase_return"
      );
    } else if (id) {
      setPurchaseValueFormatter(
        purchaseReturnSchema,
        vendorDescSchema,
        defaultValues,
        setHeaderValue,
        setVendorDescValue,
        setValue,
        purchaseReturn,
        false,
        "purchase_return"
      );
    }
  }, [purchaseReturn, setValue, setHeaderValue, id, state, setVendorDescValue]);

  const submitContactForm = (data) => {
    setVendorDescValue("contact", data);
  };

  const submitForm = async () => {
    const vendorDescIsValid = await validateVendorDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const deliveryDateError = getHeaderFieldState("delivery_date").error;
    const vendorError = getVendorDescFieldState("vendor").error;
    const itemListError = getFieldState("item_list").error;
    const reasonError = getFieldState("reason_to_return").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (vendorError ? 1 : 0) +
      (deliveryDateError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1 || itemListError?.length > 0) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(deliveryDateError)) {
      enqueueSnackbar(deliveryDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(vendorError)) {
      enqueueSnackbar("กรุณาเลือกผู้ขาย", {
        variant: "error",
      });
    }

    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!vendorDescIsValid) {
      vendorDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }

    if (!itemListIsValid && reasonError) {
      reasonRef.current.scrollIntoView();
      return;
    }

    if (vendorDescIsValid && headerIsValid && itemListIsValid) {
      const { render_status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const { vendor, identity_no, ...vendorDescValues } =
        getVendorDescValues();
      const transformedEmployeeList = headerValues.employee_list.map(
        ({
          id,
          is_active,
          jwt_modifier,
          exp,
          iat,
          created_date,
          last_login_date,
          role_list,
          status,
          ...employee
        }) => employee
      );

      const { external_ref_id_confirmation } = getConfirmationValues();

      if (render_status === "approved" && !isEdit) {
        const confirmationIsValid = await validateConfirmation();
        if (!confirmationIsValid) {
          return;
        }
        if (external_ref_id_confirmation.trim().length > 0) {
          if (headerValues.external_ref_id.trim().length > 0) {
            const externalToArray = headerValues.external_ref_id
              .trim()
              .split(",");
            const newExternalConfirmation = [
              ...externalToArray,
              external_ref_id_confirmation,
            ];
            const formatNewExternal = newExternalConfirmation.join(", ");
            headerValues.external_ref_id = formatNewExternal;
          } else {
            headerValues.external_ref_id = external_ref_id_confirmation;
          }
        }
      }

      const payload = purchasePayload(
        headerValues,
        mainValues,
        transformedEmployeeList,
        contactSnapshot,
        summary,
        vendorDescValues,
        "rs"
      );
      return payload;
    }
  };

  const saveDraftHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewPurchaseReturn(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updatePurchaseReturn(
            payload,
            watchStatus,
            false,
            purchaseReturn,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewPurchaseReturn(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updatePurchaseReturn(
            payload,
            watchStatus,
            true,
            purchaseReturn,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declinePurchaseReturn(
          {
            document_id,
            document_type: "purchase_return",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approvePurchaseReturn(
          {
            document_id,
            document_type: "purchase_return",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const createDebitNoteHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      delete payload.item_list;
      dispatch(
        updatePurchaseReturn(
          { ...payload, is_finished: true },
          watchStatus,
          false,
          purchaseReturn,
          user,
          enqueueSnackbar
        )
      );
      setOpenDebitNoteConfirmation(false);
    }
  };

  const editSubmitHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updatePurchaseReturn(
          payload,
          watchStatus,
          false,
          purchaseReturn,
          user,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/purchase/return");
  };

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.purchaseReturn) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (watchStatus) {
        switch (watchStatus) {
          case "draft":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (watchStatus) {
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, watchStatus]);

  const primarySubmitHandler = () => {
    salesPrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    salesSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "good-issue":
        return <GoodIssueTab />;
      default:
        return (
          <>
            <PurchaseHeaderForm
              ref={headerRef}
              control={headerControl}
              errors={headerErrors}
              setValue={setHeaderValue}
              setMainValue={setValue}
              getValues={getHeaderValues}
              mainFormControl={control}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              documentType="purchase_return"
              editClickHandler={editClickHandler}
              isEdit={isEdit}
              currentState={state?.id ?? purchaseReturn}
              approvalList={approvalList}
              isReturn
              setOpenDebitNoteConfirmation={setOpenDebitNoteConfirmation}
            />
            <DocumentInfoTab
              itemListRef={itemListRef}
              reasonRef={reasonRef}
              ref={vendorDescRef}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              setHeaderValue={setHeaderValue}
              getHeaderValues={getHeaderValues}
              submitVendorDesc={submitContactForm}
              vendorDescUseForm={vendorDescUseForm}
              contactUseForm={contactUseForm}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              setIsEdit={setIsEdit}
            />
            <ModalUI
              title="บันทึกใบเพิ่มหนี้"
              open={openDebitNoteConfirmation}
              handleClose={() => setOpenDebitNoteConfirmation(false)}
              maxWidth="sm"
            >
              <DialogContentText sx={{ mb: 2 }}>
                หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
              </DialogContentText>
              <Controller
                name="external_ref_id_confirmation"
                control={confirmationControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    label={t("sales.externalRefDocumentId")}
                    error={Boolean(
                      confirmationErrors.external_ref_id_confirmation
                    )}
                    helperText={
                      confirmationErrors.external_ref_id_confirmation?.message
                    }
                    required
                    {...field}
                  />
                )}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: ".5rem",
                }}
              >
                <CustomizedButton
                  title="ยกเลิก"
                  variant="outlined"
                  onClick={() => setOpenDebitNoteConfirmation(false)}
                />
                <CustomizedButton
                  title="ยืนยัน"
                  variant="contained"
                  onClick={createDebitNoteHandler}
                />
              </Box>
            </ModalUI>
            {shouldRenderButtons() &&
              ((createPermission && !id) || (editPermission && id)) && (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <CustomizedButton
                      title={secondaryButtonTitle}
                      variant="outlined"
                      type="reset"
                      onClick={secondarySubmitHandler}
                    />
                  </Box>
                  <CustomizedButton
                    title={primaryButtonTitle}
                    variant="contained"
                    onClick={primarySubmitHandler}
                  />
                </Box>
              )}
          </>
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default PurchaseReturnContainer;
