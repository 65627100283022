import { useRef, useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CompanyInfoSettingForm from "../../../components/Form/Setting/CompanyInfo";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema,
  validation,
} from "../../../components/Form/Setting/CompanyInfo/schema";
import SettingService from "../../../services/Setting";
import GlobalService from "../../../services/Global";
import { uploadFileToS3 } from "../../../utils/s3";
import { useAuth, usePermission } from "../../../hooks/use-auth";

const CompanyInfoSetting = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const user = useAuth();
  const generalFormRef = useRef();
  const {
    control,
    setValue,
    getValues,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...schema },
    resolver: yupResolver(validation),
  });
  const [isInit, setIsInit] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const { editPermission } = usePermission();

  const formatLoadedData = useCallback((data) => {
    return {
      ...data,
      company_document_id: data.company_document_id || "",
      img_url: data.img_url || "",
      last_updator_document_id: "",
      registered_capital: data.registered_capital || "",
      contact_type_1: data.contact_type_1 || "",
      contact_type_2: data.contact_type_2 || "",
      contact_name_1: data.company_name,
      contact_name_2: data.contact_name_2 || "",
      register_date: moment.unix(data.register_date).toDate(),
      vat_registration_date: data.vat_registration_date
        ? moment.unix(data.vat_registration_date).toDate()
        : "",
      contact_person_list: [],
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      const getCompanySetting = async () => {
        const data = await SettingService.getCompanySettings();
        if (data.length > 0) {
          setCompanyId(data[0].id);
          reset(formatLoadedData(data[0]));
        }
      };
      getCompanySetting();
      setIsInit(true);
    }
  }, [isInit, formatLoadedData, reset]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.companyInfo") },
  ];

  const submitCompanyInfo = async (data) => {
    let updatedImageUrl = data.img_url;
    if (typeof data.img_url[0] === "object") {
      try {
        const { Location } = await uploadFileToS3(
          data.img_url[0],
          "contact",
          user.document_id
        );
        updatedImageUrl = Location;
      } catch (err) {
        console.log("Could not upload image");
        updatedImageUrl = "";
      }
    } else if (typeof data.img_url[0] === "string") {
      updatedImageUrl = data.img_url[0];
    } else {
      updatedImageUrl = "";
    }

    const formattedData = {
      ...data,
      company_document_id: data.company_document_id || undefined,
      img_url: updatedImageUrl,
      last_updator_document_id: undefined,
      registered_capital: data.registered_capital || undefined,
      contact_type_1: data.contact_type_1 || undefined,
      contact_type_2: data.contact_type_2 || undefined,
      contact_name_1: data.company_name,
      contact_name_2: data.contact_name_2 || undefined,
      register_date: moment(data.register_date).unix(),
      vat_registration_date: data.is_registered_vat
        ? moment(data.vat_registration_date).unix()
        : undefined,
      contact_person_list: [],
    };
    if (!formattedData.company_document_id) {
      try {
        const companyDocId = await GlobalService.getDocumentId("COM");
        const unixCreatedDate = moment().unix();
        try {
          await SettingService.createCompanySetting({
            ...formattedData,
            company_document_id: companyDocId,
            created_date: unixCreatedDate,
          });
          enqueueSnackbar("สร้างข้อมูลกิจการสำเร็จ", {
            variant: "success",
          });
          navigate("/setting", { replace: true });
        } catch (err) {
          console.log(err);
          enqueueSnackbar("ไม่สามารถสร้างข้อมูลกิจการ", {
            variant: "error",
          });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar("ไม่สามารถสร้างรหัสกิจการ", {
          variant: "error",
        });
      }
    } else {
      try {
        await SettingService.updateCompanySetting({
          ...formattedData,
          id: companyId,
          last_updator_document_id: user.document_id,
        });
        enqueueSnackbar("อัพเดทข้อมูลกิจการสำเร็จ", {
          variant: "success",
        });
        navigate("/setting", { replace: true });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("ไม่สามารถอัพเดทข้อมูลกิจการ", {
          variant: "error",
        });
      }
    }
  };

  const onError = (error) => {
    console.log(error);
    generalFormRef.current.scrollIntoView();
  };

  const resetFormHandler = () => {
    reset({ ...schema });
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography sx={{ my: 4 }} variant="h5">
        {t("setting.companyInfo")}
      </Typography>
      <CompanyInfoSettingForm
        ref={generalFormRef}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        onReset={resetFormHandler}
        onSubmit={handleSubmit(submitCompanyInfo, onError)}
        editPermission={editPermission}
      />
    </>
  );
};

export default CompanyInfoSetting;
