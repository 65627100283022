import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { LeftNavbarSection } from "./LeftNavbarSection";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
// import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import CabinOutlinedIcon from "@mui/icons-material/CabinOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import { useNavbar } from "../../hooks/use-navbar";
import { useAuth, usePermission } from "../../hooks/use-auth";
import { COMPANY_NAME, CURRENT_VERSION } from "../../config/variables";
import { EngineeringOutlined } from "@mui/icons-material";

const LeftNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { sidebar } = useNavbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [searchParamsList, setsearchParamsList] = useState([]);
  const { verify, user } = useAuth();
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    const allSearchParams = [];
    for (const entry of searchParams.entries()) {
      allSearchParams.push(entry);
    }
    setsearchParamsList(allSearchParams);
  }, [pathname, searchParams]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const {
    itemPermission,
    goodsReceivePermission,
    goodsReceiveReturnPermission,
    goodsIssuePermission,
    goodsAdjustmentPermission,
    goodsTransferPermission,
    inventoryReportPermission,
    contactPermission,
    contactReportPermission,
    accountPermission,
    rbacPermission,
    companyInfoPermission,
    approvalSettingPermission,
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    manufactureSettingPermission,
    logisticSettingPermission,
    purchaseRequestPermission,
    purchaseOrderPermission,
    purchaseReturnPermission,
    purchaseReportPermission,
  } = usePermission();

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const sections = useMemo(() => {
    return [
      allPermissionList.some((permission) =>
        [
          contactPermission.VIEW,
          contactReportPermission.VIEW,
          isAdmin,
        ].includes(permission)
      ) && {
        title: t("contact.index"),
        items: [
          {
            title: t("contact.index"),
            path: "/contact",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
            children: [
              {
                title: "ทั้งหมด",
                path: "/contact/contacts",
              },
              {
                title: t("contact.contact_type.customer"),
                path: "/contact/contacts?type=customer",
              },
              {
                title: t("contact.contact_type.vendor"),
                path: "/contact/contacts?type=vendor",
              },
              {
                title: t("report"),
                path: "/contact/report",
              },
            ],
          },
        ],
      },
      allPermissionList.some((permission) =>
        [
          itemPermission.VIEW,
          goodsReceivePermission.VIEW,
          goodsReceiveReturnPermission.VIEW,
          goodsIssuePermission.VIEW,
          goodsAdjustmentPermission.VIEW,
          goodsTransferPermission.VIEW,
          inventoryReportPermission.VIEW,
          isAdmin,
        ].includes(permission)
      ) && {
        title: t("inventory.index"),
        items: [
          {
            title: t("inventory.index"),
            path: "/inventory",
            icon: <Inventory2OutlinedIcon fontSize="small" />,
            children: [
              allPermissionList.some((permission) =>
                [itemPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("inventory.items.index"),
                path: "/inventory/items",
              },
              allPermissionList.some((permission) =>
                [goodsReceivePermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("inventory.receive.index"),
                path: "/inventory/receive",
              },
              // allPermissionList.some((permission) =>
              //   [goodsReceiveReturnPermission.VIEW, isAdmin].includes(
              //     permission
              //   )
              // ) && {
              //   title: t("inventory.return.index"),
              //   path: "/inventory/return",
              // },
              allPermissionList.some((permission) =>
                [goodsIssuePermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("inventory.issue.index"),
                path: "/inventory/issue",
              },
              // allPermissionList.some((permission) =>
              //   [goodsIssuePermission.VIEW, isAdmin].includes(permission)
              // ) &&
              {
                title: t("inventory.issue.deliveryTable"),
                path: "/inventory/delivery-table",
              },
              // allPermissionList.some((permission) =>
              //   [goodsTransferPermission.VIEW, isAdmin].includes(permission)
              // ) && {
              //   title: t("inventory.transfer.index"),
              //   path: "/inventory/transfer",
              // },
              // allPermissionList.some((permission) =>
              //   [goodsAdjustmentPermission.VIEW, isAdmin].includes(permission)
              // ) && {
              //   title: t("inventory.adjustment.index"),
              //   path: "/inventory/adjustment",
              //   disabledMobile: true,
              // },
              allPermissionList.some((permission) =>
                [inventoryReportPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("inventory.report"),
                path: "/inventory/report",
              },
            ],
          },
        ],
      },
      {
        title: t("engineer.index"),
        items: [
          {
            title: t("engineer.index"),
            path: "/engineer",
            icon: <EngineeringOutlined fontSize="smaill" />,
            children: [
              {
                title: t("engineer.estimate.index"),
                path: "/engineer/estimate",
              },
            ],
          },
        ],
      },
      // allPermissionList.some((permission) =>
      //   [
      //     quotationPermission.VIEW,
      //     salesOrderPermission.VIEW,
      //     salesReturnPermission.VIEW,
      //     salesReportPermission.VIEW,
      //     isAdmin,
      //   ].includes(permission)
      // ) && {
      //   title: t("sales.index"),
      //   items: [
      //     {
      //       title: t("sales.index"),
      //       path: "/sales",
      //       icon: <ControlPointOutlinedIcon fontSize="small" />,
      //       children: [
      //         allPermissionList.some((permission) =>
      //           [quotationPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("sales.quotation.index"),
      //           path: "/sales/quotation",
      //         },
      //         allPermissionList.some((permission) =>
      //           [salesOrderPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("sales.order.index"),
      //           path: "/sales/order",
      //         },
      //         allPermissionList.some((permission) =>
      //           [salesReturnPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("sales.return.index"),
      //           path: "/sales/return",
      //         },
      //         allPermissionList.some((permission) =>
      //           [salesReportPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("sales.report.index"),
      //           path: "/sales/report",
      //         },
      //       ],
      //     },
      //   ],
      // },
      // allPermissionList.some((permission) =>
      //   [
      //     deliveryOrderPermission.VIEW,
      //     deliveryTripPermission.VIEW,
      //     deliveryReportPermission.VIEW,
      //     isAdmin,
      //   ].includes(permission)
      // ) && {
      //   title: t("logistic.index"),
      //   items: [
      //     {
      //       title: t("logistic.index"),
      //       path: "/logistic",
      //       icon: <LocalShippingOutlinedIcon fontSize="small" />,
      //       children: [
      //         allPermissionList.some((permission) =>
      //           [deliveryOrderPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("logistic.deliveryOrder.index"),
      //           path: "/logistic/delivery-order",
      //         },
      //         allPermissionList.some((permission) =>
      //           [deliveryTripPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("logistic.deliveryTrip.index"),
      //           path: "/logistic/delivery-trip",
      //         },
      //         allPermissionList.some((permission) =>
      //           [deliveryReportPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("logistic.report.index"),
      //           path: "/logistic/report",
      //         },
      //       ],
      //     },
      //   ],
      // },
      allPermissionList.some((permission) =>
        [
          purchaseRequestPermission.VIEW,
          purchaseRequestPermission.VIEW_SELF,
          purchaseOrderPermission.VIEW,
          purchaseReturnPermission.VIEW,
          purchaseReportPermission.VIEW,
          isAdmin,
        ].includes(permission)
      ) && {
        title: t("purchase.index"),
        items: [
          {
            title: t("purchase.index"),
            path: "/purchase",
            icon: <AddShoppingCartOutlinedIcon fontSize="small" />,
            children: [
              allPermissionList.some((permission) =>
                [
                  purchaseRequestPermission.VIEW,
                  purchaseRequestPermission.VIEW_SELF,
                  isAdmin,
                ].includes(permission)
              ) && {
                title: t("purchase.request.index"),
                path: "/purchase/request",
              },
              allPermissionList.some((permission) =>
                [purchaseOrderPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("purchase.order.index"),
                path: "/purchase/order",
              },
              allPermissionList.some((permission) =>
                [purchaseReturnPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("purchase.return.index"),
                path: "/purchase/return",
              },
              allPermissionList.some((permission) =>
                [purchaseReportPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("purchase.report.index"),
                path: "/purchase/report",
              },
            ],
          },
        ],
      },
      // allPermissionList.some((permission) =>
      //   [
      //     bomPermission.VIEW,
      //     manufactureOrderPermission.VIEW,
      //     workOrderPermission.VIEW,
      //     manufactureReportPermission.VIEW,
      //     isAdmin,
      //   ].includes(permission)
      // ) && {
      //   title: t("manufacture.index"),
      //   items: [
      //     {
      //       title: t("manufacture.index"),
      //       path: "/manufacture",
      //       icon: <CabinOutlinedIcon fontSize="small" />,
      //       children: [
      //         allPermissionList.some((permission) =>
      //           [bomPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("manufacture.bom.index"),
      //           path: "/manufacture/bom",
      //         },
      //         allPermissionList.some((permission) =>
      //           [manufactureOrderPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("manufacture.order.index"),
      //           path: "/manufacture/order",
      //         },
      //         allPermissionList.some((permission) =>
      //           [workOrderPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("manufacture.workOrder.index"),
      //           path: "/manufacture/work-order",
      //         },
      //         allPermissionList.some((permission) =>
      //           [manufactureReportPermission.VIEW, isAdmin].includes(permission)
      //         ) && {
      //           title: t("manufacture.report"),
      //           path: "/manufacture/report",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: t("user.index"),
        items: [
          {
            title: t("user.index"),
            path: "/user",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
            children: [
              allPermissionList.some((permission) =>
                [accountPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("user.account.index"),
                path: "/user/account",
              },
              allPermissionList.some((permission) =>
                [rbacPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("user.rbac.index"),
                path: "/user/rbac",
              },
              {
                title: t("user.setting.index"),
                path: "/user/setting",
              },
            ],
          },
        ],
      },
      allPermissionList.some((permission) =>
        [
          companyInfoPermission.VIEW,
          approvalSettingPermission.VIEW,
          salesRemarkPermission.VIEW,
          purchaseRemarkPermission.VIEW,
          logisticRemarkPermission.VIEW,
          manufactureRemarkPermission.VIEW,
          locationPermission.VIEW,
          categoryPermission.VIEW,
          uomPermission.VIEW,
          attributePermission.VIEW,
          manufactureSettingPermission.VIEW,
          logisticSettingPermission.VIEW,
          isAdmin,
        ].includes(permission)
      ) && {
        title: t("setting.index"),
        items: [
          {
            title: t("setting.index"),
            path: "/setting",
            icon: <SettingsOutlinedIcon fontSize="small" />,
            children: [
              // allPermissionList.some((permission) =>
              //   [companyInfoPermission.VIEW, isAdmin].includes(permission)
              // ) && {
              //   title: t("setting.companyInfo"),
              //   path: "/setting/company-info",
              // },
              allPermissionList.some((permission) =>
                [approvalSettingPermission.VIEW, isAdmin].includes(permission)
              ) && {
                title: t("setting.approval.index"),
                path: "/setting/approval",
              },
              allPermissionList.some((permission) =>
                [
                  salesRemarkPermission.VIEW,
                  purchaseRemarkPermission.VIEW,
                  logisticRemarkPermission.VIEW,
                  manufactureRemarkPermission.VIEW,
                  isAdmin,
                ].includes(permission)
              ) && {
                title: t("setting.remarkSetting"),
                path: "/setting/remark",
              },
              allPermissionList.some((permission) =>
                [
                  locationPermission.VIEW,
                  categoryPermission.VIEW,
                  uomPermission.VIEW,
                  attributePermission.VIEW,
                  isAdmin,
                ].includes(permission)
              ) && {
                title: t("setting.warehouseSetting"),
                path: "/setting/inventory",
              },
              // allPermissionList.some((permission) =>
              //   [manufactureSettingPermission.VIEW, isAdmin].includes(
              //     permission
              //   )
              // ) && {
              //   title: t("setting.manufacture.index"),
              //   path: "/setting/manufacture",
              // },
              // allPermissionList.some((permission) =>
              //   [logisticSettingPermission.VIEW, isAdmin].includes(permission)
              // ) && {
              //   title: t("setting.logisticSetting"),
              //   path: "/setting/logistic",
              // },
            ],
          },
        ],
      },
    ];
  }, [
    accountPermission?.VIEW,
    allPermissionList,
    approvalSettingPermission?.VIEW,
    // bomPermission?.VIEW,
    companyInfoPermission?.VIEW,
    contactPermission?.VIEW,
    contactReportPermission?.VIEW,
    // deliveryOrderPermission?.VIEW,
    // deliveryReportPermission?.VIEW,
    // deliveryTripPermission?.VIEW,
    goodsAdjustmentPermission?.VIEW,
    goodsIssuePermission?.VIEW,
    goodsReceivePermission?.VIEW,
    goodsReceiveReturnPermission?.VIEW,
    goodsTransferPermission?.VIEW,
    inventoryReportPermission?.VIEW,
    locationPermission?.VIEW,
    categoryPermission?.VIEW,
    uomPermission?.VIEW,
    attributePermission?.VIEW,
    itemPermission?.VIEW,
    logisticRemarkPermission?.VIEW,
    logisticSettingPermission?.VIEW,
    // manufactureOrderPermission?.VIEW,
    manufactureRemarkPermission?.VIEW,
    // manufactureReportPermission?.VIEW,
    manufactureSettingPermission?.VIEW,
    purchaseOrderPermission?.VIEW,
    purchaseRemarkPermission?.VIEW,
    purchaseReportPermission?.VIEW,
    purchaseRequestPermission?.VIEW,
    purchaseRequestPermission?.VIEW_SELF,
    purchaseReturnPermission?.VIEW,
    // quotationPermission?.VIEW,
    rbacPermission?.VIEW,
    // salesOrderPermission?.VIEW,
    salesRemarkPermission?.VIEW,
    // salesReportPermission?.VIEW,
    // salesReturnPermission?.VIEW,
    t,
    // workOrderPermission?.VIEW,
  ]);

  const fileredSections = sections.filter((section) => section !== false);
  const formatSections = fileredSections.map((section) => {
    const items = section.items.filter((item) => item !== false);
    const formatItems = items.map((item) => ({
      ...item,
      children: item.children.filter((child) => child !== false),
    }));
    return {
      title: section.title,
      items: formatItems,
    };
  });

  const navItemNavigateHandle = (path) => {
    navigate(path);
    verify();
    if (isSmallScreen) {
      sidebar.setIsSidebarOpen(false);
    }
  };

  const content = (
    <CustomizedScrollbar
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              pt: 3,
              pl: 3,
            }}
          >
            <img
              style={{ cursor: "pointer", width: 150 }}
              src="/static/logo.png"
              alt="logo"
              onClick={() => navigate("/")}
            />
            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                height: "auto",
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.2),
                },
              }}
              onClick={() => sidebar.setIsSidebarOpen(false)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ flexGrow: 1 }}>
            {formatSections.map((section) => (
              <Fragment key={section.title}>
                {section.title === t("user.index") && (
                  <Divider variant="middle" />
                )}
                <LeftNavbarSection
                  key={section.title}
                  path={pathname}
                  searchParams={searchParamsList}
                  onItemClick={navItemNavigateHandle}
                  sx={{
                    p: 0,
                    "&:first-of-type": {
                      pt: 1,
                      mt: 1,
                    },
                    mt: section.title === t("user.index") ? 1 : 0,
                  }}
                  {...section}
                  isSmallScreen={isSmallScreen}
                />
                {section.title === t("setting.index") && (
                  <Divider variant="middle" />
                )}
              </Fragment>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", py: 3 }}>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Powered by {COMPANY_NAME}
          </Typography>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Application version {CURRENT_VERSION}
          </Typography>
        </Box>
      </Box>
    </CustomizedScrollbar>
  );
  return (
    <Drawer
      open={sidebar.isSidebarOpen}
      onClose={() => sidebar.setIsSidebarOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          borderRightColor: "divider",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          width: 260,
          zIndex: 2,
        },
      }}
      variant={isSmallScreen ? "temporary" : "persistent"}
    >
      {content}
    </Drawer>
  );
};

export default LeftNavbar;
