import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./vehicle-initials";

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state) {
      state.isLoading.vehicle = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllVehicles(state, action) {
      state.allVehicles = action.payload;
      state.isLoading.allVehicles = false;
    },
    loadedVehicle(state, action) {
      state.vehicle = action.payload;
      state.isLoading.vehicle = false;
    },
    resetVehicle(state) {
      state.vehicle = initialState.vehicle;
      state.error = null;
    },
  },
});

export const vehicleActions = vehicleSlice.actions;

export default vehicleSlice.reducer;
