import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";

const RequireAuth = ({ allowedPermissions }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  const allPermissions = ["GENERAL__GENERAL__VIEW"];

  user?.role_list?.forEach((role) => {
    allPermissions.push(...role.permission_list);
  });

  return isAuthenticated &&
    allPermissions.find((permission) =>
      [...allowedPermissions, "ADMIN__ADMIN__ADMIN"]?.includes(permission)
    ) ? (
    <Outlet />
  ) : isAuthenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export default RequireAuth;
