import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment } from "react";
import { useWatch } from "react-hook-form";
import TraceEntryList from "./TraceEntryList";
import CloseIcon from "@mui/icons-material/Close";

const GoodsAdjustmentTable = ({
  control,
  fields,
  remove,
  disabled,
  getValues,
  setValue,
  totalError,
  setTotalError,
  errors,
}) => {
  const TotalPreviousQuantity = ({ control, index }) => {
    const watchPreviousQuantity = useWatch({
      control,
      name: `goods_adjustment_list[${index}].stock_entry_list.trace_entry_list`,
    });

    const totalPreviousQuantity =
      watchPreviousQuantity?.reduce(
        (prev, curr) => prev + parseInt(curr?.previous_quantity),
        0
      ) || 0;

    return (
      <TableCell align="center">
        {totalPreviousQuantity.toLocaleString()}
      </TableCell>
    );
  };

  const TotalPostedQuantity = ({ control, index }) => {
    const watchQuantity = useWatch({
      control,
      name: `goods_adjustment_list[${index}].stock_entry_list.trace_entry_list`,
    });

    const totalQuantity =
      watchQuantity?.reduce(
        (prev, curr) => prev + parseInt(curr?.posted_quantity),
        0
      ) || 0;
    return (
      <TableCell align="center">{totalQuantity.toLocaleString()}</TableCell>
    );
  };

  const header = [
    "ลำดับ",
    "วันที่และเวลา",
    "รหัสสินค้า - ชื่อสินค้า",
    "จำนวนคงคลัง",
    "จำนวนปรับปรุง",
    "หน่วย",
    "SN",
    "สถานที่",
    "ผู้สแกน",
    "Barcode",
    "",
  ];

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, width: "100%", overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead style={{ backgroundColor: "#E8F1E8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell key={index}>
                <Box width="max-content">{header}</Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {renderBoxMaxContent(
                    row.stock_entry_list?.item?.document_id +
                      " - " +
                      row.stock_entry_list?.item?.name
                  )}
                </TableCell>
                <TotalPreviousQuantity control={control} index={index} />
                <TotalPostedQuantity control={control} index={index} />
                <TableCell align="center">
                  {renderBoxMaxContent(row.stock_entry_list?.uom?.name)}
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {!disabled && (
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={false}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
              <TraceEntryList
                control={control}
                nestIndex={index}
                disabled={disabled}
                setValue={setValue}
                getValues={getValues}
                totalError={totalError}
                setTotalError={setTotalError}
                errors={errors}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsAdjustmentTable;
