import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import LoginForm from "../components/Form/Login";
import { COMPANY_NAME, CURRENT_VERSION } from "../config/variables";

const Login = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return !isMobile ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(116.58deg, #44af47 -5.99%, #8fcbb1 57.84%, #e2f2ec 89.53%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="login" src="/static/loginBg.svg" />
        </Box>
        <Box
          sx={{
            borderRadius: "40px 0 0 40px",
            background: "white",
            display: "flex",
            flex: 1,
            height: "inherit",
            alignItems: "center",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "space-between",
            overflowY: "scroll",
            overflowX: "hidden",
            minWidth: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              flex: 15,
            }}
          >
            <img alt="logo" src="/static/logo.png" style={{ width: 300 }} />
            <Typography variant="h4" sx={{ mb: "2.5rem" }}>
              เข้าสู่ระบบ
            </Typography>
            <LoginForm />
          </Box>
          <Box sx={{ width: "100%", pt: "2rem", flex: 1 }}>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Powered by {COMPANY_NAME}
            </Typography>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Application version {CURRENT_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background: "white",
        display: "flex",
        height: "100vh",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flex: 15,
        }}
      >
        <img alt="logo" src="/static/logo.png" style={{ width: 300 }} />
        <Typography variant="h4" sx={{ mb: "2.5rem" }}>
          เข้าสู่ระบบ
        </Typography>
        <LoginForm isMobile={isMobile} />
      </Box>
      <Box sx={{ width: "100%", pt: 4 }}>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Powered by {COMPANY_NAME}
        </Typography>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Application version {CURRENT_VERSION}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
