import { Avatar, Box, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../utils/date-converter";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";

const TraceEntryList = ({ nestIndex, control, disabled, setValue, errors }) => {
  const { fields, remove } = useFieldArray({
    control,
    name: `goods_transfer_list[${nestIndex}].stock_entry_list.trace_entry_list`,
    keyName: "genId",
  });

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  const PostedQuantity = ({ control, nestIndex, index }) => {
    const watchPostedQuantity = useWatch({
      control,
      name: `goods_transfer_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`,
    });

    return (
      <TableCell align="center">
        {Math.abs(watchPostedQuantity).toLocaleString()}
      </TableCell>
    );
  };

  return (
    <>
      {fields.map((trace, index) => (
        <TableRow key={trace.genId}>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.posted_date
                ? typeof trace.posted_date === "string"
                  ? formatDateTime(trace.posted_date)
                  : unixToDateTimeWithFormat(trace.posted_date)
                : null
            )}
          </TableCell>
          <TableCell align="center"></TableCell>
          <PostedQuantity
            control={control}
            nestIndex={nestIndex}
            index={index}
          />
          <TableCell align="center">
            {renderBoxMaxContent(trace.uom.name)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.serial_number)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              disabled || trace.location_list.length === 1 ? (
                trace.source_bin_location_document_id
              ) : (
                <Box width={220}>
                  <Controller
                    key="gi-source_bin_location_document_id-field"
                    name={`goods_transfer_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].source_bin_location_document_id`}
                    control={control}
                    render={({ field }) => (
                      <CustomizedComboBox
                        {...field}
                        options={trace.location_list || []}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.value : "");
                          setValue(
                            `goods_transfer_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`,
                            newValue.current_quantity
                          );
                        }}
                        error={
                          errors &&
                          errors?.goods_transfer_list &&
                          errors?.goods_transfer_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          Boolean(
                            errors?.goods_transfer_list[nestIndex]
                              ?.stock_entry_list?.trace_entry_list[index]
                              ?.source_bin_location_document_id
                          )
                        }
                        helperText={
                          errors &&
                          errors?.goods_transfer_list &&
                          errors?.goods_transfer_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          errors?.goods_transfer_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id.message
                        }
                        // title={t("sales.paymentReceipt.eWalletType")}
                        // readOnly={viewOnly}
                        required
                      />
                    )}
                  />
                </Box>
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.destination_bin_location.document_id)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.created_by?.img_url ? (
                <Avatar alt="img_url" src={trace.created_by?.img_url} />
              ) : (
                <CustomizedLetterAvatar
                  name={
                    trace.created_by?.first_name +
                    " " +
                    trace.created_by?.last_name
                  }
                />
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.batch_number)}
          </TableCell>
          <TableCell align="center">
            {disabled ? null : (
              <IconButton onClick={() => remove(index)} disabled={false}>
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TraceEntryList;
