import {
  Box,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import CloseIcon from "@mui/icons-material/Close";
import CategoryList from "./CategoryList";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";

const EstimateItemTable = ({ control, errors, disabled }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "engineer_data",
  });

  const status = useWatch({
    control,
    name: "status",
  });

  const [headers, setHeaders] = useState([]);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(null);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  useEffect(() => {
    if (!disabled) {
      if (status === "closed" || status === "finished") {
        setHeaders([
          {
            label: "No.",
            thaiLabel: "รายการ",
            width: 75,
          },
          {
            label: "Name",
            thaiLabel: "ชื่อสินค้า",
            width: 225,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 90,
          },
          {
            label: "Reserved Qty",
            thaiLabel: "จำนวนเผื่อ",
            width: 90,
          },
          {
            label: "Unit",
            thaiLabel: "หน่วย",
            width: 100,
          },
          {
            label: "Remark",
            thaiLabel: "หมายเหตุ",
            width: 100,
          },
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        setHeaders([
          {
            label: "No.",
            thaiLabel: "รายการ",
            width: 75,
          },
          {
            label: "Name",
            thaiLabel: "ชื่อสินค้า",
            width: 225,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 90,
          },
          {
            label: "Unit",
            thaiLabel: "หน่วย",
            width: 100,
          },
          {
            label: "Remark",
            thaiLabel: "หมายเหตุ",
            width: 100,
          },
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (status === "closed" || status === "finished") {
        setHeaders([
          {
            label: "No.",
            thaiLabel: "รายการ",
            width: 75,
          },
          {
            label: "Name",
            thaiLabel: "ชื่อสินค้า",
            width: 225,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 90,
          },
          {
            label: "Reserved Qty",
            thaiLabel: "จำนวนเผื่อ",
            width: 90,
          },
          {
            label: "Unit",
            thaiLabel: "หน่วย",
            width: 100,
          },
          {
            label: "Remark",
            thaiLabel: "หมายเหตุ",
            width: 100,
          },
        ]);
      } else {
        setHeaders([
          {
            label: "No.",
            thaiLabel: "รายการ",
            width: 75,
          },
          {
            label: "Name",
            thaiLabel: "ชื่อสินค้า",
            width: 225,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 90,
          },
          {
            label: "Unit",
            thaiLabel: "หน่วย",
            width: 100,
          },
          {
            label: "Remark",
            thaiLabel: "หมายเหตุ",
            width: 100,
          },
        ]);
      }
    }
  }, [disabled, status]);

  const openDeleteItemConfirmationHandler = (index) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const addGroupHandler = () => {
    const newGroup = {
      group_name: "",
      group_area: "",
      group_uom: "",
      category_list: [
        {
          category_name: "วัสดุไม้",
          item_list: [],
        },
        {
          category_name: "วัสดุประกอบ",
          item_list: [],
        },
        {
          category_name: "วัสดุอุปกรณ์หลัก",
          item_list: [],
        },
        {
          category_name: "วัสดุสิ้นเปลือง",
          item_list: [],
        },
        {
          category_name: "บริการ",
          item_list: [],
        },
        {
          category_name: "อื่นๆ",
          item_list: [],
        },
      ],
    };
    append(newGroup);
  };

  return (
    <>
      {!disabled && (
        <CustomizedButton
          sx={{ mb: 3 }}
          title="เพิ่มกลุ่ม"
          variant="contained"
          onClick={addGroupHandler}
        />
      )}

      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: "#E8F1E8",
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" key={index} width={header.width}>
                  <Typography fontSize={14} fontWeight={600}>
                    {header.thaiLabel}
                  </Typography>
                  <Typography fontSize={14} fontWeight={600}>
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((group, index) => (
              <Fragment key={group.id}>
                <TableRow>
                  <TableCell align="center">
                    <Typography fontSize={14} fontWeight={600}>
                      กลุ่มที่ {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <ControlledTextField
                        control={control}
                        name={`engineer_data[${index}].group_name`}
                        disabled={disabled}
                        error={
                          errors?.engineer_data &&
                          errors?.engineer_data[index]?.group_name
                        }
                      />
                      <ControlledTextField
                        control={control}
                        name={`engineer_data[${index}].group_area`}
                        disabled={disabled}
                        error={
                          errors?.engineer_data &&
                          errors?.engineer_data[index]?.group_area
                        }
                      />
                      <ControlledTextField
                        control={control}
                        name={`engineer_data[${index}].group_uom`}
                        disabled={disabled}
                        error={
                          errors?.engineer_data &&
                          errors?.engineer_data[index]?.group_uom
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                  {(status === "closed" || status === "finished") && (
                    <TableCell></TableCell>
                  )}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                        onClick={() => openDeleteItemConfirmationHandler(index)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                <CategoryList
                  control={control}
                  errors={errors}
                  groupIndex={index}
                  categoryList={group.category_list}
                  disabled={disabled}
                  status={status}
                />
              </Fragment>
            ))}
            <ModalUI
              title="ยืนยันการลบกลุ่ม?"
              open={openDeleteItemConfirmation}
              handleClose={closeDeleteItemConfirmationHandler}
              maxWidth="xs"
              fullWidth
            >
              <DialogContentText>
                ถ้าลบแล้วจะไม่สามารถเปลี่ยนแปลงได้
              </DialogContentText>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: ".5rem",
                }}
              >
                <CustomizedButton
                  title="ยกเลิก"
                  variant="outlined"
                  onClick={closeDeleteItemConfirmationHandler}
                />
                <CustomizedButton
                  title="ยืนยัน"
                  variant="contained"
                  onClick={deleteItemConfirmationAction}
                />
              </Box>
            </ModalUI>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EstimateItemTable;
