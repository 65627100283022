import { gql } from "graphql-request";

export const CREATE_TAG_LIST = gql`
  mutation TagCreate($createInput: TagCreateinput) {
    tagCreate(createInput: $createInput) {
      id
      name
      entity
    }
  }
`;

export const UPDATE_TAG_LIST = gql`
  mutation TagUpdate(
    $uniqueInput: TagWhereUniqueinput
    $updateInput: TagUpdateinput
  ) {
    tagUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      name
      entity
    }
  }
`;

export const DELETE_TAG_LIST = gql`
  mutation TagDelete($uniqueInput: TagWhereUniqueinput) {
    tagDelete(uniqueInput: $uniqueInput) {
      id
      entity
    }
  }
`;

export const DELETE_TAG_VERIFY = gql`
  mutation TagDeleteVerify($uniqueInput: TagWhereUniqueinput) {
    tagDeleteVerify(uniqueInput: $uniqueInput)
  }
`;

export const SET_DOCUMENT_STEP = gql`
  mutation DocumentSetStep($documentSetStepInput: DocumentSetStepInput!) {
    documentSetStep(documentSetStepInput: $documentSetStepInput) {
      document_id
      document_type
      status
      workflow_step_number
    }
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation DocumentCancel($documentInput: DocumentInput!) {
    documentCancel(documentInput: $documentInput) {
      document_id
      document_type
      is_canceled
    }
  }
`;

export const CREATE_CREATABLE = gql`
  mutation CreatableCreate($createInput: CreatableCreateInput!) {
    creatableCreate(createInput: $createInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const DELETE_CREATABLE = gql`
  mutation CreatableDelete($uniqueInput: CreatableUniqueInput!) {
    creatableDelete(uniqueInput: $uniqueInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;

export const DOCUMENT_NEXT_STATUS = gql`
  mutation DocumentNextStatus($documentInput: DocumentInput!) {
    documentNextStatus(documentInput: $documentInput) {
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
      }
      status
    }
  }
`;
