import * as Yup from "yup";

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const validation = Yup.object().shape({
  document_id: Yup.string().required("กรุณากรอก"),
  document_date: Yup.date()
    .required("กรุณาระบุวันที่โอนย้าย")
    .nullable()
    .transform((value) => dateIsValid(value)),
  source_warehouse_document_id: Yup.string().required("กรุณากรอก"),
  destination_warehouse_document_id: Yup.string().required("กรุณากรอก"),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  goods_transfer_list: Yup.array()
    .of(
      Yup.object().shape({
        stock_entry_list: Yup.object().shape({
          trace_entry_list: Yup.array()
            .of(
              Yup.object().shape({
                source_bin_location_document_id:
                  Yup.string().required("กรุณาระบุสถานที่"),
                posted_quantity: Yup.number().min(
                  1,
                  "จำนวนเคลื่อนย้ายต้องมากกว่า 0"
                ),
              })
            )
            .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า"),
        }),
      })
    )
    .min(1, "กรุณาเพิ่มรายการสินค้า"),
});
