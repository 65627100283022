import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import {
  createUom,
  updateUom,
} from "../../../features/Setting/Uom/uom-actions";
import { uomActions } from "../../../features/Setting/Uom/uom-slice";
import { useSnackbar } from "notistack";

const UomForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { uom, uomIndex } = useSelector((state) => state.uom);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: uom,
    resolver: yupResolver(validation),
  });

  const { t } = useTranslation();

  const onSubmit = (data) => {
    const newData = {
      ...data,
      document_id: data.document_id === "" ? data.name : data.document_id,
    };
    try {
      if (!uom.document_id) {
        dispatch(createUom({ createInput: newData }, enqueueSnackbar));
      } else if (uom.document_id) {
        delete newData.id;
        delete newData.document_id;

        dispatch(
          updateUom({
            input: {
              uniqueInput: { document_id: uom.document_id },
              updateInput: newData,
            },
            index: uomIndex,
            enqueueSnackbar,
          })
        );
      }
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    return () => {
      dispatch(uomActions.resetUom());
    };
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} mt={1}>
        {uom.id && (
          <Grid item xs={6}>
            <Controller
              name={"document_id"}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  disabled
                  label={t("setting.inventory.uom.uomID")}
                  {...field}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={uom.id ? 6 : 12}>
          <Controller
            name={"name"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                label={t("setting.inventory.uom.uomName")}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CustomizedButton
        type="reset"
        onClick={resetForm}
        title={t("button.cancel")}
        variant="outlined"
        sx={{ mt: 3, mr: 2 }}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
        sx={{ mt: 3 }}
      />
    </form>
  );
};

export default UomForm;
