import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";
import { useTheme } from "@emotion/react";
import { useAuth, usePermission } from "../../../hooks/use-auth";

const Contact = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [allPermissionList, setAllPermissionList] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const breadcrumbs = [
    {
      name: t("contact.index"),
    },
  ];

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const { contactPermission } = usePermission();

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const menuList = [
    allPermissionList.some((permission) =>
      [contactPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("contact.contact_type.all"),
      navigateTo: "all",
    },
    allPermissionList.some((permission) =>
      [contactPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("contact.contact_type.customer"),
      navigateTo: "customer",
    },
    allPermissionList.some((permission) =>
      [contactPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("contact.contact_type.vendor"),
      navigateTo: "vendor",
    },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderMenuList = () =>
    formatMenuList.map((menu) => {
      return (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
          <CustomizedMenuBox
            testId={menu.testId}
            title={menu.title}
            onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
            isGridItem={!isSmallScreen}
            isMobile={isSmallScreen}
          />
        </Grid>
      );
    });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2, textAlign: "-webkit-center" }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
