import InventoryService from "../../../services/Inventory";
import {
  createActivityLogPayload,
  createActivityLogStatusPayload,
  createActivityLogEditPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { goodsIssueActions } from "./goodsIssue-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { notifyTemplate } from "../../../utils/notifyTemplate";
import { postNotify } from "../../../services/Notification";

const checkRefFromType = (type) => {
  switch (type) {
    case "ขาย":
    case "ขายออนไลน์":
      return "sales_order";
    case "ผลิตวัตถุดิบ":
    case "ผลิตแปรรูป":
    case "ผลิตแปรรูป - PT":
      return "manufacture_order";
    case "ส่งคืน":
      return "purchase_return";
    default:
      break;
  }
};

export const getAllGoodsIssue =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGIAggrid(input);
      if (params) params.successCallback(allItems.results, allItems.count);
      dispatch(goodsIssueActions.loadedAllItems(allItems.results));
      dispatch(goodsIssueActions.loadedAllCount(allItems.count));
    } catch (err) {
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "allItems" }));
      if (params) params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodsIssueById = (input) => async (dispatch) => {
  dispatch(goodsIssueActions.onLoading("item"));
  try {
    const Item = await InventoryService.getGI(input);
    dispatch(goodsIssueActions.loadedItem(Item));
  } catch (err) {
    dispatch(goodsIssueActions.rejectedActions({ ...err, name: "item" }));
  }
};

export const createGoodsIssueWithApprove =
  (input, enqueueSnackbar, navigate, user) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("item"));
    try {
      const item = await InventoryService.createGIWithApprove(input);
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารนำออกสำเร็จ", {
          variant: "success",
        });
      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_issue",
        "สร้างใบนำออก"
      );
      const createActivityLogApprove = createActivityLogStatusPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_issue",
        null,
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLogApprove,
      });
      if (item.reference_document_id) {
        const createActivityLog = createActivityLogPayload(
          {
            document_id: item.reference_document_id,
            creator_document_id: user.document_id,
          },
          checkRefFromType(item.type),
          "สร้างใบนำออก"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLog,
        });
      }
      if (navigate) navigate(`/inventory/issue/${item.document_id}`);
      if (input.type === "เบิกตัวอย่าง") {
        const message = notifyTemplate(
          item.created_date,
          "ใบนำออก",
          item.document_id,
          item.status,
          `https://biowood-dev.npr.digital/inventory/issue/${encodeURIComponent(
            item.document_id
          )}`
        );
        await postNotify(message, process.env.REACT_APP_INVENTORY_TOKEN);
      }
    } catch (err) {
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารนำออกไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "item" }));
    }
  };

export const updateGoodsIssue =
  (input, enqueueSnackbar, user) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("item"));
    try {
      const Item = await InventoryService.updateGI(input);
      dispatch(goodsIssueActions.loadedItem(Item));
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขเอกสารสำเร็จ", {
          variant: "success",
        });

      const editActivityLog = createActivityLogEditPayload(
        {
          ...input.uniqueInput,
          ...input.updateInput,
          creator_document_id: user.document_id,
        },
        "goods_issue"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: editActivityLog,
      });
      // setDocumentStage((prev) => prev + 1);
    } catch (err) {
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขเอกสารไม่สำเร็จ", {
          variant: "error",
        });
    }
  };
