import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import AttachmentCard from "../../../UI/AttachmentCard";
import { unixToDateTimeWithFormat } from "../../../../utils/date-converter";
import { useAuth } from "../../../../hooks/use-auth";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const AttachmentDropzone = ({ setValue, valueToSet, disabled }) => {
  const { estimate } = useSelector((state) => state.estimate);
  const { id } = useParams();
  const {
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { files: [] } });
  const [files, setFiles] = useState([]);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "files",
  });

  const watchFieldArray = watch("files");

  useEffect(() => {
    if (id) {
      reset({
        files: estimate[valueToSet],
      });
      setFiles(estimate[valueToSet]);
    }
  }, [estimate, id, reset, valueToSet]);

  const { user } = useAuth();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            fileName: file.name,
          })
        ),
      ]);
      acceptedFiles.forEach((file) => {
        append({ ...file, fileName: file.name, name: file.name });
      });
    },
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (index) => () => {
    const newFiles = [...files];
    if (index > -1) {
      newFiles.splice(index, 1);
    }
    setFiles(newFiles);
    remove(index);
  };

  const updateFileName = (index) => {
    let newFiles = [...files];
    update(index, watchFieldArray[index]);
    newFiles[index] = watchFieldArray[index];
    setFiles(newFiles);
  };

  const uploadedDate = moment().format("DD/MM/YYYY HH:mm");

  const renderAttachmentCards = () => {
    return fields.map((item, index) => (
      <Grid key={item.id} item xs={12} sm={12} md={4} lg={3} xl={3}>
        <AttachmentCard
          control={control}
          errors={errors}
          fileName={item.name}
          full_name={
            item?.uploaded_by
              ? item?.uploaded_by?.first_name +
                " " +
                item?.uploaded_by?.last_name
              : user.first_name + " " + user.last_name
          }
          url={item.url}
          index={index}
          date={
            item?.uploaded_date
              ? unixToDateTimeWithFormat(item?.uploaded_date)
              : uploadedDate
          }
          onRemove={removeFile(index)}
          onUpdate={updateFileName}
          viewOnly={disabled}
        />
      </Grid>
    ));
  };

  useEffect(() => {
    setValue(valueToSet, files);
  }, [files, setValue, valueToSet]);

  return (
    <>
      {!disabled && (
        <Grid item xs={12} sx={{ my: 2 }}>
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>ลากมาวางเพื่ออัปโหลด</p>
            </div>
          </section>
        </Grid>
      )}
      <Grid container spacing={2}>
        {renderAttachmentCards()}
      </Grid>
    </>
  );
};

export default AttachmentDropzone;
