import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AgGrid from "../../../components/Table/AgGrid";
import TotalBox from "../../../components/UI/TotalBox";
import { getAllGoodsTransfer } from "../../../features/Inventory/GoodsTransfer/goodsTransfer-actions";
import {
  formatDate,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import {
  filterDateWithUnix,
  filterParamsOptions,
} from "../../../utils/filterparams";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedToolTipAvatar from "../../../components/Custom/CustomizedToolTipAvatar";
import { usePermission } from "../../../hooks/use-auth";

const GoodTransfer = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const status = searchParams.get("status");
  const { t } = useTranslation();
  const { count } = useSelector((state) => state.goodsTransfer);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { createPermission } = usePermission();
  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.transfer.index"),
    },
  ];
  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return unixToDateWithFormat(params.data.created_date);
      },
    },
    {
      field: "document_date",
      headerName: t("inventory.transfer.transferDate"),
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return unixToDateWithFormat(params.data.document_date);
      },
    },
    {
      field: "document_id",
      headerName: t("inventory.transfer.documentId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "source_warehouse",
      headerName: t("inventory.transfer.sourceWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: (params) => params.value.thai_name,
    },
    {
      field: "destination_warehouse",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: (params) => params.value.thai_name,
    },
    {
      field: "created_by",
      headerName: t("inventory.transfer.transferedBy"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <CustomizedToolTipAvatar avatar={params.value} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params) => {
          if (params.value === "draft") {
            return "ร่าง";
          }
          if (params.value === "finished") {
            return "เสร็จสิ้น";
          }
          return "ยกเลิก";
        },
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const tabs = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    // {
    //   label: t("status.draft"),
    //   path: `${pathname}?status=draft`,
    // },
    // {
    //   label: t("status.finished"),
    //   path: `${pathname}?status=finished`,
    // },
    // {
    //   label: t("status.cancelled"),
    //   path: `${pathname}?status=cancelled`,
    // },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        created_date,
        document_date,
        source_warehouse,
        destination_warehouse,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
        document_date: document_date && filterDateWithUnix(document_date),
        source_warehouse: source_warehouse && {
          filterType: "object",
          filter: {
            thai_name: source_warehouse,
          },
        },
        destination_warehouse: destination_warehouse && {
          filterType: "object",
          filter: {
            thai_name: destination_warehouse,
          },
        },
      };

      const sortbyId = [
        ...request.sortModel,
        { colId: "created_date", sort: "desc" },
      ];
      dispatch(
        getAllGoodsTransfer(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: sortbyId,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const updateTableFilter = (filter) => {
    const instance = gridRef.current.api.getFilterInstance("status");
    switch (filter) {
      case "draft":
        instance.setModel({ values: ["draft"] });
        break;
      case "finished":
        instance.setModel({ values: ["finished"] });
        break;
      case "cancelled":
        instance.setModel({ values: ["cancelled"] });
        break;
      default:
        instance.setModel({});
        break;
    }
    gridRef.current.api.onFilterChanged();
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onFirstDataRendered = () => {
    updateTableFilter(status);
  };

  const onRowClicked = (params) => {
    return navigate(`${params.data.document_id}`);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      updateTableFilter(status);
    }
  }, [status]);

  const currentTab = pathname + (status ? `?status=${status}` : "");

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TotalBox
            title="ทั้งหมด"
            total={count}
            date={formatDate(new Date())}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 3,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{t("inventory.transfer.index")}</Typography>
        {createPermission && (
          <Box display={"flex"} gap={1}>
            <CustomizedButton
              size={isSmallScreen ? "large" : "small"}
              title={t("inventory.transfer.add")}
              variant="contained"
              onClick={() => navigate(`${pathname}/add`)}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      {!isSmallScreen ? (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onRowDoubleClicked={onRowClicked}
          onFirstDataRendered={onFirstDataRendered}
        />
      ) : (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onRowClicked={onRowClicked}
        />
      )}
    </>
  );
};

export default GoodTransfer;
