import { Box, DialogContentText } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as vendorDescSchema,
  validation as vendorDescValidation,
} from "../../../components/Form/Purchase/VendorDescription/schema";
import {
  purchaseOrderSchema,
  purchaseOrderValidation,
} from "../../../components/Form/Purchase/Header/schema";
import ReturnTab from "./ReturnTab";
import { useDispatch, useSelector } from "react-redux";
import {
  approvePurchaseOrder,
  createNewPurchaseOrder,
  declinePurchaseOrder,
  getPurchaseOrderById,
  updatePurchaseOrder,
} from "../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { purchaseOrderActions } from "../../../features/Purchase/PurchaseOrder/purchase-order-slice";
import {
  salesPrimaryButtonHandler,
  salesSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import PurchaseHeaderForm from "../../../components/Form/Purchase/Header";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import GoodsReceiveTab from "./GoodsReceiveTab";
import { validationItemList } from "../../../components/Form/Purchase/AddItemListForm/schema";
import { confirmationValidation } from "../../../components/Form/Sales/Header/schema";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import {
  purchasePayload,
  setPurchaseValueFormatter,
} from "../../../utils/purchasePayloadFormatter";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useApproval } from "../../../hooks/use-approval";
import moment from "moment";
// import { getAllItems } from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { itemMasterActions } from "../../../features/Inventory/ItemMaster/itemMaster-slice";
import InventoryService from "../../../services/Inventory";

const defaultValues = {
  render_status: null,
  price_vat_type: "excluded_vat",
  creator_document_id: "",
  contact_document_id: "",
  created_by: {},
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  summary: {},
  template_remark_id: "",
  remark: "",
};

const PurchaseOrderContainer = () => {
  const { user } = useAuth();
  const { refreshApprovals } = useApproval();
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { createPermission, editPermission } = usePermission();
  const {
    isLoading,
    purchaseOrder,
    approvalList,
    isHaveApproval,
    isHaveApprovalPermission,
  } = useSelector((state) => state.purchaseOrder);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const vendorDescRef = useRef();
  const itemListRef = useRef();
  const gridRef = useRef();

  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationItemList),
  });

  const {
    control: confirmationControl,
    getValues: getConfirmationValues,
    trigger: validateConfirmation,
    formState: { errors: confirmationErrors },
  } = useForm({
    defaultValues: {
      external_ref_id_confirmation: "",
    },
    resolver: yupResolver(confirmationValidation),
  });

  const [currentTab, setCurrentTab] = useState(pathname);
  const watchStatus = useWatch({ control, name: "render_status" });

  const [openPurchaseInvoiceConfirmation, setOpenPurchaseInvoiceConfirmation] =
    useState(false);

  const vendorDescUseForm = useForm({
    defaultValues: { ...vendorDescSchema },
    resolver: yupResolver(vendorDescValidation),
  });

  const {
    trigger: validateVendorDescription,
    setValue: setVendorDescValue,
    getValues: getVendorDescValues,
    getFieldState: getVendorDescFieldState,
  } = vendorDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...purchaseOrderSchema },
    resolver: yupResolver(purchaseOrderValidation),
  });

  // const [refItem, setRefItem] = useState([]);

  const [purchaseRequestIds, setPurchaseRequestIds] = useState([]);
  const [purchaseRequestIdsSnapshot, setPurchaseRequestIdsSnapshot] = useState(
    []
  );

  // useEffect(() => {
  //   let returnValue = [];
  //   const watchItemList = getValues("item_list");
  //   watchItemList.forEach((list) => {
  //     if (list.ref_document_id) {
  //       if (
  //         returnValue.some(
  //           (value) => value.reference_document_id === list.ref_document_id
  //         )
  //       ) {
  //         return null;
  //       } else
  //         returnValue.push({
  //           reference_document_id: list.ref_document_id,
  //           reference_document_type: list.ref_document_type,
  //         });
  //     }
  //   });

  //   const purchaseRequestRef = returnValue.map(
  //     (value) => value.reference_document_id
  //   );

  //   setRefItem(returnValue);
  //   setPurchaseRequestIds(purchaseRequestRef);
  //   setPurchaseRequestIdsSnapshot(purchaseRequestRef);
  // }, [getValues]);

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/order",
    },
    {
      name: id ?? `สร้าง${t("purchase.order.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("purchase.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.receive.index"),
      path: `${pathname}?tab=goods-receive`,
    },
    {
      label: t("purchase.return.index"),
      path: `${pathname}?tab=return`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "goods-receive":
        setCurrentTab(pathname + "?tab=goods-receive");
        break;
      case "return":
        setCurrentTab(pathname + "?tab=return");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  useEffect(() => {
    switch (watchStatus) {
      case "waitApprove":
      case "approved":
      case "notApproved":
      case "cancelled":
      case "partiallyImported":
      case "fullyImported":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    dispatch(getAllLocation());
    if (state) {
      dispatch(purchaseOrderActions.loadedPurchaseOrder(state));
    } else if (id) {
      dispatch(
        getPurchaseOrderById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(purchaseOrderActions.resetPurchaseOrder());
      dispatch(itemMasterActions.resetItem());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  useEffect(() => {
    if (state) {
      const getAllItem = async () => {
        const allItemsDocumentId = state["item_list"].map(
          (item) => item.item_document_id
        );
        const items = await InventoryService.getAllItems({
          findManyInput: {
            where: {
              document_id: {
                in: allItemsDocumentId,
              },
            },
          },
        });
        const formatItemList = state["item_list"].map((item) => {
          const findItem = items.find(
            (value) => value.document_id === item.item_document_id
          );
          return {
            ...item,
            qty: item.qty - item.ordered_qty,
            qty_uom: item.qty - item.ordered_qty,
            discount_amount: 0,
            price_per_unit: findItem?.purchase_standard_price ?? 1,
            vat_type: "ไม่มี",
            pre_vat_amount:
              (item.qty - item.ordered_qty) *
              (findItem.purchase_standard_price ?? 1),
            withholding_tax: { type: "ยังไม่ระบุ", amount: 0 },
            ref_document_id: state["document_id"],
          };
        });
        const formatPurchaseReturn = {
          ...defaultValues,
          ...vendorDescSchema,
          ...state,
          expect_date: moment().add(7, "days"),
          item_list: formatItemList,
        };
        setPurchaseRequestIds([state["document_id"]]);
        setPurchaseRequestIdsSnapshot([state["document_id"]]);
        setPurchaseValueFormatter(
          purchaseOrderSchema,
          vendorDescSchema,
          defaultValues,
          setHeaderValue,
          setVendorDescValue,
          setValue,
          state.isCopied ? state : formatPurchaseReturn,
          true,
          "purchase_order"
        );
      };
      getAllItem();
    } else if (id) {
      setPurchaseValueFormatter(
        purchaseOrderSchema,
        vendorDescSchema,
        defaultValues,
        setHeaderValue,
        setVendorDescValue,
        setValue,
        purchaseOrder,
        false,
        "purchase_order"
      );
    }
  }, [purchaseOrder, setValue, setHeaderValue, id, state, setVendorDescValue]);

  const submitContactForm = (data) => {
    setVendorDescValue("contact", data);
  };

  const submitForm = async () => {
    const vendorDescIsValid = await validateVendorDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const dueDateError = getHeaderFieldState("due_date").error;
    const expectDateError = getHeaderFieldState("expect_date").error;
    const vendorError = getVendorDescFieldState("vendor").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (dueDateError ? 1 : 0) +
      (vendorError ? 1 : 0) +
      (expectDateError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1 || itemListError?.length > 0) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(dueDateError)) {
      enqueueSnackbar(dueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(expectDateError)) {
      enqueueSnackbar(expectDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(vendorError)) {
      enqueueSnackbar("กรุณาเลือกผู้ขาย", {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!vendorDescIsValid) {
      vendorDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }
    if (vendorDescIsValid && headerIsValid && itemListIsValid) {
      const { render_status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const { vendor, identity_no, ...vendorDescValues } =
        getVendorDescValues();
      const transformedEmployeeList = headerValues.employee_list.map(
        ({
          id,
          is_active,
          jwt_modifier,
          exp,
          iat,
          created_date,
          last_login_date,
          role_list,
          status,
          ...employee
        }) => employee
      );
      const { external_ref_id_confirmation } = getConfirmationValues();

      if (
        (render_status === "approved" ||
          render_status === "partiallyImported" ||
          render_status === "fullyImported") &&
        !isEdit
      ) {
        const confirmationIsValid = await validateConfirmation();
        if (!confirmationIsValid) {
          return;
        }
        if (external_ref_id_confirmation.trim().length > 0) {
          if (headerValues.external_ref_id.trim().length > 0) {
            const externalToArray = headerValues.external_ref_id
              .trim()
              .split(",");
            const newExternalConfirmation = [
              ...externalToArray,
              external_ref_id_confirmation,
            ];
            const formatNewExternal = newExternalConfirmation.join(", ");
            headerValues.external_ref_id = formatNewExternal;
          } else {
            headerValues.external_ref_id = external_ref_id_confirmation;
          }
        }
      }

      const payload = purchasePayload(
        headerValues,
        mainValues,
        transformedEmployeeList,
        contactSnapshot,
        summary,
        vendorDescValues,
        "po"
      );
      return payload;
    }
  };

  const saveDraftHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewPurchaseOrder(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updatePurchaseOrder(
            payload,
            watchStatus,
            false,
            purchaseOrder,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewPurchaseOrder(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updatePurchaseOrder(
            payload,
            watchStatus,
            true,
            purchaseOrder,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declinePurchaseOrder(
          {
            document_id,
            document_type: "purchase_order",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approvePurchaseOrder(
          {
            document_id,
            document_type: "purchase_order",
          },
          payload,
          user,
          enqueueSnackbar,
          getValues("item_list"),
          setValue,
          gridRef
        )
      );
      refreshApprovals();
    }
  };

  const createPurchaseInvoiceHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      delete payload.item_list;
      dispatch(
        updatePurchaseOrder(
          payload,
          watchStatus,
          false,
          purchaseOrder,
          user,
          enqueueSnackbar
        )
      );
      setOpenPurchaseInvoiceConfirmation(false);
    }
  };

  const editSubmitHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updatePurchaseOrder(
          payload,
          watchStatus,
          false,
          purchaseOrder,
          user,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/purchase/order");
  };

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.purchaseOrder) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (watchStatus) {
        switch (watchStatus) {
          case "draft":
            // case "notApproved":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (watchStatus) {
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, watchStatus]);

  const primarySubmitHandler = () => {
    salesPrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    salesSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "goods-receive":
        return <GoodsReceiveTab />;
      case "return":
        return <ReturnTab />;
      default:
        return (
          <>
            <PurchaseHeaderForm
              ref={headerRef}
              gridRef={gridRef}
              control={headerControl}
              errors={headerErrors}
              setValue={setHeaderValue}
              setMainValue={setValue}
              getMainValues={getValues}
              getValues={getHeaderValues}
              mainFormControl={control}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              documentType="purchase_order"
              editClickHandler={editClickHandler}
              isEdit={isEdit}
              currentState={state?.id ?? purchaseOrder}
              approvalList={approvalList}
              setOpenPurchaseInvoiceConfirmation={
                setOpenPurchaseInvoiceConfirmation
              }
              setPurchaseRequestIds={setPurchaseRequestIds}
              setPurchaseRequestIdsSnapshot={setPurchaseRequestIdsSnapshot}
              setIsEdit={setIsEdit}
              // refItem={refItem}
            />
            <DocumentInfoTab
              itemListRef={itemListRef}
              gridRef={gridRef}
              ref={vendorDescRef}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              setHeaderValue={setHeaderValue}
              getHeaderValues={getHeaderValues}
              submitVendorDesc={submitContactForm}
              vendorDescUseForm={vendorDescUseForm}
              contactUseForm={contactUseForm}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              purchaseRequestIds={purchaseRequestIds}
              setPurchaseRequestIds={setPurchaseRequestIds}
              purchaseRequestIdsSnapshot={purchaseRequestIdsSnapshot}
              setPurchaseRequestIdsSnapshot={setPurchaseRequestIdsSnapshot}
              // watchItemList={watchItemList}
            />
            <ModalUI
              title="บันทึกใบบันทึกซื้อ"
              open={openPurchaseInvoiceConfirmation}
              handleClose={() => setOpenPurchaseInvoiceConfirmation(false)}
              maxWidth="sm"
            >
              <DialogContentText sx={{ mb: 2 }}>
                หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
              </DialogContentText>
              <Controller
                name="external_ref_id_confirmation"
                control={confirmationControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    label={t("sales.externalRefDocumentId")}
                    error={Boolean(
                      confirmationErrors.external_ref_id_confirmation
                    )}
                    helperText={
                      confirmationErrors.external_ref_id_confirmation?.message
                    }
                    required
                    {...field}
                  />
                )}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: ".5rem",
                }}
              >
                <CustomizedButton
                  title="ยกเลิก"
                  variant="outlined"
                  onClick={() => setOpenPurchaseInvoiceConfirmation(false)}
                />
                <CustomizedButton
                  title="ยืนยัน"
                  variant="contained"
                  onClick={createPurchaseInvoiceHandler}
                />
              </Box>
            </ModalUI>
            {shouldRenderButtons() &&
              ((createPermission && !id) || (editPermission && id)) && (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <CustomizedButton
                      title={secondaryButtonTitle}
                      variant="outlined"
                      type="reset"
                      onClick={secondarySubmitHandler}
                    />
                  </Box>
                  <CustomizedButton
                    title={primaryButtonTitle}
                    variant="contained"
                    onClick={primarySubmitHandler}
                  />
                </Box>
              )}
          </>
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default PurchaseOrderContainer;
