import ModalUI from "./ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import ItemTable from "../Table/ItemTable";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { getAllItemsAggrid } from "../../features/Inventory/ItemMaster/itemMaster-actions";

const ItemTableModal = ({
  openItemListModal,
  closeItemListModalHandler,
  selectItemListRef,
  handleSelectItems,
  currCategory,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
        is_manufactuable: {
          filterType: "boolean",
          type: "equals",
          filter: "true",
        },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <ModalUI
      open={openItemListModal}
      handleClose={closeItemListModalHandler}
      title="สินค้า"
      width={1500}
    >
      <ItemTable
        gridRef={selectItemListRef}
        height={450}
        onGridReady={onGridReady}
        rowSelection="multiple"
        enableCheckbox
        getRowId={getRowId}
      />
      <CustomizedButton
        title="เพิ่ม"
        variant="contained"
        onClick={handleSelectItems}
        sx={{ mt: 2 }}
      />
    </ModalUI>
  );
};

export default ItemTableModal;
