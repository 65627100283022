import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import CustomizedEventContent from "./CustomizedEventContent";
import thLocale from "@fullcalendar/core/locales/th";
import { useMediaQuery } from "@mui/material";

const Calendar = ({
  events,
  setStartDate,
  setEndDate,
  eventClickHandler,
  datesSetHandler,
}) => {
  const eventDidMountHandler = (info) => {
    // info.el.style.borderRadius = "100px";
    info.el.style.padding = "4px 8px";
    info.el.style.marginBottom = "4px";
  };

  // const eventClickHandler = (info) => {
  //   const id = info.event.extendedProps.id;
  //   window.open(`${pathname}/${encodeURIComponent(id)}`, "_blank");
  // };

  // const datesSetHandler = (info) => {
  //   const { start, end } = info;
  //   // setStartDate(dateToUnix(start));
  //   // setEndDate(dateToUnix(end));
  //   console.log(info);
  // };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
      headerToolbar={
        isMobile
          ? {
              left: "prev,today,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }
          : {
              left: "today",
              center: "prev title next",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }
      }
      initialView="listMonth"
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      buttonText={{
        today: "วันนี้",
        month: "เดือน",
        week: "สัปดาห์",
        day: "วัน",
        list: "ลิสต์",
      }}
      events={events}
      eventDidMount={eventDidMountHandler}
      eventContent={(props) => <CustomizedEventContent {...props} />}
      eventClick={eventClickHandler}
      // eventBorderColor="#1E276C"
      eventBackgroundColor="#FFFFFF"
      locale={thLocale}
      // dayMaxEventRows={true} // for all non-TimeGrid views
      views={{
        week: {
          eventMaxStack: 3,
        },
        day: {
          eventMaxStack: 4,
        },
        listMonth: {
          listDayFormat: {
            day: "numeric",
            month: "short",
            year: "numeric",
          },
        },
        dayGridMonth: { dayMaxEvents: 5, dayMaxEventRows: 5 },
      }}
      datesSet={datesSetHandler}
      listDaySideFormat={false}
    />
  );
};

export default Calendar;
