import {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
  forwardRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { contactActions } from "../../../features/Contact/contact-slice";
import { Grid, Typography, Box, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Controller, useFieldArray } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GlobalService from "../../../services/Global";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckboxes";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedTab from "../../Custom/CustomizedTab";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import GeneralImageDropzone from "./GeneralImageDropzone";
import AddressTab from "./AddressTab/AddressTab";
import NewContactTab from "./NewContactTab/NewContactTab";
// import FinancialTab from "./FinancialTab/FinancialTab";
// import SpecificTab from "./SpecificTab/SpecificTab";
import AttachmentTab from "./AttachmentTab/AttachmentTab";
import SalesListTab from "./SalesListTab/SalesListTab";
import TagList from "../../UI/TagList";

const defaultContactChannelValues = {
  contact_channel: "เบอร์โทรศัพท์",
  contact_info_1: "",
  contact_info_2: "",
};

const ContactFormGeneral = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      isContactPage,
      viewOnly,
      isEditPage,
      watch,
      contactTypeError,
    },
    ref
  ) => {
    const { isInit, generalIsInit, contactSnapshot } = useSelector(
      (state) => state.contact
    );
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [contactTypeOptions, setContactTypeOptions] = useState([]);
    const [identityNoError, setIdentityNoError] = useState(false);
    const tab = searchParams.get(isContactPage ? "tab" : "contactTab");
    const { t } = useTranslation();

    const {
      fields: contactFields,
      append: appendContact,
      remove: removeContact,
    } = useFieldArray({
      control,
      name: "contact_channel_list",
    });

    const watchContactType = watch("contact_type_1");
    const contactTypeIsSelected = Boolean(watch("contact_type_1"));

    const watchContactFieldArray = watch("contact_channel_list");
    const controlledContactFields = contactFields.map((field, index) => {
      return { ...field, ...watchContactFieldArray[index] };
    });

    const memorizedCoperateTypes = useMemo(
      () => [
        {
          id: 1,
          label: t("contact.info.coperate_type.company"),
          value: "บริษัท",
        },
        {
          id: 2,
          label: t("contact.info.coperate_type.public_limited"),
          value: "บริษัทมหาชนจำกัด",
        },
        {
          id: 3,
          label: t("contact.info.coperate_type.limited_partnership"),
          value: "ห้างหุ้นส่วนจำกัด",
        },
        {
          id: 4,
          label: t("contact.info.coperate_type.juristic_partnership"),
          value: "ห้างหุ้นส่วนสามัญนิติบุคคล",
        },
        {
          id: 5,
          label: t("contact.info.coperate_type.association"),
          value: "สมาคม",
        },
        { id: 6, label: t("contact.info.coperate_type.other"), value: "อื่นๆ" },
      ],
      [t]
    );

    const memoriedNaturalPersonTypes = useMemo(
      () => [
        {
          id: 1,
          label: t("contact.info.natural_person_type.index"),
          value: "บุคคลธรรมดา",
        },
        {
          id: 2,
          label: t("contact.info.natural_person_type.ordinary_partnership"),
          value: "ห้างหุ้นส่วนสามัญ",
        },
        {
          id: 3,
          label: t("contact.info.natural_person_type.store"),
          value: "ร้านค้า",
        },
        {
          id: 4,
          label: t("contact.info.natural_person_type.entities"),
          value: "คณะบุคคล",
        },
      ],
      [t]
    );

    useEffect(() => {
      if (isInit) {
        setValue("contact_type_2", "");
        setValue("contact_name_2", "");
        setValue("initial", "");
      } else {
        dispatch(contactActions.initalizeGeneral());
      }
      switch (watchContactType) {
        case "นิติบุคคล":
          setContactTypeOptions(memorizedCoperateTypes);
          break;
        case "บุคคลธรรมดา":
          setContactTypeOptions(memoriedNaturalPersonTypes);
          break;
        default:
          break;
      }
    }, [
      watchContactType,
      memorizedCoperateTypes,
      memoriedNaturalPersonTypes,
      setValue,
      isInit,
      contactSnapshot,
      dispatch,
    ]);

    useEffect(() => {
      if (isInit && contactSnapshot) {
        if (contactSnapshot.contact_type_1 === "บุคคลธรรมดา") {
          setContactTypeOptions(memoriedNaturalPersonTypes);
          setValue("initial", contactSnapshot?.initial);
          setValue("contact_name_2", contactSnapshot?.contact_name_2);
        } else {
          setContactTypeOptions(memorizedCoperateTypes);
        }
        setValue("contact_type_2", contactSnapshot?.contact_type_2);
      }
    }, [
      isInit,
      dispatch,
      setValue,
      contactSnapshot,
      generalIsInit,
      memoriedNaturalPersonTypes,
      memorizedCoperateTypes,
    ]);

    const generateDocumentId = useCallback(async () => {
      if (viewOnly) {
        return;
      }
      try {
        const document_id = await GlobalService.getRunningDocumentId("contact");
        setValue("document_id", document_id);
      } catch (err) {
        console.log("Could not generate contact ID");
      }
    }, [setValue, viewOnly]);

    useEffect(() => {
      if (pathname === "/contact/add") {
        generateDocumentId();
      }
    }, [pathname, generateDocumentId]);

    const businessTypes = [
      {
        id: 1,
        label: t("contact.info.coperate_type.index"),
        value: "นิติบุคคล",
      },
      {
        id: 2,
        label: t("contact.info.natural_person_type.index"),
        value: "บุคคลธรรมดา",
      },
    ];

    const prefixes = [
      {
        id: 1,
        label: t("contact.contact_person.prefix.mr"),
        value: t("contact.contact_person.prefix.mr"),
      },
      {
        id: 2,
        label: t("contact.contact_person.prefix.mrs"),
        value: t("contact.contact_person.prefix.mrs"),
      },
      {
        id: 3,
        label: t("contact.contact_person.prefix.ms"),
        value: t("contact.contact_person.prefix.ms"),
      },

      {
        id: 4,
        label: t("contact.contact_person.prefix.sir"),
        value: t("contact.contact_person.prefix.sir"),
      },
      {
        id: 5,
        label: t("contact.contact_person.prefix.none"),
        value: t("contact.contact_person.prefix.none"),
      },
    ];

    const sources = [
      { id: 1, label: "Sales", value: "sales" },
      {
        id: 2,
        label: "Facebook",
        value: "facebook",
      },
      {
        id: 3,
        label: "Instagram",
        value: "instagram",
      },
      {
        id: 4,
        label: "Website",
        value: "website",
      },
      {
        id: 5,
        label: "Email",
        value: "email",
      },
      {
        id: 6,
        label: "Line@",
        value: "line@",
      },
      {
        id: 7,
        label: "โทรศัพท์ call in",
        value: "โทรศัพท์ call in",
      },
      {
        id: 8,
        label: "Marketplace",
        value: "marketplace",
      },
      {
        id: 9,
        label: "อื่นๆ",
        value: "อื่นๆ",
      },
    ];

    const contactChannels = [
      "เบอร์โทรศัพท์",
      "Email",
      "แฟกซ์",
      "Line",
      "Facebook",
      "Website",
      "Instagram",
      "อื่นๆ",
    ];

    const checkBoxField = [
      {
        name: "is_customer",
        label: t("contact.contact_type.customer"),
        xs: 12,
      },
      {
        name: "is_vendor",
        label: t("contact.contact_type.vendor"),
        xs: 12,
      },
    ];

    const radioLists = [
      { value: "active", label: "ใช้งาน" },
      { value: "inactive", label: "หยุดใช้งาน" },
    ];

    const tabs = [
      {
        label: t("contact.tab.address"),
        path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=address`,
      },
      {
        label: t("contact.tab.contact_person"),
        path: `${pathname}?${
          isContactPage ? "tab" : "contactTab"
        }=contact_person`,
      },
      // {
      //   label: t("contact.tab.financial"),
      //   path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=financial`,
      // },
      // {
      //   label: t("contact.tab.specific_info"),
      //   path: `${pathname}?${
      //     isContactPage ? "tab" : "contactTab"
      //   }=specific_info`,
      // },
      {
        label: t("contact.tab.related_external_document"),
        path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=attachment`,
      },
      {
        label: t("contact.tab.sale_list"),
        path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=sale_list`,
      },
    ];

    const renderCheckBox = (name, label) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomizedCheckboxes
            label={label}
            {...field}
            isDisabled={viewOnly}
            error={contactTypeError}
          />
        )}
      />
    );

    const renderCoperateType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-type"
              name="contact_type_2"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_type_2)}
                  helperText={errors.contact_type_2?.message}
                  label={t("contact.info.coperate_type.index")}
                  options={contactTypeOptions}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-brand"
              name="contact_name_1"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.contact_name_1)}
                  helperText={errors.contact_name_1?.message}
                  label={t("contact.info.brand_name")}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-taxpayer_id"
              name="identity_no"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.identity_no) || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  label={t("contact.info.taxpayer_id")}
                  {...field}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-authorized_capital"
              name="registered_capital"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  type="number"
                  error={Boolean(errors.registered_capital)}
                  helperText={errors.registered_capital?.message}
                  label={t("contact.info.authorized_capital")}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-source"
              name="contact_source"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_source)}
                  helperText={errors.contact_source?.message}
                  label={t("contact.info.source.index")}
                  options={sources}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
        </>
      );
    };

    const renderContactChannelAction = (type, info) => {
      if (!info) {
        return null;
      }
      let icon;
      let action;
      switch (type) {
        case "เบอร์โทรศัพท์":
        case "แฟกซ์":
          icon = <PhoneIcon />;
          action = () => {
            window.open(`tel:${info}`, "_self");
          };
          break;
        case "Email":
          icon = <EmailIcon />;
          action = () => {
            window.open("mailto:" + info, "_self");
          };
          break;
        case "Line":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://line.me/R/ti/p/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Facebook":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://facebook.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Website":
          icon = <PublicIcon />;
          action = () => {
            window.open(`https://${info}`, "_blank", "noopener,noreferrer");
          };
          break;
        case "Instagram":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://instagram.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        default:
          icon = null;
          action = () => {};
          break;
      }
      if (!icon) {
        return null;
      }
      return (
        <IconButton
          onClick={action}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {icon}
        </IconButton>
      );
    };

    const renderNaturalPersonType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-type"
              name="contact_type_2"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_type_2)}
                  helperText={errors.contact_type_2?.message}
                  label={t("contact.info.natural_person_type.index")}
                  options={contactTypeOptions}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-taxpayer_id"
              name="identity_no"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.identity_no) || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  label={t("contact.info.taxpayer_id")}
                  {...field}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-initial"
              name="initial"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.initial)}
                  helperText={errors.initial?.message}
                  label={t("contact.contact_person.prefix.index")}
                  options={prefixes}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-firstname"
              name="contact_name_1"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.contact_name_1)}
                  helperText={errors.contact_name_1?.message}
                  label={t("contact.contact_person.name.first_name")}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-lastname"
              name="contact_name_2"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.contact_name_2)}
                  helperText={errors.contact_name_2?.message}
                  label={t("contact.contact_person.name.last_name")}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-source"
              name="contact_source"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_source)}
                  helperText={errors.contact_source?.message}
                  label={t("contact.info.source.index")}
                  options={sources}
                  {...field}
                  disabled={viewOnly || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
        </>
      );
    };

    const renderContactChannels = () => {
      return controlledContactFields.map((item, index) => {
        return (
          <Grid item xs={12} key={item.id}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                order={{ xs: 2, md: 1 }}
              >
                <Controller
                  control={control}
                  error={errors}
                  name={`contact_channel_list.${index}.contact_channel`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("contact.contact_channel.type")}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }
                        return option === value;
                      }}
                      renderValue={(value) => {
                        return value;
                      }}
                      onChange={(e, value) => field.onChange(value)}
                      options={contactChannels}
                      disabled={viewOnly}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
                order={{ xs: 2, md: 3 }}
              >
                <Controller
                  name={`contact_channel_list.${index}.contact_info_1`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(
                        errors.contact_channel_list?.[index]?.contact_info_1
                      )}
                      helperText={
                        errors.contact_channel_list?.[index]?.contact_info_1
                          ?.message
                      }
                      label={t("contact.contact_channel.info")}
                      {...field}
                      disabled={viewOnly}
                    />
                  )}
                />
              </Grid>
              {!viewOnly && (
                <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeContact(index)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
              {viewOnly && (
                <Grid item xs={1} order={{ xs: 4 }}>
                  {renderContactChannelAction(
                    item.contact_channel,
                    item.contact_info_1
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      });
    };

    const renderTab = (tab) => {
      switch (tab) {
        case "address":
        case null:
          return (
            <AddressTab
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              viewOnly={viewOnly}
            />
          );
        case "contact_person":
          return (
            <NewContactTab
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              viewOnly={viewOnly}
            />
          );
        // case "financial":
        //   return (
        //     <FinancialTab
        //       control={control}
        //       errors={errors}
        //       setValue={setValue}
        //       getValues={getValues}
        //       viewOnly={viewOnly}
        //     />
        //   );
        // case "specific_info":
        //   return (
        //     <SpecificTab
        //       control={control}
        //       errors={errors}
        //       viewOnly={viewOnly}
        //     />
        //   );
        case "attachment":
          return (
            <AttachmentTab
              control={control}
              setValue={setValue}
              getValues={getValues}
              viewOnly={viewOnly}
            />
          );
        case "sale_list":
          return (
            <SalesListTab
              control={control}
              errors={errors}
              setValue={setValue}
              viewOnly={viewOnly}
            />
          );
        default:
          return;
      }
    };

    const currentTab = tab
      ? pathname + `?${isContactPage ? "tab" : "contactTab"}=${tab}`
      : pathname + `?${isContactPage ? "tab" : "contactTab"}=address`;

    return (
      <>
        <CustomizedBox
          boxShadow={!isContactPage ? "none" : undefined}
          margin={!isContactPage ? 0 : "2rem"}
          ref={ref}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
                  ประเภทผู้ติดต่อ
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      {checkBoxField.map((field) => (
                        <Fragment key={field.label}>
                          {renderCheckBox(field.name, field.label)}
                        </Fragment>
                      ))}
                    </Box>
                    {contactTypeError && (
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          sx={{ color: "error.main" }}
                          variant="caption"
                        >
                          กรุณาเลือกประเภทผู้ติดต่อ
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                  <Grid
                    item
                    xs={isEditPage ? 12 : 10}
                    sm={isEditPage ? 12 : 10}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <Controller
                      name="document_id"
                      control={control}
                      render={({ field }) => (
                        <CustomizedTextField
                          fullWidth
                          error={Boolean(errors.document_id)}
                          helperText={errors.document_id?.message}
                          label={t("contact.document_id")}
                          {...field}
                          disabled={viewOnly || isEditPage}
                        />
                      )}
                    />
                  </Grid>
                  {!isEditPage && (
                    <Grid item xs={2}>
                      <Box sx={{ ml: -1 }}>
                        <IconButton
                          onClick={generateDocumentId}
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CustomizedTooltip
                            title={"เรียกเลขที่ผู้ติดต่อใหม่"}
                            enterNextDelay={200}
                            noMaxWidth
                          >
                            <RestartAltOutlinedIcon />
                          </CustomizedTooltip>
                        </IconButton>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <GeneralImageDropzone
                  control={control}
                  setValue={setValue}
                  valueToSet={"img_url"}
                  filesLimit={1}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              ข้อมูลกิจการ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  name="contact_type_1"
                  control={control}
                  render={({ field }) => (
                    <CustomizedSelect
                      fullWidth
                      error={Boolean(errors.contact_type_1)}
                      helperText={errors.contact_type_1?.message}
                      label={t("contact.info.business_type")}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return "เลือกประเภทกิจการ";
                        }
                        return selected;
                      }}
                      options={businessTypes}
                      {...field}
                      disabled={viewOnly}
                    />
                  )}
                />
              </Grid>
              {watchContactType === "บุคคลธรรมดา"
                ? renderNaturalPersonType()
                : renderCoperateType()}
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_channel.index")}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomizedButton
                  title={"เพิ่มช่องทางติดต่อ"}
                  variant="outlined"
                  size="medium"
                  onClick={() =>
                    appendContact({ ...defaultContactChannelValues })
                  }
                  disabled={viewOnly}
                />
              </Grid>
              {renderContactChannels()}
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_status")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="contact_status"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <CustomizedRadioGroup
                        radioList={radioLists}
                        row
                        {...field}
                        disabled={viewOnly}
                      />
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  name="contact_status_remark"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.contact_status_remark)}
                      helperText={errors.contact_status_remark?.message}
                      label={t("contact.contact_status_remark")}
                      {...field}
                      disabled={viewOnly}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography fontWeight="bold" my={2} ml={1}>
                  {t("inventory.items.groupTagList")}
                </Typography>
              </Grid>
              <TagList
                control={control}
                name="tag_list"
                entity={"contact"}
                viewOnly={!isContactPage || viewOnly}
                title=""
              />
            </Grid>
          </Grid>
        </CustomizedBox>
        {!isContactPage && <Divider />}
        <CustomizedBox
          boxShadow={!isContactPage ? "none" : undefined}
          margin={!isContactPage ? 0 : "2rem"}
        >
          <Box sx={{ width: "100%", mt: -4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              divider
            />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {renderTab(tab)}
            </Grid>
          </Grid>
        </CustomizedBox>
        {!isContactPage && <Divider />}
        <CustomizedBox
          boxShadow={!isContactPage ? "none" : undefined}
          margin={!isContactPage ? 0 : "2rem"}
        >
          <Typography fontWeight="bold" marginBottom={1} marginLeft={1}>
            หมายเหตุ
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Controller
                name="remark"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors.remark)}
                    helperText={errors.remark?.message}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      </>
    );
  }
);

export default ContactFormGeneral;
