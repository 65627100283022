import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const StepperUI = ({ steps, activeStep, errorHandler, error }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          const labelProps = {};
          if (errorHandler(index)) {
            labelProps.error = true;
            labelProps.icon = <CancelIcon color="error" />;
          }
          return (
            <Step key={step.value}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default StepperUI;
