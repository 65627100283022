import moment from "moment";
import * as Yup from "yup";
// import SalesService from "../../../../services/Sales";

export const purchaseRequestSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  reference_document_list: [],
  destination_warehouse_document_id: "",
  created_date: moment(),
  issue_date: moment(),
  due_date: moment().add(7, "days"),
  employee_list: [],
};

export const purchaseOrderSchema = {
  id: null,
  document_id: "",
  destination_warehouse_document_id: "",
  reference_document_list: [],
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  due_date: moment().add(7, "days"),
  expect_date: moment().add(7, "days"),
  credit_day: "",
  employee_list: [],
};

export const purchaseInvoiceSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  destination_warehouse_document_id: "",
  reference_document_id: [],
  created_date: moment(),
  issue_date: moment(),
  due_date: moment().add(7, "days"),
  credit_day: "",
  employee_list: [],
};

export const paymentMadeSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  reference_document_id: [],
  created_date: moment(),
  issue_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const purchaseReturnSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  destination_warehouse_document_id: "",
  reference_document_id: [],
  created_date: moment(),
  issue_date: moment(),
  delivery_date: moment().add(7, "days"),
  credit_day: "",
  employee_list: [],
};

export const deliveryOrderSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  delivery_date: moment().add(7, "days"),
  employee_list: [],
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const purchaseRequestValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .required("กรุณาระบุวันที่ต้องการ")
    .nullable()
    .transform((value) => dateIsValid(value)),
});

export const purchaseOrderValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .required("กรุณาระบุต้องการภายในวันที่")
    .nullable()
    .transform((value) => dateIsValid(value)),
  expect_date: Yup.date()
    .required("กรุณาระบุวันประมาณการณ์สินค้าเข้า")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const purchaseInvoiceValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .required("กรุณาระบุวันที่ใช้ได้ถึง")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const paymentReceiptValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const purchaseReturnValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const deliveryOrderValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  delivery_date: Yup.date()
    .required("กรุณาระบุวันกำหนดส่งของ")
    .nullable()
    .transform((value) => dateIsValid(value)),
});
