import { Avatar, Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../utils/date-converter";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";

const TraceEntryList = ({
  nestIndex,
  control,
  disabled,
  errors,
  totalError,
  setTotalError,
  watchWarehouse,
}) => {
  const { fields, remove, replace } = useFieldArray({
    control,
    name: `goods_receive_list[${nestIndex}].stock_entry_list.trace_entry_list`,
    keyName: "genId",
  });
  // const watchType = useWatch({ control, name: "type" });
  const watchTotalItemFromDocument = useWatch({
    control,
    name: `goods_receive_list[${nestIndex}].posted_quantity`,
  });

  const watchAlreadyPostedItemFromDocument = useWatch({
    control,
    name: `goods_receive_list[${nestIndex}].already_posted_quantity`,
  });

  const watchQuantity = useWatch({
    control,
    name: `goods_receive_list[${nestIndex}].stock_entry_list.trace_entry_list`,
  });

  const watchStockEntry = useWatch({
    control,
    name: `goods_receive_list[${nestIndex}].stock_entry_list`,
  });

  const totalQuantity =
    watchQuantity?.reduce(
      (prev, curr) => prev + parseInt(curr?.posted_quantity),
      0
    ) || 0;

  // const watchTraceEntry = useWatch({
  //   control,
  //   name: `goods_receive_list[${nestIndex}].stock_entry_list.trace_entry_list`,
  // });

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  useEffect(() => {
    if (
      totalQuantity >
      watchTotalItemFromDocument - watchAlreadyPostedItemFromDocument
    ) {
      setTotalError(true);
    } else {
      setTotalError(false);
    }
  }, [
    setTotalError,
    totalQuantity,
    watchTotalItemFromDocument,
    watchAlreadyPostedItemFromDocument,
  ]);

  useEffect(() => {
    if (!disabled) replace([]);
  }, [replace, watchWarehouse, disabled]);

  return (
    <>
      {fields.map((trace, index) => (
        <TableRow key={trace.genId}>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.posted_date
                ? typeof trace.posted_date === "string"
                  ? formatDateTime(trace.posted_date)
                  : unixToDateTimeWithFormat(trace.posted_date)
                : null
            )}
          </TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {disabled ? (
              Math.abs(trace.posted_quantity).toLocaleString()
            ) : (
              <Box width={125}>
                <Controller
                  control={control}
                  name={`goods_receive_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`}
                  render={({ field }) => (
                    <CustomizedTextField
                      {...field}
                      disabled={false}
                      error={
                        errors &&
                        errors?.goods_receive_list &&
                        errors?.goods_receive_list[nestIndex]?.stock_entry_list
                          ?.trace_entry_list[index]?.posted_quantity
                          ? Boolean(
                              errors?.goods_receive_list[nestIndex]
                                ?.stock_entry_list?.trace_entry_list[index]
                                ?.posted_quantity
                            )
                          : totalError
                      }
                      type="number"
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      helperText={
                        errors &&
                        errors?.goods_receive_list &&
                        errors?.goods_receive_list[nestIndex]?.stock_entry_list
                          ?.trace_entry_list[index]?.posted_quantity
                          ? errors?.goods_receive_list[nestIndex]
                              ?.stock_entry_list?.trace_entry_list[index]
                              ?.posted_quantity.message
                          : totalError
                          ? `กรุณาระบุจำนวนรับคืนไม่เกินจำนวนรอรับคืน`
                          : ""
                      }
                    />
                  )}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(watchStockEntry.uom.name)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.serial_number)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.source_bin_location_document_id)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.created_by?.img_url ? (
                <Avatar alt="img_url" src={trace.created_by?.img_url} />
              ) : (
                <CustomizedLetterAvatar
                  name={
                    trace.created_by?.first_name +
                    " " +
                    trace.created_by?.last_name
                  }
                />
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.batch_number)}
          </TableCell>
          <TableCell align="center">
            {disabled ? null : (
              <IconButton onClick={() => remove(index)} disabled={false}>
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TraceEntryList;
