import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HistoryIcon from "@mui/icons-material/History";
import ReceiveDetail from "./Detail";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  approveGoodsReceive,
  cancelGoodsReceive,
  createGoodsReceive,
  getGoodsReceiveById,
  updateGoodsReceive,
} from "../../../features/Inventory/GoodsReceive/goodsReceive-actions";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RightDrawer from "../../../components/UI/RightDrawer";
import GlobalService from "../../../services/Global";
import GoodsReceiveTable from "../../../components/Table/DocumentTable/GoodsReceiveTable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import { goodsReceiveActions } from "../../../features/Inventory/GoodsReceive/goodsReceive-slice";
import { getAllItemsAggrid } from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { formatGRPayload } from "../../../utils/dataTransformer";
import InventoryService from "../../../services/Inventory";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import { dateToUnix, formatDate } from "../../../utils/date-converter";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { exportAsExcelBarcode } from "../../../utils/exporter";
import moment from "moment";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GoodsReceiveContainer = ({ status }) => {
  // const gridRef = useRef();
  const scrollRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { item, isLoading } = useSelector((state) => state.goodsReceive);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [documentStage, setDocumentStage] = useState(0);
  const [createDateIsOpen, setCreateDateIsOpen] = useState(false);
  const [receiveDateIsOpen, setReceiveDateIsOpen] = useState(false);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [filterIsScan, setFilterIsScan] = useState("แสดงทั้งหมด");
  const [currentTab, setCurrentTab] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [optionsLocation, setOptionsLocation] = useState("");
  const snCount = useRef(1);
  const [showCamera, setShowCamera] = useState(false);
  const [showError, setShowError] = useState({
    type: "success",
    value: true,
    text: "awdascascawdaw dapowdk a; mdl",
  });
  const [scanData, setScanData] = useState("");
  const [scanDataCurr, setScanDataCurr] = useState("");
  const [isInterVal, setIsInterval] = useState(false);
  const { state } = useLocation();
  const { createPermission, cancelPermission } = usePermission();

  useEffect(() => {
    if (currentTab) {
      setShowCamera(false);
    }
  }, [currentTab]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const watchList = useWatch({ control, name: "goods_receive_list" });
  const watchType = useWatch({ control, name: "type" });
  const watchRefDoc = useWatch({ control, name: "reference_document_id" });
  const watchWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  useEffect(() => {
    if (state) {
      if (state.reference_document_type === "purchase_order") {
        const formatValue = state.item_list.map((item) => {
          return {
            initial_quantity: item.qty,
            posted_quantity: item.qty,
            posted_value: 0,
            already_posted_quantity: item.received_qty ?? 0,
            stock_entry_list: {
              item_uid: item.uid,
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: {
                document_id: item.item_document_id,
                name: item.item_name,
                description: item.item_dscription,
                quantity: item.qty,
                ...item,
              },
              posted_date: dateToUnix(new Date()),
              posted_quantity: item.qty,
              posted_value: 0,
              reference_document_id: state.document_id,
              reference_document_type: "receive",
              source_warehouse_document_id:
                state.destination_warehouse_document_id,
              trace_entry_list: [],
              created_by: user,
              uom: item.uom,
            },
          };
        });
        setValue("goods_receive_list", formatValue);
        setValue("type", "ซื้อ");
        setValue(
          "source_warehouse_document_id",
          state.destination_warehouse_document_id
        );
      } else if (state.reference_document_type === "manufacture_order") {
        const formatValue = [
          {
            initial_quantity: state.actual_production_qty,
            posted_quantity:
              state.actual_production_qty - state.goods_receive_qty,
            posted_value: 0,
            already_posted_quantity: state.goods_receive_qty ?? 0,
            stock_entry_list: {
              item_uid: uuidv4(),
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: {
                document_id: state.item_document_id,
                name: state.item_name,
                description: state.item_dscription,
              },
              posted_date: dateToUnix(new Date()),
              posted_quantity:
                state.actual_production_qty - state.goods_receive_qty,
              posted_value: 0,
              reference_document_id: state.document_id,
              reference_document_type: state.reference_document_type,
              source_warehouse_document_id: "",
              trace_entry_list: [],
              created_by: user,
              uom: { document_id: state.stock_uom, name: state.stock_uom },
            },
          },
        ];
        setValue("goods_receive_list", formatValue);
        setValue("type", "ผลิต");
      }
      setValue("reference_document_id", state.document_id);
    }
  }, [setValue, state, user]);

  const {
    control: barcodeControl,
    handleSubmit: handleBarcodeSubmit,
    getValues: getValuesBarcode,
    setValue: setValueBarcode,
  } = useForm({
    defaultValues: {
      barcode: "",
      source_bin_location_document_id: "",
    },
  });

  useEffect(() => {
    dispatch(getAllLocation());
    if (id) {
      dispatch(
        getGoodsReceiveById({
          uniqueInput: { document_id: id },
        })
      );
    }
    return () => dispatch(goodsReceiveActions.resetItem());
  }, [dispatch, id]);

  const generateDocumentId = useCallback(async () => {
    const newDocId = await GlobalService.getRunningDocumentId("goods_receive");
    setValue("document_id", newDocId);
  }, [setValue]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (id) {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "status") {
          if (value === "draft") setDocumentStage(1);
          if (
            value === "finished" ||
            value === "approved" ||
            value === "cancelled"
          )
            setDocumentStage(2);
        }
        if (key === "goods_receive_list") {
          value.forEach((list) =>
            list.stock_entry_list.trace_entry_list.forEach(
              (_) => (snCount.current = snCount.current + 1)
            )
          );
        }
        setValue(key, value);
      });
    } else {
      setValue("lot", new Date());
      setValue("created_date", moment());
      setValue("document_date", moment().endOf("day"));
      generateDocumentId();
    }
  }, [generateDocumentId, id, item, setValue]);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.receive.index"),
      to: "/inventory/receive",
    },
    {
      name: id ?? t("inventory.receive.add"),
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: {
              ...request.filterModel,
              document_id: {
                ...request.filterModel.document_id,
                mode: "insensitive",
              },
              name: {
                ...request.filterModel.name,
                mode: "insensitive",
              },
              is_stockable: {
                filterType: "boolean",
                type: "equals",
                filter: "true",
              },
              is_active: {
                filterType: "boolean",
                type: "equals",
                filter: "true",
              },
            },
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params) => {
    return params.data.item_document_id || params.data.document_id;
  }, []);

  const createDoc = async (data) => {
    const serializedData = await formatGRPayload(data, true);
    await serializedData.goods_receive_list.forEach((list) => {
      list.stock_entry_list.trace_entry_list.forEach(async (teList) => {
        try {
          await InventoryService.createSerialNumber({
            serial_number: teList.serial_number,
          });
          await InventoryService.createBatchNumber({
            batch_number: teList.batch_number,
            item_document_id: list.stock_entry_list.item_document_id,
          });
        } catch (error) {
          try {
            await InventoryService.createBatchNumber({
              batch_number: teList.batch_number,
              item_document_id: list.stock_entry_list.item_document_id,
            });
          } catch (error) {}
        }
      });
    });
    dispatch(
      createGoodsReceive(serializedData, enqueueSnackbar, navigate, user)
    );
  };

  const updateDoc = async (data) => {
    const serializedData = await formatGRPayload(data, false);
    await serializedData.goods_receive_list.forEach((list) => {
      list.stock_entry_list.trace_entry_list.forEach(async (teList) => {
        try {
          await InventoryService.createSerialNumber({
            serial_number: teList.serial_number,
          });
          await InventoryService.createBatchNumber({
            batch_number: teList.batch_number,
            item_document_id: list.stock_entry_list.item_document_id,
          });
        } catch (error) {
          try {
            await InventoryService.createBatchNumber({
              batch_number: teList.batch_number,
              item_document_id: list.stock_entry_list.item_document_id,
            });
          } catch (error) {}
        }
      });
    });
    const payload = {
      uniqueInput: { document_id: serializedData.document_id },
      updateInput: serializedData,
    };

    //delete document id for update payload
    delete payload.updateInput.document_id;
    dispatch(updateGoodsReceive(payload, enqueueSnackbar, user));
  };

  // const moType = ["ผลิต", "แปรรูป"];

  const updateWithApprove = async (data, payload) => {
    const serializedData = await formatGRPayload(data, false, true);
    await serializedData.goods_receive_list.forEach((list) => {
      list.stock_entry_list.trace_entry_list.forEach(async (teList) => {
        try {
          await InventoryService.createSerialNumber({
            serial_number: teList.serial_number,
          });
          await InventoryService.createBatchNumber({
            batch_number: teList.batch_number,
            item_document_id: list.stock_entry_list.item_document_id,
          });
        } catch (error) {
          try {
            await InventoryService.createBatchNumber({
              batch_number: teList.batch_number,
              item_document_id: list.stock_entry_list.item_document_id,
            });
          } catch (error) {
            console.log("already have batch");
          }
          console.log("already have serial");
        }
      });
    });
    const payloadUpdate = {
      uniqueInput: { document_id: serializedData.document_id },
      updateInput: serializedData,
    };
    //delete document id for update payload
    delete payloadUpdate.updateInput.document_id;
    dispatch(
      approveGoodsReceive(
        payload,
        payloadUpdate,
        enqueueSnackbar,
        navigate,
        user
      )
    );
    // if (moType.includes(serializedData.type))
    //   serializedData.goods_receive_list.forEach(async (goods) => {
    //     goods.stock_entry_list.trace_entry_list.forEach(async (trace) => {
    //       if (trace.is_scanned)
    //         await InventoryService.updateItemQuantity(
    //           goods.stock_entry_list.item_document_id,
    //           {
    //             current_ordered_manufacture_qty: {
    //               decrement: trace.posted_quantity,
    //             },
    //           }
    //         );
    //     });
    //   });
  };

  const onSubmit = async (data, e) => {
    if (documentStage === 0 && !id) {
      if (
        data.goods_receive_list.some(
          (list) => list.stock_entry_list.trace_entry_list.length > 0
        )
      )
        await createDoc(data);
      else
        return enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้าอย่างน้อย 1 ตัว", {
          variant: "error",
        });
    }
    if (documentStage === 0 && id) {
      setDocumentStage((prev) => prev + 1);
    }
    if (documentStage === 1) {
      await updateDoc(data);
    }
  };

  const onSubmitWithApprove = async (data) => {
    const payload = {
      uniqueInput: {
        document_id: data.document_id,
      },
    };
    await updateWithApprove(data, payload, user);
  };

  useEffect(() => {
    if (
      !(
        errors &&
        Object.keys(errors).length === 0 &&
        Object.getPrototypeOf(errors) === Object.prototype
      )
    ) {
      if (errors?.goods_receive_list?.message) {
        enqueueSnackbar("กรุณาเพิ่มรายการสินค้า", {
          variant: "error",
        });
      } else if (
        errors?.goods_receive_list?.some(
          (goods) => goods.stock_entry_list.trace_entry_list.message
        )
      ) {
        enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", {
          variant: "error",
        });
      }
    }
  }, [errors, enqueueSnackbar]);

  const handleClickExport = () => {
    const lot = getValues("lot");
    const initailValue = getValues("goods_receive_list");
    const documentId = getValues("document_id");
    let formatValue = [];
    initailValue.forEach((list) =>
      list.stock_entry_list.trace_entry_list.forEach((teList) =>
        formatValue.push({
          รหัสสินค้า: list.stock_entry_list.item.document_id,
          ชื่อสินค้า: list.stock_entry_list.item.name,
          // รายละเอียดสินค้า: list.stock_entry_list.item.description,
          SN: teList.serial_number,
          Lot: formatDate(lot),
          Barcode: teList.batch_number,
          Qty: teList.posted_quantity,
          uom: list.stock_entry_list.uom?.name,
        })
      )
    );
    exportAsExcelBarcode(formatValue, "Barcode" + documentId);
    enqueueSnackbar("นำออกBarcodeสำเร็จ", {
      variant: "success",
    });
  };

  const handleClickPrintBarcode = (allValues, isPrinting) => {
    const lot = getValues("lot");
    const formatValues = allValues.map((value) => ({
      productId: value.item.document_id,
      productName: value.item.name,
      productDescription: value.item.description,
      productSn: value.serial_number,
      productLot: formatDate(lot),
      productQty: value.posted_quantity,
      productUom: value.uom.name,
      productBarcode: value.batch_number,
    }));

    if (formatValues && formatValues.length > 0) {
      localStorage.setItem("barcode-list", JSON.stringify(formatValues));
    }

    if (!isPrinting) window.open("/print-barcode", "_blank", "noreferrer");
    else
      axios
        .post(`http://localhost:4000/print`, formatValues)
        .then((res) => {
          if (res.status === "success")
            enqueueSnackbar("พิมพ์สำเร็จ", {
              variant: "success",
            });
          else if (res.status === "error")
            enqueueSnackbar("พบการขัดข้องในการพิมพ์", {
              variant: "error",
            });
        })
        .catch((err) => {
          enqueueSnackbar("ไม่สามารถเชื่อมต่อกับเครื่องพิมพ์", {
            variant: "error",
          });
        });
  };

  const handleClickChangeFilter = (e) => {
    setFilterIsScan(e.target.textContent);
  };

  const handleCancelDocument = () => {
    const initailValue = getValues("document_id");
    const input = {
      document_id: initailValue,
    };
    dispatch(cancelGoodsReceive(input, enqueueSnackbar, user));
  };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const setValueTraceEntryHandler = useCallback(
    (index, subIndex, name, value) => {
      if (name)
        return setValue(
          `goods_receive_list[${index}].stock_entry_list.trace_entry_list[${subIndex}].${name}`,
          value
        );
      else
        return setValue(
          `goods_receive_list[${index}].stock_entry_list.trace_entry_list`,
          value
        );
    },
    [setValue]
  );

  const checkSmallScreenError = useCallback(
    (type, text) => {
      return !isSmallScreen
        ? enqueueSnackbar(text, {
            variant: type,
          })
        : setShowError({ type: type, value: true, text });
    },
    [enqueueSnackbar, isSmallScreen]
  );

  const onBarcodeSubmitHandler = useCallback(
    async (value) => {
      //BARCODE11012020TEST
      const serialNumber = value
        .split("#", 4)
        .filter((_, index) => index !== 0)
        .join("#");
      const itemList = getValues("goods_receive_list");
      const allTe = [];
      itemList.forEach((list, index) =>
        list.stock_entry_list.trace_entry_list.forEach((teList, subIndex) => {
          allTe.push({
            ...teList,
            seIndex: index,
            teIndex: subIndex,
          });
        })
      );
      const findIsHave = allTe.find((te) => te.batch_number === value);
      if (findIsHave) {
        if (findIsHave.is_scanned)
          return checkSmallScreenError(
            "error",
            "QR/Barcode สินค้านี้ถูกสแกนและบันทึกลงรายการแล้ว"
          );
        else {
          setValueTraceEntryHandler(
            findIsHave.seIndex,
            findIsHave.teIndex,
            "is_scanned",
            true
          );
          setValueTraceEntryHandler(
            findIsHave.seIndex,
            findIsHave.teIndex,
            "scanned_date",
            new Date()
          );
          setValueTraceEntryHandler(
            findIsHave.seIndex,
            findIsHave.teIndex,
            "source_bin_location_document_id",
            getValuesBarcode("source_bin_location_document_id")
          );
          setValueTraceEntryHandler(
            findIsHave.seIndex,
            findIsHave.teIndex,
            "scanned_by",
            user
          );
          setValueTraceEntryHandler(
            findIsHave.seIndex,
            findIsHave.teIndex,
            "posted_date",
            dateToUnix(new Date())
          );
          checkSmallScreenError(
            "success",
            `สแกน SN: ${serialNumber} สำเร็จ จำนวน ${
              allTe.filter((te) => te.is_scanned).length + 1
            }/${allTe.length}`
          );
        }
      } else {
        checkSmallScreenError(
          "error",
          "QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่"
        );
      }
      setValueBarcode("barcode", "");
    },
    [
      checkSmallScreenError,
      getValues,
      getValuesBarcode,
      setValueBarcode,
      setValueTraceEntryHandler,
      user,
    ]
  );

  useEffect(() => {
    if (showError.value) {
      setTimeout(() => {
        setShowError({ type: "success", value: false, text: "" });
      }, 1.5 * 1000);
    }
  }, [showError]);

  const getBinLocations = useCallback(async () => {
    const input = {
      where: {
        warehouse: {
          document_id: {
            equals: watchWarehouse,
          },
        },
      },
    };
    const result = await InventoryService.findBinLocations(input);
    const newValue = result.map((res) => {
      return {
        ...res,
        label: res.document_id,
        value: res.document_id,
      };
    });
    setOptionsLocation(newValue);
  }, [watchWarehouse]);

  useEffect(() => {
    getBinLocations();
  }, [getBinLocations]);

  const watchBinLocation = useWatch({
    control: barcodeControl,
    name: "source_bin_location_document_id",
  });

  const tabError =
    errors &&
    (errors?.document_id ||
      errors?.type ||
      errors?.reference_document_id ||
      errors?.lot ||
      errors?.source_warehouse_document_id ||
      errors?.remark);

  useEffect(() => {
    if (isInterVal && scanData !== scanDataCurr) {
      setScanDataCurr(scanData);
      onBarcodeSubmitHandler(scanData);
      setTimeout(() => {
        setIsInterval(false);
        setScanDataCurr("");
      }, 2000);
    }
  }, [isInterVal, onBarcodeSubmitHandler, scanData, scanDataCurr]);

  // const debouncedChangeHandler = useCallback(
  //   (result) => debounce(onBarcodeSubmitHandler(result), 1500),
  //   [onBarcodeSubmitHandler]
  // );

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  return (
    <>
      <form
        noValidate
        onKeyDown={(e) => {
          if (e.code === "Enter") e.preventDefault();
        }}
        ref={scrollRef}
      >
        {!isSmallScreen ? (
          <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                <Typography variant="h5">
                  {t("inventory.receive.goodsReceiveList")}
                </Typography>
                <CustomizedStatus status={item.status} />
              </Box>
              <CustomizedTooltip title="ดูการเคลื่อนไหว">
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
                </IconButton>
              </CustomizedTooltip>
              <RightDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                title={t("inventory.activity")}
                documentId={id}
                documentType="goods_receive"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: "2rem",
              }}
            >
              <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
                <Typography>{t("inventory.receive.importedBy")}</Typography>
                <CustomizedAvatar
                  avatars={
                    item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                  }
                />
              </Box>
              <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  error={errors.created_date}
                  isOpen={createDateIsOpen}
                  onClose={() => setCreateDateIsOpen(false)}
                  onOpen={() => setCreateDateIsOpen(true)}
                  label={t("inventory.stockEntry.createdDate")}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  disabled
                />
                <ControlledDatePicker
                  name="document_date"
                  control={control}
                  error={errors.document_date}
                  isOpen={receiveDateIsOpen}
                  onClose={() => setReceiveDateIsOpen(false)}
                  onOpen={() => setReceiveDateIsOpen(true)}
                  label={t("inventory.receive.receiveDate")}
                  disabled={Boolean(id)}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  required
                />
                {documentStage === 1 ? (
                  <CustomizedMenuOption
                    label="ตัวเลือก"
                    options={[
                      {
                        value: "ยกเลิก",
                        disabled: !cancelPermission,
                      },
                    ]}
                    size="medium"
                    onSelect={handleCancelDocument}
                  />
                ) : null}
              </Box>
            </Box>
            <ReceiveDetail
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              getValues={getValues}
              viewOnly={documentStage > 0 || isLoading.item}
              disabled={id && !createPermission}
              documentStage={documentStage}
              watchType={watchType}
              watchRefDoc={watchRefDoc}
              generateDocumentId={generateDocumentId}
              isInventoryPage
            />
            <CustomizedBox>
              {documentStage !== 0 ? (
                <Box display={"flex"} justifyContent="space-between">
                  <Typography sx={{ fontWeight: 700, mb: 3 }}>
                    {documentStage === 1 ? "สแกน Barcode" : null}
                  </Typography>
                  <Typography sx={{ fontWeight: 700, mb: 3 }}>
                    <CustomizedMenuOption
                      label="นำออก Barcode"
                      options={["นำออก Excel", "พิมพ์ทั้งหมด"]}
                      // options={["นำออก Excel", "พิมพ์ PDF", "พิมพ์ทั้งหมด"]}
                      size="medium"
                      onSelect={(e) => {
                        const value = e.target;
                        let allValues = [];
                        switch (value.innerText) {
                          case "นำออก Excel":
                            handleClickExport();
                            break;
                          // case "พิมพ์ทั้งหมด":
                          //   getValues("goods_receive_list").forEach((list) => {
                          //     list.stock_entry_list.trace_entry_list.forEach(
                          //       (teList) =>
                          //         allValues.push({
                          //           ...teList,
                          //           uom: list.stock_entry_list.uom,
                          //         })
                          //     );
                          //   });
                          //   handleClickPrintBarcode(allValues, true);
                          //   break;
                          case "พิมพ์ทั้งหมด":
                            getValues("goods_receive_list").forEach((list) => {
                              list.stock_entry_list.trace_entry_list.forEach(
                                (teList) => {
                                  allValues.push({
                                    ...teList,
                                    uom: list.stock_entry_list.uom,
                                  });
                                }
                              );
                            });
                            handleClickPrintBarcode(allValues);
                            break;
                          default:
                            console.log("print");
                        }
                      }}
                    />
                  </Typography>
                </Box>
              ) : null}
              {Boolean(id) && documentStage === 1 ? (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={!isSmallScreen ? 3 : 12}>
                    <Controller
                      control={barcodeControl}
                      name="source_bin_location_document_id"
                      render={({ field }) => (
                        <CustomizedComboBox
                          {...field}
                          options={optionsLocation}
                          label={t("inventory.chooseLocation")}
                          onChange={(_, newValue) =>
                            field.onChange(newValue ? newValue.value : "")
                          }
                          disabled={
                            isLoading.item || Boolean(id && !createPermission)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={barcodeControl}
                      name="barcode"
                      render={({ field }) => (
                        <CustomizedTextField
                          {...field}
                          label={t("inventory.scanBarcode")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && e.shiftKey === false) {
                              const data = e.target.value;
                              handleBarcodeSubmit(onBarcodeSubmitHandler(data));
                            }
                          }}
                          disabled={isLoading.item || watchBinLocation === ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ) : null}
              <GoodsReceiveTable
                rowData={watchList}
                documentStage={documentStage}
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                onGridReady={onGridReady}
                disabled={isLoading.item}
                isUpdate={Boolean(id)}
                filterIsScan={filterIsScan}
                watchType={watchType}
                watchWarehouse={watchWarehouse}
                getRowId={getRowId}
                snCount={snCount}
                handleClickPrintBarcode={handleClickPrintBarcode}
              />
            </CustomizedBox>
            <Box display="flex" gap={1}>
              {documentStage === 0 && (
                <CustomizedButton
                  variant="contained"
                  title="ถัดไป"
                  onClick={handleSubmit(onSubmit)}
                  // type="submit"
                  disabled={isLoading.item}
                />
              )}
              {documentStage === 1 && createPermission && (
                <Box display="flex" gap={1}>
                  <CustomizedButton
                    onClick={() => {
                      setDocumentStage(documentStage - 1);
                    }}
                    variant="outlined"
                    title="ย้อนกลับ"
                    disabled={isLoading.item}
                  />
                  <CustomizedButton
                    id="update"
                    onClick={handleSubmit(onSubmit)}
                    variant="outlined"
                    title="บันทึกร่าง"
                    // type="submit"
                    disabled={isLoading.item}
                  />
                  <CustomizedButton
                    id="updateWithApprove"
                    onClick={handleSubmit(onSubmitWithApprove)}
                    variant="contained"
                    title="นำเข้า"
                    disabled={isLoading.item}
                    // type="submit"
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <Box my={3}>
              <Tabs
                centered
                value={currentTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor:
                      tabError && currentTab === 0 ? "#F44336" : "#419644",
                  },
                }}
              >
                <Tab
                  label="ข้อมูลนำเข้า"
                  {...a11yProps(0)}
                  sx={{
                    "&.Mui-selected": {
                      color: tabError ? "#F44336" : "#419644",
                    },
                    color: tabError ? "#F44336" : "#419644",
                  }}
                />
                <Tab label="สแกน Barcode" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              <Box
                sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
              >
                <Box
                  sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                >
                  <Typography variant="h5">
                    {t("inventory.receive.goodsReceiveList")}
                  </Typography>
                  <CustomizedStatus status={item.status} />
                </Box>
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
                </IconButton>
                <RightDrawer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  title={t("inventory.activity")}
                  documentId={id}
                  documentType="goods_receive"
                />
              </Box>
              <Box
                display={"flex"}
                sx={{ alignItems: "center", gap: 1 }}
                mt={3}
              >
                <ControlledDatePicker
                  name="created_date"
                  control={control}
                  error={errors.created_date}
                  isOpen={createDateIsOpen}
                  onClose={() => setCreateDateIsOpen(false)}
                  onOpen={() => setCreateDateIsOpen(true)}
                  label={t("inventory.stockEntry.createdDate")}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  disabled
                />
                <ControlledDatePicker
                  name="document_date"
                  control={control}
                  error={errors.document_date}
                  isOpen={receiveDateIsOpen}
                  onClose={() => setReceiveDateIsOpen(false)}
                  onOpen={() => setReceiveDateIsOpen(true)}
                  label={t("inventory.receive.receiveDate")}
                  disabled={Boolean(id)}
                  sx={{ width: { sx: "auto", md: 155 } }}
                  required
                />
              </Box>
              <Box
                display={"flex"}
                sx={{ alignItems: "center", gap: 1 }}
                my={3}
              >
                <Typography>ผู้สร้าง</Typography>
                <CustomizedAvatar
                  avatars={
                    item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                  }
                />
              </Box>
              <ReceiveDetail
                control={control}
                errors={errors}
                reset={reset}
                setValue={setValue}
                getValues={getValues}
                viewOnly={documentStage > 0 || isLoading.item}
                documentStage={documentStage}
                disabled={id && !createPermission}
                watchType={watchType}
                watchRefDoc={watchRefDoc}
                generateDocumentId={generateDocumentId}
                isInventoryPage
              />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <CustomizedMenuOption
                    fullWidth
                    label={filterIsScan}
                    options={["แสดงทั้งหมด", "สแกนแล้ว", "รอสแกน"]}
                    size="medium"
                    onSelect={handleClickChangeFilter}
                  />
                </Grid>
                <Grid item xs={6}>
                  {documentStage === 1 ? (
                    <CustomizedMenuOption
                      fullWidth
                      label="ตัวเลือก"
                      options={["ยกเลิก"]}
                      size="medium"
                      onSelect={handleCancelDocument}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Box mt={2}>
                {Boolean(id) && documentStage === 1 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={!isSmallScreen ? 3 : 12}>
                      <Controller
                        control={barcodeControl}
                        name="source_bin_location_document_id"
                        render={({ field }) => (
                          <CustomizedComboBox
                            {...field}
                            options={optionsLocation}
                            label={t("inventory.chooseLocation")}
                            onChange={(_, newValue) =>
                              field.onChange(newValue ? newValue.value : "")
                            }
                            disabled={
                              isLoading.item || Boolean(id && !createPermission)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={!isSmallScreen ? 3 : 12}>
                      <Box display="flex" gap={1} alignItems="center">
                        <Controller
                          control={barcodeControl}
                          name="barcode"
                          render={({ field }) => (
                            <CustomizedTextField
                              {...field}
                              label={t("inventory.scanBarcode")}
                              onKeyDown={(e) => {
                                // e.preventDefault();
                                if (e.key === "Enter" && e.shiftKey === false) {
                                  const data = e.target.value;
                                  handleBarcodeSubmit(
                                    onBarcodeSubmitHandler(data)
                                  );
                                }
                              }}
                              disabled={
                                isLoading.item || watchBinLocation === ""
                              }
                            />
                          )}
                        />
                        {isSmallScreen ? (
                          <IconButton
                            disabled={isLoading.item || watchBinLocation === ""}
                            onClick={() => setShowCamera(!showCamera)}
                          >
                            <QrCodeScannerIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                      {showError.value && (
                        <Box
                          sx={(theme) => ({
                            backgroundColor:
                              showError.type === "success"
                                ? theme.palette.success.light
                                : theme.palette.error.light,
                            width: "maxContent",
                            height: 47,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          })}
                          mt={1}
                          display="flex"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                showError.type === "success"
                                  ? "#1E4620"
                                  : "#621B16",
                            }}
                          >
                            {showError.text}
                          </Typography>
                        </Box>
                      )}
                      {isSmallScreen ? (
                        <Box
                          display={showCamera ? "block" : "none"}
                          marginY={2}
                        >
                          {showCamera && (
                            <QrReader
                              constraints={{ facingMode: "environment" }}
                              onResult={(result, error) => {
                                if (!!result) {
                                  setScanData(result.text);
                                  setIsInterval(true);
                                }
                                if (!!error) {
                                  // console.log("error", error);
                                  // console.info(error);
                                }
                              }}
                              scanDelay={2000}
                              style={{
                                width: "100%",
                                height: "100%",
                                contentVisibility: "auto",
                              }}
                            />
                          )}
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
              <Box margin={showCamera ? "0 0 2rem 0" : "2rem 0"}>
                <GoodsReceiveTable
                  rowData={watchList}
                  documentStage={documentStage}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  onGridReady={onGridReady}
                  disabled={isLoading.item}
                  filterIsScan={filterIsScan}
                  watchType={watchType}
                  watchWarehouse={watchWarehouse}
                  getRowId={getRowId}
                  snCount={snCount}
                  handleClickPrintBarcode={handleClickPrintBarcode}
                  isSmallScreen
                />
              </Box>
              <Box display="flex" gap={1}>
                {documentStage === 0 && (
                  <CustomizedButton
                    variant="contained"
                    title="ถัดไป"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading.item}
                    size="medium"
                  />
                )}
                {documentStage === 1 && createPermission && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomizedButton
                        id="update"
                        variant="outlined"
                        title="บันทึกร่าง"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading.item}
                        fullWidth
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomizedButton
                        id="updateWithApprove"
                        onClick={handleSubmit(onSubmitWithApprove)}
                        variant="contained"
                        title="นำเข้า"
                        disabled={isLoading.item}
                        // type="submit"
                        fullWidth
                        size="medium"
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </TabPanel>
          </>
        )}
      </form>
    </>
  );
};

export default GoodsReceiveContainer;
