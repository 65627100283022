import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { filteredRow } from "../../../utils/dataTransformer";
import AgGrid from "../../Table/AgGrid";

const EngineerPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "ESTIMATION",
        name: t("engineer.estimate.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
      },
    ];
    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default EngineerPermission;
