import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const Report = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("inventory.index"),
    },
  ];

  const menuList = [
    { title: t("inventory.items.index"), navigateTo: "items" },
    { title: t("inventory.receive.index"), navigateTo: "receive" },
    // { title: t("inventory.return.index"), navigateTo: "return" },
    { title: t("inventory.issue.index"), navigateTo: "issue" },
    // { title: t("inventory.transfer.index"), navigateTo: "transfer" },
    // { title: t("inventory.adjustment.index"), navigateTo: "adjustment" },
    { title: t("inventory.stockEntry.index"), navigateTo: "stock-entry" },
    { title: t("inventory.traceEntry.index"), navigateTo: "trace-entry" },
    // { title: t("inventory.noEntry.index"), navigateTo: "items-no-entry" },
    {
      title: t("inventory.itemCurrentStock.index"),
      navigateTo: "itemcurrent-stock",
    },
    {
      title: t("inventory.itemCurrentStockLocation.index"),
      navigateTo: "itemcurrent-stock-location",
    },
    // {
    //   title: t("inventory.itemCurrentStockSerialNumber.index"),
    //   navigateTo: "itemcurrent-stock-serial-number",
    // },
    // {
    //   title: t("inventory.notActiveSerialNumber.index"),
    //   navigateTo: "not-active-serial-number",
    // },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Report;
