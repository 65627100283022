import * as Yup from "yup";

const stringValidate = Yup.string()
  .required("กรุณากรอก")
  .typeError("กรุณากรอกค่าให้ถูกต้อง");

const positiveNumberValidate = Yup.number()
  .min(0, "กรุณากรอกมากกว่าหรือเท่ากับ 0")
  .required("กรุณากรอก")
  .typeError("กรุณากรอกค่าให้ถูกต้อง");

const uomValidate = Yup.object()
  .shape({
    name: Yup.string()
      .required("กรุณากรอก")
      .typeError("กรุณากรอกค่าให้ถูกต้อง"),
  })
  .typeError("กรุณากรอกค่าให้ถูกต้อง");

export const validation = Yup.object().shape({
  name: Yup.string().required("กรุณากรอก"),
  document_id: Yup.string().required("กรุณากรอก"),
  // sku: Yup.string().required("กรุณากรอก"),
  type: Yup.string().required("กรุณากรอก"),
  weight: positiveNumberValidate,
  length: positiveNumberValidate,
  height: positiveNumberValidate,
  width: positiveNumberValidate,
  package_width: positiveNumberValidate,
  package_length: positiveNumberValidate,
  package_height: positiveNumberValidate,
  package_weight: positiveNumberValidate,
  // thickness: positiveNumberValidate,
  // volume: positiveNumberValidate,
  weight_uom: uomValidate,
  length_uom: uomValidate,
  height_uom: uomValidate,
  width_uom: uomValidate,
  // thickness_uom: uomValidate,
  // volume_uom: uomValidate,
  base_uom: uomValidate,
  purchase_uom: uomValidate,
  // deliver_uom: uomValidate,
  sales_uom: uomValidate,
  item_group_sub_level_1_document_id: stringValidate,
  attribute_list: Yup.array().of(
    Yup.object().shape({
      attribute_value: Yup.string().required("กรุณากรอก"),
    })
  ),
  sales_standard_price: positiveNumberValidate,
  // sales_maximum_discount: positiveNumberValidate,
  purchase_standard_price: positiveNumberValidate,
  purchase_minimum_qty: positiveNumberValidate,
  sales_vat_type: stringValidate,
  purchase_vat_type: stringValidate,
});
