export const initialState = {
  isLoading: {
    allUsers: false,
    user: false,
  },
  allUsers: [],
  user: {
    document_id: "",
    first_name: "",
    last_name: "",
    email: "",
    department: "",
    position: "",
    phone: "",
    img_url: "",
    password: "",
    created_date: "",
    last_login_date: "",
    status: "active",
    role_list: [],
  },
  error: null,
};
