import React, { Fragment } from "react";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import ControlledSelect from "../../../Controlled/ControlledSelect";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import ControlledRadioGroup from "../../../Controlled/ControlledRadioGroup";

const projectType = [
  {
    id: 1,
    label: "ประมูล",
    value: "ประมูล",
  },
  {
    id: 2,
    label: "ประมาณการซื้อ",
    value: "ประมาณการซื้อ",
  },
  {
    id: 3,
    label: "สเปค",
    value: "สเปค",
  },
  {
    id: 4,
    label: "ก่อสร้าง",
    value: "ก่อสร้าง",
  },
  {
    id: 5,
    label: "อื่นๆ",
    value: "อื่นๆ",
  },
];

const radioLists = [
  { id: 1, label: "ทั่วไป", value: "ทั่วไป" },
  {
    id: 2,
    label: "ด่วน",
    value: "ด่วน",
  },
];

const PreliminaryInfoForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();

  const checkBoxField = [
    {
      name: "is_reproduction",
      label: t("engineer.estimate.reproduction"),
    },
    {
      name: "is_installation",
      label: t("engineer.estimate.installation"),
    },
    {
      name: "is_adjustment",
      label: t("engineer.estimate.adjustment"),
    },
  ];

  const renderCheckBox = (name, label) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckboxes label={label} {...field} isDisabled={disabled} />
      )}
    />
  );

  const jobPriority = useWatch({
    control,
    name: "job_priority",
  });

  return (
    <CustomizedBox>
      <Typography ml={1} fontWeight="bold">
        {t("engineer.estimate.preliminary_info")}
      </Typography>
      <Box sx={{ ml: 1, mt: 1 }}>
        {checkBoxField.map((field) => (
          <Fragment key={field.label}>
            {renderCheckBox(field.name, field.label)}
          </Fragment>
        ))}
      </Box>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <ControlledSelect
              control={control}
              name="job_project_type"
              error={errors.job_project_type}
              label={t("engineer.estimate.job_project_type")}
              options={projectType}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <ControlledTextField
              control={control}
              name="job_description"
              error={errors.job_description}
              label={t("engineer.estimate.job_description")}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography ml={1} fontWeight="bold">
        {t("engineer.estimate.job_priority")}
      </Typography>
      <Box sx={{ ml: 1, mt: 1 }}>
        <ControlledRadioGroup
          control={control}
          name="job_priority"
          disabled={disabled}
          radioLists={radioLists}
          row
        />
      </Box>
      {jobPriority === "ด่วน" && (
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <ControlledTextField
                label="หมายเหตุ"
                control={control}
                name="job_priority_remark"
                error={errors.job_priority_remark}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </CustomizedBox>
  );
};

export default PreliminaryInfoForm;
