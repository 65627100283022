import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import CustomizedTextField from "./CustomizedTextField";

const CustomizedNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  onChange,
  helperText,
  pricePerUom,
  onKeyDown,
  textAlign,
  InputLabelProps,
}) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          label={label}
          value={field.value}
          allowNegative={false}
          thousandSeparator=","
          decimalScale="2"
          onValueChange={(v) => {
            field.onChange(v.floatValue ?? 0);
            onChange && onChange(v.floatValue ?? 0);
            if (pricePerUom) {
              const value = parseFloat(v.floatValue ?? 0);
              if (pricePerUom > value) {
                showWarning();
              } else hideWarning();
            }
          }}
          disabled={disabled}
          customInput={CustomizedTextField}
          helperText={helperText}
          error={error || warn}
          inputProps={{
            style: { textAlign: textAlign ? textAlign : "right" },
          }}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={{
            style: { fontSize: "10px" },
          }}
          onKeyDown={onKeyDown}
          sx={sx}
        />
      )}
    />
  );
};

export default CustomizedNumberTextField;
