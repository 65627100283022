import { useState, useEffect, useRef, forwardRef, useCallback } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { billingAddressValidation, deliveryAddressValidation } from "./schema";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import ModalUI from "../../../UI/ModalUI";
import BillingAddressModal from "./BillingAddressModal";
import DeliveryAddressModal from "./DeliveryAddressModal";
import addressData from "../../../../data/address.json";
import ContactForm from "../../Contact/index";
import CheckboxModalTable from "../../../Table/CheckboxModalTable";
import { contactColumnDefs } from "../../../Table/ColumnDefs/Contact";
import { useAuth } from "../../../../hooks/use-auth";
import { contactActions } from "../../../../features/Contact/contact-slice";
import { resetForm as resetContactForm } from "../../../../features/Contact/contact-actions";
import ContactService from "../../../../services/Contact";
import { uploadFileToS3 } from "../../../../utils/s3";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CompanyDeliveryAddressModal from "./CompanyDeliveryAddressModal";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const billingAddressDefaultState = {
  address: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  country: "",
};

const deliveryAddressDefaultState = {
  address_type: "",
  is_same_as_default_address: false,
  address_contact_name: "",
  address_contact_phone: "",
  address: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  country: "",
};

const VendorDescriptionForm = forwardRef(
  (
    {
      control,
      mainControl,
      setValue,
      getValues,
      errors,
      contactUseForm,
      viewOnly,
      isDeliveryOrder,
      isReturn,
      isOrder,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { contactSnapshot, isInit: contactIsInit } = useSelector(
      (state) => state.contact
    );
    const { user } = useAuth();
    const selectVendorGridRef = useRef();
    const { t } = useTranslation();
    const [editBillingAddress, setEditBillingAddress] = useState(false);
    const [editDeliveryAddress, setEditDeliveryAddress] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [showSelectVendor, setShowSelectVendor] = useState(false);
    const [vendorIds, setVendorIds] = useState([]);
    const [vendorIdsSnapshot, setVendorIdsSnapshot] = useState([]);
    const [showContactForm, setShowContactForm] = useState(false);
    const [subdistrictOptions, setSubdistrictOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [searchSubdistrict, setSearchSubdistrict] = useState();
    const [searchDistrict, setSearchDistrict] = useState();
    const [searchProvince, setSearchProvince] = useState();
    const [phoneOptions, setPhoneOptions] = useState([]);

    const [contactsGroupsList, setContactsGroupsList] = useState([]);

    // const watchAddress = useWatch({
    //   control,
    //   name: "billing_address",
    // });

    const {
      control: billingAddressControl,
      setValue: setBillingAddressValue,
      handleSubmit: billingAddressSubmit,
      reset: resetBillingAddressForm,
      formState: { errors: billingAddressErrors },
    } = useForm({
      defaultValues: { ...billingAddressDefaultState },
      resolver: yupResolver(billingAddressValidation),
    });

    const {
      control: deliveryAddressControl,
      setValue: setDeliveryAddressValue,
      getValues: getDeliveryAddressValues,
      handleSubmit: deliveryAddressSubmit,
      reset: resetDeliveryAddressForm,
      formState: { errors: deliveryAddressErrors },
    } = useForm({
      defaultValues: { ...deliveryAddressDefaultState },
      resolver: yupResolver(deliveryAddressValidation),
    });

    const {
      control: contactControl,
      handleSubmit: submitContact,
      setValue: setContactValue,
      getValues: getContactValues,
      watch: watchContact,
      formState: { errors: contactErrors },
      reset: resetContact,
    } = contactUseForm;

    const contact_document_id = useWatch({
      control: mainControl,
      name: "contact_document_id",
    });

    const watchVendor = useWatch({ control, name: "vendor" });
    const watchVendorId = useWatch({
      control: contactControl,
      name: "document_id",
    });

    const updateVendorDescForm = useCallback(
      (data, currentPhoneVal, currentEmailVal, currentFaxValue) => {
        if (data) {
          const email =
            data.contact_channel_list?.filter((channel) =>
              ["Email", "E-mail"].includes(channel.contact_channel)
            )[0]?.contact_info_1 || "";
          const fax =
            data.contact_channel_list?.filter((channel) =>
              ["แฟกซ์", "โทรสาร"].includes(channel.contact_channel)
            )[0]?.contact_info_1 || "";
          const phoneOptions =
            data.contact_channel_list
              ?.filter((channel) => channel.contact_channel === "เบอร์โทรศัพท์")
              .map((channel) => {
                return {
                  id: channel.contact_info_1,
                  label: channel.contact_info_1,
                  value: channel.contact_info_1,
                };
              }) || [];
          setPhoneOptions(phoneOptions);
          setValue(
            "vendor",
            `${data.document_id} - ${data.contact_name_1}${
              data.contact_name_2 ? ` ${data.contact_name_2}` : ""
            }`
          );
          if (typeof currentEmailVal !== "undefined") {
            setValue("email", currentEmailVal || "");
          } else if (email) {
            setValue("email", email || "");
          } else {
            setValue("email", "");
          }
          if (typeof currentPhoneVal !== "undefined") {
            setValue("phone", currentPhoneVal || "");
          } else if (phoneOptions.length >= 1) {
            setValue("phone", phoneOptions[0].value || "");
          }
          if (typeof currentFaxValue !== "undefined") {
            setValue("fax", currentFaxValue || "");
          } else if (fax) {
            setValue("fax", fax || "");
          } else {
            setValue("fax", "");
          }
          setValue("fax", fax);
          setValue("identity_no", data.identity_no);
          const billingAddress = {
            address: data.address_list[0]?.address || "",
            sub_district: data.address_list[0]?.sub_district || "",
            district: data.address_list[0]?.district || "",
            province: data.address_list[0]?.province || "",
            postal_code: data.address_list[0]?.postal_code || "",
            country: data.address_list[0]?.country || "",
          };
          setValue("billing_address", { ...billingAddress });
          resetBillingAddressForm({ ...billingAddress });
          if (isReturn) {
            const deliveryAddress =
              data.address_list.filter(
                (address) => address.address_type === "ที่อยู่จัดส่ง"
              )[0] || null;
            if (deliveryAddress) {
              const formattedDeliveryAddress = {
                address_type: deliveryAddress.address_type || "",
                is_same_as_default_address:
                  deliveryAddress.same_as_default_address || false,
                address_contact_name:
                  deliveryAddress.address_contact_name || "",
                address_contact_phone:
                  deliveryAddress.address_contact_phone || "",
                address: deliveryAddress.address || "",
                sub_district: deliveryAddress.sub_district || "",
                district: deliveryAddress.district || "",
                province: deliveryAddress.province || "",
                postal_code: deliveryAddress.postal_code || "",
                country: deliveryAddress.country || "",
              };
              setValue("delivery_address", { ...formattedDeliveryAddress });
              resetDeliveryAddressForm({ ...formattedDeliveryAddress });
            } else {
              const formattedDeliveryAddress = {
                address_type: "",
                is_same_as_default_address: false,
                address_contact_name: "",
                address_contact_phone: "",
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              };
              setValue("delivery_address", { ...formattedDeliveryAddress });
              resetDeliveryAddressForm({ ...formattedDeliveryAddress });
            }
          }
        } else {
          setPhoneOptions([]);
          setValue("vendor", "");
          setValue("email", "");
          setValue("identity_no", "");
        }
      },
      [setValue, resetBillingAddressForm, isReturn, resetDeliveryAddressForm]
    );

    useEffect(() => {
      const getAllContactsGroupsList = async () => {
        const contactsGroupsList = await ContactService.getAllContactsGroups();
        setContactsGroupsList(contactsGroupsList);
      };
      getAllContactsGroupsList();
    }, []);

    useEffect(() => {
      dispatch(contactActions.uninitialize());
    }, [dispatch]);

    useEffect(() => {
      if (!contactIsInit) {
        if (contact_document_id) {
          const getContactData = async () => {
            try {
              const contactData = await ContactService.getContact({
                document_id: contact_document_id,
              });
              setVendorIds([contact_document_id]);
              setVendorIdsSnapshot([contact_document_id]);
              if (contactData) {
                dispatch(contactActions.uninitialize());
                const billingAddress = getValues("billing_address");
                const deliveryAddress = getValues("delivery_address");
                const phone = getValues("phone");
                const email = getValues("email");
                const fax = getValues("fax");
                setValue("phone", phone);
                setValue("email", email);
                setValue("fax", fax);
                setValue("billing_address", { ...billingAddress });
                resetBillingAddressForm({ ...billingAddress });
                setValue("delivery_address", { ...deliveryAddress });
                resetDeliveryAddressForm({ ...deliveryAddress });
                setValue(
                  "vendor",
                  `${contactData.document_id} - ${contactData.contact_name_1}${
                    contactData.contact_name_2
                      ? ` ${contactData.contact_name_2}`
                      : ""
                  }`
                );
                setValue("identity_no", contactData.identity_no);
                dispatch(contactActions.initialize(contactData));
              }
            } catch (err) {
              console.log(err);
            }
          };
          getContactData();
        }
      }
    }, [
      contactIsInit,
      contact_document_id,
      dispatch,
      getValues,
      resetBillingAddressForm,
      resetDeliveryAddressForm,
      setValue,
    ]);

    useEffect(() => {
      if (contactIsInit && contactSnapshot) {
        resetContact({
          ...contactSnapshot,
          created_date: moment.unix(contactSnapshot.created_date).toDate(),
          contact_type_2: "",
          registered_capital: contactSnapshot.registered_capital || "",
          estimate_sales_volume: contactSnapshot.estimate_sales_volume || "",
          finance: {
            ...contactSnapshot.finance,
            billing_day: contactSnapshot.finance.billing_day || "",
            payment_day: contactSnapshot.finance.payment_day || "",
            request_credit: {
              credit_limit_day:
                contactSnapshot.finance.request_credit.credit_limit_day || "",
              credit_limit_value:
                contactSnapshot.finance.request_credit.credit_limit_value || "",
            },
          },
        });
      }
    }, [contactIsInit, contactSnapshot, resetContact]);

    useEffect(() => {
      let filteredAddressData = addressData;
      if (searchSubdistrict) {
        filteredAddressData = addressData.filter((item) =>
          item.sub_district.includes(searchSubdistrict)
        );
      }
      if (searchDistrict) {
        filteredAddressData = addressData.filter((item) =>
          item.district.includes(searchDistrict)
        );
      }
      if (searchProvince) {
        filteredAddressData = addressData.filter((item) =>
          item.province.includes(searchProvince)
        );
      }
      let filteredProvinces = [];
      filteredAddressData.forEach((item) => {
        if (!filteredProvinces.includes(item.province)) {
          filteredProvinces = [...filteredProvinces, item.province];
        }
      });
      setProvinceOptions(filteredProvinces);

      let filteredDistricts = [];
      filteredAddressData.forEach((item) => {
        if (!filteredDistricts.includes(item.district)) {
          filteredDistricts = [...filteredDistricts, item.district];
        }
      });
      setDistrictOptions(filteredDistricts);

      let filteredSubDistricts = [];
      filteredAddressData.forEach((item) => {
        if (!filteredSubDistricts.includes(item.sub_district)) {
          filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
        }
      });
      setSubdistrictOptions(filteredSubDistricts);
    }, [searchDistrict, searchSubdistrict, searchProvince]);

    const openVendorTable = () => {
      setShowSelectVendor(true);
    };

    const closeVendorTable = () => {
      setShowSelectVendor(false);
    };

    const finishVendorSelectHandler = (data) => {
      dispatch(contactActions.uninitialize());
      if (data) {
        updateVendorDescForm(data);
        dispatch(contactActions.initialize(data));
      } else {
        updateVendorDescForm(null);
      }
      setShowSelectVendor(false);
    };

    const openContactForm = () => {
      setShowContactForm(true);
    };

    const closeContactForm = () => {
      setShowContactForm(false);
      searchParams.delete("contactTab");
      setSearchParams(searchParams);
    };

    const formatContactData = async (data) => {
      const unixCreatedDate = moment(data.created_date).unix();
      let updatedImageUrl = data.img_url;
      if (typeof data.img_url[0] === "object") {
        try {
          const { Location } = await uploadFileToS3(
            data.img_url[0],
            "contact",
            user.document_id
          );
          updatedImageUrl = Location;
        } catch (err) {
          console.log("Could not upload image");
          updatedImageUrl = "";
        }
      } else if (typeof data.img_url[0] === "string") {
        updatedImageUrl = data.img_url[0];
      } else {
        updatedImageUrl = "";
      }

      const transformedContactPersons = await Promise.all(
        data.contact_person_list.map(async (person, index) => {
          let updatedImgUrl = person.img_url;
          if (
            Array.isArray(person.img_url) &&
            typeof person.img_url[0] === "object"
          ) {
            try {
              const { Location } = await uploadFileToS3(
                person.img_url[0],
                "contact",
                user.document_id
              );
              updatedImgUrl = Location;
            } catch (err) {
              console.log("Could not upload image", index);
              updatedImgUrl = "";
            }
          }
          return { ...person, img_url: updatedImgUrl };
        })
      );

      const transformedAttachments = await Promise.all(
        data.attachment_list.map(async (attachment) => {
          // check if there's url (exists only on newly added files)
          if (typeof attachment.url === "undefined") {
            try {
              const { Location } = await uploadFileToS3(
                attachment,
                "contact",
                attachment.uploaded_by.document_id
              );
              return {
                name: attachment.attachment_name,
                url: Location,
                creator_document_id: attachment.uploaded_by.document_id,
              };
            } catch (err) {
              console.log("Could not upload attachment");
              return null;
            }
          }
          return {
            name: attachment.attachment_name || attachment.name,
            url: attachment.url,
            creator_document_id: attachment.uploaded_by?.document_id,
          };
        })
      );
      const filteredAttachments = transformedAttachments.filter(
        (attachment) => attachment !== null
      );

      const transformedSalesList = data.sales_list.map(
        ({
          id,
          status,
          role_list,
          created_date,
          created_by,
          last_login_date,
          last_login_by,
          last_updated_date,
          ...sale
        }) => sale
      );
      // check if numeric data is empty string or not, if so, convert to null before submitting
      const updatedContactData = {
        ...data,
        id: undefined,
        document_id: undefined,
        created_date: unixCreatedDate,
        created_by: undefined,
        creator_document_id: data.creator_document_id
          ? data.creator_document_id
          : user.document_id,
        last_updator_document_id: data.creator_document_id
          ? user.document_id
          : null,
        img_url: updatedImageUrl,
        contact_person_list: transformedContactPersons,
        attachment_list: filteredAttachments,
        sales_list: transformedSalesList,
        registered_capital: data.registered_capital
          ? data.registered_capital
          : null,
        estimate_sales_volume: data.estimate_sales_volume
          ? data.estimate_sales_volume
          : null,
        finance: {
          ...data.finance,
          payment_day: data.finance.payment_day
            ? data.finance.payment_day
            : null,
          billing_day: data.finance.billing_day
            ? data.finance.billing_day
            : null,
          request_credit: {
            ...data.finance.request_credit,
            credit_limit_value: data.finance.request_credit.credit_limit_value
              ? data.finance.request_credit.credit_limit_value
              : null,
            credit_limit_day: data.finance.request_credit.credit_limit_day
              ? data.finance.request_credit.credit_limit_value
              : null,
          },
        },
      };
      return updatedContactData;
    };

    const submitContactForm = async (data) => {
      //TODO if data.document_id !== contactSnapshot.document_id, update idsSnapshot[0]
      const { document_id } = data;
      const formattedFormData = await formatContactData(data);
      try {
        const updatedContact = await ContactService.updateContact(
          { document_id },
          formattedFormData
        );
        searchParams.delete("contactTab");
        dispatch(contactActions.uninitialize());
        dispatch(contactActions.initialize(updatedContact));
        updateVendorDescForm(updatedContact);
        setSearchParams(searchParams);
        setShowContactForm(false);
      } catch (err) {
        console.log(err);
      }
    };

    const resetContactHandler = () => {
      if (contactSnapshot) {
        resetContact({
          ...contactSnapshot,
          created_date: moment.unix(contactSnapshot.created_date).toDate(),
          contact_type_2: "",
          registered_capital: contactSnapshot.registered_capital || "",
          estimate_sales_volume: contactSnapshot.estimate_sales_volume || "",
          finance: {
            ...contactSnapshot.finance,
            billing_day: contactSnapshot.finance.billing_day || "",
            payment_day: contactSnapshot.finance.payment_day || "",
            request_credit: {
              credit_limit_day:
                contactSnapshot.finance.request_credit.credit_limit_day || "",
              credit_limit_value:
                contactSnapshot.finance.request_credit.credit_limit_value || "",
            },
          },
        });
        resetContactForm(dispatch);
      }
    };

    const openBillingAddressForm = () => {
      setEditBillingAddress(true);
    };

    const closeBillingAddressForm = () => {
      const billingAddressValues = getValues("billing_address");
      if (
        JSON.stringify(billingAddressValues) ===
        JSON.stringify(billingAddressDefaultState)
      ) {
        resetBillingAddressForm({ ...billingAddressDefaultState });
      } else {
        resetBillingAddressForm({ ...billingAddressValues });
      }
      setEditBillingAddress(false);
      resetSearchOptions();
    };

    const openDeliveryAddressForm = () => {
      setEditDeliveryAddress(true);
      const deliveryAddressValues = getValues("delivery_address");
      if (
        JSON.stringify(deliveryAddressValues) !==
        JSON.stringify(deliveryAddressDefaultState)
      ) {
        resetDeliveryAddressForm({
          is_same_as_default_address:
            deliveryAddressValues.is_same_as_default_address || false,
          address: deliveryAddressValues.address || "",
          address_contact_name:
            deliveryAddressValues.address_contact_name || "",
          address_contact_phone:
            deliveryAddressValues.address_contact_phone || "",
          address_type: deliveryAddressValues.address_type || "",
          sub_district: deliveryAddressValues.sub_district || "",
          district: deliveryAddressValues.district || "",
          province: deliveryAddressValues.province || "",
          postal_code: deliveryAddressValues.postal_code || "",
          country: deliveryAddressValues.country || "",
        });
      }
    };

    const closeDeliveryAddressForm = () => {
      const deliveryAddressValues = getValues("delivery_address");
      if (isDeliveryOrder) {
        const deliveryAddress =
          contactSnapshot.address_list.filter(
            (address) => address.address_type === "ที่อยู่จัดส่ง"
          )[0] || null;
        if (deliveryAddress) {
          const formattedDeliveryAddress = {
            address_type: deliveryAddress.address_type || "",
            is_same_as_default_address:
              deliveryAddress.same_as_default_address || false,
            address_contact_name: deliveryAddress.address_contact_name || "",
            address_contact_phone: deliveryAddress.address_contact_phone || "",
            address: deliveryAddress.address || "",
            sub_district: deliveryAddress.sub_district || "",
            district: deliveryAddress.district || "",
            province: deliveryAddress.province || "",
            postal_code: deliveryAddress.postal_code || "",
            country: deliveryAddress.country || "",
          };
          resetDeliveryAddressForm({ ...formattedDeliveryAddress });
          setEditDeliveryAddress(false);
          resetSearchOptions();
          return;
        }
      }
      if (
        JSON.stringify(deliveryAddressValues) ===
        JSON.stringify(deliveryAddressDefaultState)
      ) {
        resetDeliveryAddressForm({ ...deliveryAddressDefaultState });
      } else {
        resetDeliveryAddressForm({ ...deliveryAddressValues });
      }
      setEditDeliveryAddress(false);
      resetSearchOptions();
    };

    const resetSearchOptions = () => {
      setSearchSubdistrict();
      setSearchDistrict();
      setSearchProvince();
    };

    const updateDeliveryAddress = () => {
      const deliveryAddressValues = getValues("delivery_address");
      const billingAddressValues = getValues("billing_address");
      if (!deliveryAddressValues.is_same_as_default_address) {
        return;
      }
      setValue("delivery_address", {
        address_type: deliveryAddressValues.address_type,
        is_same_as_default_address:
          deliveryAddressValues.is_same_as_default_address,
        address_contact_name: deliveryAddressValues.address_contact_name,
        address_contact_phone: deliveryAddressValues.address_contact_phone,
        ...billingAddressValues,
      });
      setDeliveryAddressValue("address", billingAddressValues.address);
      setDeliveryAddressValue(
        "sub_district",
        billingAddressValues.sub_district
      );
      setDeliveryAddressValue("district", billingAddressValues.district);
      setDeliveryAddressValue("province", billingAddressValues.province);
      setDeliveryAddressValue("postal_code", billingAddressValues.postal_code);
    };

    const billingSubmitHandler = (data) => {
      if (!viewOnly) {
        setValue("billing_address", data);
      }
      setEditBillingAddress(false);
      if (!isDeliveryOrder) {
        updateDeliveryAddress();
      }
      resetSearchOptions();
    };

    const deliverySubmitHandler = (data) => {
      if (!viewOnly) {
        setValue("delivery_address", data);
      }
      setEditDeliveryAddress(false);
      resetSearchOptions();
    };

    const renderAddressString = (values) => {
      const addressValues = getValues(values);
      const {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      const orderedValues = {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            if (orderedValues[key]?.length > 0) {
              result = result + orderedValues[key] + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }

      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
    };

    const watchFullName = useWatch({
      control,
      name: "delivery_address.address_contact_name",
    });
    const watchPhoneNo = useWatch({
      control,
      name: "delivery_address.address_contact_phone",
    });

    const deliveryAddressNameFields = [
      "delivery_address.address_contact_name",
      "delivery_address.address_contact_phone",
    ];

    const deliveryAddressFields = [
      "delivery_address.address",
      "delivery_address.sub_district",
      "delivery_address.district",
      "delivery_address.province",
      "delivery_address.postal_code",
      "delivery_address.country",
    ];

    const contactGroupClickHandler = (group) => {
      if (selectVendorGridRef.current.api) {
        const instance =
          selectVendorGridRef.current.api.getFilterInstance("contact_group");
        instance.setModel({ filterType: "set", values: [group] });
        selectVendorGridRef.current.api.onFilterChanged();
      }
    };

    const columnDefs = contactColumnDefs(
      t,
      contactsGroupsList,
      contactGroupClickHandler
    );

    const datasource = {
      async getRows(params) {
        const request = params.request;
        try {
          const allContacts = await ContactService.getAllContactsAgGrid(
            {
              startRow: request.startRow,
              endRow: request.endRow,
              filterModel: {
                ...request.filterModel,
                is_vendor: {
                  filterType: "boolean",
                  type: "equals",
                  filter: "true",
                },
                contact_group: request.filterModel.contact_group
                  ? {
                      filterType: "array",
                      type: "hasSome",
                      values: request.filterModel.contact_group?.values || [],
                    }
                  : undefined,
              },
              sortModel: request.sortModel,
            },
            params
          );
          params.successCallback(allContacts.results, allContacts.count);
        } catch (err) {
          console.log(err);
          params.failCallback();
        }
      },
    };

    const onFirstDataRendered = () => {
      const instance =
        selectVendorGridRef.current.api.getFilterInstance("contact_status");
      instance.setModel({ filterType: "set", values: ["active"] });
      selectVendorGridRef.current.api.onFilterChanged();
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    return (
      <>
        <CustomizedBox ref={ref}>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
            <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
              <Typography fontWeight="bold" sx={{ ml: 1 }}>
                {t("purchase.vendorDescription")}
              </Typography>
            </Grid>
            {!viewOnly && !isDeliveryOrder && (
              <Grid item xs={2}>
                <Box>
                  <CustomizedTooltip title="เลือกผู้ขาย">
                    <IconButton
                      onClick={openVendorTable}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name="vendor"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    fullWidth
                    error={Boolean(errors.vendor)}
                    helperText={errors.vendor?.message}
                    label="รหัสผู้ขาย - ชื่อผู้ขาย"
                    // InputLabelProps={{ shrink: viewOnly }}
                    disabled
                    required
                    sx={{
                      input: { visibility: viewOnly ? "hidden" : "visible" },
                    }}
                    InputProps={{
                      startAdornment: viewOnly && (
                        <InputAdornment position="start">
                          <StyledLink onClick={openContactForm}>
                            {watchVendor || "รหัสผู้ขาย - ชื่อผู้ขาย"}
                          </StyledLink>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {!viewOnly && contactIsInit && (
                            <CustomizedTooltip title="กดเพื่อดูรายละเอียดผู้ขาย">
                              <IconButton
                                onClick={openContactForm}
                                sx={{
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <LaunchOutlinedIcon />
                              </IconButton>
                            </CustomizedTooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name="identity_no"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.identity_no)}
                    helperText={errors.identity_no?.message}
                    label="เลขประจำตัวผู้เสียภาษี"
                    {...field}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                key="vendor_phone_number"
                name="phone"
                control={control}
                render={({ field }) =>
                  phoneOptions.length > 1 ? (
                    <CustomizedCreatable
                      fullWidth
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                      label={t("user.account.phone")}
                      options={phoneOptions}
                      {...field}
                      onChange={(e, option) => {
                        if (!option) {
                          return field.onChange("");
                        }
                        if (option.value) {
                          return field.onChange(option.value);
                        }
                        if (option.inputValue) {
                          return field.onChange(option.inputValue);
                        }
                      }}
                      disabled={viewOnly}
                    />
                  ) : (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                      label={t("user.account.phone")}
                      {...field}
                      onChange={(e) => {
                        if (!e.target.value) {
                          return field.onChange("");
                        }
                        return field.onChange(e.target.value);
                      }}
                      disabled={viewOnly}
                    />
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    label={t("user.account.email")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Controller
                name="fax"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.fax)}
                    helperText={errors.fax?.message}
                    label="โทรสาร"
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
          </Grid>
          {!isDeliveryOrder && (
            <>
              <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold" sx={{ ml: 1 }}>
                    ที่อยู่จดทะเบียน
                  </Typography>
                </Grid>
                {!viewOnly && contactSnapshot && (
                  <Grid item xs={2}>
                    <Box>
                      <CustomizedTooltip title="แก้ไขที่อยู่จดทะเบียน">
                        <IconButton
                          onClick={openBillingAddressForm}
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </CustomizedTooltip>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Typography variant="body1" sx={{ ml: 1, mr: 2, mb: 3 }}>
                {renderAddressString("billing_address")}
              </Typography>
              {isReturn && (
                <>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                      <Typography fontWeight="bold" sx={{ ml: 1 }}>
                        ที่อยู่จัดส่ง
                      </Typography>
                    </Grid>
                    {!viewOnly && contactSnapshot && (
                      <Grid item xs={2}>
                        <Box>
                          <CustomizedTooltip title="แก้ไขที่อยู่จัดส่ง">
                            <IconButton
                              onClick={openDeliveryAddressForm}
                              sx={{
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </CustomizedTooltip>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  {(watchFullName?.trim().length > 0 ||
                    watchPhoneNo?.trim().length > 0) && (
                    <Typography variant="body1" sx={{ ml: 1, mr: 2 }}>
                      {renderAddressString(deliveryAddressNameFields)}
                    </Typography>
                  )}
                  <Typography variant="body1" sx={{ ml: 1, mr: 2, mb: 2 }}>
                    {renderAddressString(deliveryAddressFields)}
                  </Typography>
                </>
              )}
            </>
          )}
        </CustomizedBox>
        {isOrder && (
          <CustomizedBox margin="0 0 2rem 0">
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                <Typography fontWeight="bold" sx={{ ml: 1 }}>
                  ที่อยู่จัดส่งผู้รับ
                </Typography>
              </Grid>
              {!viewOnly && (
                <Grid item xs={2}>
                  <Box>
                    <CustomizedTooltip title="แก้ไขที่อยู่จัดส่ง">
                      <IconButton
                        onClick={openDeliveryAddressForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  </Box>
                </Grid>
              )}
            </Grid>
            {(watchFullName?.trim().length > 0 ||
              watchPhoneNo?.trim().length > 0) && (
              <Typography variant="body1" sx={{ ml: 1, mr: 2 }}>
                {renderAddressString(deliveryAddressNameFields)}
              </Typography>
            )}
            <Typography variant="body1" sx={{ ml: 1, mr: 2, mb: 2 }}>
              {renderAddressString(deliveryAddressFields)}
            </Typography>
          </CustomizedBox>
        )}
        <CheckboxModalTable
          modalTitle={t("purchase.vendorInfo")}
          btnTitle={t("button.add")}
          gridRef={selectVendorGridRef}
          height={450}
          columnDefs={columnDefs}
          rowSelection="single"
          onFinishEditing={finishVendorSelectHandler}
          modalIsOpen={showSelectVendor}
          closeModal={closeVendorTable}
          onGridReady={onGridReady}
          selectedIds={vendorIds}
          setSelectedIds={setVendorIds}
          idsSnapshot={vendorIdsSnapshot}
          setIdsSnapshot={setVendorIdsSnapshot}
          onFirstDataRendered={onFirstDataRendered}
        />
        <ModalUI
          open={showContactForm}
          navigateTo={`/contact/edit/${watchVendorId}`}
          handleClose={closeContactForm}
          fullWidth
          title="ผู้ขาย"
          maxWidth="lg"
        >
          <ContactForm
            control={contactControl}
            onSubmit={submitContact(submitContactForm)}
            onCancel={resetContactHandler}
            setValue={setContactValue}
            getValues={getContactValues}
            watch={watchContact}
            errors={contactErrors}
            disableModes={viewOnly}
            reset={resetContact}
            isContactPage={false}
          />
        </ModalUI>
        <BillingAddressModal
          control={billingAddressControl}
          errors={billingAddressErrors}
          setValue={setBillingAddressValue}
          onSubmit={billingAddressSubmit(billingSubmitHandler)}
          isOpen={editBillingAddress}
          onClose={closeBillingAddressForm}
          viewOnly={viewOnly}
          setSubdistrict={setSearchSubdistrict}
          setDistrict={setSearchDistrict}
          setProvince={setSearchProvince}
          subdistrictOptions={subdistrictOptions}
          districtOptions={districtOptions}
          provinceOptions={provinceOptions}
        />
        {isOrder ? (
          <CompanyDeliveryAddressModal
            control={deliveryAddressControl}
            errors={deliveryAddressErrors}
            setValue={setDeliveryAddressValue}
            onSubmit={deliveryAddressSubmit(deliverySubmitHandler)}
            isOpen={editDeliveryAddress}
            onClose={closeDeliveryAddressForm}
            disabled={viewOnly}
            setSubdistrict={setSearchSubdistrict}
            setDistrict={setSearchDistrict}
            setProvince={setSearchProvince}
            subdistrictOptions={subdistrictOptions}
            districtOptions={districtOptions}
            provinceOptions={provinceOptions}
            reset={resetDeliveryAddressForm}
          />
        ) : (
          <DeliveryAddressModal
            control={deliveryAddressControl}
            errors={deliveryAddressErrors}
            setValue={setDeliveryAddressValue}
            getDeliveryAddressValues={getDeliveryAddressValues}
            onSubmit={deliveryAddressSubmit(deliverySubmitHandler)}
            isOpen={editDeliveryAddress}
            onClose={closeDeliveryAddressForm}
            viewOnly={viewOnly}
            setSubdistrict={setSearchSubdistrict}
            setDistrict={setSearchDistrict}
            setProvince={setSearchProvince}
            subdistrictOptions={subdistrictOptions}
            districtOptions={districtOptions}
            provinceOptions={provinceOptions}
            isDeliveryOrder={isDeliveryOrder}
            reset={resetDeliveryAddressForm}
          />
        )}
      </>
    );
  }
);

export default VendorDescriptionForm;
