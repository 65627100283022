import { Box, Button, Collapse, IconButton, ListItem } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import { useEffect, useState } from "react";

export const LeftNavbarItem = (props) => {
  const {
    active,
    activeBg,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    onItemClick,
    ...other
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openProp) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openProp]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...other}
      >
        <Box>
          <Button
            component={Link}
            to={path}
            disableRipple
            startIcon={icon}
            sx={{
              color: active ? "primary.main" : "inherit",
              backgroundColor: (theme) =>
                activeBg ? alpha(theme.palette.primary.main, 0.1) : "inherit",
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.2),
              },
              justifyContent: "flex-start",
              pl: `${paddingLeft}px`,
              pr: 3,
              textAlign: "left",
              textTransform: "none",
              width: "100%",
              "& .MuiButton-startIcon": {
                color: active ? "primary.main" : "inherit",
              },
              "& .MuiButton-endIcon": {
                color: "inherit",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
          <IconButton
            onClick={handleToggle}
            size="small"
            sx={{
              position: "absolute",
              top: 3,
              right: 20,
              color: active ? "primary.main" : "inherit",
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
          >
            {!open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        onClick={() => onItemClick(path)}
        startIcon={icon}
        endIcon={chip}
        disableRipple
        sx={{
          borderRadius: 1,
          justifyContent: "flex-start",
          pl: `${paddingLeft}px`,
          pr: 3,
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          ...(active
            ? {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                color: (theme) => theme.palette.primary.main,
              }
            : {
                color: "inherit",
              }),
          "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};
