import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import HistoryIcon from "@mui/icons-material/History";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useWatch, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";
import { useAuth } from "../../../hooks/use-auth";
import GoodsTransferTable from "../../../components/Table/DocumentTable/GoodsTransferTable";
import {
  createGoodsTransferWithApprove,
  getGoodsTransferById,
} from "../../../features/Inventory/GoodsTransfer/goodsTransfer-actions";
import { goodsTransferActions } from "../../../features/Inventory/GoodsTransfer/goodsTransfer-slice";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import TransferDetail from "./Detail";
import {
  dateToUnix,
  // unixToDate,
  // unixToDateWithFormat,
} from "../../../utils/date-converter";
import RightDrawer from "../../../components/UI/RightDrawer";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import InventoryService from "../../../services/Inventory";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import { useSnackbar } from "notistack";
import moment from "moment";
import GlobalService from "../../../services/Global";
import { formatGTPayload } from "../../../utils/dataTransformer";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GoodsTransferContainer = ({ isCreate }) => {
  const scrollRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { item, isLoading } = useSelector((state) => state.goodsTransfer);
  const { user } = useAuth();
  const [createDateIsOpen, setCreateDateIsOpen] = useState(false);
  const [transferDateIsOpen, setTransferDateIsOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [binLocationOptions, setBinLocationOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showCamera, setShowCamera] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState({
    type: "success",
    value: true,
    text: "awdascascawdaw dapowdk a; mdl",
  });
  const [scanData, setScanData] = useState("");
  const [scanDataCurr, setScanDataCurr] = useState("");
  const [isInterVal, setIsInterval] = useState(false);

  useEffect(() => {
    if (currentTab) {
      setShowCamera(false);
    }
  }, [currentTab]);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const creator = item.created_by ? item.created_by : user;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const {
    control: barcodeControl,
    handleSubmit: handleBarcodeSubmit,
    setValue: setValueField,
    getValues: getValuesField,
  } = useForm({
    defaultValues: {
      barcode: "",
      destination_bin_location_document_id: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goods_transfer_list",
    keyName: "genId",
  });

  const generateDocumentId = useCallback(async () => {
    const newDocumentId = await GlobalService.getRunningDocumentId(
      "goods_transfer"
    );
    return setValue("document_id", newDocumentId);
  }, [setValue]);

  useEffect(() => {
    dispatch(getAllLocation());
    if (id) {
      dispatch(getGoodsTransferById({ uniqueInput: { document_id: id } }));
    } else {
      generateDocumentId();
    }
    return () => dispatch(goodsTransferActions.resetItem());
  }, [dispatch, generateDocumentId, id]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      setValue("created_date", moment());
      setValue("document_date", moment().endOf("day"));
    }
  }, [id, item, setValue]);

  const currentSourceWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const currentDestinationWarehouse = useWatch({
    control,
    name: "destination_warehouse_document_id",
  });

  const currentDestinationBinLocation = useWatch({
    control: barcodeControl,
    name: "destination_bin_location_document_id",
  });

  const getBinLocations = useCallback(async () => {
    if (!currentDestinationWarehouse) {
      setBinLocationOptions([]);
      return;
    }
    const input = {
      where: {
        warehouse: {
          document_id: {
            equals: currentDestinationWarehouse,
          },
        },
      },
    };
    const result = await InventoryService.findBinLocations(input);
    const newValue = result.map((res) => {
      return {
        ...res,
        label: res.document_id,
        value: res.document_id,
      };
    });
    setBinLocationOptions(newValue);
  }, [currentDestinationWarehouse]);

  useEffect(() => {
    getBinLocations();
  }, [getBinLocations]);

  useEffect(() => {
    if (errors && errors?.goods_transfer_list?.message) {
      enqueueSnackbar(errors?.goods_transfer_list?.message, {
        variant: "error",
      });
    } else if (errors && Array.isArray(errors?.goods_transfer_list)) {
      if (
        errors?.goods_transfer_list.some(
          (goods) =>
            goods.stock_entry_list.trace_entry_list.message ===
            "กรุณาเพิ่ม SN ในรายการสินค้า"
        )
      ) {
        enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  const checkSmallScreenError = useCallback(
    (type, text) => {
      return !isSmallScreen
        ? enqueueSnackbar(text, {
            variant: type,
          })
        : setShowError({ type: type, value: true, text });
    },
    [enqueueSnackbar, isSmallScreen]
  );

  const onBarcodeSubmitHandler = useCallback(
    async (data) => {
      if (data.trim().length > 0) {
        const serialNumber = data
          .split("#", 4)
          .filter((_, index) => index !== 0)
          .join("#");
        //A00002#WH01#GR2211090013#00001#5
        const { results } =
          await InventoryService.getItemCurrentStockSerialNumber({
            startRow: 0,
            endRow: 999,
            filterModel: {
              serial_number: {
                filter: serialNumber,
                filterType: "text",
                type: "equals",
              },
            },
          });
        if (results.length === 0)
          return checkSmallScreenError(
            "error",
            "QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่"
          );
        const currentBinLocation = getValuesField(
          "destination_bin_location_document_id"
        );
        const checkItemIsActive = results.some((te) => !te.item_is_active);
        if (checkItemIsActive)
          return checkSmallScreenError(
            "error",
            "ไม่สามารถสแกนได้ เนื่องจากสินค้านี้มีสถานะหยุดการใช้งานอยู่"
          );

        const filterTraceEntry = results.filter(
          (result) => result.warehouse_document_id === currentSourceWarehouse
        );

        if (filterTraceEntry.length === 0)
          return checkSmallScreenError(
            "error",
            "QR/Barcode นี้ไม่อยู่ในคลังต้นทางที่เลือกกรุณาสแกนใหม่"
          );

        if (
          filterTraceEntry.filter(
            (te) => te.source_bin_location_document_id !== currentBinLocation
          ).length === 0
        )
          return checkSmallScreenError(
            "error",
            "กรุณาเลือกสถานที่ใหม่เนื่องจาก QR/Barcode นี้อยู่สถานที่ ที่ถูกเลือก"
          );

        if (filterTraceEntry.every((result) => result.current_quantity <= 0))
          return checkSmallScreenError("error", "SN นี้ไม่มีสินค้าในคลัง");
        const teQuantityNotZero = filterTraceEntry.filter(
          (te) => te.current_quantity !== 0
        );
        const lastIndexTraceEntry =
          teQuantityNotZero[teQuantityNotZero.length - 1];
        if (lastIndexTraceEntry) {
          const goodsTransferList = getValues("goods_transfer_list");
          const foundMatchItem = goodsTransferList.findIndex(
            (item) =>
              item.stock_entry_list.item.document_id ===
              lastIndexTraceEntry.item_document_id
          );
          const currItemList = getValues(`goods_transfer_list`);
          //1MT10.0-LJ#WH01#GR2301170001#00001#10
          const formatTraceEntry = {
            ...lastIndexTraceEntry,
            uom: {
              name: lastIndexTraceEntry.base_uom_name,
              document_id: lastIndexTraceEntry.base_uom_document_id,
            },
            item: {
              name: lastIndexTraceEntry.item_name,
              document_id: lastIndexTraceEntry.item_document_id,
              sku: lastIndexTraceEntry.item_sku,
            },
            warehouse: {
              document_id: lastIndexTraceEntry.warehouse_document_id,
              name: lastIndexTraceEntry.warehouse_thai_name,
            },
            destination_bin_location: {
              document_id: currentDestinationBinLocation,
            },
            source_bin_location_document_id:
              filterTraceEntry.filter(
                (te) =>
                  te.source_bin_location_document_id !== currentBinLocation
              )[0].source_bin_location_document_id || "",
            batch_number: data,
            is_scanned: true,
            posted_quantity: lastIndexTraceEntry.current_quantity,
            created_by: user,
            scanned_by: user,
            scanned_date: new Date(),
            posted_date: moment().unix(),
            location_list: filterTraceEntry
              .filter(
                (te) =>
                  te.source_bin_location_document_id !== currentBinLocation
              )
              .map((result) => {
                return {
                  id: result.source_bin_location_document_id,
                  label: `${result.source_bin_location_document_id} (${result.current_quantity} ${lastIndexTraceEntry.base_uom_name})`,
                  value: result.source_bin_location_document_id,
                  current_quantity: result.current_quantity,
                };
              }),
          };
          const formatItemList = [
            ...currItemList,
            {
              initial_quantity: 0,
              posted_quantity: 0,
              posted_value: 0,
              stock_entry_list: {
                source_warehouse_document_id:
                  formatTraceEntry.warehouse.document_id,
                posted_quantity: 0,
                posted_value: 0,
                posted_date: dateToUnix(new Date()),
                uom: formatTraceEntry.uom,
                item: formatTraceEntry.item,
                created_by: user,
                trace_entry_list: [formatTraceEntry],
              },
            },
          ];
          if (foundMatchItem >= 0) {
            const currTraceEntry = getValues(
              `goods_transfer_list[${foundMatchItem}].stock_entry_list.trace_entry_list`
            );
            if (currTraceEntry.some((teList) => teList.batch_number === data)) {
              checkSmallScreenError(
                "error",
                "QR/Barcode สินค้านี้ถูกสแกนและบันทึกลงรายการแล้ว"
              );
            } else {
              setValue(
                `goods_transfer_list[${foundMatchItem}].stock_entry_list.trace_entry_list`,
                [...currTraceEntry, formatTraceEntry]
              );
              checkSmallScreenError(
                "success",
                `สแกน SN: ${serialNumber} สำเร็จ`
              );
            }
          } else {
            setValue(`goods_transfer_list`, formatItemList);
            checkSmallScreenError("success", `สแกน SN: ${serialNumber} สำเร็จ`);
          }
        } else {
          checkSmallScreenError(
            "error",
            "QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่"
          );
        }
      }
    },
    [
      checkSmallScreenError,
      currentDestinationBinLocation,
      currentSourceWarehouse,
      getValues,
      getValuesField,
      setValue,
      user,
    ]
  );

  useEffect(() => {
    if (showError.value) {
      setTimeout(() => {
        setShowError({ type: "success", value: false, text: "" });
      }, 1.5 * 1000);
    }
  }, [showError]);

  const onSubmit = async (data) => {
    const serializedData = await formatGTPayload(data);
    dispatch(
      createGoodsTransferWithApprove(
        serializedData,
        enqueueSnackbar,
        navigate,
        user
      )
    );
  };

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.transfer.index"),
      to: "/inventory/transfer",
    },
    {
      name: id ?? t("inventory.transfer.add"),
    },
  ];

  // const mapBinLocationIdToName = () => {
  //   const findId = binLocationOptions?.find(
  //     (location) => location.document_id === currentDestinationBinLocation
  //   );
  //   if (findId) return findId.name;
  //   else return "";
  // };

  useEffect(() => {
    if (errors && Array.isArray(errors?.goods_transfer_list)) {
      if (
        errors?.goods_transfer_list.find((goods) =>
          goods.stock_entry_list.trace_entry_list.some(
            (trace) =>
              trace.posted_quantity.message === "จำนวนเคลื่อนย้ายต้องมากกว่า 0"
          )
        )
      ) {
        enqueueSnackbar("กรุณาเคลื่อนย้ายสินค้าที่มีจำนวนมากว่า 0", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  const handlePrintDocument = () => {
    navigate(`/inventory/transfer/${id}/pdf`);
  };

  const tabError =
    errors &&
    (errors?.document_id ||
      errors?.type ||
      errors?.reference_document_id ||
      errors?.source_warehouse_document_id ||
      errors?.destination_warehouse_document_id ||
      errors?.remark);

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  useEffect(() => {
    if (isInterVal && scanData !== scanDataCurr) {
      setScanDataCurr(scanData);
      onBarcodeSubmitHandler(scanData);
      setTimeout(() => {
        setIsInterval(false);
        setScanDataCurr("");
      }, 2000);
    }
  }, [isInterVal, onBarcodeSubmitHandler, scanData, scanDataCurr]);

  return (
    <>
      {isLoading.item ? null : (
        <>
          {!isSmallScreen ? (
            <>
              <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
              <Box
                sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
              >
                <Box
                  sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                >
                  <Typography variant="h5">
                    {t("inventory.transfer.goodsTransferList")}
                  </Typography>
                  <CustomizedStatus status={item.status} />
                </Box>
                <CustomizedTooltip title="ดูการเคลื่อนไหว">
                  <IconButton onClick={() => setOpenDrawer(true)}>
                    <HistoryIcon
                      fontSize="small"
                      color=" rgba(0, 0, 0, 0.54)"
                    />
                  </IconButton>
                </CustomizedTooltip>
                <RightDrawer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  title={t("inventory.activity")}
                  documentId={id}
                  documentType="goods_transfer"
                />
              </Box>
              <Box
                sx={{
                  display: { sm: "block", md: "flex" },
                  justifyContent: "space-between",
                  my: "2rem",
                }}
              >
                <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
                  <Typography>
                    {t("inventory.transfer.transferedBy")}
                  </Typography>
                  <CustomizedAvatar avatars={[{ ...creator }]} />
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem" }}>
                  <ControlledDatePicker
                    name="created_date"
                    control={control}
                    error={errors.created_date}
                    isOpen={createDateIsOpen}
                    onClose={() => setCreateDateIsOpen(false)}
                    onOpen={() => setCreateDateIsOpen(true)}
                    label={t("inventory.stockEntry.createdDate")}
                    sx={{ width: { sx: "auto", md: 155 } }}
                    disabled
                  />
                  <ControlledDatePicker
                    name="document_date"
                    control={control}
                    error={errors.document_date}
                    isOpen={transferDateIsOpen}
                    onClose={() => setTransferDateIsOpen(false)}
                    onOpen={() => setTransferDateIsOpen(true)}
                    label={t("inventory.transfer.transferDate")}
                    disabled={Boolean(id)}
                    sx={{ width: { sx: "auto", md: 155 } }}
                    required
                  />
                  {Boolean(id) ? (
                    <CustomizedMenuOption
                      label="พิมพ์เอกสาร"
                      options={["พิมพ์เอกสารโอนย้าย"]}
                      size="medium"
                      onSelect={handlePrintDocument}
                    />
                  ) : null}
                </Box>
              </Box>
              <TransferDetail
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                generateDocumentId={generateDocumentId}
                viewOnly={Boolean(id)}
                reset={reset}
                setValueField={setValueField}
                isInventoryPage
              />
              <CustomizedBox>
                <Typography sx={{ fontWeight: 700, mb: 3 }}>
                  {"สแกน Barcode"}
                </Typography>
                {!isCreate ? null : (
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Controller
                        key={"destination_bin_location"}
                        control={barcodeControl}
                        name={"destination_bin_location_document_id"}
                        render={({ field }) => {
                          return (
                            <CustomizedComboBox
                              {...field}
                              options={binLocationOptions}
                              label={t("inventory.chooseLocation")}
                              onChange={(_, newValue) =>
                                field.onChange(newValue ? newValue.value : "")
                              }
                              disabled={
                                Boolean(id) ||
                                currentDestinationWarehouse === ""
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Controller
                        name="barcode"
                        control={barcodeControl}
                        render={({ field }) => {
                          return (
                            <CustomizedTextField
                              {...field}
                              label={t("inventory.scanBarcode")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && e.shiftKey === false) {
                                  const data = e.target.value;
                                  handleBarcodeSubmit(
                                    onBarcodeSubmitHandler(data)
                                  );
                                  setValueField("barcode", "");
                                }
                              }}
                              disabled={Boolean(id)}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <GoodsTransferTable
                  control={control}
                  fields={fields}
                  append={append}
                  remove={remove}
                  setValue={setValue}
                  disabled={id}
                  currentDestinationBinLocation={currentDestinationBinLocation}
                />
              </CustomizedBox>
            </>
          ) : (
            <>
              <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
              <Box my={3}>
                <Tabs
                  centered
                  value={currentTab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor:
                        tabError && currentTab === 0 ? "#F44336" : "#419644",
                    },
                  }}
                >
                  <Tab
                    label="ข้อมูลโอนย้าย"
                    sx={{
                      "&.Mui-selected": {
                        color: tabError ? "#F44336" : "#419644",
                      },
                      color: tabError ? "#F44336" : "#419644",
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab label="สแกน Barcode" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={currentTab} index={0}>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                  >
                    <Typography variant="h5">
                      {t("inventory.transfer.goodsTransferList")}
                    </Typography>
                    <CustomizedStatus status={item.status} />
                  </Box>
                  <IconButton onClick={() => setOpenDrawer(true)}>
                    <HistoryIcon
                      fontSize="small"
                      color=" rgba(0, 0, 0, 0.54)"
                    />
                  </IconButton>
                  <RightDrawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    title={t("inventory.activity")}
                    documentId={id}
                    documentType="goods_transfer"
                  />
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem", mt: 2 }}>
                  <ControlledDatePicker
                    name="created_date"
                    control={control}
                    error={errors.created_date}
                    isOpen={createDateIsOpen}
                    onClose={() => setCreateDateIsOpen(false)}
                    onOpen={() => setCreateDateIsOpen(true)}
                    label={t("inventory.stockEntry.createdDate")}
                    sx={{ width: { sx: "auto", md: 155 } }}
                    disabled
                  />
                  <ControlledDatePicker
                    name="document_date"
                    control={control}
                    error={errors.document_date}
                    isOpen={transferDateIsOpen}
                    onClose={() => setTransferDateIsOpen(false)}
                    onOpen={() => setTransferDateIsOpen(true)}
                    label={t("inventory.transfer.transferDate")}
                    disabled={Boolean(id)}
                    sx={{ width: { sx: "auto", md: 155 } }}
                    required
                  />
                </Box>
                <Box
                  display={"flex"}
                  sx={{ alignItems: "center", gap: 1 }}
                  my={3}
                >
                  <Typography>ผู้สร้าง</Typography>
                  <CustomizedAvatar
                    avatars={
                      item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                    }
                  />
                </Box>
                <TransferDetail
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  generateDocumentId={generateDocumentId}
                  viewOnly={Boolean(id)}
                  reset={reset}
                  setValueField={setValueField}
                  isInventoryPage
                />
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                {!id && (
                  <Grid container rowSpacing={2}>
                    <Grid item xs={10}>
                      <Controller
                        key={"destination_bin_location"}
                        control={barcodeControl}
                        name={"destination_bin_location_document_id"}
                        render={({ field }) => {
                          return (
                            <CustomizedComboBox
                              {...field}
                              options={binLocationOptions}
                              label={t("inventory.chooseLocation")}
                              onChange={(_, newValue) =>
                                field.onChange(newValue ? newValue.value : "")
                              }
                              disabled={Boolean(id)}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Controller
                        control={barcodeControl}
                        name="barcode"
                        render={({ field }) => (
                          <CustomizedTextField
                            {...field}
                            label={t("inventory.scanBarcode")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && e.shiftKey === false) {
                                const data = e.target.value;
                                handleBarcodeSubmit(
                                  onBarcodeSubmitHandler(data)
                                );
                                setValueField("barcode", "");
                              }
                            }}
                            disabled={Boolean(id)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconButton onClick={() => setShowCamera(!showCamera)}>
                        <QrCodeScannerIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {showError.value && (
                  <Box
                    sx={(theme) => ({
                      backgroundColor:
                        showError.type === "success"
                          ? theme.palette.success.light
                          : theme.palette.error.light,
                      width: "maxContent",
                      height: 47,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                    })}
                    mt={1}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          showError.type === "success" ? "#1E4620" : "#621B16",
                      }}
                    >
                      {showError.text}
                    </Typography>
                  </Box>
                )}
                <Box display={showCamera ? "block" : "none"} marginY={2}>
                  {showCamera && (
                    <QrReader
                      constraints={{ facingMode: "environment" }}
                      onResult={(result, error) => {
                        if (!!result) {
                          setScanData(result.text);
                          setIsInterval(true);
                        }
                        if (!!error) {
                          // console.log("error", error);
                          // console.info(error);
                        }
                      }}
                      scanDelay={1000}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </Box>
                <Box margin={showCamera ? "0 0 2rem 0" : "2rem 0"}>
                  <GoodsTransferTable
                    control={control}
                    fields={fields}
                    append={append}
                    remove={remove}
                    disabled={id}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                  />
                </Box>
              </TabPanel>
            </>
          )}
          {(isCreate && !isSmallScreen) ||
          (isCreate && isSmallScreen && currentTab === 1) ? (
            <Box display="flex" gap={1} mt={isSmallScreen ? 2 : 0}>
              <CustomizedButton
                size={isSmallScreen ? "medium" : "small"}
                fullWidth={isSmallScreen}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                title="โอนย้าย"
                disabled={isLoading.item}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default GoodsTransferContainer;
