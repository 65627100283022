import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import moment from "moment";
import { Grid } from "@mui/material";
import { useAuth } from "../../../../hooks/use-auth";
import { contactActions } from "../../../../features/Contact/contact-slice";
import AttachmentCard from "./AttachmentCard";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DocDropzone = ({ mainControl, setValue, getValues, disabled }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { isReset, attachmentIsInit, contactSnapshot } = useSelector(
    (state) => state.contact
  );
  const {
    control,
    setValue: setDocValue,
    // watch,
    formState: { errors },
    reset: resetDocs,
  } = useForm({ defaultValues: { files: getValues("attachment_list") } });
  const { fields, append, remove } = useFieldArray({ control, name: "files" });

  const watchFieldArray = useWatch({ control, name: "files" });
  const watchAttachmentList = useWatch({
    control: mainControl,
    name: "attachment_list",
  });

  console.log(watchFieldArray, watchAttachmentList);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [files, setFiles] = useState(watchFieldArray || []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            attachment_name: file.name,
            tempName: file.name,
            uploaded_by: user,
          })
        ),
      ]);
      acceptedFiles.forEach((file) => {
        append({
          ...file,
          attachment_name: file.name,
          tempName: file.name,
          uploaded_date: moment().unix(),
          uploaded_by: user,
        });
      });
    },
    disabled: disabled,
  });

  useEffect(() => {
    if (!attachmentIsInit && contactSnapshot) {
      const snapshotFiles = contactSnapshot.attachment_list ?? [];
      const formattedSnapshotFiles = snapshotFiles.map((file) => ({
        ...file,
        tempName: file.name,
      }));
      setFiles(formattedSnapshotFiles);
      resetDocs({ files: formattedSnapshotFiles });
      dispatch(contactActions.initializeAttachment());
    }
  }, [attachmentIsInit, contactSnapshot, resetDocs, dispatch]);

  useEffect(() => {
    if (isReset) {
      if (contactSnapshot) {
        const snapshotFiles = contactSnapshot.attachment_list ?? [];
        const formattedSnapshotFiles = snapshotFiles.map((file) => ({
          ...file,
          tempName: file.name,
        }));
        setFiles(formattedSnapshotFiles);
        resetDocs({ files: formattedSnapshotFiles });
      } else {
        setFiles([]);
        resetDocs({ files: [] });
      }
    }
  }, [isReset, contactSnapshot, resetDocs, watchFieldArray]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (index) => () => {
    const newFiles = files.filter(
      (file, currentIndex) => index !== currentIndex
    );
    console.log(newFiles);
    setFiles(newFiles);
    remove(index);
  };

  const updateFileName = (index) => {
    let newFiles = [...files];
    const updatedFileName = watchFieldArray[index].tempName;
    setDocValue(`files.${index}.attachment_name`, updatedFileName);
    newFiles[index].attachment_name = updatedFileName;
    setFiles(newFiles);
  };

  const renderDocCards = () => {
    return controlledFields.map((item, index) => (
      <Grid key={item.id} item xs={12} sm={6} md={3} lg={3} xl={2}>
        <AttachmentCard
          control={control}
          errors={errors}
          fileName={item.attachment_name || item.tempName}
          full_name={`${item.uploaded_by?.first_name || "-"} ${
            item.uploaded_by?.last_name || ""
          }`}
          index={index}
          date={
            moment.unix(item.uploaded_date).format("DD/MM/YYYY HH:mm") || "-"
          }
          onRemove={removeFile(index)}
          onUpdate={updateFileName}
          viewOnly={disabled}
          navigateTo={item.url || null}
        />
      </Grid>
    ));
  };

  useEffect(() => {
    setValue("attachment_list", files);
  }, [files, setValue, getValues]);

  return (
    <>
      {!disabled && (
        <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>ลากมาวางเพื่ออัปโหลด</p>
            </div>
          </section>
        </Grid>
      )}
      <Grid container spacing={2}>
        {files.length > 0 && renderDocCards()}
      </Grid>
    </>
  );
};

export default DocDropzone;
