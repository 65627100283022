import { gql } from "graphql-request";

export const GET_ALL_ESTIMATES = gql`
  query EstimationAggrid($aggridInput: AnyAggridInput!) {
    estimationAggrid(aggridInput: $aggridInput) {
      count
      results {
        document_id
        contact_document_id
        created_by {
          first_name
          img_url
          last_name
          document_id
        }
        created_date
        customer
        delivery_cartage_method
        delivery_count
        delivery_floor
        delivery_method
        delivery_scaffold
        employee_list {
          first_name
          last_name
          document_id
          img_url
        }
        input_attachments {
          id
          name
          url
          uploaded_by {
            document_id
            first_name
            last_name
          }
          uploaded_date
        }
        deliver_attachments {
          id
          name
          url
          uploaded_by {
            document_id
            first_name
            last_name
          }
          uploaded_date
        }
        end_date
        engineer_data
        in_date
        status
        start_date
        issue_date
        job_description
        job_priority
        job_priority_remark
        job_project_type
        job_type
        remark
        contact {
          contact_name_1
          contact_name_2
        }
        revision_count
      }
    }
  }
`;

export const GET_ESTIMATE = gql`
  query Estimation($uniqueInput: EstimationWhereUniqueInput!) {
    estimation(uniqueInput: $uniqueInput) {
      document_id
      contact_document_id
      created_by {
        first_name
        img_url
        last_name
        document_id
      }
      created_date
      customer
      delivery_cartage_method
      delivery_count
      delivery_floor
      delivery_method
      delivery_scaffold
      employee_list {
        first_name
        last_name
        document_id
        img_url
      }
      input_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      deliver_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      end_date
      engineer_data
      in_date
      status
      start_date
      issue_date
      job_description
      job_priority
      job_priority_remark
      job_project_type
      job_type
      remark
      contact {
        contact_name_1
        contact_name_2
      }
      revision_count
    }
  }
`;
