import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../../components/Custom/CustomizedTab";

import AgGrid from "../../../components/Table/AgGrid";
import TotalBox from "../../../components/UI/TotalBox";
import { getAllGoodsIssue } from "../../../features/Inventory/GoodsIssue/goodsIssue-actions";
import {
  unixToDateWithFormat,
  formatDate,
} from "../../../utils/date-converter";
import { goodsIssueActions } from "../../../features/Inventory/GoodsIssue/goodsIssue-slice";
import {
  filterDateWithUnix,
  filterParamsOptions,
} from "../../../utils/filterparams";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import debounce from "lodash/debounce";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { useCallback } from "react";
import CustomizedToolTipAvatar from "../../../components/Custom/CustomizedToolTipAvatar";
import { usePermission } from "../../../hooks/use-auth";

const GoodIssue = () => {
  const scrollRef = useRef();
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const status = searchParams.get("status");
  const { count } = useSelector((state) => state.goodsIssue);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showCamera, setShowCamera] = useState(false);
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.issue.index"),
    },
  ];

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.data.created_date) return "";
        return unixToDateWithFormat(params.data.created_date);
      },
    },
    {
      field: "document_date",
      headerName: t("inventory.issue.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.data.document_date) return "";
        return unixToDateWithFormat(params.data.document_date);
      },
    },
    {
      field: "document_id",
      headerName: t("inventory.issue.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "reference_document_id",
      headerName: t("inventory.referenceNo"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "source_warehouse",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: (params) => params.value.thai_name,
    },
    {
      field: "type",
      headerName: t("inventory.issue.issueType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "ขาย",
          "ขายออนไลน์",
          "ผลิตวัตถุดิบ",
          "ผลิตแปรรูป",
          "ผลิตแปรรูป - PT",
          "ส่งคืน",
          "สินค้าเสีย",
          "อื่นๆ",
        ],
      },
    },
    {
      field: "created_by",
      headerName: t("inventory.issue.exportedBy"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <CustomizedToolTipAvatar avatar={params.value} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params) => {
          if (params.value === "draft") {
            return "ร่าง";
          }
          if (params.value === "finished") {
            return "เสร็จสิ้น";
          }
          return "ยกเลิก";
        },
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.data.status} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const tabs = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    // {
    //   label: t("status.draft"),
    //   path: `${pathname}?status=draft`,
    // },
    // {
    //   label: t("status.finished"),
    //   path: `${pathname}?status=finished`,
    // },
    // {
    //   label: t("status.cancelled"),
    //   path: `${pathname}?status=cancelled`,
    // },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;

      const { filterModel } = request;
      const { created_date, document_date, source_warehouse } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
        document_date: document_date && filterDateWithUnix(document_date),
        source_warehouse: source_warehouse && {
          filterType: "object",
          filter: {
            thai_name: source_warehouse,
          },
        },
      };
      const sortbyId = [
        ...request.sortModel,
        { colId: "created_date", sort: "desc" },
      ];

      dispatch(
        getAllGoodsIssue(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: sortbyId,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const updateTableFilter = (filter) => {
    const instance = gridRef.current.api.getFilterInstance("status");
    switch (filter) {
      case "draft":
        instance.setModel({ values: ["draft"] });
        break;
      case "finished":
        instance.setModel({ values: ["finished"] });
        break;
      case "cancelled":
        instance.setModel({ values: ["cancelled"] });
        break;
      default:
        instance.setModel({});
        break;
    }
    gridRef.current.api.onFilterChanged();
  };

  const updateTableFilterRef = useCallback(
    (filter, camera) => {
      const instance = gridRef.current.api.getFilterInstance(
        "reference_document_id"
      );
      if (camera)
        try {
          const splitValue = filter.split("/")[filter.split("/").length - 2];
          if (splitValue[0] !== "S" && splitValue[1] !== "O")
            return enqueueSnackbar("QRCode ไม่ถูกต้อง", {
              variant: "error",
            });
          if (splitValue)
            instance.setModel({
              filter: splitValue,
              filterType: "text",
              type: "contains",
            });
          else instance.setModel({});
        } catch (error) {
          enqueueSnackbar("QRCode ไม่สามารถใช้งานได้", {
            variant: "error",
          });
        }
      else
        instance.setModel({
          filter: filter.target ? filter.target.value : filter,
          filterType: "text",
          type: "contains",
        });
      gridRef.current.api.onFilterChanged();
    },
    [enqueueSnackbar]
  );

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onFirstDataRendered = () => {
    updateTableFilter(status);
  };

  const onRowClicked = (params) => {
    return navigate(`${params.data.document_id}`);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      updateTableFilter(status);
    }
    return () => {
      dispatch(goodsIssueActions.resetAllItems());
    };
  }, [status, dispatch]);

  const currentTab = pathname + (status ? `?status=${status}` : "");

  const debouncedResults = useMemo(() => {
    return debounce(updateTableFilterRef, 400);
  }, [updateTableFilterRef]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <Box ref={scrollRef}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TotalBox
            title="ทั้งหมด"
            total={count}
            date={formatDate(new Date())}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 3,
          mb: 1,
        }}
      >
        <Typography variant="h5">{t("inventory.issue.index")}</Typography>
        {createPermission && (
          <CustomizedButton
            size={isSmallScreen ? "large" : "small"}
            variant="contained"
            title={t("inventory.issue.add")}
            onClick={() => navigate("add")}
          />
        )}
      </Box>
      {isSmallScreen ? (
        <Box display="flex" gap={1} my={1} alignItems="center">
          <CustomizedTextField
            label={t("inventory.referenceNo")}
            onChange={debouncedResults}
            type="text"
          />
          <IconButton onClick={() => setShowCamera(!showCamera)}>
            <QrCodeScannerIcon />
          </IconButton>
        </Box>
      ) : null}
      {isSmallScreen ? (
        <Box display={showCamera ? "block" : "none"} marginY={2}>
          {showCamera ? (
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result, error) => {
                if (!!result) {
                  updateTableFilterRef(result.text, true);
                }
                if (!!error) {
                  // console.log("error", error);
                  // console.info(error);
                }
              }}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : null}
        </Box>
      ) : null}
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      {!isSmallScreen ? (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onRowDoubleClicked={onRowClicked}
          onFirstDataRendered={onFirstDataRendered}
        />
      ) : (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onRowClicked={onRowClicked}
        />
      )}
    </Box>
  );
};

export default GoodIssue;
