import { Box, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import PurchaseRequestTable from "../../../components/Table/DocumentTable/PurchaseRequestTable";
import {
  getAllPurchaseRequest,
  getAllPurchaseRequestSelf,
} from "../../../features/Purchase/PurchaseRequest/purchase-request-actions";
import { purchaseRequestActions } from "../../../features/Purchase/PurchaseRequest/purchase-request-slice";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { filterDateWithUnix } from "../../../utils/filterparams";

const PurchaseRequest = ({ report }) => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const isFilter = searchParams.get("filter");
  const { createPermission } = usePermission();
  const [allPermissionList, setAllPermissionList] = useState([]);
  const { user } = useAuth();
  const [isViewSelf, setIsViewSelf] = useState(false);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    if (
      allPermissionList.some(
        (permission) => permission === "PURCHASE__REQUEST__VIEW_SELF"
      )
    ) {
      setIsViewSelf(true);
    } else {
      setIsViewSelf(false);
    }
  }, [allPermissionList]);

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
    },
  ];

  report &&
    breadcrumbs.splice(1, 0, {
      name: t("purchase.report.index"),
      to: "/purchase/report",
    });

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.waitApprove"),
      path: `${pathname}?filter=waitApprove`,
    },
    {
      label: t("status.notApproved"),
      path: `${pathname}?filter=notApproved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.partiallyOrdered"),
      path: `${pathname}?filter=partiallyOrdered`,
    },
    {
      label: t("status.fullyOrdered"),
      path: `${pathname}?filter=fullyOrdered`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "waitApprove":
          instance?.setModel({ values: ["PENDING"] });
          break;
        case "notApproved":
          instance?.setModel({ values: ["DECLINED"] });
          break;
        case "waitAccept":
          instance?.setModel({ values: ["waitAccept"] });
          break;
        case "accepted":
          instance?.setModel({ values: ["accepted"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "approved":
          instance?.setModel({ values: ["approved"] });
          break;
        case "partiallyOrdered":
          instance?.setModel({ values: ["partiallyOrdered"] });
          break;
        case "fullyOrdered":
          instance?.setModel({ values: ["fullyOrdered"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { issue_date, due_date, created_by, employee_list } = filterModel;
      const formatFilterModel = {
        ...request.filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        due_date: due_date && filterDateWithUnix(due_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
      };
      dispatch(
        getAllPurchaseRequest(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const viewSelfDatasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { issue_date, due_date, created_by, employee_list } = filterModel;
      const formatFilterModel = {
        ...request.filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        due_date: due_date && filterDateWithUnix(due_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
      };
      dispatch(
        getAllPurchaseRequestSelf(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReadySelf = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(viewSelfDatasource);
  };
  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(purchaseRequestActions.resetPurchaseRequest());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("purchase.request.index")}</Typography>
        {!report && createPermission && (
          <CustomizedButton
            title={t("purchase.request.add")}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      {!report && (
        <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      )}
      {isViewSelf && (
        <PurchaseRequestTable gridRef={gridRef} onGridReady={onGridReadySelf} />
      )}
      {!isViewSelf && (
        <PurchaseRequestTable gridRef={gridRef} onGridReady={onGridReady} />
      )}
    </>
  );
};

export default PurchaseRequest;
