import { Box } from "@mui/material";
import React, { forwardRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { updateUomGroup } from "../../../features/Setting/Uom/uom-actions";

const ConversionForm = forwardRef(({ handleClose }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uomGroup, allUoms } = useSelector((state) => state.uom);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: uomGroup,
    resolver: yupResolver(validation),
  });

  const { fields, append, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "uom_conversion_list", // unique name for your Field Array
  });

  const mapValueUom = (value) => {
    // console.log("value", value);
    const mapValue = allUoms.find((uom) => uom.document_id === value);
    if (mapValue) return mapValue.name || "";
    else return "";
  };

  const columnDefs = [
    {
      field: "target_uom_rate",
      headerName: t("setting.inventory.conversion.quantity"),
      cellRenderer: (params) => {
        return (
          <Box sx={{ mt: "2px" }}>
            <Controller
              name={`uom_conversion_list[${params.node.rowIndex}].target_uom_rate`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  type={"number"}
                  error={Boolean(
                    errors.uom_conversion_list?.[params.node.rowIndex]
                      ?.target_uom_rate
                  )}
                  helperText={
                    errors.uom_conversion_list?.[params.node.rowIndex]
                      ?.target_uom_rate?.message
                  }
                  defaultValue=""
                  {...field}
                  onChange={(e) => {
                    if (e.target.value !== null)
                      setValue(
                        `uom_conversion_list[${params.node.rowIndex}].target_uom_rate`,
                        parseFloat(e.target.value)
                      );
                    else
                      setValue(
                        `uom_conversion_list[${params.node.rowIndex}].target_uom_rate`,
                        e.target.value
                      );
                  }}
                />
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "target_uom_document_id",
      headerName: t("setting.inventory.uom.index"),
      floatingFilter: false,
      cellRenderer: (params) => {
        return (
          <Box sx={{ mt: "2px" }}>
            <Controller
              name={`uom_conversion_list[${params.node.rowIndex}].target_uom_document_id`}
              error={errors}
              control={control}
              render={({ field }) => (
                <CustomizedComboBox
                  options={allUoms?.map((uom) => {
                    return {
                      id: uom.document_id,
                      value: uom.document_id,
                      label: uom.name,
                    };
                  })}
                  {...field}
                  value={mapValueUom(field.value)}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.id : "");
                  }}
                />
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "base_uom_rate",
      headerName: t("setting.inventory.conversion.quantity"),
      cellRenderer: (params) => {
        return (
          <Box sx={{ mt: "2px" }}>
            <Controller
              name={`uom_conversion_list[${params.node.rowIndex}].base_uom_rate`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  type="number"
                  error={Boolean(
                    errors.uom_conversion_list?.[params.node.rowIndex]
                      ?.base_uom_rate
                  )}
                  helperText={
                    errors.uom_conversion_list?.[params.node.rowIndex]
                      ?.base_uom_rate?.message
                  }
                  defaultValue=""
                  {...field}
                  onChange={(e) => {
                    if (e.target.value !== null)
                      setValue(
                        `uom_conversion_list[${params.node.rowIndex}].base_uom_rate`,
                        parseFloat(e.target.value)
                      );
                    else
                      setValue(
                        `uom_conversion_list[${params.node.rowIndex}].base_uom_rate`,
                        e.target.value
                      );
                  }}
                />
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "base_uom_document_id",
      headerName: t("setting.inventory.conversion.baseUom"),
      cellRenderer: (params) => {
        return (
          <Box sx={{ mt: "2px" }} alignItems={"center"}>
            <Controller
              name={`base_uom_document_id`}
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  disabled
                  options={
                    allUoms?.map((uom) => {
                      return {
                        id: uom.document_id,
                        value: uom.document_id,
                        label: uom.name,
                      };
                    }) || []
                  }
                  {...field}
                />
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 90,
      cellRenderer: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                const newValue = fields.filter(
                  (_, index) => index !== params.node.rowIndex
                );
                replace(newValue);
              }}
            >
              <ClearIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const onSubmit = (data) => {
    delete data.id;
    delete data.base_uom;

    const input = {
      uniqueInput: {
        document_id: uomGroup.document_id,
      },
      updateInput: {
        ...data,
        convertable_uom_list: data.convertable_uom_list.map((uomList) => {
          return uomList.document_id;
        }),
        uom_conversion_list: data.uom_conversion_list.map((uomConversion) => {
          return {
            target_uom_document_id: uomConversion.target_uom_document_id,
            base_uom_rate: parseFloat(uomConversion.base_uom_rate),
            target_uom_rate: parseFloat(uomConversion.target_uom_rate),
          };
        }),
      },
    };

    dispatch(updateUomGroup({ input }));
    handleClose();
  };

  const handleAppendData = () => {
    append({
      base_uom_rate: 1,
      target_uom_rate: 1,
      base_uom_document_id: uomGroup.base_uom_document_id,
      target_uom_document_id: allUoms[0].document_id,
    });
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
      <Box sx={{ my: 1 }}>
        <div
          className="ag-theme-material"
          style={{ height: 400, width: "720px" }}
        >
          <AgGridReact ref={ref} rowData={fields} columnDefs={columnDefs} />
        </div>
        <CustomizedButton
          onClick={handleAppendData}
          title={t("button.add")}
          variant="outlined"
          sx={{ mt: 1, mr: 2 }}
        />
      </Box>
      <CustomizedButton
        type="reset"
        onClick={resetForm}
        title={t("button.cancel")}
        variant="outlined"
        sx={{ mt: 1, mr: 2 }}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
        sx={{ mt: 1 }}
      />
    </form>
  );
});

export default ConversionForm;
