import { useState, useRef } from "react";
import { Grid, Box, Typography, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import ImporterDropzoneUI from "../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../components/UI/ModalUI";
import ContactService from "../../services/Contact";
import AgGrid from "../../components/Table/AgGrid";
import { useAuth } from "../../hooks/use-auth";
import { formatFloat, formatStringArray } from "../../utils/dataTransformer";

const keys = [
  "finance.bank_list",
  "contact_channel_list",
  "contact_person_list",
  "address_list",
  "contact_group",
  "estimate_sales_item_group",
  ["contact_person_list", "contact_channel_list"],
];

const requiredKeys = ["document_id", "contact_name_1"];

const ContactImporterPage = () => {
  //keys need to be defined in order
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "contact_type_1",
      headerName: t("contact.info.business_type"),
      filter: "agTextColumnFilter",
    },
    {
      field: "is_vendor",
      headerName: t("contact.contact_type.index"),
      filter: false,
      cellRenderer: ({ data }) => {
        if (data.is_vendor && data.is_customer) {
          return "ลูกค้า, ผู้ขาย";
        }
        if (data.is_vendor) {
          return "ผู้ขาย";
        }
        if (data.is_customer) {
          return "ลูกค้า";
        }
        return "";
      },
    },
    {
      field: "is_customer",
      filter: "agTextColumnFilter",
      filterParams: {
        textMatcher: ({ data, filterText }) => {
          return data.is_customer.toString() === filterText;
        },
      },
      hide: true,
    },
    {
      field: "contact_name_1",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      cellRenderer: ({ data }) => {
        if (data.contact_type_1 === "นิติบุคคล") {
          return data.contact_name_1;
        }
        return `${data.contact_name_1} ${data.contact_name_2}`;
      },
    },
    {
      field: "contact_status",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"],
      },
      cellRenderer: (params) => {
        if (params.value === "active") {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="in_active" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const breadcrumbs = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: "นำเข้าผู้ติดต่อ",
    },
  ];

  const formatContactData = async (data) => {
    const isAllNull = (item) => {
      if (typeof item === "object" && item !== null) {
        for (const val of Object.values(item)) {
          if (!isAllNull(val)) {
            return false;
          }
        }
      } else if (item !== null) {
        return false;
      }
      return true;
    };
    const formatAddressList = (addressList) => {
      const result = addressList?.map((address, index) => {
        return {
          ...address,
          default_address: index === 0 ? true : false,
          same_as_default_address: index === 0 ? undefined : false,
          sub_district: address.sub_district?.toString().trim() ?? "",
          district: address.district?.toString().trim() ?? "",
          province: address.province?.toString().trim() ?? "",
          address: address.address?.toString().trim() ?? "",
          address_contact_name: address.address_contact_name ?? "",
          address_contact_phone: address.address_contact_phone ?? "",
          postal_code: address.postal_code?.toString() ?? "",
          country: address.country?.replace("ประเทศ", "").trim() ?? "",
        };
      });
      return result;
    };

    const formatContactChannelList = (contactChannelList) => {
      const result = contactChannelList
        ?.map((channel) => {
          return {
            contact_channel: channel.contact_channel?.toString().trim() ?? "",
            contact_info_1: channel.contact_info_1?.toString().trim() ?? "",
            contact_info_2: channel.contact_info_2?.toString().trim() ?? "",
          };
        })
        .filter((channel) => {
          return channel.contact_channel && channel.contact_info_1;
        });
      return result;
    };

    const formatContactPersonList = (contactPersonList) => {
      const result = contactPersonList
        ?.map((person) => {
          const formattedContactChannelList = formatContactChannelList(
            person.contact_channel_list
          );
          return {
            contact_channel_list: formattedContactChannelList,
            img_url: person.img_url?.trim()
              ? person.img_url?.trim()
              : undefined,
            initial: person.initial?.trim()
              ? person.initial?.trim()
              : undefined,
            name_1: person.name_1?.trim() ? person.name_1?.trim() : undefined,
            name_2: person.name_2?.trim() ? person.name_2?.trim() : undefined,
            name_3: person.name_3?.trim() ? person.name_3?.trim() : undefined,
            position: person.position?.trim()
              ? person.position?.trim()
              : undefined,
            remark: person.remark?.trim() ? person.remark?.trim() : undefined,
          };
        })
        .filter((person) => {
          return !Object.values(person).every(
            (item) => typeof item === "undefined" || typeof item === "object"
          );
        });
      return result;
    };

    const formatBankList = (bankList) => {
      const result = bankList
        ?.map((account) => {
          return {
            bank_name: account.bank_name?.toString().trim(),
            bank_account_name: account.bank_account_name?.toString().trim(),
            bank_account_no: account.bank_account_no?.toString().trim(),
            bank_account_branch: account.bank_account_branch?.toString().trim(),
            bank_account_type: account.bank_account_type?.toString().trim(),
            description: account.description?.toString().trim(),
          };
        })
        .filter((account) => {
          return !Object.values(account).every(
            (item) => typeof item === "undefined"
          );
        });
      return result;
    };

    const formatAttachmentList = (attachmentList) => {
      return attachmentList
        ? attachmentList
            .map((attachment) => {
              return {
                name: attachment?.name?.toString().trim(),
                url: attachment?.url?.toString().trim(),
                creator_document_id: attachment?.creator_document_id
                  ?.toString()
                  .trim(),
              };
            })
            .filter((attachment) => isAllNull(attachment))
        : [];
    };

    try {
      const formattedData = data.map((item) => {
        return {
          ...item,
          created_date: moment().unix(),
          document_id: item.document_id?.toString().trim(),
          creator_document_id: user.document_id,
          is_vendor:
            item.is_vendor && item.is_vendor !== null ? item.is_vendor : false,
          is_customer:
            item.is_customer && item.is_vendor !== null
              ? item.is_customer
              : false,
          identity_no: item.identity_no?.toString().trim(),
          contact_name_1: item.contact_name_1
            ?.replace("หจก.", "")
            .replace("บริษัท", "")
            .replace("คุณ", "")
            .trim(),
          contact_status:
            item.contact_status === "inactive" ? "inactive" : "active",
          finance: {
            ...item.finance,
            is_cash:
              item.finance.is_cash && item.finance.is_cash !== null
                ? item.finance.is_cash
                : false,
            is_cheque:
              item.finance.is_cheque && item.finance.is_cheque !== null
                ? item.finance.is_cheque
                : false,
            is_transfer:
              item.finance.is_transfer && item.finance.is_transfer !== null
                ? item.finance.is_transfer
                : false,
            bank_list: formatBankList(item.finance.bank_list),
            account_receivable_id:
              item.finance.account_receivable_id?.toString(),
            account_payable_id: item.finance.account_payable_id?.toString(),
            request_credit: {
              credit_limit_day: isNaN(
                parseInt(item.finance.request_credit.credit_limit_day)
              )
                ? undefined
                : parseInt(item.finance.request_credit.credit_limit_day),
              credit_limit_value: isNaN(
                parseInt(item.finance.request_credit.credit_limit_value)
              )
                ? undefined
                : parseInt(item.finance.request_credit.credit_limit_value),
            },
            billing_contact_name: item.finance.billing_contact_name
              ?.toString()
              .trim(),
            billing_contact_phone: item.finance.billing_contact_phone
              ?.toString()
              .trim(),
            billing_day: !isNaN(parseInt(item.finance.billing_day))
              ? parseInt(item.finance.billing_day)
              : undefined,
            credit_count: item.finance.credit_count?.toString().trim(),
            payment_day: !isNaN(parseInt(item.finance.payment_day))
              ? parseInt(item.finance.payment_day)
              : undefined,
          },
          img_url: item.img_url?.toString().trim(),
          contact_source: item.contact_source?.toString().trim(),
          contact_group: formatStringArray(item.contact_group),
          estimate_sales_item_group: formatStringArray(
            item.estimate_sales_item_group
          ),
          estimate_sales_volume: !isNaN(parseFloat(item.estimate_sales_volume))
            ? formatFloat(item.estimate_sales_volume)
            : undefined,
          registered_capital: !isNaN(parseFloat(item.registered_capital))
            ? formatFloat(item.registered_capital)
            : undefined,
          last_updator_document_id: item.last_updator_document_id ?? undefined,
          remark: item.remark?.toString().trim(),
          address_list: formatAddressList(item.address_list),
          contact_channel_list: formatContactChannelList(
            item.contact_channel_list
          ),
          contact_person_list: formatContactPersonList(
            item.contact_person_list
          ),
          attachment_list: formatAttachmentList(item.attachment_list),
        };
      });
      return formattedData;
    } catch (err) {
      console.log(err);
      setCreateErrors([
        {
          type: "template",
        },
      ]);
      setHasCreateError(true);
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setIsLoading(true);
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      try {
        const data = await ContactService.createContacts({
          createManyInput: formattedData,
        });
        console.log(data);
        if (data.errors.length === 0) {
          enqueueSnackbar("นำเข้าผู้ติดต่อสำเร็จ", {
            variant: "success",
          });
          navigate("/contact");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        enqueueSnackbar("ไม่สามารถนำเข้าผู้ติดต่อได้", {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  const renderCreateErrors = createErrors.map((error, index) => {
    if (error.type === "template") {
      return (
        <Typography key={index} variant="body1" color="error">
          Template ไม่ถูกต้อง กรุณาอัปโหลดใหม่
        </Typography>
      );
    }
    let errorMessage;

    if (error.type === "required") {
      errorMessage = ", โปรดใส่ Required Field ให้ครบ";
    } else if (error.errorMessage === "document_id is already in use.") {
      errorMessage = " เนื่องจากมีอยู่แล้วในระบบ";
    } else {
      errorMessage = "";
    }

    return (
      <Typography
        key={error.document_id || index}
        sx={{ mb: 2 }}
        variant="body2"
        color="error"
      >
        {`ไม่สามารถสร้าง ${error.document_id}${errorMessage}`}
      </Typography>
    );
  });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าผู้ติดต่อ"}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatContactData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={formattedData.length === 0 || isLoading}
            />
            <CustomizedButton
              title="ดาวน์โหลด Template"
              variant="contained"
              onClick={() =>
                window.open("/static/contact_import_template.xlsx", "_blank")
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={formattedData}
            groupDefaultExpanded={1}
            disableFloatingFilter
          />
        </Box>
      )}
      <ModalUI
        open={hasCreateError}
        handleClose={() => setHasCreateError(false)}
        fullWidth
        maxWidth="xs"
      >
        {renderCreateErrors}
      </ModalUI>
    </>
  );
};

export default ContactImporterPage;
