import moment from "moment";
import * as Yup from "yup";

export const estimateInitialState = {
  isLoading: {
    allEstimates: false,
    estimate: false,
  },
  allEstimates: [],
  estimate: {
    document_id: "",
    created_date: moment().startOf("day").unix(),
    issue_date: moment().startOf("day").unix(),
    start_date: moment().startOf("day").unix(),
    end_date: moment().endOf("day").add(7, "days").unix(),
    is_reproduction: false,
    is_installation: false,
    is_adjustment: false,
    job_project_type: "",
    job_description: "",
    job_priority: "ทั่วไป",
    job_priority_remark: "",
    delivery_method: "",
    delivery_count: "",
    delivery_floor: "",
    delivery_scaffold: "",
    employee_list: [],
    creator_document_id: "",
    contact_document_id: "",
    created_by: null,
    delivery_cartage_method: [],
    revision_count: 1,
    engineer_data: [
      {
        group_name: "",
        group_area: "",
        group_uom: "",
        category_list: [
          {
            category_name: "วัสดุไม้",
            item_list: [],
          },
          {
            category_name: "วัสดุประกอบ",
            item_list: [],
          },
          {
            category_name: "วัสดุอุปกรณ์หลัก",
            item_list: [],
          },
          {
            category_name: "วัสดุสิ้นเปลือง",
            item_list: [],
          },
          {
            category_name: "บริการ",
            item_list: [],
          },
          {
            category_name: "อื่นๆ",
            item_list: [],
          },
        ],
      },
    ],
    input_attachments: [],
    deliver_attachments: [],
    remark: "",
  },
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

const itemValidation = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
  reserved_qty: Yup.number().when("status", {
    is: "closed",
    then: (schema) =>
      schema
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .positive("กรุณาระบุจำนวน")
        .required("กรุณาระบุจำนวน"),
  }),
});

const category = Yup.object().shape({
  group_name: Yup.string().required("กรุณาระบุชื่อกลุ่ม"),
  group_uom: Yup.string(),
  group_area: Yup.string(),
  category_list: Yup.array()
    .of(
      Yup.object().shape({
        item_list: Yup.array().of(itemValidation),
      })
    )
    .transform((list) => {
      return list.filter((item) => item.item_list.length !== 0);
    })
    .min(1, "กรุณาระบุสินค้าอย่างน้อย 1 ชิ้นในกลุ่ม"),
});

export const estimateValidation = Yup.object().shape(
  {
    document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
    status: Yup.string().nullable(),
    issue_date: Yup.date()
      .required("กรุณาระบุวันที่ออกเอกสาร")
      .nullable()
      .transform((value) => dateIsValid(value)),
    is_reproduction: Yup.boolean().when(["is_installation", "is_adjustment"], {
      is: (is_installation, is_adjustment) =>
        !is_installation && !is_adjustment,
      then: Yup.boolean().oneOf([true], "กรุณาระบุประเภทงาน"),
    }),
    is_installation: Yup.boolean().when(["is_reproduction", "is_adjustment"], {
      is: (is_reproduction, is_adjustment) =>
        !is_reproduction && !is_adjustment,
      then: Yup.boolean().oneOf([true], "กรุณาระบุประเภทงาน"),
    }),
    is_adjustment: Yup.boolean().when(["is_reproduction", "is_installation"], {
      is: (is_reproduction, is_installation) =>
        !is_reproduction && !is_installation,
      then: Yup.boolean().oneOf([true], "กรุณาระบุประเภทงาน"),
    }),
    job_project_type: Yup.string().required("กรุณาระบุข้อมูลลักษณะงาน"),
    job_description: Yup.string().required("กรุณาระบุลักษณะงานตัวอย่าง"),
    input_attachments: Yup.array().min(1, "กรุณาระบุเอกสารเปิดงาน"),
    engineer_data: Yup.array().of(category),
  },
  [
    ["is_reproduction", "is_installation"],
    ["is_reproduction", "is_adjustment"],
    ["is_installation", "is_adjustment"],
  ]
);
