import { gql } from "graphql-request";

export const GET_DOCUMENT_ID = gql`
  query Query($prefix: prefix!) {
    generateDocumentId(prefix: $prefix)
  }
`;

export const GET_RUNNING_NUMBER = gql`
  query Query($documentType: DocumentType!) {
    generateRunningNumber(documentType: $documentType)
  }
`;

export const GET_DOCUMENT_RELATION = gql`
  query DocumentRelations($documentId: String!) {
    documentRelations(document_id: $documentId) {
      id
      document_id
      document_type
      reference_document_id
      reference_document_type
    }
  }
`;

export const GET_REFERENCE_DOCUMENT_RELATION = gql`
  query ReferenceDocumentRelations($referenceDocumentId: String!) {
    referenceDocumentRelations(reference_document_id: $referenceDocumentId) {
      id
      document_id
      document_type
      reference_document_id
      reference_document_type
    }
  }
`;

export const GET_TAG_LIST = gql`
  query Results($aggridInput: AnyAggridInput) {
    tagsAggrid(aggridInput: $aggridInput) {
      results {
        id
        name
        entity
      }
      count
    }
  }
`;

export const GET_TAG_LIST_BY_ENTITY = gql`
  query TagsFindByEntity($entityName: entityType) {
    tagsFindByEntity(entityName: $entityName) {
      id
      name
      entity
    }
  }
`;

export const TRIGGER_CRON = gql`
  query TriggerCron {
    triggerIslateCron
  }
`;

export const GET_CREATABLES = gql`
  query CreatablesFindByField($findManyInput: CreatableFindByFieldInput!) {
    creatablesFindByField(findManyInput: $findManyInput) {
      name
      usage_field_type
      usage_field_name
    }
  }
`;
