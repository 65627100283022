import { gql } from "graphql-request";

export const CREATE_ESTIMATE = gql`
  mutation EstimationCreate($createInput: EstimationCreateInput!) {
    estimationCreate(createInput: $createInput) {
      document_id
      contact_document_id
      created_by {
        first_name
        img_url
        last_name
        document_id
      }
      created_date
      customer
      delivery_cartage_method
      delivery_count
      delivery_floor
      delivery_method
      delivery_scaffold
      employee_list {
        first_name
        last_name
        document_id
        img_url
      }
      end_date
      engineer_data
      in_date
      status
      start_date
      issue_date
      job_description
      job_priority
      job_priority_remark
      job_project_type
      job_type
      remark
      contact {
        contact_name_1
        contact_name_2
      }
      input_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      deliver_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      revision_count
    }
  }
`;

export const UPDATE_ESTIMATE = gql`
  mutation EstimationUpdate(
    $uniqueInput: EstimationWhereUniqueInput!
    $updateInput: EstimationUpdateInput!
  ) {
    estimationUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      document_id
      contact_document_id
      created_by {
        first_name
        img_url
        last_name
        document_id
      }
      created_date
      customer
      delivery_cartage_method
      delivery_count
      delivery_floor
      delivery_method
      delivery_scaffold
      employee_list {
        first_name
        last_name
        document_id
        img_url
      }
      end_date
      engineer_data
      in_date
      status
      start_date
      issue_date
      job_description
      job_priority
      job_priority_remark
      job_project_type
      job_type
      remark
      contact {
        contact_name_1
        contact_name_2
      }
      input_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      deliver_attachments {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      revision_count
    }
  }
`;
