import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import EstimateTable from "../../../components/Table/DocumentTable/EstimateTable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { estimateActions } from "../../../features/Engineer/Estimate/estimate-slice";
import { getAllEstimates } from "../../../features/Engineer/Estimate/estimate-actions";
import { filterDateWithUnix } from "../../../utils/filterparams";

const Estimate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      name: t("engineer.index"),
      to: "/engineer",
    },
    {
      name: t("engineer.estimate.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.new_estimation"),
      path: `${pathname}?filter=new_estimation`,
    },
    {
      label: t("status.audited"),
      path: `${pathname}?filter=audited`,
    },
    {
      label: t("status.queuing"),
      path: `${pathname}?filter=queuing`,
    },
    {
      label: t("status.assigning"),
      path: `${pathname}?filter=assigning`,
    },
    {
      label: t("status.work_in_progress"),
      path: `${pathname}?filter=work_in_progress`,
    },
    {
      label: t("status.reviewing"),
      path: `${pathname}?filter=reviewing`,
    },
    {
      label: t("status.closed"),
      path: `${pathname}?filter=closed`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact,
        issue_date,
        start_date,
        end_date,
        created_by,
        employee_list,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact: contact && {
          operator: "OR",
          condition1: {
            filterType: "object",
            filter: {
              contact_name_1: {
                filterType: "text",
                type: "contain",
                filter: contact.filter,
              },
            },
          },
          condition2: {
            filterType: "object",
            filter: {
              contact_name_2: {
                filterType: "text",
                type: "contain",
                filter: contact.filter,
              },
            },
          },
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        start_date: start_date && filterDateWithUnix(start_date),
        end_date: end_date && filterDateWithUnix(end_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
      };

      dispatch(
        getAllEstimates(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          break;
        case "new_estimation":
          instance.setModel({ values: ["new_estimation"] });
          break;
        case "audited":
          instance.setModel({ values: ["audited", "not_approve_audit"] });
          break;
        case "queuing":
          instance.setModel({ values: ["queuing", "not_approve_queue"] });
          break;
        case "assigning":
          instance.setModel({ values: ["assigning"] });
          break;
        case "work_in_progress":
          instance.setModel({ values: ["work_in_progress"] });
          break;
        case "reviewing":
          instance.setModel({ values: ["reviewing", "not_approve_review"] });
          break;
        case "closed":
          instance.setModel({ values: ["closed"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          break;
        default:
          instance.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef?.current?.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(estimateActions.resetAllEstimates());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("engineer.estimate.index")}</Typography>
        <CustomizedButton
          title={`${t("button.add")}${t("engineer.estimate.index")}`}
          variant="contained"
          onClick={() => navigate("/engineer/estimate/add")}
        />
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <EstimateTable gridRef={gridRef} onGridReady={onGridReady} />
    </>
  );
};

export default Estimate;
