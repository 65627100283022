import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import SettingService from "../../services/Setting";

const PDFHeader = ({ documentType, noCompany, imgSize }) => {
  const [companyInfo, setCompanyInfo] = useState({
    company_type: "",
    company_name: "-",
    company_address: "-",
    identity_no: "-",
    contact_phone: "-",
  });

  useEffect(() => {
    const getCompany = async () => {
      const companyInfoData = await SettingService.getCompanySetting(1);
      const company_address = companyInfoData.address_list.find(
        (address) => address.address_type === "ที่อยู่จดทะเบียน"
      );
      const formatCompanyData = {
        company_type:
          companyInfoData.contact_type_2 && companyInfoData.contact_type_2,
        company_name: companyInfoData.company_name,
        company_address,
        identity_no: companyInfoData.identity_no,
        contact_phone: companyInfoData.contact_channel_list
          .filter((contact) => contact.contact_channel === "เบอร์โทรศัพท์")
          .map((info) => info.contact_info_1)
          .join(", "),
        contact_fax: companyInfoData.contact_channel_list
          .filter((contact) => contact.contact_channel === "แฟกซ์")
          .map((info) => info.contact_info_1)
          .join(", "),
      };
      setCompanyInfo(formatCompanyData);
    };
    getCompany();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <img width={imgSize ?? 200} alt="logo" src="/static/logo.png" />
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                {companyInfo.company_type} {companyInfo.company_name}{" "}
                สำนักงานใหญ่
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                {companyInfo.company_address.address}{" "}
                {companyInfo.company_address.sub_district}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                {companyInfo.company_address.district} จังหวัด
                {companyInfo.company_address.province}{" "}
                {companyInfo.company_address.postal_code}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                โทรศัพท์ {companyInfo.contact_phone || "-"} แฟกซ์{" "}
                {companyInfo.contact_fax || "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                เลขประจำตัวผู้เสียภาษีอากร {companyInfo.identity_no}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {documentType !== "โอนย้าย" && documentType !== "สูตรการผลิต/BOM" && (
            <Typography
              sx={{ fontSize: 9, fontWeight: 600, textAlign: "right" }}
            >
              (ต้นฉบับ/Original)
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {documentType}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
