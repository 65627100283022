import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { workCenterValidation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import {
  createWorkCenter,
  updateWorkCenter,
} from "../../../../features/Setting/Manufacture/manufacture-actions";
import { useSnackbar } from "notistack";
// import { useSnackbar } from "notistack";

const WorkCenterForm = ({ handleClose, type, open }) => {
  const dispatch = useDispatch();
  const { workCenter } = useSelector((state) => state.workCenter);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: workCenter,
    resolver: yupResolver(workCenterValidation),
  });

  useEffect(() => {
    if (workCenter.name) reset(workCenter);
    else reset();
  }, [workCenter, reset]);

  const { t } = useTranslation();

  const onSubmit = (data) => {
    try {
      if (type === "create") {
        dispatch(createWorkCenter(data, enqueueSnackbar));
      } else {
        const { name, ...otherData } = data;
        dispatch(
          updateWorkCenter(
            {
              uniqueInput: { name },
              updateInput: otherData,
            },
            enqueueSnackbar
          )
        );
      }
    } catch (error) {
      console.log("");
    }
    handleClose();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.code === "Enter") e.preventDefault();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={"plant"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                label={t("setting.manufacture.factory")}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={"name"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                label={t("setting.manufacture.workCenter")}
                disabled={type === "edit"}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={"description"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                label={t("setting.manufacture.description")}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={"remark"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                label={t("setting.manufacture.remark")}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CustomizedButton
        onClick={handleClose}
        title={t("button.cancel")}
        variant="outlined"
        sx={{ mt: 3, mr: 2 }}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
        sx={{ mt: 3 }}
      />
    </form>
  );
};

export default WorkCenterForm;
