import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../../../utils/date-converter";

const PurchaseOrderPDFInfo = ({
  purchaseOrder,
  contact,
  destinationWarehouse,
}) => {
  const renderAddressString = (addressValues) => {
    let result = "";

    if (Array.isArray(addressValues)) {
      addressValues.forEach((item) => {
        if (item.length > 0) {
          result = result + item + ", ";
        }
      });
    } else {
      const keys = Object.keys(addressValues);
      const filteredKey = keys.filter(
        (key) => key !== "address_type" && key !== "is_same_as_default_address"
      );
      filteredKey.forEach((key) => {
        if (addressValues[key].length > 0) {
          result = result + addressValues[key] + ", ";
        }
      });
    }
    if (result.trim().length === 0) {
      return "-";
    }
    // remove whitespace and last comma
    return result.trim().slice(0, -1);
  };

  const leftHeader = [
    "รหัสผู้ขาย / Vendor ID",
    "ชื่อผู้ขาย / Vendor Name",
    "ที่อยู่ / Address",
    "เลขผู้เสียภาษี / Tax ID",
    "เบอร์โทรศัพท์ / Phone",
    "แฟกซ์ / Fax",
    "อีเมล / Email",
  ];

  const leftInfo = [
    purchaseOrder?.contact_document_id || "-",
    contact?.contact_name || "-",
    renderAddressString(purchaseOrder?.vendor?.billing_address || {}) || "-",
    contact?.identity_no || "-",
    purchaseOrder?.vendor?.phone || "-",
    purchaseOrder?.vendor?.fax || "-",
    purchaseOrder?.vendor?.email || "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "ที่อยู่จัดส่งผู้รับ / Delivery Address",
    "คลังปลายทาง / Destination Warehouse",
    "เครดิต / Credit",
    "วันที่ออกเอกสาร / Issue date",
    "ต้องการภายในวันที่ / Due date",
    "วันประมาณการณ์สินค้าเข้า / Expect Date",
  ];

  const rightInfo = [
    purchaseOrder?.document_id || "-",
    purchaseOrder?.reference_document_list &&
    purchaseOrder?.reference_document_list.length > 0
      ? purchaseOrder?.reference_document_list
          .map((ref) => ref.reference_document_id)
          .join(", ")
      : "-",
    renderAddressString(purchaseOrder?.delivery_address || {}) || "-",
    destinationWarehouse || "-",
    purchaseOrder?.credit_day ? purchaseOrder?.credit_day + " วัน" : "-",
    formatDate(purchaseOrder?.issue_date) || "-",
    formatDate(purchaseOrder?.due_date) || "-",
    formatDate(purchaseOrder?.expect_date) || "-",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={6}>
        {leftHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={6}>
        {rightHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {rightInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default PurchaseOrderPDFInfo;
