export const initialState = {
  isLoading: {
    allItems: false,
    item: false,
  },
  allItems: [],
  count: [],
  item: {
    document_id: "",
    type: "ส่งคืน",
    reference_document_id: "",
    source_warehouse_document_id: "",
    remark: "",
    created_date: null,
    document_date: null,
    goods_issue_list: [],
    delivery_address: "",
    start_delivery_date: null,
    end_delivery_date: null,
    delivery_employee: "",
    google_map_link: "",
  },
  error: null,
};
