import React, { useCallback, useRef } from "react";
import { filteredRow } from "../../../utils/dataTransformer";
import AgGrid from "../../Table/AgGrid";

const CrmPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "PROJECT",
        name: "โครงการ",
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "APPOINTMENT",
        name: "การนัดหมาย",
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
    ];

    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default CrmPermission;
