import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  estimateStatusValue,
  filterParamsOptions,
} from "../../../utils/filterparams";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import CustomizedStatus from "../../Custom/CustomizedStatus";
// import { formatNumber } from "../../../utils/dataTransformer";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import AgGrid from "../AgGrid";

const EstimateTable = ({ gridRef, onGridReady }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("engineer.estimate.document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 250,
    },
    {
      field: "job_type",
      headerName: t("engineer.estimate.job_type"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ถอดแบบ", "ติดตั้ง", "งานเพิ่ม/ลด"],
      },
    },
    {
      field: "job_priority",
      headerName: t("engineer.estimate.job_priority"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "created_date",
      headerName: t("engineer.estimate.created_date"),
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "issue_date",
      headerName: t("engineer.estimate.issue_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.issue_date),
      width: 200,
    },
    {
      field: "start_date",
      headerName: t("engineer.estimate.start_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.start_date),
      width: 200,
    },
    {
      field: "end_date",
      headerName: t("engineer.estimate.end_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.end_date),
      width: 200,
    },
    {
      field: "created_by",
      headerName: t("sales.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.value) {
          return <CustomizedAvatar avatars={[params?.data?.created_by]} />;
        }
      },
      width: 150,
      sortable: false,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.value && params.value.length > 0) {
          return <CustomizedAvatar avatars={params?.data?.employee_list} />;
        }
      },
      sortable: false,
    },
    {
      field: "status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return estimateStatusValue(params.value);
        },
        values: [
          "draft",
          "new_estimation",
          "not_approve_audit",
          "audited",
          "not_approve_queue",
          "queuing",
          "assigning",
          "work_in_progress",
          "not_approve_review",
          "reviewing",
          "closed",
          "finished",
          "cancelled",
        ],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
  ];

  const onRowDoubleClicked = (params) => {
    navigate(
      `/engineer/estimate/${encodeURIComponent(params.data.document_id)}`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default EstimateTable;
