import { unixToDateTimeWithFormat } from "./date-converter";

const formatStatus = (status) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "waitApprove":
      return "รออนุมัติ";
    case "notApproved":
      return "ไม่อนุมัติ";
    case "waitAccept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "approved":
      return "อนุมัติแล้ว";
    case "waitPayment":
      return "รอชำระ";
    case "partiallyPaid":
      return "ชำระแล้วบางส่วน";
    case "partiallyImported":
      return "นำเข้าแล้วบางส่วน";
    case "partiallyOrdered":
      return "สั่งซื้อแล้วบางส่วน";
    case "fullyOrdered":
      return "สั่งซื้อแล้ว";
    case "fullyImported":
      return "นำเข้าแล้ว";
    case "fullyPaid":
      return "ชำระแล้ว";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    case "new_estimation":
      return "งานถอดแบบใหม่";
    case "not_approve_audit":
      return "ไม่อนุมัติ - การตรวจสอบ";
    case "audited":
      return "ตรวจสอบแล้ว";
    case "not_approve_queue":
      return "ไม่อนุมัติ - การลงคิว";
    case "queuing":
      return "รับลงคิว";
    case "assigning":
      return "มอบหมาย";
    case "work_in_progress":
      return "ดำเนินงาน";
    case "not_approve_review":
      return "ไม่อนุมัติ - การรับงาน";
    case "reviewing":
      return "ตรวจรับงาน";
    case "closed":
      return "ใบสรุปประมาณถอดแบบ";
    default:
      return "";
  }
};

export const notifyTemplate = (
  date,
  document_name,
  document_id,
  document_status,
  document_url
) => {
  const formatDate = unixToDateTimeWithFormat(date);
  const formatDocumentStatus = formatStatus(document_status);

  const message =
    `วันที่ ${formatDate}\n` +
    `เอกสาร ${document_name}\n` +
    `เลขที่ ${document_id}\n` +
    `สถานะ ${formatDocumentStatus}\n` +
    `${document_url}`;

  return message;
};
