import { graphQLClientWithHeader } from "../graphQL";
import {
  CREATE_CREATABLE,
  CREATE_TAG_LIST,
  DELETE_CREATABLE,
  DELETE_TAG_LIST,
  DELETE_TAG_VERIFY,
  DOCUMENT_CANCEL,
  DOCUMENT_NEXT_STATUS,
  SET_DOCUMENT_STEP,
  UPDATE_TAG_LIST,
} from "./mutation";
import {
  GET_CREATABLES,
  GET_DOCUMENT_ID,
  GET_DOCUMENT_RELATION,
  GET_REFERENCE_DOCUMENT_RELATION,
  GET_RUNNING_NUMBER,
  GET_TAG_LIST,
  GET_TAG_LIST_BY_ENTITY,
  TRIGGER_CRON,
} from "./queries";

class GlobalService {
  async getDocumentId(prefix) {
    const graphQLClient = graphQLClientWithHeader();
    const { generateDocumentId } = await graphQLClient.request(
      GET_DOCUMENT_ID,
      {
        prefix,
      }
    );
    return generateDocumentId;
  }
  async getRunningDocumentId(documentType) {
    const graphQLClient = graphQLClientWithHeader();
    const { generateRunningNumber } = await graphQLClient.request(
      GET_RUNNING_NUMBER,
      {
        documentType,
      }
    );
    return generateRunningNumber;
  }
  async getDocumentRelations(documentId) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentRelations } = await graphQLClient.request(
      GET_DOCUMENT_RELATION,
      {
        documentId,
      }
    );
    return documentRelations;
  }
  async getReferenceDocumentRelations(referenceDocumentId) {
    const graphQLClient = graphQLClientWithHeader();
    const { referenceDocumentRelations } = await graphQLClient.request(
      GET_REFERENCE_DOCUMENT_RELATION,
      {
        referenceDocumentId,
      }
    );
    return referenceDocumentRelations;
  }
  async getTagList(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagsAggrid } = await graphQLClient.request(GET_TAG_LIST, {
      aggridInput,
    });
    return tagsAggrid;
  }

  async getTagListByEntity(entityName) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagsFindByEntity } = await graphQLClient.request(
      GET_TAG_LIST_BY_ENTITY,
      {
        entityName,
      }
    );
    return tagsFindByEntity;
  }
  async createTagList(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagCreate } = await graphQLClient.request(CREATE_TAG_LIST, input);
    return tagCreate;
  }
  async updateTagList(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagUpdate } = await graphQLClient.request(UPDATE_TAG_LIST, input);
    return tagUpdate;
  }
  async deleteTagList(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagDelete } = await graphQLClient.request(DELETE_TAG_LIST, input);
    return tagDelete;
  }
  async deleteTagVerify(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { tagDeleteVerify } = await graphQLClient.request(
      DELETE_TAG_VERIFY,
      input
    );
    return tagDeleteVerify;
  }
  async updateIsLate() {
    const graphQLClient = graphQLClientWithHeader();
    await graphQLClient.request(TRIGGER_CRON);
  }
  async setDocumentStep(documentSetStepInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentSetStep } = await graphQLClient.request(SET_DOCUMENT_STEP, {
      documentSetStepInput,
    });
    return documentSetStep;
  }
  async cancelDocument(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentCancel } = await graphQLClient.request(DOCUMENT_CANCEL, {
      documentInput,
    });
    return documentCancel;
  }

  async postNextStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentNextStatus } = await graphQLClient.request(
      DOCUMENT_NEXT_STATUS,
      {
        documentInput,
      }
    );
    return documentNextStatus;
  }

  async getCreatables(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatablesFindByField } = await graphQLClient.request(
      GET_CREATABLES,
      {
        findManyInput,
      }
    );
    return creatablesFindByField;
  }
  async createCreatable(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableCreate } = await graphQLClient.request(CREATE_CREATABLE, {
      createInput,
    });
    return creatableCreate;
  }
  async deleteCreatable(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creatableDelete } = await graphQLClient.request(DELETE_CREATABLE, {
      uniqueInput,
    });
    return creatableDelete;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GlobalService();
