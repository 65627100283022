import ActivityLogsService from "../../../services/ActivityLogs";
import EngineerService from "../../../services/Engineer";
import { postNotify } from "../../../services/Notification";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { dateToUnix } from "../../../utils/date-converter";
import { notifyTemplate } from "../../../utils/notifyTemplate";
import { estimateActions } from "./estimate-slice";

const formatEstimatePayload = (data, status) => {
  const {
    issue_date,
    start_date,
    end_date,
    created_date,
    is_reproduction,
    is_installation,
    is_adjustment,
    employee_list,
    created_by,
    creator_document_id,
    delivery_count,
    delivery_floor,
    delivery_cartage_method,
    contact,
    revision_count,
    ...otherData
  } = data;

  let job_type = [];

  if (is_reproduction) {
    job_type.push("ถอดแบบ");
  }
  if (is_installation) {
    job_type.push("ติดตั้ง");
  }
  if (is_adjustment) {
    job_type.push("งานเพิ่ม/ลด");
  }

  const unixIssueDate = dateToUnix(issue_date);
  const unixStartDate = dateToUnix(start_date);
  const unixEndDate = dateToUnix(end_date);

  const employee_document_id_list = employee_list.map(
    (employee) => employee.document_id
  );

  const formatDeliveryCount = delivery_count ? parseInt(delivery_count) : null;
  const formatDeliveryFloor = delivery_floor ? parseInt(delivery_floor) : null;
  const formatRevisionCount = revision_count
    ? parseInt(revision_count)
    : undefined;

  const formatDeliveryCartageMethod = delivery_cartage_method.map(
    (method) => method.value
  );

  const payload = {
    ...otherData,
    job_type,
    issue_date: unixIssueDate,
    start_date: unixStartDate,
    end_date: unixEndDate,
    employee_document_id_list,
    status,
    revision_count: formatRevisionCount,
    delivery_count: formatDeliveryCount,
    delivery_floor: formatDeliveryFloor,
    delivery_cartage_method: formatDeliveryCartageMethod,
  };
  return payload;
};

const formatEstimate = (data) => {
  const { job_type, delivery_cartage_method, ...otherData } = data;

  let is_reproduction = false,
    is_installation = false,
    is_adjustment = false;

  if (job_type.includes("ถอดแบบ")) {
    is_reproduction = true;
  }
  if (job_type.includes("ติดตั้ง")) {
    is_installation = true;
  }
  if (job_type.includes("งานเพิ่ม/ลด")) {
    is_adjustment = true;
  }

  const formatDeliveryCartageMethod = delivery_cartage_method.map((method) => ({
    id: method,
    label: method,
    value: method,
  }));

  const payload = {
    ...otherData,
    is_reproduction,
    is_installation,
    is_adjustment,
    delivery_cartage_method: formatDeliveryCartageMethod,
  };

  return payload;
};

export const getAllEstimates =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(estimateActions.onLoading("allEstimates"));
    try {
      const allEstimates = await EngineerService.getAllEstimates(input);
      params.successCallback(allEstimates.results, allEstimates.count);
      dispatch(estimateActions.loadedAllEstimates(allEstimates.results));
    } catch (err) {
      console.error(err);
      dispatch(
        estimateActions.rejectedActions({ ...err, name: "allEstimates" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getEstimateById =
  (document_id, enqueueSnackbar) => async (dispatch) => {
    dispatch(estimateActions.onLoading("estimate"));
    try {
      const estimate = await EngineerService.getEstimateById(document_id);
      const formattedEstimation = formatEstimate(estimate);
      dispatch(estimateActions.loadedEstimate(formattedEstimation));
    } catch (err) {
      console.error(err);
      dispatch(estimateActions.rejectedActions({ ...err, name: "estimate" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createEstimate =
  (status, data, enqueueSnackbar, navigate, user, state) =>
  async (dispatch) => {
    dispatch(estimateActions.onLoading("estimate"));
    try {
      const formatPayload = formatEstimatePayload(data, status);
      const createdEstimate = await EngineerService.createEstimate(
        formatPayload
      );

      const activityLogPayload = {
        document_id: createdEstimate.document_id,
        creator_document_id: user.document_id,
      };
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "estimation",
        "สร้างใบถอดแบบ/ติดตั้ง"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "estimation"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }
      navigate(
        `/engineer/estimate/${encodeURIComponent(createdEstimate.document_id)}`
      );
      if (status === "draft") {
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("บันทึกงานถอดแบบใหม่สำเร็จ", {
          variant: "success",
        });
      }
      const message = notifyTemplate(
        createdEstimate.created_date,
        "ใบถอดแบบติดตั้ง",
        createdEstimate.document_id,
        createdEstimate.status,
        `https://biowood-dev.npr.digital/engineer/estimate/${encodeURIComponent(
          createdEstimate.document_id
        )}`
      );
      await postNotify(message, process.env.REACT_APP_ENGINEER_TOKEN);
    } catch (err) {
      console.error(err);
      if (status === "draft") {
        enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("บันทึกงานถอดแบบใหม่ไม่สำเร็จ", {
          variant: "error",
        });
      }
      dispatch(estimateActions.rejectedActions({ ...err, name: "estimate" }));
    }
  };

export const updateEstimate =
  (status, currStatus, updateInput, enqueueSnackbar, user, existing) =>
  async (dispatch) => {
    dispatch(estimateActions.onLoading("estimate"));
    try {
      const formatPayload = formatEstimatePayload(updateInput, status);
      const { document_id, ...otherPayload } = formatPayload;
      const updatedEstimate = await EngineerService.updateEstimate(
        document_id,
        otherPayload
      );

      const activityLogPayload = {
        document_id: updatedEstimate.document_id,
        creator_document_id: user.document_id,
      };

      const activityLogWithEmployee = {
        document_id: updatedEstimate.document_id,
        creator_document_id: user.document_id,
        employee_list: updatedEstimate.employee_list,
      };

      if (status === currStatus) {
        const editActivityLog = createActivityLogEditPayload(
          activityLogPayload,
          "estimation"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(activityLogWithEmployee, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogWithEmployee,
            "add_related_employee",
            addedEmployeeList,
            "estimation"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogWithEmployee,
            "delete_related_employee",
            deletedEmployeeList,
            "estimation"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }
        enqueueSnackbar("แก้ไขใบถอดแบบ/ติดตั้งสำเร็จ", {
          variant: "success",
        });
      } else {
        const updatedDate = dateToUnix(new Date());
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "estimation",
          currStatus,
          status
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });

        switch (status) {
          case "draft": {
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "new_estimation": {
            if (currStatus === "not_approve_audit") {
              enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("บันทึกงานถอดแบบใหม่สำเร็จ", {
                variant: "success",
              });
            }
            break;
          }
          case "not_approve_audit": {
            enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "audited": {
            enqueueSnackbar("อนุมัติสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "not_approve_queue": {
            enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "queuing": {
            if (currStatus === "not_approve_queue") {
              enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("ดำเนินต่อสำเร็จ", {
                variant: "success",
              });
            }
            const message = notifyTemplate(
              updatedDate,
              "ใบถอดแบบติดตั้ง",
              updatedEstimate.document_id,
              updatedEstimate.status,
              `https://biowood-dev.npr.digital/engineer/estimate/${encodeURIComponent(
                updatedEstimate.document_id
              )}`
            );
            await postNotify(message, process.env.REACT_APP_ENGINEER_TOKEN);
            break;
          }
          case "not_approve_review": {
            enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "assigning": {
            enqueueSnackbar("อนุมัติสำเร็จ", {
              variant: "success",
            });
            const message = notifyTemplate(
              updatedDate,
              "ใบถอดแบบติดตั้ง",
              updatedEstimate.document_id,
              updatedEstimate.status,
              `https://biowood-dev.npr.digital/engineer/estimate/${encodeURIComponent(
                updatedEstimate.document_id
              )}`
            );
            await postNotify(message, process.env.REACT_APP_ENGINEER_TOKEN);
            break;
          }
          case "reviewing": {
            if (currStatus === "not_approve_review") {
              enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("ดำเนินต่อสำเร็จ", {
                variant: "success",
              });
            }
            break;
          }
          case "closed": {
            enqueueSnackbar("อนุมัติสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "cancelled": {
            enqueueSnackbar("ยกเลิกสำเร็จ", {
              variant: "success",
            });
            break;
          }
          case "finished": {
            enqueueSnackbar("บันทึกสำเร็จ", {
              variant: "success",
            });
            break;
          }
          default:
            enqueueSnackbar("ดำเนินต่อสำเร็จ", {
              variant: "success",
            });
            break;
        }
      }

      const formatUpdatedEstimate = formatEstimate(updatedEstimate);
      dispatch(estimateActions.loadedEstimate(formatUpdatedEstimate));
    } catch (err) {
      console.error(err);
      switch (status) {
        case "draft": {
          enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "new_estimation": {
          if (currStatus === "not_approve_audit") {
            enqueueSnackbar("ส่งอนุมัติไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("บันทึกงานถอดแบบใหม่ไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        }
        case "not_approve_audit": {
          enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "audited": {
          enqueueSnackbar("อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "not_approve_queue": {
          enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "queuing": {
          if (currStatus === "not_approve_queue") {
            enqueueSnackbar("ส่งอนุมัติไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("ดำเนินต่อไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        }
        case "not_approve_review": {
          enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "assigning": {
          enqueueSnackbar("อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "reviewing": {
          if (currStatus === "not_approve_review") {
            enqueueSnackbar("ส่งอนุมัติไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("ดำเนินต่อไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        }
        case "closed": {
          enqueueSnackbar("อนุมัติไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "cancelled": {
          enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        case "finished": {
          enqueueSnackbar("บันทึกไม่สำเร็จ", {
            variant: "error",
          });
          break;
        }
        default:
          enqueueSnackbar("ดำเนินต่อไม่สำเร็จ", {
            variant: "error",
          });
          break;
      }
      dispatch(estimateActions.rejectedActions({ ...err, name: "estimate" }));
    }
  };
