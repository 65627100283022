import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../components/Custom/CustomizedMenuBox";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
// import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTheme } from "@emotion/react";
import { useAuth, usePermission } from "../../hooks/use-auth";

const Inventory = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [allPermissionList, setAllPermissionList] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const breadcrumbs = [
    {
      name: t("inventory.index"),
    },
  ];

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const {
    itemPermission,
    goodsReceivePermission,
    // goodsReceiveReturnPermission,
    goodsIssuePermission,
    // goodsAdjustmentPermission,
    // goodsTransferPermission,
    inventoryReportPermission,
  } = usePermission();

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const menuList = [
    allPermissionList.some((permission) =>
      [itemPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("inventory.items.index"),
      navigateTo: "items",
      testId: "navigate-to-itemmaster",
    },
    allPermissionList.some((permission) =>
      [goodsReceivePermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("inventory.receive.index"),
      navigateTo: "receive",
      testId: "navigate-to-goods-receive",
    },
    // allPermissionList.some((permission) =>
    //   [goodsReceiveReturnPermission.VIEW, isAdmin].includes(permission)
    // ) && {
    //   title: t("inventory.return.index"),
    //   navigateTo: "return",
    //   testId: "navigate-to-goods-return",
    // },
    allPermissionList.some((permission) =>
      [goodsIssuePermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("inventory.issue.index"),
      navigateTo: "issue",
      testId: "navigate-to-goods-issue",
    },
    // allPermissionList.some((permission) =>
    //   [goodsTransferPermission.VIEW, isAdmin].includes(permission)
    // ) && {
    //   title: t("inventory.transfer.index"),
    //   navigateTo: "transfer",
    //   testId: "navigate-to-goods-transfer",
    // },
    // allPermissionList.some((permission) =>
    //   [goodsAdjustmentPermission.VIEW, isAdmin].includes(permission)
    // ) && {
    //   title: t("inventory.adjustment.index"),
    //   navigateTo: "adjustment",
    //   testId: "navigate-to-goods-adjustment",
    // },
    {
      title: t("inventory.issue.deliveryTable"),
      navigateTo: "delivery-table",
      testId: "navigate-to-inventory-delivery-table",
    },
    allPermissionList.some((permission) =>
      [inventoryReportPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("inventory.report"),
      navigateTo: "report",
      testId: "navigate-to-inventory-report",
    },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderIcon = (name) => {
    switch (name) {
      case "items":
        return (
          <Inventory2OutlinedIcon
            fontSize={isSmallScreen ? "large" : "small"}
          />
        );
      case "receive":
        return (
          <LoginOutlinedIcon fontSize={isSmallScreen ? "large" : "small"} />
        );
      // case "return":
      //   return (
      //     <LoginOutlinedIcon fontSize={isSmallScreen ? "large" : "small"} />
      //   );
      case "issue":
        return (
          <LogoutOutlinedIcon fontSize={isSmallScreen ? "large" : "small"} />
        );
      case "delivery-table":
        return (
          <DescriptionOutlinedIcon
            fontSize={isSmallScreen ? "large" : "small"}
          />
        );
      // case "transfer":
      //   return (
      //     <SwapHorizOutlinedIcon fontSize={isSmallScreen ? "large" : "small"} />
      //   );
      // case "adjustment":
      //   return (
      //     <TaskOutlinedIcon fontSize={isSmallScreen ? "large" : "small"} />
      //   );
      case "report":
        return (
          <DescriptionOutlinedIcon
            fontSize={isSmallScreen ? "large" : "small"}
          />
        );
      default:
        break;
    }
  };

  const renderMenuList = () =>
    formatMenuList.map((menu) => {
      if (isSmallScreen && menu.navigateTo === "adjustment") return null;
      return (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
          <CustomizedMenuBox
            testId={menu.testId}
            title={menu.title}
            onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
            icon={renderIcon(menu.navigateTo)}
            isGridItem={!isSmallScreen}
            isMobile={isSmallScreen}
          />
        </Grid>
      );
    });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2, textAlign: "-webkit-center" }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Inventory;
