import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Typography, Box, IconButton, Menu, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomizedBox } from "../Custom/CustomizedBox";
import CustomizedTextField from "../Custom/CustomizedTextField";

const AttachmentCard = ({
  control,
  errors,
  onRemove,
  index,
  fileName,
  full_name,
  url,
  date,
  onUpdate,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event) => {
    if (viewOnly) {
      return;
    }
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const startEditingHandler = () => {
    closeActionsHandler();
    setIsEditing(true);
  };

  const finishEditingHandler = () => {
    onUpdate(index);
    setIsEditing(false);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={() => onRemove(index)}>
          {t("contact.utility.delete")}
        </MenuItem>
        <MenuItem onClick={startEditingHandler}>
          {t("contact.utility.name_change")}
        </MenuItem>
      </Menu>
      <CustomizedBox margin={0} padding="16px" height="100%">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              wordBreak: "break-all",
            }}
          >
            {isEditing ? (
              <Controller
                name={`files.${index}.name`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    fullWidth
                    error={Boolean(errors.files?.[index]?.name)}
                    helperText={errors.files?.[index]?.name?.message}
                    disabled={viewOnly}
                    onClick={url ? () => window.open(url, "_blank") : null}
                    sx={{
                      cursor: url ? "pointer" : "default",
                    }}
                  />
                )}
              />
            ) : (
              <Typography
                onClick={url ? () => window.open(url, "_blank") : null}
                sx={{
                  cursor: url ? "pointer" : "default",
                }}
              >
                {fileName}
              </Typography>
            )}
          </Box>
          {isEditing && (
            <IconButton
              onClick={finishEditingHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CheckIcon />
            </IconButton>
          )}
          {!viewOnly && !isEditing && (
            <IconButton
              onClick={openActionsHandler}
              sx={{
                height: "fit-content",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>

        {full_name && (
          <Typography
            variant="body2"
            sx={{ my: 2, color: (theme) => theme.palette.grey[600] }}
          >
            {full_name}
          </Typography>
        )}
        {date && (
          <Typography
            variant="body2"
            sx={{ my: 2, color: (theme) => theme.palette.grey[600] }}
          >
            วันที่ {date}
          </Typography>
        )}
      </CustomizedBox>
    </>
  );
};

export default AttachmentCard;
