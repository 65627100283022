import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../components/Custom/CustomizedMenuBox";
import { useAuth, usePermission } from "../../hooks/use-auth";

const Purchase = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [allPermissionList, setAllPermissionList] = useState([]);

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  const breadcrumbs = [
    {
      name: t("purchase.index"),
    },
  ];

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  const {
    purchaseRequestPermission,
    purchaseOrderPermission,
    purchaseReturnPermission,
    purchaseReportPermission,
  } = usePermission();

  const menuList = [
    allPermissionList.some((permission) =>
      [
        purchaseRequestPermission.VIEW,
        purchaseRequestPermission.VIEW_SELF,
        isAdmin,
      ].includes(permission)
    ) && { title: t("purchase.request.index"), navigateTo: "request" },
    allPermissionList.some((permission) =>
      [purchaseOrderPermission.VIEW, isAdmin].includes(permission)
    ) && { title: t("purchase.order.index"), navigateTo: "order" },
    allPermissionList.some((permission) =>
      [purchaseReturnPermission.VIEW, isAdmin].includes(permission)
    ) && { title: t("purchase.return.index"), navigateTo: "return" },
    allPermissionList.some((permission) =>
      [purchaseReportPermission.VIEW, isAdmin].includes(permission)
    ) && { title: t("purchase.report.index"), navigateTo: "report" },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderMenuList = () =>
    formatMenuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          onClick={() => navigate(`${pathname}/${menu.navigateTo}`)}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Purchase;
