import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllRemarkTemplate } from "../../../features/Setting/RemarkTemplate/remark-template-actions";
import { calcSummary, formatNumber } from "../../../utils/dataTransformer";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";
import CustomizedTextField from "../../Custom/CustomizedTextField";

const Summary = ({
  control,
  invoiceControl,
  setValue,
  viewOnly,
  getValues,
  withholdingTaxType,
  documentType,
  onlyTemplate,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const itemList = useWatch({
    control: documentType === "rt" ? invoiceControl : control,
    name: documentType === "rt" ? "sales_invoice_list" : "item_list",
  });
  const shippingCost = useWatch({ control, name: "shipping_cost" });
  const additionalDiscount = useWatch({ control, name: "additional_discount" });
  const { allRemarkTemplate } = useSelector((state) => state.remarkTemplate);

  const priceVatType = useWatch({ control, name: "price_vat_type" });

  const summary = calcSummary(
    priceVatType,
    itemList,
    shippingCost || 0,
    additionalDiscount,
    documentType,
    withholdingTaxType
  );

  const mapDocumentType = useCallback(() => {
    switch (documentType) {
      case "qa":
        return "quotation";
      case "so":
        return "sales_order";
      case "di":
        return "deposit_invoice";
      case "si":
        return "sales_invoice";
      case "rt":
        return "payment_receive";
      case "sr":
        return "sales_return";
      case "cn":
        return "credit_note";
      case "do":
        return "delivery_order";
      case "pr":
        return "purchase_request";
      case "po":
        return "purchase_order";
      case "pi":
        return "purchase_invoice";
      case "pm":
        return "payment_made";
      case "rs":
        return "purchase_return";
      default:
        break;
    }
  }, [documentType]);

  const fetchRemark = useCallback(() => {
    dispatch(getAllRemarkTemplate({}, mapDocumentType()));
  }, [dispatch, mapDocumentType]);

  useEffect(() => {
    fetchRemark();
  }, [fetchRemark]);

  useEffect(() => {
    setValue("summary", summary);
  }, [setValue, summary]);

  const mapTemplateRemarkIdToRemark = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === id
        );
        if (findById) setValue("remark", findById.remark_text);
      } else {
        setValue("remark", "");
      }
    },
    [allRemarkTemplate.remarkTemplate, setValue]
  );

  const mapTemplateToValue = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === parseInt(id)
        );
        if (findById) {
          return findById.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    [allRemarkTemplate.remarkTemplate]
  );

  const summaryList = [
    {
      name: "additional_discount",
      label: `${t("sales.additionalDiscount")} / Additional Discount`,
      amount: formatNumber(getValues("additional_discount") ?? 0),
    },
    {
      label: `${t("sales.subTotal")} / Sub Total`,
      amount: summary.sub_total,
    },
    {
      label: `${t("sales.vatExemptedAmount")} / VAT Exempted Amount`,
      amount: summary.vat_exempted_amount,
    },
    {
      label: `${t("sales.vat0%Amount")} / VAT 0% Amount`,
      amount: summary.vat_0_amount,
    },
    {
      label: `${t("sales.vat7%Amount")} / VAT 7% Amount`,
      amount: summary.vat_7_amount,
    },
    {
      label: `${t("sales.totalVatAmount")} / VAT Amount`,
      amount: summary.vat_amount,
    },
    {
      label: `${t("sales.totalNetAmount")} / Net Amount`,
      amount: summary.net_amount,
    },
    {
      label: `${t("sales.withholdingTax")} / Withholding Tax`,
      amount: summary.withholding_tax_amount,
    },
    {
      name: "shipping_cost",
      label: `${t("sales.shippingCost")} / Shipping Cost`,
      amount: formatNumber(getValues("shipping_cost") ?? 0),
    },
    {
      label: `${t("sales.totalAmount")} / Total Amount`,
      amount: summary.total_amount,
    },
  ];

  const filterSummaryList = () => {
    switch (documentType) {
      case "rs":
        return [...summaryList.slice(0, 8), summaryList[9]];
      default:
        return summaryList;
    }
  };

  const renderSummaryList = () => {
    return filterSummaryList().map((item, index) => (
      <Box key={index} sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="subtitle1">{item.label}</Typography>
          {!["shipping_cost", "additional_discount"].includes(item.name) ||
          viewOnly ? (
            <Typography
              variant="subtitle1"
              sx={{ width: "170px", textAlign: "right", mx: 3 }}
            >
              {item.amount}
            </Typography>
          ) : (
            <CustomizedNumberTextField
              name={item.name}
              control={control}
              sx={{ width: 170, mx: 3 }}
            />
          )}
          <Typography variant="subtitle1">บาท</Typography>
        </Box>
        {index === filterSummaryList().length - 2 && <Divider sx={{ mb: 3 }} />}
        {index === filterSummaryList().length - 1 && (
          <>
            <Divider sx={{ mb: 1 }} />
            <Divider sx={{ mb: 2 }} />
          </>
        )}
      </Box>
    ));
  };

  return (
    <CustomizedBox padding="1rem">
      <Grid container spacing={2}>
        <Grid
          item
          container
          rowSpacing={2}
          xs={12}
          md={5}
          // lg={5}
          direction="column"
        >
          <Grid item>
            {viewOnly ? (
              <Typography sx={{ fontWeight: "bold" }}>หมายเหตุ</Typography>
            ) : (
              <Controller
                name="template_remark_id"
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label="หมายเหตุ"
                    options={allRemarkTemplate.remarkTemplate.map(
                      (template) => {
                        return {
                          id: template.id,
                          label: template.name,
                          value: template.id,
                        };
                      }
                    )}
                    value={mapTemplateToValue(field.value)}
                    onChange={(e, value) => {
                      if (value) {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(value.value);
                      } else {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(null);
                      }
                    }}
                    sx={{ width: "70%" }}
                    disabled={viewOnly}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  multiline
                  rows={4}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
        {!onlyTemplate && (
          <Grid
            item
            container
            xs
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-end"
          >
            {renderSummaryList()}
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default Summary;
