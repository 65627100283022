import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton, styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const StyledSnackbar = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  "&.SnackbarItem-variantError": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  "&.SnackbarItem-variantInfo": {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
  "& > .SnackbarItem-message": {
    display: "flex",
    gap: "12px",
  },
}));

export default function CustomizedSnackbar({ children }) {
  const notistackRef = useRef(null);
  const dismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <StyledSnackbar
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        success: <CheckCircleOutlinedIcon color="success" />,
        error: <ErrorOutlineIcon color="error" />,
        warning: <ReportProblemOutlinedIcon color="warning" />,
        info: <InfoOutlinedIcon color="info" />,
      }}
      maxSnack={7}
      preventDuplicate={true}
      action={(key) => (
        <IconButton size="small" onClick={() => dismiss(key)}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
      style={{ fontFamily: "Kanit" }}
    >
      {children}
    </StyledSnackbar>
  );
}
