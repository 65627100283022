import moment from "moment";

export const unixToDate = (unix) => {
  return moment.unix(unix).format();
};

export const unixToDateWithFormat = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY");
};

export const unixToDateWithFormatCalendar = (unix) => {
  return moment.unix(unix).format("YYYY-MM-DD");
};

export const unixToDateWithFormatCalendarWithMinute = (unix) => {
  return moment.unix(unix).format("YYYY-MM-DD HH:mm");
};

export const unixToDateTimeWithFormat = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY, HH:mm:ss");
};

export const unixToDateTimeWithFormatNoSeconds = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY HH:mm");
};

export const unixToDateTimeWithFormatNoSecond = (unix) => {
  return moment.unix(unix).format("DD/MM/YYYY, HH:mm");
};

export const unixToTimeWithFormat = (unix) => {
  return moment.unix(unix).format("HH:mm");
};

export const dateToUnix = (date) => {
  return parseInt(moment(date).format("X"));
};

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateExport = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const formatDateTime = (date) => {
  return moment(date).format("DD/MM/YYYY, HH:mm");
};

export const formatDateTimeWithCalendar = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const documentTypeToThai = (input) => {
  switch (input) {
    case "buy":
      return "ซื้อ";
    case "sell":
      return "ขาย";
    case "manufacture":
      return "ผลิต";
    case "return":
      return "ส่งคืน";
    case "add":
      return "เพิ่ม";
    case "deduct":
      return "ลด";
    default:
      return input;
  }
};

export const mapStatusToThai = (status) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "finished":
      return "เสร็จสิ้น";
    case "in_progress":
    case "inProgress":
      return "กำลังผลิต";
    case "pendingManu":
    case "pending_manu":
      return "รอผลิต";
    case "cancelled":
    case "canceled":
      return "ยกเลิก";
    default:
      return status;
  }
};
