import { useEffect, useState } from "react";

export const useActiveStep = (status) => {
  const [activeStep, setActiveStep] = useState(null);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    switch (status) {
      case "draft":
        setActiveStep(0);
        setPercentage(100 / 9);
        break;
      case "not_approve_audit":
      case "new_estimation":
        setActiveStep(1);
        setPercentage(2 * (100 / 9));
        break;
      case "audited":
        setActiveStep(2);
        setPercentage(3 * (100 / 9));
        break;
      case "not_approve_queue":
      case "queuing":
        setActiveStep(3);
        setPercentage(4 * (100 / 9));
        break;
      case "assigning":
        setActiveStep(4);
        setPercentage(5 * (100 / 9));
        break;
      case "work_in_progress":
        setActiveStep(5);
        setPercentage(6 * (100 / 9));
        break;
      case "not_approve_review":
      case "reviewing":
        setActiveStep(6);
        setPercentage(7 * (100 / 9));
        break;
      case "closed":
        setActiveStep(7);
        setPercentage(8 * (100 / 9));
        break;
      case "finished":
        setActiveStep(9);
        setPercentage(100);
        break;
      default:
        setActiveStep(null);
    }
  }, [setActiveStep, status]);

  return { activeStep, percentage };
};
