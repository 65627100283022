import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const container = {
  display: "block",
  position: "relative",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "auto",
  objectFit: "contain",
};

const DropzoneUI = ({
  setValue,
  files,
  setFiles,
  valueToSet,
  manualRevoke,
  filesLimit,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    maxFiles: filesLimit ? filesLimit : 0,
    onDrop: (acceptedFiles) => {
      if (filesLimit && files.length >= filesLimit) {
        enqueueSnackbar("เพิ่มรูปภาพได้สูงสุดหนึ่งรูป", {
          variant: "warning",
        });
        return;
      }
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file, index) => {
    if (!file) {
      return null;
    }
    return (
      <div style={container} key={index}>
        <div style={thumb}>
          <div style={thumbInner}>
            {typeof file !== "object" && (
              <img
                alt={file}
                src={file}
                style={img}
                // Revoke data uri after image is loaded
                onLoad={
                  !manualRevoke
                    ? () => {
                        URL.revokeObjectURL(file);
                      }
                    : () => {}
                }
              />
            )}
            {file.preview && (
              <img
                alt={file?.name || file}
                src={file?.preview || file}
                style={img}
                // Revoke data uri after image is loaded
                onLoad={
                  !manualRevoke
                    ? () => {
                        URL.revokeObjectURL(file.preview);
                      }
                    : () => {}
                }
              />
            )}
            {file.url && (
              <img
                alt={file?.name || file}
                src={file?.url || file}
                style={img}
                // Revoke data uri after image is loaded
                onLoad={
                  !manualRevoke
                    ? () => {
                        URL.revokeObjectURL(file.url);
                      }
                    : () => {}
                }
              />
            )}
          </div>
        </div>
        {!disabled && (
          <IconButton
            aria-label="cancel"
            onClick={removeFile(file)}
            sx={{ position: "absolute", top: -20, right: -12 }}
            color="primary"
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
    );
  });

  useEffect(() => {
    setValue(valueToSet, files);
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (!manualRevoke) {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    };
  }, [files, setValue, valueToSet, manualRevoke]);

  return (
    <section className="container">
      {!disabled && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>ลากมาวางเพื่ออัปโหลด</p>
        </div>
      )}
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};

export default DropzoneUI;
