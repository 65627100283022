import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AgGrid from "../../../components/Table/AgGrid";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import { purchaseRequestActions } from "../../../features/Purchase/PurchaseRequest/purchase-request-slice";
import { getPurchaseOrderTab } from "../../../features/Purchase/PurchaseRequest/purchase-request-actions";
import { formatNumber } from "../../../utils/dataTransformer";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const OrderTab = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { purchaseOrderTab } = useSelector((state) => state.purchaseRequest);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderTab(id, enqueueSnackbar));
    }
    return () => dispatch(purchaseRequestActions.resetPurchaseOrderTab());
  }, [dispatch, enqueueSnackbar, id]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 250,
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_name",
      headerName: t("purchase.order.vendorName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.issue_date),
      width: 200,
    },
    {
      field: "due_date",
      headerName: t("purchase.request.dueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.due_date),
      width: 200,
    },
    {
      field: "expect_date",
      headerName: t("purchase.order.expectDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.expect_date),
    },
    {
      field: "total_amount",
      headerName: t("purchase.request.totalNetAmount"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.data.total_amount),
    },
    {
      field: "created_by",
      headerName: t("sales.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
      sortable: false,
      width: 150,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.employee_list) {
          return <CustomizedAvatar avatars={params.data.employee_list} />;
        }
      },
      valueGetter: (params) =>
        params.data.employee_list.map(
          (employee) => employee.first_name + " " + employee.last_name
        ),
      sortable: false,
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "PENDING",
          "DECLINED",
          "approved",
          "finished",
          "cancelled",
        ],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.render_status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
  ];

  const onRowDoubleClicked = (params) => {
    navigate(`/purchase/order/${encodeURIComponent(params.data.document_id)}`);
  };

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("purchase.order.index")}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={purchaseOrderTab}
        onRowDoubleClicked={onRowDoubleClicked}
        height={650}
      />
    </>
  );
};

export default OrderTab;
