import React from "react";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import AttachmentDropzone from "./AttachmentDropzone";

const DeliverAttachmentForm = ({ control, setValue, getValues, disabled }) => {
  return (
    <CustomizedBox>
      <Typography ml={1} fontWeight="bold">
        เอกสารส่งกลับ
      </Typography>
      <Grid container>
        <Grid xs={12}>
          <Grid container sx={{ alignItems: "center", ml: -1 }}>
            <AttachmentDropzone
              control={control}
              setValue={setValue}
              getValues={getValues}
              valueToSet="deliver_attachments"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default DeliverAttachmentForm;
