import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { dateToUnix } from "../../../utils/date-converter";
import { useAuth } from "../../../hooks/use-auth";
// import InventoryService from "../../../services/Inventory";
import PurchaseService from "../../../services/Purchase";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

const ReceiveDetail = ({
  control,
  errors,
  getValues,
  setValue,
  viewOnly,
  isInventoryPage,
  documentStage,
  watchType,
  watchRefDoc,
  reset,
  disabled,
  generateDocumentId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  // const { item } = useSelector((state) => state.goodsReceive);
  const { allLocation } = useSelector((state) => state.location);
  const [referenceDocumentOptions, setReferenceDocumentOptions] = useState([]);
  const [receiveDateIsOpen, setReceiveDateIsOpen] = useState(false);

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  const typeOption = [
    { label: "ซื้อ", value: "ซื้อ" },
    // { label: "รับคืน", value: "รับคืน" },
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  const fetchDocument = useCallback(async (docType) => {
    let results = null;
    if (docType === "PO") {
      results = await PurchaseService.getAllPurchaseOrderWithoutAggrid();
    }
    const formatData = results.map((doc) => {
      return {
        ...doc,
        item_list: doc.item_list.map((item) => {
          return {
            ...item,
            document_id: item.item_document_id,
            name: item.item_name,
            description: item.item_description,
            quantity: item.qty,
            received_qty: item.received_qty,
            item_uid: item.uid,
            uom: item.uom,
          };
        }),
      };
    });
    setReferenceDocumentOptions(formatData);
  }, []);

  const resetValue = useCallback(() => {
    if (!Boolean(id)) {
      setValue("reference_document_id", "");
      setValue("goods_receive_list", []);
    }
  }, [id, setValue]);

  const checkTypeIfSameDoc = useCallback(async () => {
    if (watchRefDoc.slice(0, 2) === "PO" && watchType !== "ซื้อ") {
      resetValue();
    } else if (watchType === "อื่นๆ") {
      resetValue();
    }
  }, [watchRefDoc, watchType, resetValue]);

  useEffect(() => {
    //TODO: get sales order document list
    if (watchType === "ซื้อ") {
      fetchDocument("PO");
    } else {
      setReferenceDocumentOptions([]);
    }
    checkTypeIfSameDoc();
  }, [checkTypeIfSameDoc, fetchDocument, watchType]);

  // const getTeList = useCallback(
  //   async (allItems) => {
  //     let input = {
  //       startRow: 0,
  //       endRow: 999999,
  //       filterModel: {
  //         inventory_object_item: {
  //           filterType: "object",
  //           filter: {
  //             goods_receive: {
  //               filterType: "object",
  //               filter: {
  //                 reference_document_id: {
  //                   filterType: "text",
  //                   filter: watchRefDoc,
  //                   type: "contains",
  //                 },
  //               },
  //             },
  //           },
  //         },
  //         is_active: {
  //           filterType: "boolean",
  //           type: "equals",
  //           filter: "true",
  //         },
  //       },
  //       sortModel: [],
  //     };
  // if (watchRefDoc) {
  //   const allItems = getValues("goods_receive_list");
  //   const { results } = await InventoryService.getAllSEAggrid(input);
  //   const newItemWithCalData = [];
  //   console.log("allItems", allItems);
  //   console.log("results", results);
  //   allItems.forEach((item) => {
  //     const newItems = results.filter(
  //       (result) =>
  //         result.item_document_id === item.stock_entry_list.item.document_id
  //     );
  //     return newItemWithCalData.push({
  //       ...item,
  //       already_posted_quantity: newItems.reduce(
  //         (prev, curr) => prev + Math.abs(curr.posted_quantity),
  //         0
  //       ),
  //     });
  //   });
  //   setValue("goods_receive_list", newItemWithCalData);
  // }
  //   },
  //   [getValues, setValue, watchRefDoc]
  // );

  // useEffect(() => {
  //   getTeList();
  // }, [getTeList, watchRefDoc]);

  const setValueFormRefDoc = (field, newValue) => {
    const currValue = getValues();
    if (newValue && newValue.value) {
      if (currValue.reference_document_id !== newValue.value) {
        reset({
          ...currValue,
          reference_document_id: newValue.value,
          goods_receive_list: [],
        });
      }
      const findItemFromRef = referenceDocumentOptions.find(
        (doc) => doc.document_id === newValue.value
      );
      if (findItemFromRef) {
        const formatValue = findItemFromRef.item_list.map((item) => {
          return {
            initial_quantity: item.quantity,
            posted_quantity: item.quantity,
            posted_value: 0,
            already_posted_quantity: item.received_qty || 0,
            stock_entry_list: {
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: item,
              item_uid: item.item_uid,
              posted_date: dateToUnix(new Date()),
              posted_quantity: 0,
              posted_value: 0,
              reference_document_id: "",
              reference_document_type: "issue",
              source_warehouse_document_id: getValues(
                "source_warehouse_document_id"
              ),
              trace_entry_list: [],
              created_by: user,
              uom: item.uom,
            },
          };
        });
        setValue(`goods_receive_list`, formatValue);
      }
      field.onChange(newValue.value);
    } else {
      reset({
        ...currValue,
        reference_document_id: "",
        goods_receive_list: [],
      });
      field.onChange("");
    }
    field.onChange(newValue ? newValue.value : "");
  };

  const checkStage = documentStage === 0 && !id;

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={checkStage ? 10 : 12}
          sm={checkStage ? 10 : 12}
          md={6}
          lg={4}
          xl={4}
        >
          <Controller
            control={control}
            name={"document_id"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.receive.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
              />
            )}
          />
        </Grid>
        {documentStage === 0 && !id && (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={generateDocumentId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"type"}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={typeOption}
                label={t("inventory.receive.receiveType")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {watchType === "อื่นๆ" ? (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.referenceNo")}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  disabled={
                    !isInventoryPage || viewOnly || watchType === "อื่นๆ" || id
                      ? true
                      : false
                  }
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  options={referenceDocumentOptions.map((doc) => {
                    return {
                      id: doc.id,
                      value: doc.document_id,
                      label: doc.document_id,
                    };
                  })}
                  label={t("inventory.referenceNo")}
                  onChange={(_, newValue) =>
                    setValueFormRefDoc(field, newValue)
                  }
                  disabled={
                    !isInventoryPage || viewOnly || Boolean(id) ? true : false
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledDatePicker
            name="lot"
            control={control}
            error={errors.document_date}
            isOpen={receiveDateIsOpen}
            onClose={() => setReceiveDateIsOpen(false)}
            onOpen={() => setReceiveDateIsOpen(true)}
            label="Lot"
            disabled={documentStage === 2 || disabled}
            // sx={{ width: { sx: "auto", md: 155 } }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                value={mapWarehouseIdToName()}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={documentStage === 2 || disabled}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ReceiveDetail;
