import { Typography, IconButton } from "@mui/material";
import {
  customHeaderColRender,
  RenderCustomizedSelect,
  RenderCustomizedTextField,
  RenderItemNameCell,
} from "../../../../utils/customCellRenderer";
import CloseIcon from "@mui/icons-material/Close";

export const columnDefs = (
  t,
  status,
  documentType,
  control,
  errors,
  getValues,
  setValue,
  handleShowItemModal,
  openDeleteItemConfirmationHandler,
  viewOnly
) => {
  const customCellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 6px",
  };

  return [
    {
      field: "no",
      rowDrag: viewOnly ? false : true,
      headerComponentParams: {
        template: customHeaderColRender("No.", "รายการ"),
      },
      valueGetter: (params) => params.node.rowIndex + 1,
      cellStyle: customCellStyle,
      width: 90,
    },
    {
      field: "item_name",
      headerComponentParams: {
        template: customHeaderColRender("Name", "ชื่อสินค้า"),
      },
      cellRenderer: (params) => (
        <RenderItemNameCell
          data={params.node.data}
          index={params.node.rowIndex}
          control={control}
          errors={errors}
          viewOnly={viewOnly}
          handleShowItemModal={handleShowItemModal}
          documentType={documentType}
          status={status}
          name="item_list"
        />
      ),
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        padding: "0 6px",
      },
      width: 280,
    },
    {
      field: "qty",
      headerComponentParams: {
        template: customHeaderColRender("Qty", "จำนวน"),
      },
      cellRenderer: (params) => {
        return (
          <>
            {viewOnly ? (
              <Typography variant="body2" align="center">
                {params.data.qty.toLocaleString()}
              </Typography>
            ) : (
              <RenderCustomizedTextField
                control={control}
                name={`item_list[${params.node.rowIndex}].qty`}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
              />
            )}
          </>
        );
      },
      cellStyle: customCellStyle,
      width: 160,
    },
    {
      field: "uom_id",
      headerComponentParams: {
        template: customHeaderColRender("Unit", "หน่วย", true),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.uom.name}
            </Typography>
          ) : (
            <RenderCustomizedSelect
              control={control}
              error={Boolean(
                errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex]?.uom_id
              )}
              helperText={
                errors?.item_list &&
                errors?.item_list[params.node.rowIndex] &&
                errors?.item_list[params.node.rowIndex]?.uom_id &&
                errors?.item_list[params.node.rowIndex]?.uom_id?.message
              }
              name={`item_list[${params.node.rowIndex}].uom_id`}
              options={[
                {
                  label: params.data.uom_group?.base_uom?.name,
                  value: params.data.uom_group?.base_uom?.id,
                },
                ...params.data.uom_group?.convertable_uom_list?.map((uom) => ({
                  label: uom.name,
                  value: uom.id,
                })),
              ]}
              getValues={getValues}
              setValue={setValue}
            />
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 200,
    },
    {
      field: "ordered_qty",
      headerComponentParams: {
        template: customHeaderColRender("PO Qty", t("purchase.request.poQty")),
      },
      cellStyle: customCellStyle,
      valueFormatter: (params) => params.value ?? 0,
      width: 130,
    },
    {
      field: "remark",
      headerComponentParams: {
        template: customHeaderColRender("Remark", t("purchase.remark")),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.item_remark}
            </Typography>
          ) : (
            <>
              <RenderCustomizedTextField
                control={control}
                name={`item_list[${params.node.rowIndex}].item_remark`}
                inputProps={{
                  style: { textAlign: "center" },
                }}
              />
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() =>
                  openDeleteItemConfirmationHandler(params.node.rowIndex)
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 250,
    },
  ];
};
