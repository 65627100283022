import { useState, useEffect } from "react";
import QRCode from "qrcode.react";

import { Box, Toolbar } from "@mui/material";
import CustomizedDashboardNavbar from "../../../components/Custom/CustomizedDashboardNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";

const BarcodePdf = () => {
  const [barcodeList, setBarcodeList] = useState([]);

  useEffect(() => {
    const lsState = JSON.parse(localStorage.getItem("barcode-list"));
    setBarcodeList(lsState);
    return () => {
      setBarcodeList([]);
    };
  }, []);

  const a4Style = {
    // width: "21cm",
    // minHeight: "29.7cm",
    width: "10cm",
    minHeight: "5cm",
    // margin: "auto",
    // padding: "2mm",
    boxSizing: "border-box",
  };

  const pageStyle = {
    // Remove marginTop from pageStyle
  };

  const rowStyle = {
    // display: "flex",
    // justifyContent: "space-between",
    // marginBottom: "5px", // Reduce space between rows
    transform: "rotate(-90deg)",
    height: "10cm",
    width: "10cm",
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)" /* Two equal-width columns */,
    gridGap: "0",
    marginBottom: "0",
  };

  const labelStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // border: "1px solid black",
    padding: "10px 5px", // Reduce padding to reduce gap between labels
    boxSizing: "border-box",
    // height: "180px",
    height: "5cm",
    overflow: "hidden",
  };

  const infoStyle = {
    flex: 1,
    display: "flex",
    color: "black",
    flexDirection: "column",
    overflow: "hidden",
  };

  const infoItemStyle = {
    // marginBottom: "5px", // Add margin at the bottom of each info item
  };

  const qrStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "10px", // Reduce space between QR code and info
    marginLeft: "5px", // Reduce space between QR code and info
  };

  const pageBreakStyle = {
    pageBreakBefore: "always", // Page break before each new page
    //marginTop: "18px", // Add a margin at the top of each new page
  };

  // const rowsPerPage = 6;
  // const labelsPerRow = 2;
  const rowsPerPage = 1;
  const labelsPerRow = 1;

  const getPageRows = () => {
    const pageRows = [];

    for (let i = 0; i < barcodeList.length; i += labelsPerRow * rowsPerPage) {
      const pageItems = barcodeList.slice(i, i + labelsPerRow * rowsPerPage);

      const rowLabels = [];
      for (let j = 0; j < pageItems.length; j += labelsPerRow) {
        const labelItems = pageItems.slice(j, j + labelsPerRow);
        const qrCodeSize = 100;

        const labels = labelItems.map((item, index) => (
          <div key={index} style={labelStyle} className="a4-label">
            <div style={qrStyle}>
              <QRCode value={item.productBarcode} size={qrCodeSize} />
            </div>
            <div style={infoStyle}>
              <div style={infoItemStyle}>
                <strong>รหัส:</strong> {item.productId}
              </div>
              <div style={infoItemStyle}>
                <strong>ชื่อ:</strong> {item.productName}
              </div>
              <div style={infoItemStyle}>{item.productDescription}</div>
              <div style={infoItemStyle}>
                <strong>SN:</strong> {item.productSn}
              </div>
              <div style={infoItemStyle}>
                <strong>Lot:</strong> {item.productLot}
              </div>
              <div style={infoItemStyle}>
                <strong>จำนวน:</strong> {item.productQty} {item.productUom}
              </div>
            </div>
          </div>
        ));

        rowLabels.push(
          <div key={j} style={rowStyle}>
            {labels}
          </div>
        );
      }

      // Add a page break before each new page
      pageRows.push(
        <div
          key={i}
          style={{ ...pageStyle, ...pageBreakStyle }}
          className="a4-page-break"
        >
          {rowLabels}
        </div>
      );
    }

    return pageRows;
  };

  const handlePrint = () => {
    // Trigger the print dialog when the button is clicked
    window.print();
  };

  return (
    <Box>
      <CustomizedDashboardNavbar className="print-button">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: "center",
            borderBottom: "2px solid #328F35",
          }}
        >
          <CustomizedButton
            variant="contained"
            title="พิมพ์ Label"
            onClick={handlePrint}
          />
        </Toolbar>
      </CustomizedDashboardNavbar>
      <div className="a4-container">
        <div className="a4-page" style={a4Style}>
          {getPageRows()}
        </div>
      </div>
    </Box>
  );
};

export default BarcodePdf;
