import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../contexts/auth-context";
import {
  useContactPermission,
  useContactReport,
  useCustomerPermission,
  useVendorPermission,
} from "./Auth/use-contact";
import {
  useGoodsAdjustmentPermission,
  useGoodsIssuePermission,
  useGoodsReceivePermission,
  useGoodsReceiveReturnPermission,
  useGoodsTransferPermission,
  useInventoryReport,
  useItemPermission,
} from "./Auth/use-inventory";
import {
  useDeliveryOrderPermission,
  useDeliveryReportPermission,
  useDeliveryTripPermission,
} from "./Auth/use-logistic";
import {
  useBomPermission,
  useManufactureOrderPermission,
  useManufactureReportPermission,
  useWorkOrderPermission,
} from "./Auth/use-manufacture";
import {
  usePurchaseOrderPermission,
  usePurchaseReportPermission,
  usePurchaseRequestPermission,
  usePurchaseReturnPermission,
} from "./Auth/use-purchase";
import {
  useQuotationPermission,
  useSalesOrderPermission,
  useSalesReturnPermission,
} from "./Auth/use-sales";
import {
  useApprovalSettingPermission,
  useAttributePermission,
  useCategoryPermission,
  useCompanyInfoPermission,
  useLocationPermission,
  useLogisticRemarkPermission,
  useLogisticSettingPermission,
  useManufactureRemarkPermission,
  useManufactureSettingPermission,
  usePurchaseRemarkPermission,
  useSalesRemarkPermission,
  useUomPermission,
} from "./Auth/use-setting";
import { useAccountPermission, useRbacPermission } from "./Auth/use-user";

export const useAuth = () => useContext(AuthContext);

export const usePermission = () => {
  const itemPermission = useItemPermission();
  const goodsReceivePermission = useGoodsReceivePermission();
  const goodsReceiveReturnPermission = useGoodsReceiveReturnPermission();
  const goodsIssuePermission = useGoodsIssuePermission();
  const goodsAdjustmentPermission = useGoodsAdjustmentPermission();
  const goodsTransferPermission = useGoodsTransferPermission();
  const inventoryReportPermission = useInventoryReport();

  const contactPermission = useContactPermission();
  const customerPermission = useCustomerPermission();
  const vendorPermission = useVendorPermission();
  const contactReportPermission = useContactReport();

  const quotationPermission = useQuotationPermission();
  const salesOrderPermission = useSalesOrderPermission();
  const salesReturnPermission = useSalesReturnPermission();
  const salesReportPermission = useSalesReturnPermission();

  const purchaseRequestPermission = usePurchaseRequestPermission();
  const purchaseOrderPermission = usePurchaseOrderPermission();
  const purchaseReturnPermission = usePurchaseReturnPermission();
  const purchaseReportPermission = usePurchaseReportPermission();

  const deliveryOrderPermission = useDeliveryOrderPermission();
  const deliveryTripPermission = useDeliveryTripPermission();
  const deliveryReportPermission = useDeliveryReportPermission();

  const bomPermission = useBomPermission();
  const manufactureOrderPermission = useManufactureOrderPermission();
  const workOrderPermission = useWorkOrderPermission();
  const manufactureReportPermission = useManufactureReportPermission();

  const accountPermission = useAccountPermission();
  const rbacPermission = useRbacPermission();

  const companyInfoPermission = useCompanyInfoPermission();
  const approvalSettingPermission = useApprovalSettingPermission();
  const salesRemarkPermission = useSalesRemarkPermission();
  const purchaseRemarkPermission = usePurchaseRemarkPermission();
  const logisticRemarkPermission = useLogisticRemarkPermission();
  const manufactureRemarkPermission = useManufactureRemarkPermission();
  const locationPermission = useLocationPermission();
  const categoryPermission = useCategoryPermission();
  const uomPermission = useUomPermission();
  const attributePermission = useAttributePermission();
  const manufactureSettingPermission = useManufactureSettingPermission();
  const logisticSettingPermission = useLogisticSettingPermission();

  const createPermission = usePermissionCreate();
  const editPermission = usePermissionEdit();
  const viewPermission = usePermissionView();
  const deletePermission = usePermissionDelete();
  const cancelPermission = usePermissionCancel();

  return {
    itemPermission,
    goodsReceivePermission,
    goodsReceiveReturnPermission,
    goodsIssuePermission,
    goodsAdjustmentPermission,
    goodsTransferPermission,
    inventoryReportPermission,
    contactPermission,
    customerPermission,
    vendorPermission,
    contactReportPermission,
    quotationPermission,
    salesOrderPermission,
    salesReturnPermission,
    salesReportPermission,
    purchaseRequestPermission,
    purchaseOrderPermission,
    purchaseReturnPermission,
    purchaseReportPermission,
    deliveryOrderPermission,
    deliveryTripPermission,
    deliveryReportPermission,
    bomPermission,
    manufactureOrderPermission,
    workOrderPermission,
    manufactureReportPermission,
    accountPermission,
    rbacPermission,
    companyInfoPermission,
    approvalSettingPermission,
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    manufactureSettingPermission,
    logisticSettingPermission,
    createPermission,
    editPermission,
    viewPermission,
    deletePermission,
    cancelPermission,
  };
};

export const usePermissionCreate = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    const mapModule = (module) => {
      switch (module) {
        case "setting":
          return "config";
        default:
          return module;
      }
    };

    const mapRemarkPermission = () => {
      if (remarkType === "sales") return "SALES_REMARK";
      else if (remarkType === "purchase") return "PURCHASE_REMARK";
      else if (remarkType === "logistic") return "LOGISTIC_REMARK";
      else return "MANUFACTURE_REMARK";
    };

    const module = pathname?.split("/")[1] || "";
    const documentType = pathname?.split("/")[2] || "";
    const remarkType = pathname?.split("/")[3] || "";

    const actionPermission = allPermissionList
      .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
      .filter((list) => {
        if (module === "contact") return list.split("__")[1] === "ALL";
        else if (module === "inventory" && documentType === "items")
          return list.split("__")[1] === "ITEM_MASTER";
        else if (module === "setting" && documentType === "inventory")
          return (
            list.split("__")[1] === remarkType.replace("-", "_").toUpperCase()
          );
        else if (module === "setting" && documentType === "remark")
          return list.split("__")[1] === mapRemarkPermission();
        else if (module === "engineer" && documentType === "estimate")
          return list.split("__")[1] === "ESTIMATION";
        else
          return (
            list.split("__")[1] === documentType.replace("-", "_").toUpperCase()
          );
      })
      .map((list) => list.split("__")[2]);

    const hasPermission =
      allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
      actionPermission.includes("CREATE");

    setHasPermission(hasPermission);
  }, [allPermissionList, pathname]);

  return hasPermission;
};

export const usePermissionEdit = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    const mapModule = (module) => {
      switch (module) {
        case "setting":
          return "config";
        default:
          return module;
      }
    };

    const mapRemarkPermission = () => {
      if (remarkType === "sales") return "SALES_REMARK";
      else if (remarkType === "purchase") return "PURCHASE_REMARK";
      else if (remarkType === "logistic") return "LOGISTIC_REMARK";
      else return "MANUFACTURE_REMARK";
    };

    const module = pathname?.split("/")[1] || "";
    const documentType = pathname?.split("/")[2] || "";
    const remarkType = pathname?.split("/")[3] || "";

    const actionPermission = allPermissionList
      .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
      .filter((list) => {
        if (module === "inventory" && documentType === "items")
          return list.split("__")[1] === "ITEM_MASTER";
        else if (module === "contact" && documentType === "edit") return true;
        else if (module === "setting" && documentType === "inventory")
          return (
            list.split("__")[1] === remarkType.replace("-", "_").toUpperCase()
          );
        else if (module === "setting" && documentType === "remark")
          return list.split("__")[1] === mapRemarkPermission();
        else if (module === "engineer" && documentType === "estimate")
          return list.split("__")[1] === "ESTIMATION";
        else
          return (
            list.split("__")[1] === documentType.replace("-", "_").toUpperCase()
          );
      })
      .map((list) => list.split("__")[2]);

    const hasPermission =
      allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
      actionPermission.includes("EDIT");

    setHasPermission(hasPermission);
  }, [allPermissionList, pathname]);

  return hasPermission;
};

export const usePermissionView = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    const mapModule = (module) => {
      switch (module) {
        case "setting":
          return "config";
        default:
          return module;
      }
    };

    const mapRemarkPermission = () => {
      if (remarkType === "sales") return "SALES_REMARK";
      else if (remarkType === "purchase") return "PURCHASE_REMARK";
      else if (remarkType === "logistic") return "LOGISTIC_REMARK";
      else return "MANUFACTURE_REMARK";
    };

    const module = pathname?.split("/")[1] || "";
    const documentType = pathname?.split("/")[2] || "";
    const remarkType = pathname?.split("/")[3] || "";

    const actionPermission = allPermissionList
      .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
      .filter((list) => {
        if (module === "inventory" && documentType === "items")
          return list.split("__")[1] === "ITEM_MASTER";
        else if (module === "setting" && documentType === "inventory")
          return (
            list.split("__")[1] === remarkType.replace("-", "_").toUpperCase()
          );
        else if (module === "setting" && documentType === "remark")
          return list.split("__")[1] === mapRemarkPermission();
        else if (module === "engineer" && documentType === "estimate")
          return list.split("__")[1] === "ESTIMATION";
        else
          return (
            list.split("__")[1] === documentType.replace("-", "_").toUpperCase()
          );
      })
      .map((list) => list.split("__")[2]);

    const hasPermission =
      allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
      actionPermission.includes("VIEW");

    setHasPermission(hasPermission);
  }, [allPermissionList, pathname]);

  return hasPermission;
};

export const usePermissionDelete = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    const mapModule = (module) => {
      switch (module) {
        case "setting":
          return "config";
        default:
          return module;
      }
    };

    const mapRemarkPermission = () => {
      if (remarkType === "sales") return "SALES_REMARK";
      else if (remarkType === "purchase") return "PURCHASE_REMARK";
      else if (remarkType === "logistic") return "LOGISTIC_REMARK";
      else return "MANUFACTURE_REMARK";
    };

    const module = pathname?.split("/")[1] || "";
    const documentType = pathname?.split("/")[2] || "";
    const remarkType = pathname?.split("/")[3] || "";

    const actionPermission = allPermissionList
      .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
      .filter((list) => {
        if (module === "inventory" && documentType === "items")
          return list.split("__")[1] === "ITEM_MASTER";
        else if (module === "contact" && documentType === "edit") return true;
        else if (module === "setting" && documentType === "inventory")
          return (
            list.split("__")[1] === remarkType.replace("-", "_").toUpperCase()
          );
        else if (module === "setting" && documentType === "remark")
          return list.split("__")[1] === mapRemarkPermission();
        else if (module === "engineer" && documentType === "estimate")
          return list.split("__")[1] === "ESTIMATION";
        else
          return (
            list.split("__")[1] === documentType.replace("-", "_").toUpperCase()
          );
      })
      .map((list) => list.split("__")[2]);

    const hasPermission =
      allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
      actionPermission.includes("DELETE");

    setHasPermission(hasPermission);
  }, [allPermissionList, pathname]);

  return hasPermission;
};

export const usePermissionCancel = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [allPermissionList, setAllPermissionList] = useState([]);

  useEffect(() => {
    user?.role_list?.forEach((role) =>
      setAllPermissionList((prev) => [...prev, ...role?.permission_list])
    );
  }, [user?.role_list]);

  useEffect(() => {
    const mapModule = (module) => {
      switch (module) {
        case "setting":
          return "config";
        default:
          return module;
      }
    };

    const mapRemarkPermission = () => {
      if (remarkType === "sales") return "SALES_REMARK";
      else if (remarkType === "purchase") return "PURCHASE_REMARK";
      else if (remarkType === "logistic") return "LOGISTIC_REMARK";
      else return "MANUFACTURE_REMARK";
    };

    const module = pathname?.split("/")[1] || "";
    const documentType = pathname?.split("/")[2] || "";
    const remarkType = pathname?.split("/")[3] || "";

    const actionPermission = allPermissionList
      .filter((list) => list.split("__")[0] === mapModule(module).toUpperCase())
      .filter((list) => {
        if (module === "inventory" && documentType === "items")
          return list.split("__")[1] === "ITEM_MASTER";
        else if (module === "contact" && documentType === "edit") return true;
        else if (module === "setting" && documentType === "inventory")
          return (
            list.split("__")[1] === remarkType.replace("-", "_").toUpperCase()
          );
        else if (module === "setting" && documentType === "remark")
          return list.split("__")[1] === mapRemarkPermission();
        else if (module === "engineer" && documentType === "estimate")
          return list.split("__")[1] === "ESTIMATION";
        else
          return (
            list.split("__")[1] === documentType.replace("-", "_").toUpperCase()
          );
      })
      .map((list) => list.split("__")[2]);

    const hasPermission =
      allPermissionList.includes("ADMIN__ADMIN__ADMIN") ||
      actionPermission.includes("CANCEL");

    setHasPermission(hasPermission);
  }, [allPermissionList, pathname]);

  return hasPermission;
};
