import { createSlice } from "@reduxjs/toolkit";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const inventoryReportSlice = createSlice({
  name: "inventoryReport",
  initialState: {
    isLoading: {
      stockEntries: false,
      traceEntry: false,
      itemCurrentStock: false,
      itemCurrentStockLocation: false,
      itemCurrentStockSerialNumber: false,
    },
    sum: 0,
    stockEntries: [],
    traceEntry: [],
    itemCurrentStock: [],
    itemCurrentStockLocation: [],
    itemCurrentStockSerialNumber: [],
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedStockEntries(state, action) {
      state.stockEntries = action.payload.map((data, index) => {
        return {
          ...data,
          index: index + 1,
          created_date: unixToDateWithFormat(data.created_date),
          posted_date: unixToDateWithFormat(data.posted_date),
        };
      });
      state.isLoading.stockEntries = false;
    },
    loadedTraceEntry(state, action) {
      state.traceEntry = action.payload.map((data, index) => {
        return {
          ...data,
          index: index + 1,
          itemInfo: data.item?.document_id + " - " + data.item?.name,
          created_date: unixToDateWithFormat(data.created_date),
          posted_date: unixToDateWithFormat(data.posted_date),
        };
      });
      state.isLoading.traceEntry = false;
    },
    loadedItemCurrentStock(state, action) {
      state.itemCurrentStock = action.payload;
      state.isLoading.itemCurrentStock = false;
    },
    loadedItemCurrentStockLocation(state, action) {
      state.itemCurrentStockLocation = action.payload;
      state.isLoading.itemCurrentStockLocation = false;
    },
    loadedItemCurrentStockSerialNumber(state, action) {
      state.itemCurrentStockSerialNumber = action.payload;
      state.isLoading.itemCurrentStockSerialNumber = false;
    },
    resetStockEntries(state, action) {
      state.stockEntries = [];
    },
    resetTraceEntry(state, action) {
      state.traceEntry = [];
    },
    resetItemCurrentStock(state, action) {
      state.itemCurrentStock = [];
    },
    resetItemCurrentStockLocation(state, action) {
      state.itemCurrentStockLocation = [];
    },
    resetItemCurrentStockSerialNumber(state, action) {
      state.itemCurrentStockSerialNumber = [];
    },
  },
});

export const inventoryReportActions = inventoryReportSlice.actions;

export default inventoryReportSlice.reducer;
