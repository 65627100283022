import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckboxes";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import DropzoneUI from "../../UI/DropzoneUI";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import { useSelector } from "react-redux";
import TagList from "../../../components/UI/TagList";
import { useNavigate, useParams } from "react-router-dom";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import InventoryService from "../../../services/Inventory";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function Information({
  control,
  errors,
  getValues,
  setValue,
  viewOnly,
  isInventoryPage,
  getID,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { item } = useSelector((state) => state.itemMaster);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const watchIsActive = useWatch({ control, name: "is_active" });
  const [currentStockStatus, setCurrentStockStatus] = useState(false);

  useEffect(() => {
    if (item.document_id) {
      setFiles(item.attachment_list);
    }
  }, [item.attachment_list, item.document_id]);

  const checkIfHaveCurrentStock = useCallback(async () => {
    const filterItemCurrentStock = {
      item_document_id: {
        filterType: "text",
        type: "equals",
        filter: id ?? getValues("document_id"),
      },
    };
    const { results } = await InventoryService.getAllICS({
      startRow: 0,
      endRow: 9999999,
      filterModel: filterItemCurrentStock,
    });
    if (results.some((result) => result.current_quantity > 0))
      return setCurrentStockStatus(true);
    else return setCurrentStockStatus(false);
  }, [getValues, id]);

  useEffect(() => {
    checkIfHaveCurrentStock();
  }, [checkIfHaveCurrentStock]);

  const typeOptions = [
    { label: "วัสดุไม้", value: "วัสดุไม้" },
    { label: "วัสดุประกอบ", value: "วัสดุประกอบ" },
    { label: "วัสดุอุปกรณ์หลัก", value: "วัสดุอุปกรณ์หลัก" },
    { label: "วัสดุสิ้นเปลือง", value: "วัสดุสิ้นเปลือง" },
    { label: "บริการ", value: "บริการ" },
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  const onClickTagListHandler = (tagName) => {
    navigate(`/inventory/items?filterTag=` + tagName);
  };

  const watchType = useWatch({
    control,
    name: "type",
  });

  const renderComboBox = (name, label, isDisabled, error, required) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedSelect
          options={typeOptions}
          {...field}
          required={required}
          label={label}
          error={Boolean(error)}
          helperText={error?.message}
          disabled={isDisabled}
        />
      )}
    />
  );

  const renderTextField = (
    name,
    label,
    isDisabled,
    error,
    required,
    testId
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedTextField
          {...field}
          required={required}
          label={label}
          disabled={isDisabled}
          error={Boolean(error)}
          helperText={error?.message}
          testId={testId}
        />
      )}
    />
  );

  const renderCheckBox = (name, label, isDisabled, testId) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckboxes
          testId={testId}
          label={label}
          {...field}
          isDisabled={isDisabled}
        />
      )}
    />
  );

  const firstTextField = [
    {
      name: "document_id",
      testId: "item-document-text-field",
      label: t("inventory.items.itemId"),
      required: true,
      xs: 11,
      sm: 11,
      md: 11,
      lg: 11,
      xl: 11,
      error: errors.document_id,
    },
    {
      name: "name",
      testId: "item-name-text-field",
      label: t("inventory.items.itemName"),
      required: true,
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      error: errors.name,
    },
    {
      name: "sku",
      testId: "item-sku-text-field",
      label: t("inventory.items.itemInternalName"),
      required: true,
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      error: errors.sku,
    },
    // {
    //   name: "barcode",
    //   testId: "item-barcode-text-field",
    //   label: t("inventory.barcode"),
    //   required: false,
    //   xs: 12,
    //   sm: 12,
    //   md: 6,
    //   lg: 6,
    //   xl: 6,
    //   error: errors.barcode,
    // },
    {
      name: "description",
      testId: "item-description-text-field",
      label: t("inventory.items.itemDescription"),
      xs: 12,
      error: errors.description,
    },
  ];

  const checkBoxField = [
    {
      testId: "item-is-purchase-check-box",
      name: "is_purchasable",
      label: t("inventory.items.purchaseItem"),
      xs: 12,
    },
    {
      testId: "item-is-sales-check-box",
      name: "is_salable",
      label: t("inventory.items.salesItem"),
      xs: 12,
    },
    {
      testId: "item-is-stock-check-box",
      name: "is_stockable",
      label: t("inventory.items.stockItem"),
      xs: 12,
      disabled: item.is_stockable || watchType === "บริการ",
    },
    // {
    //   testId: "item-is-manufacture-check-box",
    //   name: "is_manufactuable",
    //   label: t("inventory.items.manufactuarItem"),
    //   xs: 12,
    // },
  ];

  const radioLists = [
    { value: true, label: "ใช้งาน", testId: "item-active-radio" },
    { value: false, label: "ไม่ใช้งาน", testId: "item-inactive-radio" },
  ];

  useEffect(() => {
    if (watchType === "บริการ") setValue("is_stockable", false);
  }, [setValue, watchType]);

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                {t("inventory.items.itemType")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {renderComboBox(
                "type",
                t("inventory.items.itemType"),
                viewOnly,
                errors.type,
                true
              )}
            </Grid>
            {isInventoryPage && (
              <Grid item xs={12}>
                <Typography fontWeight="bold">
                  {t("inventory.items.itemDescription")}
                </Typography>
              </Grid>
            )}
            {firstTextField.map((field) => {
              const isDisabled = field.name === "document_id";
              return (
                <>
                  <Grid
                    item
                    xs={!isDisabled ? field.xs : field.xs + 1}
                    sm={!isDisabled ? field.sm : field.sm + 1}
                    md={!isDisabled ? field.md : field.md + 1}
                    lg={!isDisabled ? field.lg : field.lg + 1}
                    xl={!isDisabled ? field.xl : field.xl + 1}
                    key={field.id + field.label}
                  >
                    <Box display={"flex"}>
                      {renderTextField(
                        field.name,
                        field.label,
                        (isDisabled && (id || viewOnly)) ||
                          !isInventoryPage ||
                          viewOnly,
                        field.error,
                        field.required,
                        field.testId
                      )}
                      {field.name === "document_id" && !id && (
                        <CustomizedTooltip title="เรียกรหัสสินค้าใหม่">
                          <IconButton
                            id="item-btn-reload-document-id"
                            data-test-id="item-btn-reload-document-id"
                            onClick={getID}
                            sx={{
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <RestartAltOutlinedIcon />
                          </IconButton>
                        </CustomizedTooltip>
                      )}
                    </Box>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                {t("inventory.items.picture")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DropzoneUI
                files={files}
                setFiles={setFiles}
                setValue={setValue}
                disabled={!isInventoryPage || viewOnly}
                valueToSet={"attachment_list"}
                mainControl={control}
                filesLimit={1}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        {checkBoxField.map((field) => (
          <Grid item key={field.name + field.label}>
            {renderCheckBox(
              field.name,
              field.label,
              field.disabled,
              field.testId
            )}
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mt={2} mb={1}>
            <Typography fontWeight="bold">{t("inventory.status")}</Typography>
            <CustomizedTooltip
              title="ไม่สามารถปิดการใช้งานได้ เนื่องจากมีสินค้าคงคลังคงเหลือ จะปิดการใช้งานได้ต่อเมื่อ สินค้าคงคลังเท่ากับศูนย์"
              noMaxWidth
              placement="right"
            >
              <ErrorOutlineIcon sx={{ fontSize: "14px", ml: 1 }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="is_active"
            control={control}
            render={({ field }) => (
              <CustomizedRadioGroup
                radioList={radioLists}
                {...field}
                disabled={!isInventoryPage || currentStockStatus || viewOnly}
                row
              />
            )}
          />
        </Grid>
        {(watchIsActive === "false" || watchIsActive === false) && (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              name="remark_status"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={"หมายเหตุสถานะ"}
                  disabled={!isInventoryPage || viewOnly}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography fontWeight="bold" my={2}>
            {t("inventory.items.groupTagList")}
          </Typography>
        </Grid>
        <TagList
          control={control}
          name="tag_list"
          entity={"item"}
          viewOnly={!isInventoryPage || viewOnly}
          onClickTagListHandler={onClickTagListHandler}
        />
      </Grid>
    </Box>
  );
}
