import { useSnackbar } from "notistack";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useRef, useState } from "react";
import AgGrid from "../../../components/Table/AgGrid";
import { useDispatch } from "react-redux";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { getItemsCurrentStockLocation } from "../../../features/Inventory/Report/report-actions";
import InventoryService from "../../../services/Inventory";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { exportAsExcel } from "../../../utils/exporter";
import { formatValueItemCurrentStockByTraceEntry } from "./formatValue";
import TotalBox from "../../../components/UI/TotalBox";
import { formatDate } from "../../../utils/date-converter";
import { useEffect } from "react";
import { filterParamsOptions } from "../../../utils/filterparams";
import isEmpty from "lodash/isEmpty";

const ItemCurrentStockLocation = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [sum, setSum] = useState(0);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.itemCurrentStockLocation.index"),
    },
  ];

  const columnDefs = [
    {
      field: "item_document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    // {
    //   field: "item_sku",
    //   headerName: t("inventory.items.itemInternalName"),
    //   filter: "agTextColumnFilter",
    // },
    {
      field: "item_description",
      headerName: t("inventory.items.itemDescription"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_group_sub_level_1_name",
      headerName: t("inventory.items.itemGroup") + " " + 1,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "current_quantity",
      headerName: t("inventory.itemCurrentStock.currentQuantity"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
    },
    {
      field: "base_uom_name",
      headerName: t("inventory.unit"),
      filter: false,
      sortable: false,
    },
    {
      field: "warehouse_thai_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "bin_location_document_id",
      headerName: t("inventory.location"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
  ];

  const exportHandler = async () => {
    setIsLoadingExport(true);
    try {
      const { results: itemCurrentStockByTraceEntryToExport } =
        await InventoryService.getAllICSL({ filterModel: filterModel });
      const formattedItem = itemCurrentStockByTraceEntryToExport.map((ics) => {
        const formatValue = formatValueItemCurrentStockByTraceEntry(ics);
        return formatValue;
      });
      exportAsExcel(
        formattedItem,
        t("inventory.itemCurrentStockLocation.index")
      );
      enqueueSnackbar("นำออกรายงานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกรายงานไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      setIsLoadingExport(false);
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const sortbyId = [
        ...request.sortModel,
        { colId: "item_document_id", sort: "asc" },
        { colId: "warehouse_thai_name", sort: "asc" },
      ];
      setFilterModel(request.filterModel);
      dispatch(
        getItemsCurrentStockLocation(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: sortbyId,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getSumValue = useCallback(async (all, filterModel) => {
    const input = {
      startRow: 0,
      endRow: 99999,
      filterModel: {},
    };
    if (all) {
      const { results } =
        await InventoryService.getItemCurrentStockSerialNumber(input);
      const result = await results.reduce(
        (prev, curr) => prev + curr.current_quantity,
        0
      );
      setSum(result);
    } else {
      input.filterModel = filterModel;
      const { results } =
        await InventoryService.getItemCurrentStockSerialNumber(input);
      const result = await results.reduce(
        (prev, curr) => prev + curr.current_quantity,
        0
      );
      setSum(result);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(filterModel)) {
      getSumValue(false, filterModel);
    } else getSumValue(true);
  }, [filterModel, getSumValue]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4} md={3}>
          <TotalBox title="ยอดรวม" total={sum} date={formatDate(new Date())} />
        </Grid>
      </Grid>
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("inventory.itemCurrentStockLocation.index")}
        </Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
          disabled={isLoadingExport}
        />
      </Box>
      <Box mb={2} mr={2} alignSelf="flex-end">
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
        />
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        height={649}
      />
    </>
  );
};

export default ItemCurrentStockLocation;
