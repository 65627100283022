import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import EstimateHeaderForm from "../../../components/Form/Engineer/Estimate/Header";
import { useForm, useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import PreliminaryInfoForm from "../../../components/Form/Engineer/Estimate/PreliminaryInfoForm";
import InstallationForm from "../../../components/Form/Engineer/Estimate/InstallationForm";
import {
  estimateInitialState,
  estimateValidation,
} from "../../../features/Engineer/Estimate/estimate-inital";
import { unixToDate } from "../../../utils/date-converter";
import CustomerDescriptionForm from "../../../components/Form/Sales/CustomerDescription";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as customerDescSchema,
  validation as customerDescValidation,
} from "../../../components/Form/Sales/CustomerDescription/schema";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import { useSnackbar } from "notistack";
import RemarkForm from "../../../components/Form/Engineer/Estimate/RemarkForm";
import EstimateItemTable from "../../../components/Table/DocumentTable/EstimateItemTable";
import { useDispatch, useSelector } from "react-redux";
import {
  createEstimate,
  getEstimateById,
  updateEstimate,
} from "../../../features/Engineer/Estimate/estimate-actions";
import { estimateActions } from "../../../features/Engineer/Estimate/estimate-slice";
import moment from "moment";
import { useAuth } from "../../../hooks/use-auth";
import InputAttachmentForm from "../../../components/Form/Engineer/Estimate/InputAttachmentForm";
import { uploadFileToS3 } from "../../../utils/s3";
import DeliverAttachmentForm from "../../../components/Form/Engineer/Estimate/DeliverAttachmentForm";

const EstimateContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { estimate, isLoading } = useSelector((state) => state.estimate);
  const { t } = useTranslation();
  const customerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { state } = useLocation();
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const { user } = useAuth();
  const [editMode, setEditMode] = useState(false);

  const breadcrumbs = [
    {
      name: t("engineer.index"),
      to: "/engineer",
    },
    {
      name: t("engineer.estimate.index"),
      to: "/engineer/estimate",
    },
    {
      name: id ?? `สร้าง${t("engineer.estimate.index")}`,
    },
  ];

  const {
    trigger,
    control,
    setValue,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      ...estimateInitialState.estimate,
      created_date: unixToDate(estimateInitialState.estimate.created_date),
      issue_date: unixToDate(estimateInitialState.estimate.issue_date),
      start_date: unixToDate(estimateInitialState.estimate.start_date),
      end_date: unixToDate(estimateInitialState.estimate.end_date),
    },
    resolver: yupResolver(estimateValidation),
  });

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const {
    trigger: validateCustomerDescription,
    control: customerDescControl,
    setValue: setCustomerDescValue,
    getValues: getCustomerDescValues,
    formState: { errors: customerDescErrors },
    getFieldState: getCustomerDescFieldState,
    reset: resetCustomerDesc,
  } = useForm({
    defaultValues: { ...customerDescSchema },
    resolver: yupResolver(customerDescValidation),
  });

  const submitContactForm = (data) => {
    setCustomerDescValue("contact", data);
  };

  const status = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    if (id) {
      dispatch(getEstimateById(id, enqueueSnackbar));
    }
    return () => {
      dispatch(estimateActions.resetEstimate());
    };
  }, [dispatch, id, enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      reset({
        ...estimate,
        created_date: unixToDate(estimate.created_date),
        issue_date: unixToDate(estimate.issue_date),
        start_date: unixToDate(estimate.start_date),
        end_date: unixToDate(estimate.end_date),
      });
      resetCustomerDesc(estimate.customer);
    } else if (state) {
      if (state.isCopied) {
        const {
          id,
          document_id,
          created_by,
          creator_document_id,
          isCopied,
          status,
          ...payload
        } = state;
        reset({
          ...payload,
          created_date: moment(),
          issue_date: moment(),
          start_date: moment(),
          end_date: moment().add(7, "days").endOf("day"),
        });
        resetCustomerDesc(payload.customer);
      }
    }
  }, [estimate, id, reset, resetCustomerDesc, state]);

  useEffect(() => {
    switch (status) {
      case "new_estimation":
      case "audited":
      case "queuing":
      case "assigning":
      case "work_in_progress":
      case "reviewing":
      case "closed":
      case "finished":
      case "cancelled":
        // setShowButton(false);
        setDisabled(true);
        break;
      default:
        setDisabled(false);
      // setShowButton(true);
    }
  }, [status]);

  const formatPayload = async () => {
    const customerDescIsValid = await validateCustomerDescription();
    const mainFormIsValid = await trigger();

    const customerError = getCustomerDescFieldState("customer").error;
    const errorsCount = customerError ? 1 : 0;

    if (errorsCount === 1 && Boolean(customerError)) {
      enqueueSnackbar("กรุณาเลือกลูกค้า", {
        variant: "error",
      });
    }
    if (!customerDescIsValid) {
      customerRef.current.scrollIntoView();
      return;
    }
    if (!mainFormIsValid) {
      return;
    }

    const mainValues = getValues();
    const customer = getCustomerDescValues();

    if (
      mainValues.input_attachments &&
      mainValues?.input_attachments?.length > 0
    ) {
      for (const [index, value] of mainValues.input_attachments.entries()) {
        console.log("value", value);
        if (value && value instanceof File) {
          console.log("upload");
          const { Location } = await uploadFileToS3(
            value,
            "estimation",
            user.document_id
          );
          mainValues.input_attachments[index].url = Location;
        }
      }
    }

    if (
      mainValues.deliver_attachments &&
      mainValues?.deliver_attachments?.length > 0
    ) {
      for (const [index, value] of mainValues.deliver_attachments.entries()) {
        if (value && value instanceof File) {
          const { Location } = await uploadFileToS3(
            value,
            "estimation",
            user.document_id
          );
          mainValues.deliver_attachments[index].url = Location;
        }
      }
    }

    const payload = {
      ...mainValues,
      input_attachments:
        mainValues?.input_attachments.length > 0
          ? mainValues?.input_attachments.map((attachment) => ({
              name: attachment.fileName ?? attachment.name,
              url: attachment.url,
              creator_document_id: user.document_id,
            }))
          : [],
      deliver_attachments:
        mainValues?.deliver_attachments.length > 0
          ? mainValues?.deliver_attachments.map((attachment) => ({
              name: attachment.fileName ?? attachment.name,
              url: attachment.url,
              creator_document_id: user.document_id,
            }))
          : [],
      customer,
      contact_document_id: contactSnapshot.document_id,
    };
    return payload;
  };

  const saveDraftHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      if (id) {
        dispatch(
          updateEstimate(
            "draft",
            status,
            payload,
            enqueueSnackbar,
            user,
            estimate
          )
        );
      } else {
        dispatch(
          createEstimate(
            "draft",
            payload,
            enqueueSnackbar,
            navigate,
            user,
            state
          )
        );
      }
    }
  };

  const newEstimationHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      if (id) {
        dispatch(
          updateEstimate(
            "new_estimation",
            status,
            payload,
            enqueueSnackbar,
            user,
            estimate
          )
        );
      } else {
        dispatch(
          createEstimate(
            "new_estimation",
            payload,
            enqueueSnackbar,
            navigate,
            user,
            state
          )
        );
      }
    }
  };

  const notApproveAuditHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "not_approve_audit",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const approveAuditHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "audited",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const queuingHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "queuing",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const notApproveQueueHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "not_approve_queue",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const approveQueueHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "assigning",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const assigningHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "work_in_progress",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const workInProgressHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "reviewing",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const notApproveReviewHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "not_approve_review",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const approveReviewHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "closed",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const closedHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "finished",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
  };

  const renderButton = () => {
    switch (status) {
      case null:
      case undefined:
      case "draft":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.saveDraft")}
              onClick={saveDraftHandler}
            />
            <CustomizedButton
              variant="contained"
              title="ดำเนินการต่อ"
              onClick={newEstimationHandler}
            />
          </Box>
        );
      case "new_estimation":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="outlined"
              title="ไม่อนุมัติ"
              onClick={notApproveAuditHandler}
            />
            <CustomizedButton
              variant="contained"
              title="อนุมัติ"
              onClick={approveAuditHandler}
            />
          </Box>
        );
      case "not_approve_audit":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="ส่ง"
              onClick={newEstimationHandler}
            />
          </Box>
        );
      case "audited":
        return (
          <CustomizedButton
            variant="contained"
            title="ดำเนินการต่อ"
            onClick={queuingHandler}
          />
        );
      case "queuing":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="outlined"
              title="ไม่อนุมัติ"
              onClick={notApproveQueueHandler}
            />
            <CustomizedButton
              variant="contained"
              title="อนุมัติ"
              onClick={approveQueueHandler}
            />
          </Box>
        );
      case "not_approve_queue":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="ส่ง"
              onClick={queuingHandler}
            />
          </Box>
        );
      case "assigning":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="ดำเนินการต่อ"
              onClick={assigningHandler}
            />
          </Box>
        );
      case "work_in_progress":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="ดำเนินการต่อ"
              onClick={workInProgressHandler}
            />
          </Box>
        );
      case "reviewing":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="outlined"
              title="ไม่อนุมัติ"
              onClick={notApproveReviewHandler}
            />
            <CustomizedButton
              variant="contained"
              title="อนุมัติ"
              onClick={approveReviewHandler}
            />
          </Box>
        );
      case "not_approve_review":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="ส่ง"
              onClick={workInProgressHandler}
            />
          </Box>
        );
      case "closed":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              variant="contained"
              title="บันทึก"
              onClick={closedHandler}
            />
          </Box>
        );
      default:
        return;
    }
  };

  const copyEstimateHandler = () => {
    navigate("/engineer/estimate/add", {
      state: { ...estimate, isCopied: true },
    });
    enqueueSnackbar("คัดลอกสำเร็จ", {
      variant: "success",
    });
  };

  const cancelEstimateHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(
          "cancelled",
          status,
          payload,
          enqueueSnackbar,
          user,
          estimate
        )
      );
    }
    setCancelConfirmation(false);
  };

  const editClickHandler = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const saveEditHandler = async () => {
    const payload = await formatPayload();
    if (payload) {
      dispatch(
        updateEstimate(status, status, payload, enqueueSnackbar, user, estimate)
      );
      setEditMode(false);
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (errors) {
      const {
        is_reproduction,
        is_installation,
        is_adjustment,
        engineer_data,
        input_attachments,
        job_project_type,
        job_description,
      } = errors;
      if (is_reproduction || is_installation || is_adjustment) {
        enqueueSnackbar(is_installation.message, {
          variant: "error",
        });
      }
      if (job_project_type) {
        enqueueSnackbar(job_project_type.message, {
          variant: "error",
        });
      }
      if (job_description) {
        enqueueSnackbar(job_description.message, {
          variant: "error",
        });
      }
      if (input_attachments) {
        enqueueSnackbar(input_attachments.message, {
          variant: "error",
        });
      }
      if (
        engineer_data &&
        engineer_data.length > 0 &&
        engineer_data[0].category_list &&
        !Array.isArray(engineer_data[0].category_list)
      ) {
        enqueueSnackbar(engineer_data[0].category_list.message, {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <form>
        <EstimateHeaderForm
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          copyEstimateHandler={copyEstimateHandler}
          cancelEstimateHandler={cancelEstimateHandler}
          cancelConfirmation={cancelConfirmation}
          setCancelConfirmation={setCancelConfirmation}
          editClickHandler={editClickHandler}
        />
        <PreliminaryInfoForm
          control={control}
          errors={errors}
          disabled={disabled}
        />
        <CustomerDescriptionForm
          ref={customerRef}
          mainControl={control}
          control={customerDescControl}
          onSubmitContact={submitContactForm}
          setValue={setCustomerDescValue}
          getValues={getCustomerDescValues}
          getMainValues={getValues}
          errors={customerDescErrors}
          contactUseForm={contactUseForm}
          viewOnly={disabled}
        />
        <InstallationForm
          control={control}
          errors={errors}
          disabled={disabled}
        />
        <InputAttachmentForm
          control={control}
          disabled={disabled}
          getValues={getValues}
          setValue={setValue}
        />
        <DeliverAttachmentForm
          control={control}
          disabled={disabled}
          getValues={getValues}
          setValue={setValue}
        />
        <EstimateItemTable
          control={control}
          errors={errors}
          disabled={disabled}
        />
        <RemarkForm control={control} errors={errors} disabled={disabled} />
        {editMode ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={() => navigate("/engineer/estimate")}
              disabled={isLoading.estimate}
            />
            <CustomizedButton
              variant="contained"
              title="บันทึก"
              onClick={saveEditHandler}
              disabled={isLoading.estimate}
            />
          </Box>
        ) : (
          renderButton()
        )}
      </form>
    </>
  );
};

export default EstimateContainer;
